/* tslint:disable */
/* eslint-disable */
/**
 * Digimuth.GwExperts.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccountingAttachmentTypeCreateDto
 */
export interface AccountingAttachmentTypeCreateDto {
    /**
     * 
     * @type {number}
     * @memberof AccountingAttachmentTypeCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountingAttachmentTypeCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AccountingAttachmentTypeCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountingAttachmentTypeCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccountingAttachmentTypeCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccountingAttachmentTypeCreateDto
     */
    name?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountingAttachmentTypeCreateDto
     */
    attachmentTypes?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface AccountingAttachmentTypeDetailsDto
 */
export interface AccountingAttachmentTypeDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof AccountingAttachmentTypeDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountingAttachmentTypeDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AccountingAttachmentTypeDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountingAttachmentTypeDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccountingAttachmentTypeDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccountingAttachmentTypeDetailsDto
     */
    name?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountingAttachmentTypeDetailsDto
     */
    attachmentTypes?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface AccountingAttachmentTypeDto
 */
export interface AccountingAttachmentTypeDto {
    /**
     * 
     * @type {number}
     * @memberof AccountingAttachmentTypeDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountingAttachmentTypeDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AccountingAttachmentTypeDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountingAttachmentTypeDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccountingAttachmentTypeDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccountingAttachmentTypeDto
     */
    name?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountingAttachmentTypeDto
     */
    attachmentTypes?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface AccountingAttachmentTypeUpdateDto
 */
export interface AccountingAttachmentTypeUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof AccountingAttachmentTypeUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountingAttachmentTypeUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AccountingAttachmentTypeUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountingAttachmentTypeUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccountingAttachmentTypeUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccountingAttachmentTypeUpdateDto
     */
    name?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountingAttachmentTypeUpdateDto
     */
    attachmentTypes?: Array<string> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AccountingBooksType {
    Kpir = 'Kpir',
    Kp = 'Kp',
    Pk = 'Pk',
    R = 'R',
    Agriculture = 'Agriculture'
}

/**
 * 
 * @export
 * @interface AccountingPotentialDto
 */
export interface AccountingPotentialDto {
    /**
     * 
     * @type {string}
     * @memberof AccountingPotentialDto
     */
    vatId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountingPotentialDto
     */
    pkd?: string | null;
    /**
     * 
     * @type {LegalFormOfBusiness}
     * @memberof AccountingPotentialDto
     */
    legalForm?: LegalFormOfBusiness;
    /**
     * 
     * @type {TaxationForm}
     * @memberof AccountingPotentialDto
     */
    taxationMethod?: TaxationForm;
    /**
     * 
     * @type {string}
     * @memberof AccountingPotentialDto
     */
    estimatedDocumentsQuantity?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccountingPotentialDto
     */
    foreignInvoices?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AccountingPotentialDto
     */
    numberOfEmployees?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccountingPotentialDto
     */
    vatRegistered?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AccountingPotentialDto
     */
    yearlyRevenue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountingPotentialDto
     */
    yearlyCosts?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountingPotentialDto
     */
    other?: string | null;
}
/**
 * 
 * @export
 * @interface AccountingTypeCreateDto
 */
export interface AccountingTypeCreateDto {
    /**
     * 
     * @type {number}
     * @memberof AccountingTypeCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountingTypeCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AccountingTypeCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountingTypeCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccountingTypeCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccountingTypeCreateDto
     */
    name?: string | null;
    /**
     * 
     * @type {Array<DefaultSummaryFieldDefinitionDto>}
     * @memberof AccountingTypeCreateDto
     */
    defaultSummaryFieldsNames?: Array<DefaultSummaryFieldDefinitionDto> | null;
}
/**
 * 
 * @export
 * @interface AccountingTypeDetailsDto
 */
export interface AccountingTypeDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof AccountingTypeDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountingTypeDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AccountingTypeDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountingTypeDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccountingTypeDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccountingTypeDetailsDto
     */
    name?: string | null;
    /**
     * 
     * @type {Array<DefaultSummaryFieldDefinitionDto>}
     * @memberof AccountingTypeDetailsDto
     */
    defaultSummaryFieldsNames?: Array<DefaultSummaryFieldDefinitionDto> | null;
}
/**
 * 
 * @export
 * @interface AccountingTypeDto
 */
export interface AccountingTypeDto {
    /**
     * 
     * @type {number}
     * @memberof AccountingTypeDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountingTypeDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AccountingTypeDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountingTypeDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccountingTypeDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccountingTypeDto
     */
    name?: string | null;
    /**
     * 
     * @type {Array<DefaultSummaryFieldDefinitionDto>}
     * @memberof AccountingTypeDto
     */
    defaultSummaryFieldsNames?: Array<DefaultSummaryFieldDefinitionDto> | null;
}
/**
 * 
 * @export
 * @interface AccountingTypeUpdateDto
 */
export interface AccountingTypeUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof AccountingTypeUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountingTypeUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AccountingTypeUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountingTypeUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccountingTypeUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AccountingTypeUpdateDto
     */
    name?: string | null;
    /**
     * 
     * @type {Array<DefaultSummaryFieldDefinitionDto>}
     * @memberof AccountingTypeUpdateDto
     */
    defaultSummaryFieldsNames?: Array<DefaultSummaryFieldDefinitionDto> | null;
}
/**
 * 
 * @export
 * @interface AddressDto
 */
export interface AddressDto {
    /**
     * 
     * @type {number}
     * @memberof AddressDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof AddressDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddressDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    street?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    houseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    flatNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    city?: string | null;
}
/**
 * 
 * @export
 * @interface AmbasadorRankingDto
 */
export interface AmbasadorRankingDto {
    /**
     * 
     * @type {number}
     * @memberof AmbasadorRankingDto
     */
    income?: number;
    /**
     * 
     * @type {number}
     * @memberof AmbasadorRankingDto
     */
    lead?: number;
    /**
     * 
     * @type {number}
     * @memberof AmbasadorRankingDto
     */
    leader?: number;
    /**
     * 
     * @type {number}
     * @memberof AmbasadorRankingDto
     */
    expert?: number;
    /**
     * 
     * @type {number}
     * @memberof AmbasadorRankingDto
     */
    fixedCosts?: number;
    /**
     * 
     * @type {number}
     * @memberof AmbasadorRankingDto
     */
    ebitda?: number;
    /**
     * 
     * @type {number}
     * @memberof AmbasadorRankingDto
     */
    id?: number;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof AmbasadorRankingDto
     */
    user?: ApplicationUserMinimalDto;
}
/**
 * 
 * @export
 * @interface ApplicationUserActivationDto
 */
export interface ApplicationUserActivationDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActivationDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActivationDto
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActivationDto
     */
    token?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserActivationDto
     */
    acceptedTermsAndConditions?: boolean;
}
/**
 * 
 * @export
 * @interface ApplicationUserChangePasswordDto
 */
export interface ApplicationUserChangePasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserChangePasswordDto
     */
    currentPassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserChangePasswordDto
     */
    newPassword?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserDetailsDto
 */
export interface ApplicationUserDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDetailsDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {Array<ApplicationUserRole>}
     * @memberof ApplicationUserDetailsDto
     */
    roles?: Array<ApplicationUserRole> | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    companyName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    companyLogoId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    birthDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    pesel?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    patronId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof ApplicationUserDetailsDto
     */
    patron?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {Array<number>}
     * @memberof ApplicationUserDetailsDto
     */
    departments?: Array<number> | null;
    /**
     * 
     * @type {AddressDto}
     * @memberof ApplicationUserDetailsDto
     */
    addressOfResidence?: AddressDto;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDetailsDto
     */
    mailingAddressDifferentThanResidence?: boolean;
    /**
     * 
     * @type {AddressDto}
     * @memberof ApplicationUserDetailsDto
     */
    mailingAddress?: AddressDto;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    profileImageId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    expertOwnerId?: number | null;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof ApplicationUserDetailsDto
     */
    expertOwner?: ApplicationUserDto;
    /**
     * 
     * @type {ProfessionDto}
     * @memberof ApplicationUserDetailsDto
     */
    profession?: ProfessionDto;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    managerTitleId?: number | null;
    /**
     * 
     * @type {ManagerTitleDto}
     * @memberof ApplicationUserDetailsDto
     */
    managerTitle?: ManagerTitleDto;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    gwExpertsCooperationBeginDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDetailsDto
     */
    isFirstLoginDataFilled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDetailsDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {ClientSize}
     * @memberof ApplicationUserDetailsDto
     */
    clientSize?: ClientSize;
    /**
     * 
     * @type {ApplicationUserStatus}
     * @memberof ApplicationUserDetailsDto
     */
    status?: ApplicationUserStatus;
    /**
     * 
     * @type {Array<OrganisationUserDto>}
     * @memberof ApplicationUserDetailsDto
     */
    organisationUsers?: Array<OrganisationUserDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDetailsDto
     */
    hasSubscription?: boolean;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof ApplicationUserDetailsDto
     */
    createdBy?: ApplicationUserMinimalDto;
}
/**
 * 
 * @export
 * @interface ApplicationUserDto
 */
export interface ApplicationUserDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    companyName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    companyLogoId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    profileImageId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    expertOwnerId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {ProfessionDto}
     * @memberof ApplicationUserDto
     */
    profession?: ProfessionDto;
    /**
     * 
     * @type {ClientSize}
     * @memberof ApplicationUserDto
     */
    clientSize?: ClientSize;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    managerTitleId?: number | null;
    /**
     * 
     * @type {ManagerTitleDto}
     * @memberof ApplicationUserDto
     */
    managerTitle?: ManagerTitleDto;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    gwExpertsCooperationBeginDate?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ApplicationUserDto
     */
    departments?: Array<number> | null;
    /**
     * 
     * @type {ApplicationUserStatus}
     * @memberof ApplicationUserDto
     */
    status?: ApplicationUserStatus;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ApplicationUserDto
     */
    caseProductIds?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDto
     */
    hasSubscription?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ApplicationUserDto
     */
    managerIds?: Array<number> | null;
    /**
     * 
     * @type {Array<ApplicationUserRole>}
     * @memberof ApplicationUserDto
     */
    roles?: Array<ApplicationUserRole> | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserForExportDto
 */
export interface ApplicationUserForExportDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserForExportDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserForExportDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserForExportDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserForExportDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserForExportDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserForExportDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserForExportDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserForExportDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserForExportDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserForExportDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserForExportDto
     */
    companyName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserForExportDto
     */
    companyLogoId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserForExportDto
     */
    profileImageId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserForExportDto
     */
    expertOwnerId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserForExportDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {ProfessionDto}
     * @memberof ApplicationUserForExportDto
     */
    profession?: ProfessionDto;
    /**
     * 
     * @type {ClientSize}
     * @memberof ApplicationUserForExportDto
     */
    clientSize?: ClientSize;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserForExportDto
     */
    managerTitleId?: number | null;
    /**
     * 
     * @type {ManagerTitleDto}
     * @memberof ApplicationUserForExportDto
     */
    managerTitle?: ManagerTitleDto;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserForExportDto
     */
    gwExpertsCooperationBeginDate?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ApplicationUserForExportDto
     */
    departments?: Array<number> | null;
    /**
     * 
     * @type {ApplicationUserStatus}
     * @memberof ApplicationUserForExportDto
     */
    status?: ApplicationUserStatus;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserForExportDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ApplicationUserForExportDto
     */
    caseProductIds?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserForExportDto
     */
    hasSubscription?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ApplicationUserForExportDto
     */
    managerIds?: Array<number> | null;
    /**
     * 
     * @type {Array<ApplicationUserRole>}
     * @memberof ApplicationUserForExportDto
     */
    roles?: Array<ApplicationUserRole> | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserForExportDto
     */
    clientPotential?: string | null;
    /**
     * 
     * @type {ClientPotentialMessageDto}
     * @memberof ApplicationUserForExportDto
     */
    potentialMessage?: ClientPotentialMessageDto;
    /**
     * 
     * @type {Array<CaseMinimalDto>}
     * @memberof ApplicationUserForExportDto
     */
    latestCasesInProgress?: Array<CaseMinimalDto> | null;
    /**
     * 
     * @type {SubscriptionCaseDto}
     * @memberof ApplicationUserForExportDto
     */
    subscription?: SubscriptionCaseDto;
    /**
     * 
     * @type {Array<SubscriptionCaseDto>}
     * @memberof ApplicationUserForExportDto
     */
    meetings?: Array<SubscriptionCaseDto> | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserMinimalDto
 */
export interface ApplicationUserMinimalDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserMinimalDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserMinimalDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserMinimalDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserMinimalDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserMinimalDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserMinimalDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserMinimalDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserMinimalDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserMinimalDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserMinimalDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserMinimalDto
     */
    profileImageId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserMinimalDto
     */
    hasSubscription?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserMinimalDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserMinimalDto
     */
    hasSavedCardInfo?: boolean;
}
/**
 * 
 * @export
 * @interface ApplicationUserNameDto
 */
export interface ApplicationUserNameDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserNameDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserNameDto
     */
    lastName?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserRegisterDto
 */
export interface ApplicationUserRegisterDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserRegisterDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    companyName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    companyLogoId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    birthDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    pesel?: string | null;
    /**
     * 
     * @type {AddressDto}
     * @memberof ApplicationUserRegisterDto
     */
    addressOfResidence?: AddressDto;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserRegisterDto
     */
    mailingAddressDifferentThanResidence?: boolean;
    /**
     * 
     * @type {AddressDto}
     * @memberof ApplicationUserRegisterDto
     */
    mailingAddress?: AddressDto;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    profileImageId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    professionId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    expertOwnerId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    companyId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    managerTitleId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    gwExpertsCooperationBeginDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserRegisterDto
     */
    isFirstLoginDataFilled?: boolean;
    /**
     * 
     * @type {ClientSize}
     * @memberof ApplicationUserRegisterDto
     */
    clientSize?: ClientSize;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    leadId?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ApplicationUserRole {
    Admin = 'Admin',
    Patron = 'Patron',
    Client = 'Client',
    ExpertEmployee = 'ExpertEmployee',
    ExpertOwner = 'ExpertOwner',
    Manager = 'Manager',
    Ambassador = 'Ambassador'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApplicationUserStatus {
    Active = 'Active',
    Registered = 'Registered',
    RegisteredNotConfirmed = 'RegisteredNotConfirmed',
    NotConfirmed = 'NotConfirmed',
    Deleted = 'Deleted'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum AttachmentType {
    Other = 'Other',
    Agreement = 'Agreement',
    Invoice = 'Invoice'
}

/**
 * 
 * @export
 * @interface BasicInfoDto
 */
export interface BasicInfoDto {
    /**
     * 
     * @type {string}
     * @memberof BasicInfoDto
     */
    vatId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicInfoDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicInfoDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicInfoDto
     */
    bankAccount?: string | null;
}
/**
 * 
 * @export
 * @interface CalendarEventApplicationUserLinkUpdateDto
 */
export interface CalendarEventApplicationUserLinkUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof CalendarEventApplicationUserLinkUpdateDto
     */
    calendarEventId?: number;
    /**
     * 
     * @type {CalendarEventDto}
     * @memberof CalendarEventApplicationUserLinkUpdateDto
     */
    calendarEvent?: CalendarEventDto;
    /**
     * 
     * @type {CalendarReminderTime}
     * @memberof CalendarEventApplicationUserLinkUpdateDto
     */
    reminder?: CalendarReminderTime;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarEventApplicationUserLinkUpdateDto
     */
    sendNotification?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarEventApplicationUserLinkUpdateDto
     */
    isObserver?: boolean;
}
/**
 * 
 * @export
 * @interface CalendarEventApplicationUserLinkWithOrganisationIdDto
 */
export interface CalendarEventApplicationUserLinkWithOrganisationIdDto {
    /**
     * 
     * @type {number}
     * @memberof CalendarEventApplicationUserLinkWithOrganisationIdDto
     */
    calendarEventId?: number;
    /**
     * 
     * @type {CalendarEventDto}
     * @memberof CalendarEventApplicationUserLinkWithOrganisationIdDto
     */
    calendarEvent?: CalendarEventDto;
    /**
     * 
     * @type {CalendarReminderTime}
     * @memberof CalendarEventApplicationUserLinkWithOrganisationIdDto
     */
    reminder?: CalendarReminderTime;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarEventApplicationUserLinkWithOrganisationIdDto
     */
    sendNotification?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarEventApplicationUserLinkWithOrganisationIdDto
     */
    isObserver?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventApplicationUserLinkWithOrganisationIdDto
     */
    organisationId?: number;
}
/**
 * 
 * @export
 * @interface CalendarEventCreateDto
 */
export interface CalendarEventCreateDto {
    /**
     * 
     * @type {number}
     * @memberof CalendarEventCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventCreateDto
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventCreateDto
     */
    endTime?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarEventCreateDto
     */
    allDay?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventCreateDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventCreateDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarEventCreateDto
     */
    sendNotification?: boolean;
    /**
     * 
     * @type {CalendarReminderTime}
     * @memberof CalendarEventCreateDto
     */
    reminder?: CalendarReminderTime;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventCreateDto
     */
    userId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventCreateDto
     */
    caseId?: number | null;
    /**
     * 
     * @type {CaseMinimalWithManagersDto}
     * @memberof CalendarEventCreateDto
     */
    _case?: CaseMinimalWithManagersDto;
    /**
     * 
     * @type {Array<number>}
     * @memberof CalendarEventCreateDto
     */
    applicationUsersIds?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarEventCreateDto
     */
    isVisibleToClientAndExpert?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventCreateDto
     */
    linkedCaseField?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CalendarEventCreateDto
     */
    accessUsersIds?: Array<number> | null;
    /**
     * 
     * @type {RecurringEventDefinitionDto}
     * @memberof CalendarEventCreateDto
     */
    recurringDefinition?: RecurringEventDefinitionDto;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventCreateDto
     */
    parentId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarEventCreateDto
     */
    isRecurring?: boolean;
}
/**
 * 
 * @export
 * @interface CalendarEventDetailsDto
 */
export interface CalendarEventDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof CalendarEventDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventDetailsDto
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventDetailsDto
     */
    endTime?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarEventDetailsDto
     */
    allDay?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventDetailsDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventDetailsDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarEventDetailsDto
     */
    sendNotification?: boolean;
    /**
     * 
     * @type {CalendarReminderTime}
     * @memberof CalendarEventDetailsDto
     */
    reminder?: CalendarReminderTime;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventDetailsDto
     */
    userId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventDetailsDto
     */
    caseId?: number | null;
    /**
     * 
     * @type {CaseMinimalWithManagersDto}
     * @memberof CalendarEventDetailsDto
     */
    _case?: CaseMinimalWithManagersDto;
    /**
     * 
     * @type {Array<number>}
     * @memberof CalendarEventDetailsDto
     */
    applicationUsersIds?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarEventDetailsDto
     */
    isVisibleToClientAndExpert?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventDetailsDto
     */
    linkedCaseField?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CalendarEventDetailsDto
     */
    accessUsersIds?: Array<number> | null;
    /**
     * 
     * @type {RecurringEventDefinitionDto}
     * @memberof CalendarEventDetailsDto
     */
    recurringDefinition?: RecurringEventDefinitionDto;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventDetailsDto
     */
    parentId?: number | null;
}
/**
 * 
 * @export
 * @interface CalendarEventDto
 */
export interface CalendarEventDto {
    /**
     * 
     * @type {number}
     * @memberof CalendarEventDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventDto
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventDto
     */
    endTime?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarEventDto
     */
    allDay?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarEventDto
     */
    sendNotification?: boolean;
    /**
     * 
     * @type {CalendarReminderTime}
     * @memberof CalendarEventDto
     */
    reminder?: CalendarReminderTime;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventDto
     */
    userId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventDto
     */
    caseId?: number | null;
    /**
     * 
     * @type {CaseMinimalWithManagersDto}
     * @memberof CalendarEventDto
     */
    _case?: CaseMinimalWithManagersDto;
    /**
     * 
     * @type {Array<number>}
     * @memberof CalendarEventDto
     */
    applicationUsersIds?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarEventDto
     */
    isVisibleToClientAndExpert?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventDto
     */
    linkedCaseField?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CalendarEventDto
     */
    accessUsersIds?: Array<number> | null;
    /**
     * 
     * @type {RecurringEventDefinitionDto}
     * @memberof CalendarEventDto
     */
    recurringDefinition?: RecurringEventDefinitionDto;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventDto
     */
    parentId?: number | null;
}
/**
 * 
 * @export
 * @interface CalendarEventUpdateDto
 */
export interface CalendarEventUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof CalendarEventUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventUpdateDto
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventUpdateDto
     */
    endTime?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarEventUpdateDto
     */
    allDay?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventUpdateDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventUpdateDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarEventUpdateDto
     */
    sendNotification?: boolean;
    /**
     * 
     * @type {CalendarReminderTime}
     * @memberof CalendarEventUpdateDto
     */
    reminder?: CalendarReminderTime;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventUpdateDto
     */
    userId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventUpdateDto
     */
    caseId?: number | null;
    /**
     * 
     * @type {CaseMinimalWithManagersDto}
     * @memberof CalendarEventUpdateDto
     */
    _case?: CaseMinimalWithManagersDto;
    /**
     * 
     * @type {Array<number>}
     * @memberof CalendarEventUpdateDto
     */
    applicationUsersIds?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarEventUpdateDto
     */
    isVisibleToClientAndExpert?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventUpdateDto
     */
    linkedCaseField?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CalendarEventUpdateDto
     */
    accessUsersIds?: Array<number> | null;
    /**
     * 
     * @type {RecurringEventDefinitionDto}
     * @memberof CalendarEventUpdateDto
     */
    recurringDefinition?: RecurringEventDefinitionDto;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventUpdateDto
     */
    parentId?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CalendarReminderTime {
    None = 'None',
    FifteenMinutes = 'FifteenMinutes',
    OneHour = 'OneHour',
    TwoHours = 'TwoHours',
    FiveHours = 'FiveHours',
    TwentyFourHours = 'TwentyFourHours',
    TwoDays = 'TwoDays',
    ThreeDays = 'ThreeDays',
    FiveDays = 'FiveDays',
    Week = 'Week'
}

/**
 * 
 * @export
 * @interface Card
 */
export interface Card {
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    number?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Card
     */
    expirationMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof Card
     */
    expirationYear?: number;
}
/**
 * 
 * @export
 * @interface CardToken
 */
export interface CardToken {
    /**
     * 
     * @type {string}
     * @memberof CardToken
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CardToken
     */
    brandImageUrl?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CardToken
     */
    preferred?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CardToken
     */
    status?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CardToken
     */
    cardExpirationMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof CardToken
     */
    cardExpirationYear?: number;
    /**
     * 
     * @type {string}
     * @memberof CardToken
     */
    cardNumberMasked?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CardToken
     */
    cardScheme?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CardToken
     */
    cardBrand?: string | null;
}
/**
 * 
 * @export
 * @interface CaseAttachmentDetailsDto
 */
export interface CaseAttachmentDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof CaseAttachmentDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseAttachmentDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CaseAttachmentDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseAttachmentDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseAttachmentDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CaseAttachmentDetailsDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseAttachmentDetailsDto
     */
    contentType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseAttachmentDetailsDto
     */
    description?: string | null;
    /**
     * 
     * @type {AttachmentType}
     * @memberof CaseAttachmentDetailsDto
     */
    type?: AttachmentType;
    /**
     * 
     * @type {boolean}
     * @memberof CaseAttachmentDetailsDto
     */
    isRead?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CaseAttachmentDetailsDto
     */
    casePeriodAttachmentType?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CaseAttachmentDetailsDto
     */
    paid?: boolean;
}
/**
 * 
 * @export
 * @interface CaseAttachmentDto
 */
export interface CaseAttachmentDto {
    /**
     * 
     * @type {number}
     * @memberof CaseAttachmentDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseAttachmentDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CaseAttachmentDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseAttachmentDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseAttachmentDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CaseAttachmentDto
     */
    description?: string | null;
    /**
     * 
     * @type {AttachmentType}
     * @memberof CaseAttachmentDto
     */
    type?: AttachmentType;
    /**
     * 
     * @type {string}
     * @memberof CaseAttachmentDto
     */
    casePeriodAttachmentType?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CaseAttachmentDto
     */
    paid?: boolean;
}
/**
 * 
 * @export
 * @interface CaseAttachmentUpdateDto
 */
export interface CaseAttachmentUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof CaseAttachmentUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseAttachmentUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CaseAttachmentUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseAttachmentUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseAttachmentUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CaseAttachmentUpdateDto
     */
    caseId?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseAttachmentUpdateDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CaseAttachmentUpdateDto
     */
    paid?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CaseAttachmentUpdateDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {AttachmentType}
     * @memberof CaseAttachmentUpdateDto
     */
    type?: AttachmentType;
    /**
     * 
     * @type {string}
     * @memberof CaseAttachmentUpdateDto
     */
    casePeriodAttachmentType?: string | null;
}
/**
 * 
 * @export
 * @interface CaseAttachmentUpdatePaidStatusDto
 */
export interface CaseAttachmentUpdatePaidStatusDto {
    /**
     * 
     * @type {boolean}
     * @memberof CaseAttachmentUpdatePaidStatusDto
     */
    paid?: boolean;
}
/**
 * 
 * @export
 * @interface CaseAttachmentUpdateTypeDto
 */
export interface CaseAttachmentUpdateTypeDto {
    /**
     * 
     * @type {string}
     * @memberof CaseAttachmentUpdateTypeDto
     */
    type?: string | null;
}
/**
 * 
 * @export
 * @interface CaseCreateDto
 */
export interface CaseCreateDto {
    /**
     * 
     * @type {number}
     * @memberof CaseCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CaseCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CaseCreateDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseCreateDto
     */
    productId?: number;
    /**
     * 
     * @type {CaseStatus}
     * @memberof CaseCreateDto
     */
    status?: CaseStatus;
    /**
     * 
     * @type {number}
     * @memberof CaseCreateDto
     */
    clientId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CaseCreateDto
     */
    managersIds?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof CaseCreateDto
     */
    expertId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CaseCreateDto
     */
    beginDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseCreateDto
     */
    description?: string | null;
    /**
     * 
     * @type {PricingPolicyDto}
     * @memberof CaseCreateDto
     */
    pricingPolicy?: PricingPolicyDto;
    /**
     * 
     * @type {string}
     * @memberof CaseCreateDto
     */
    clientCompany?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseCreateDto
     */
    agreementId?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CaseCreateDto
     */
    clientAttachmentsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CaseCreateDto
     */
    expertAttachmentsIds?: Array<number> | null;
    /**
     * 
     * @type {LawsuitCaseDto}
     * @memberof CaseCreateDto
     */
    lawsuitDetails?: LawsuitCaseDto;
    /**
     * 
     * @type {MeetingCaseDto}
     * @memberof CaseCreateDto
     */
    meetingDetails?: MeetingCaseDto;
    /**
     * 
     * @type {string}
     * @memberof CaseCreateDto
     */
    deadline?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseCreateDto
     */
    milestoneValues?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseCreateDto
     */
    accountingTypeId?: number | null;
}
/**
 * 
 * @export
 * @interface CaseDetailsDto
 */
export interface CaseDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof CaseDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CaseDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CaseDetailsDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseDetailsDto
     */
    productId?: number;
    /**
     * 
     * @type {CaseStatus}
     * @memberof CaseDetailsDto
     */
    status?: CaseStatus;
    /**
     * 
     * @type {number}
     * @memberof CaseDetailsDto
     */
    clientId: number;
    /**
     * 
     * @type {number}
     * @memberof CaseDetailsDto
     */
    expertId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CaseDetailsDto
     */
    beginDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDetailsDto
     */
    clientCompany?: string | null;
    /**
     * 
     * @type {ClientMinimalDto}
     * @memberof CaseDetailsDto
     */
    client?: ClientMinimalDto;
    /**
     * 
     * @type {ExpertEmployeeDetailsDto}
     * @memberof CaseDetailsDto
     */
    expert?: ExpertEmployeeDetailsDto;
    /**
     * 
     * @type {Array<ManagerMinimalDto>}
     * @memberof CaseDetailsDto
     */
    managers?: Array<ManagerMinimalDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CaseDetailsDto
     */
    managerLeaderId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CaseDetailsDto
     */
    leadId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof CaseDetailsDto
     */
    lead?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof CaseDetailsDto
     */
    salesId?: number | null;
    /**
     * 
     * @type {ManagerMinimalDto}
     * @memberof CaseDetailsDto
     */
    sales?: ManagerMinimalDto;
    /**
     * 
     * @type {string}
     * @memberof CaseDetailsDto
     */
    deadline?: string | null;
    /**
     * 
     * @type {CaseType}
     * @memberof CaseDetailsDto
     */
    caseTypeOld?: CaseType;
    /**
     * 
     * @type {ProductMinimalDto}
     * @memberof CaseDetailsDto
     */
    product?: ProductMinimalDto;
    /**
     * 
     * @type {string}
     * @memberof CaseDetailsDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseDetailsDto
     */
    agreementId?: number | null;
    /**
     * 
     * @type {CaseAttachmentDetailsDto}
     * @memberof CaseDetailsDto
     */
    agreement?: CaseAttachmentDetailsDto;
    /**
     * 
     * @type {PricingPolicyDto}
     * @memberof CaseDetailsDto
     */
    pricingPolicy?: PricingPolicyDto;
    /**
     * 
     * @type {CompanyDetailsDto}
     * @memberof CaseDetailsDto
     */
    companyDetails?: CompanyDetailsDto;
    /**
     * 
     * @type {Array<number>}
     * @memberof CaseDetailsDto
     */
    clientAttachmentsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<CaseAttachmentDetailsDto>}
     * @memberof CaseDetailsDto
     */
    clientAttachments?: Array<CaseAttachmentDetailsDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CaseDetailsDto
     */
    expertAttachmentsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<CaseAttachmentDetailsDto>}
     * @memberof CaseDetailsDto
     */
    expertAttachments?: Array<CaseAttachmentDetailsDto> | null;
    /**
     * 
     * @type {LawsuitCaseDto}
     * @memberof CaseDetailsDto
     */
    lawsuitDetails?: LawsuitCaseDto;
    /**
     * 
     * @type {MeetingCaseDto}
     * @memberof CaseDetailsDto
     */
    meetingDetails?: MeetingCaseDto;
    /**
     * 
     * @type {Array<CasePeriodDetailsDto>}
     * @memberof CaseDetailsDto
     */
    casePeriodDetails?: Array<CasePeriodDetailsDto> | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof CaseDetailsDto
     */
    createdBy?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {string}
     * @memberof CaseDetailsDto
     */
    milestoneValues?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseDetailsDto
     */
    accountingTypeId?: number | null;
    /**
     * 
     * @type {AccountingTypeDto}
     * @memberof CaseDetailsDto
     */
    accountingType?: AccountingTypeDto;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof CaseDetailsDto
     */
    milestoneValuesDictionary?: { [key: string]: any; } | null;
    /**
     * 
     * @type {SubscriptionDetailsDto}
     * @memberof CaseDetailsDto
     */
    subscription?: SubscriptionDetailsDto;
    /**
     * 
     * @type {number}
     * @memberof CaseDetailsDto
     */
    marketingCampaignId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CaseDetailsDto
     */
    leadSourceId?: number | null;
    /**
     * 
     * @type {Array<CaseMeetingDto>}
     * @memberof CaseDetailsDto
     */
    meetings?: Array<CaseMeetingDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CaseDetailsDto
     */
    accountingAttachmentTypeId?: number | null;
    /**
     * 
     * @type {AccountingAttachmentTypeDto}
     * @memberof CaseDetailsDto
     */
    accountingAttachmentType?: AccountingAttachmentTypeDto;
    /**
     * 
     * @type {boolean}
     * @memberof CaseDetailsDto
     */
    isVatPayer?: boolean | null;
}
/**
 * 
 * @export
 * @interface CaseDto
 */
export interface CaseDto {
    /**
     * 
     * @type {number}
     * @memberof CaseDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CaseDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CaseDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseDto
     */
    productId?: number;
    /**
     * 
     * @type {CaseStatus}
     * @memberof CaseDto
     */
    status?: CaseStatus;
    /**
     * 
     * @type {number}
     * @memberof CaseDto
     */
    clientId: number;
    /**
     * 
     * @type {number}
     * @memberof CaseDto
     */
    expertId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CaseDto
     */
    beginDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseDto
     */
    deadline?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseDto
     */
    clientCompany?: string | null;
    /**
     * 
     * @type {ClientMinimalDto}
     * @memberof CaseDto
     */
    client?: ClientMinimalDto;
    /**
     * 
     * @type {ExpertEmployeeMinimalDto}
     * @memberof CaseDto
     */
    expert?: ExpertEmployeeMinimalDto;
    /**
     * 
     * @type {Array<ManagerMinimalDto>}
     * @memberof CaseDto
     */
    managers?: Array<ManagerMinimalDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CaseDto
     */
    managerLeaderId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CaseDto
     */
    leadId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof CaseDto
     */
    lead?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof CaseDto
     */
    salesId?: number | null;
    /**
     * 
     * @type {ManagerMinimalDto}
     * @memberof CaseDto
     */
    sales?: ManagerMinimalDto;
    /**
     * 
     * @type {string}
     * @memberof CaseDto
     */
    milestoneValues?: string | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof CaseDto
     */
    milestoneValuesDictionary?: { [key: string]: any; } | null;
}
/**
 * 
 * @export
 * @interface CaseGeneralInfoUpdateDto
 */
export interface CaseGeneralInfoUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof CaseGeneralInfoUpdateDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseGeneralInfoUpdateDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseGeneralInfoUpdateDto
     */
    beginDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseGeneralInfoUpdateDto
     */
    deadline?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseGeneralInfoUpdateDto
     */
    marketingCampaignId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CaseGeneralInfoUpdateDto
     */
    leadSourceId?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CaseHistoryValues {
    Type = 'Type',
    Status = 'Status',
    Expert = 'Expert',
    CompanyName = 'CompanyName',
    Description = 'Description',
    CasePeriodStatus = 'CasePeriodStatus',
    AttachmentDescription = 'AttachmentDescription',
    Agreement = 'Agreement',
    ClientAttachment = 'ClientAttachment',
    ExpertAttachment = 'ExpertAttachment',
    PeriodClientAttachment = 'PeriodClientAttachment',
    PeriodExpertAttachment = 'PeriodExpertAttachment',
    BeginDate = 'BeginDate',
    Name = 'Name',
    DeadLine = 'DeadLine',
    Plaintiff = 'Plaintiff',
    Defendant = 'Defendant',
    Value = 'Value',
    FirstInstance = 'FirstInstance',
    FirstInstanceJudgeName = 'FirstInstanceJudgeName',
    FirstInstanceCourt = 'FirstInstanceCourt',
    FirstInstanceSignature = 'FirstInstanceSignature',
    SecondInstance = 'SecondInstance',
    SecondInstanceJudgeName = 'SecondInstanceJudgeName',
    SecondInstanceCourt = 'SecondInstanceCourt',
    SecondInstanceSignature = 'SecondInstanceSignature',
    Cassation = 'Cassation',
    CassationJudgeName = 'CassationJudgeName',
    CassationCourt = 'CassationCourt',
    CassationSignature = 'CassationSignature',
    RegisterRemoval = 'RegisterRemoval',
    BikRemoval = 'BikRemoval',
    Vat = 'Vat',
    CorporateTax = 'CorporateTax',
    PersonalTax = 'PersonalTax',
    Insurance = 'Insurance',
    Other = 'Other',
    Message = 'Message',
    CaseCreated = 'CaseCreated',
    Manager = 'Manager',
    Product = 'Product',
    Milestone = 'Milestone',
    Leader = 'Leader',
    SummaryField = 'SummaryField',
    AccountingType = 'AccountingType',
    Subscription = 'Subscription',
    CalendarEvent = 'CalendarEvent',
    Lead = 'Lead',
    UserCreated = 'UserCreated',
    AttachmentPaidStatus = 'AttachmentPaidStatus',
    Sales = 'Sales'
}

/**
 * 
 * @export
 * @interface CaseMeetingDto
 */
export interface CaseMeetingDto {
    /**
     * 
     * @type {number}
     * @memberof CaseMeetingDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseMeetingDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CaseMeetingDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseMeetingDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseMeetingDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CaseMeetingDto
     */
    startTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseMeetingDto
     */
    endTime?: string | null;
}
/**
 * 
 * @export
 * @interface CaseMessageCreateDto
 */
export interface CaseMessageCreateDto {
    /**
     * 
     * @type {number}
     * @memberof CaseMessageCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseMessageCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CaseMessageCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseMessageCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseMessageCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CaseMessageCreateDto
     */
    content?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseMessageCreateDto
     */
    caseId?: number;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof CaseMessageCreateDto
     */
    createdBy?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {Array<CaseMessageRecipientDto>}
     * @memberof CaseMessageCreateDto
     */
    recipients?: Array<CaseMessageRecipientDto> | null;
}
/**
 * 
 * @export
 * @interface CaseMessageDetailsDto
 */
export interface CaseMessageDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof CaseMessageDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseMessageDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CaseMessageDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseMessageDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseMessageDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CaseMessageDetailsDto
     */
    content?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseMessageDetailsDto
     */
    caseId?: number;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof CaseMessageDetailsDto
     */
    createdBy?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {Array<CaseMessageRecipientDto>}
     * @memberof CaseMessageDetailsDto
     */
    recipients?: Array<CaseMessageRecipientDto> | null;
    /**
     * 
     * @type {string}
     * @memberof CaseMessageDetailsDto
     */
    caseName?: string | null;
}
/**
 * 
 * @export
 * @interface CaseMessageDto
 */
export interface CaseMessageDto {
    /**
     * 
     * @type {number}
     * @memberof CaseMessageDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseMessageDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CaseMessageDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseMessageDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseMessageDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CaseMessageDto
     */
    content?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseMessageDto
     */
    caseId?: number;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof CaseMessageDto
     */
    createdBy?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {Array<CaseMessageRecipientDto>}
     * @memberof CaseMessageDto
     */
    recipients?: Array<CaseMessageRecipientDto> | null;
}
/**
 * 
 * @export
 * @interface CaseMessageRecipientDto
 */
export interface CaseMessageRecipientDto {
    /**
     * 
     * @type {CaseMessageRecipients}
     * @memberof CaseMessageRecipientDto
     */
    type?: CaseMessageRecipients;
    /**
     * 
     * @type {number}
     * @memberof CaseMessageRecipientDto
     */
    recipientId?: number | null;
    /**
     * 
     * @type {ManagerMinimalDto}
     * @memberof CaseMessageRecipientDto
     */
    recipient?: ManagerMinimalDto;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CaseMessageRecipients {
    Client = 'Client',
    Patron = 'Patron',
    Expert = 'Expert',
    Manager = 'Manager'
}

/**
 * 
 * @export
 * @interface CaseMessageUpdateDto
 */
export interface CaseMessageUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof CaseMessageUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseMessageUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CaseMessageUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseMessageUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseMessageUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CaseMessageUpdateDto
     */
    content?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseMessageUpdateDto
     */
    caseId?: number;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof CaseMessageUpdateDto
     */
    createdBy?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {Array<CaseMessageRecipientDto>}
     * @memberof CaseMessageUpdateDto
     */
    recipients?: Array<CaseMessageRecipientDto> | null;
}
/**
 * 
 * @export
 * @interface CaseMinimalDto
 */
export interface CaseMinimalDto {
    /**
     * 
     * @type {number}
     * @memberof CaseMinimalDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseMinimalDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CaseMinimalDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseMinimalDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseMinimalDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CaseMinimalDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseMinimalDto
     */
    productId?: number;
    /**
     * 
     * @type {CaseStatus}
     * @memberof CaseMinimalDto
     */
    status?: CaseStatus;
    /**
     * 
     * @type {number}
     * @memberof CaseMinimalDto
     */
    clientId?: number;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof CaseMinimalDto
     */
    client?: ApplicationUserMinimalDto;
}
/**
 * 
 * @export
 * @interface CaseMinimalWithManagersDto
 */
export interface CaseMinimalWithManagersDto {
    /**
     * 
     * @type {number}
     * @memberof CaseMinimalWithManagersDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseMinimalWithManagersDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CaseMinimalWithManagersDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseMinimalWithManagersDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseMinimalWithManagersDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CaseMinimalWithManagersDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseMinimalWithManagersDto
     */
    productId?: number;
    /**
     * 
     * @type {CaseStatus}
     * @memberof CaseMinimalWithManagersDto
     */
    status?: CaseStatus;
    /**
     * 
     * @type {number}
     * @memberof CaseMinimalWithManagersDto
     */
    clientId?: number;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof CaseMinimalWithManagersDto
     */
    client?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {Array<number>}
     * @memberof CaseMinimalWithManagersDto
     */
    managersIds?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface CasePeriodDetailsDto
 */
export interface CasePeriodDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof CasePeriodDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CasePeriodDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CasePeriodDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CasePeriodDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CasePeriodDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<CasePeriodSummaryFieldDto>}
     * @memberof CasePeriodDetailsDto
     */
    summaryFields?: Array<CasePeriodSummaryFieldDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CasePeriodDetailsDto
     */
    caseId?: number;
    /**
     * 
     * @type {number}
     * @memberof CasePeriodDetailsDto
     */
    month?: number;
    /**
     * 
     * @type {number}
     * @memberof CasePeriodDetailsDto
     */
    year?: number;
    /**
     * 
     * @type {CasePeriodStatus}
     * @memberof CasePeriodDetailsDto
     */
    status?: CasePeriodStatus;
    /**
     * 
     * @type {Array<number>}
     * @memberof CasePeriodDetailsDto
     */
    clientAttachmentsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<CaseAttachmentDetailsDto>}
     * @memberof CasePeriodDetailsDto
     */
    clientAttachments?: Array<CaseAttachmentDetailsDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CasePeriodDetailsDto
     */
    expertAttachmentsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<CaseAttachmentDetailsDto>}
     * @memberof CasePeriodDetailsDto
     */
    expertAttachments?: Array<CaseAttachmentDetailsDto> | null;
}
/**
 * 
 * @export
 * @interface CasePeriodDetailsMinimalDto
 */
export interface CasePeriodDetailsMinimalDto {
    /**
     * 
     * @type {number}
     * @memberof CasePeriodDetailsMinimalDto
     */
    month?: number;
    /**
     * 
     * @type {number}
     * @memberof CasePeriodDetailsMinimalDto
     */
    year?: number;
}
/**
 * 
 * @export
 * @interface CasePeriodNewsDetailsDto
 */
export interface CasePeriodNewsDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof CasePeriodNewsDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CasePeriodNewsDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CasePeriodNewsDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CasePeriodNewsDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CasePeriodNewsDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {Array<CasePeriodSummaryFieldDto>}
     * @memberof CasePeriodNewsDetailsDto
     */
    summaryFields?: Array<CasePeriodSummaryFieldDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CasePeriodNewsDetailsDto
     */
    caseId?: number;
    /**
     * 
     * @type {number}
     * @memberof CasePeriodNewsDetailsDto
     */
    month?: number;
    /**
     * 
     * @type {number}
     * @memberof CasePeriodNewsDetailsDto
     */
    year?: number;
    /**
     * 
     * @type {CasePeriodStatus}
     * @memberof CasePeriodNewsDetailsDto
     */
    status?: CasePeriodStatus;
    /**
     * 
     * @type {Array<number>}
     * @memberof CasePeriodNewsDetailsDto
     */
    clientAttachmentsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<CaseAttachmentDetailsDto>}
     * @memberof CasePeriodNewsDetailsDto
     */
    clientAttachments?: Array<CaseAttachmentDetailsDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CasePeriodNewsDetailsDto
     */
    expertAttachmentsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<CaseAttachmentDetailsDto>}
     * @memberof CasePeriodNewsDetailsDto
     */
    expertAttachments?: Array<CaseAttachmentDetailsDto> | null;
    /**
     * 
     * @type {CaseMinimalDto}
     * @memberof CasePeriodNewsDetailsDto
     */
    _case?: CaseMinimalDto;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CasePeriodStatus {
    NotStarted = 'NotStarted',
    InProgress = 'InProgress',
    Done = 'Done',
    ReadyForAccounting = 'ReadyForAccounting'
}

/**
 * 
 * @export
 * @interface CasePeriodSummaryFieldDto
 */
export interface CasePeriodSummaryFieldDto {
    /**
     * 
     * @type {number}
     * @memberof CasePeriodSummaryFieldDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CasePeriodSummaryFieldDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CasePeriodSummaryFieldDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CasePeriodSummaryFieldDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CasePeriodSummaryFieldDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CasePeriodSummaryFieldDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CasePeriodSummaryFieldDto
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CasePeriodSummaryFieldDto
     */
    paymentDueDate?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CasePeriodSummaryFieldDto
     */
    paid?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CasePeriodSummaryFieldDto
     */
    accountNumberForTransfer?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CasePeriodSummaryFieldDto
     */
    casePeriodDetailsId?: number;
    /**
     * 
     * @type {string}
     * @memberof CasePeriodSummaryFieldDto
     */
    paymentIdentifier?: string | null;
}
/**
 * 
 * @export
 * @interface CasePeriodSummaryFieldUpdateDto
 */
export interface CasePeriodSummaryFieldUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof CasePeriodSummaryFieldUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CasePeriodSummaryFieldUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CasePeriodSummaryFieldUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CasePeriodSummaryFieldUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CasePeriodSummaryFieldUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CasePeriodSummaryFieldUpdateDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CasePeriodSummaryFieldUpdateDto
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CasePeriodSummaryFieldUpdateDto
     */
    paymentDueDate?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CasePeriodSummaryFieldUpdateDto
     */
    paid?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CasePeriodSummaryFieldUpdateDto
     */
    accountNumberForTransfer?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CasePeriodSummaryFieldUpdateDto
     */
    casePeriodDetailsId?: number;
    /**
     * 
     * @type {string}
     * @memberof CasePeriodSummaryFieldUpdateDto
     */
    paymentIdentifier?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CaseStatus {
    New = 'New',
    InProgress = 'InProgress',
    Done = 'Done',
    Offering = 'Offering',
    Rejected = 'Rejected'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum CaseType {
    Other = 'Other',
    ForeignCurrencyLoan = 'ForeignCurrencyLoan',
    LawsuitCivil = 'LawsuitCivil',
    PeriodicOther = 'PeriodicOther',
    Accounting = 'Accounting',
    LiquidationFee = 'LiquidationFee',
    Unww = 'Unww',
    Recruitment = 'Recruitment',
    Insurance = 'Insurance',
    ForeignCurrencyLoanDefense = 'ForeignCurrencyLoanDefense',
    ForeignCurrencyLoanCassation = 'ForeignCurrencyLoanCassation',
    ForeignCurrencyLoanMediation = 'ForeignCurrencyLoanMediation',
    ForeignCurrencyLoanSettlement = 'ForeignCurrencyLoanSettlement',
    LawsuitCriminal = 'LawsuitCriminal',
    LegalAssistance = 'LegalAssistance',
    Leasing = 'Leasing',
    Loan = 'Loan',
    Consultation = 'Consultation',
    Marketing = 'Marketing',
    Amazon = 'Amazon',
    GweStock = 'GweStock',
    Investment = 'Investment',
    It = 'IT',
    Divorce = 'Divorce',
    DebtCollection = 'DebtCollection'
}

/**
 * 
 * @export
 * @interface CaseUpdateDto
 */
export interface CaseUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof CaseUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CaseUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CaseUpdateDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseUpdateDto
     */
    productId?: number;
    /**
     * 
     * @type {ProductDto}
     * @memberof CaseUpdateDto
     */
    product?: ProductDto;
    /**
     * 
     * @type {CaseStatus}
     * @memberof CaseUpdateDto
     */
    status?: CaseStatus;
    /**
     * 
     * @type {number}
     * @memberof CaseUpdateDto
     */
    clientId: number;
    /**
     * 
     * @type {number}
     * @memberof CaseUpdateDto
     */
    expertId?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CaseUpdateDto
     */
    managersIds?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof CaseUpdateDto
     */
    beginDate?: string;
    /**
     * 
     * @type {ExpertEmployeeMinimalDto}
     * @memberof CaseUpdateDto
     */
    expert?: ExpertEmployeeMinimalDto;
    /**
     * 
     * @type {string}
     * @memberof CaseUpdateDto
     */
    deadline?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseUpdateDto
     */
    description?: string | null;
    /**
     * 
     * @type {PricingPolicyDto}
     * @memberof CaseUpdateDto
     */
    pricingPolicy?: PricingPolicyDto;
    /**
     * 
     * @type {number}
     * @memberof CaseUpdateDto
     */
    agreementId?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CaseUpdateDto
     */
    clientAttachmentsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CaseUpdateDto
     */
    expertAttachmentsIds?: Array<number> | null;
    /**
     * 
     * @type {LawsuitCaseDto}
     * @memberof CaseUpdateDto
     */
    lawsuitDetails?: LawsuitCaseDto;
    /**
     * 
     * @type {MeetingCaseDto}
     * @memberof CaseUpdateDto
     */
    meetingDetails?: MeetingCaseDto;
    /**
     * 
     * @type {string}
     * @memberof CaseUpdateDto
     */
    clientCompany?: string | null;
    /**
     * 
     * @type {CompanyDetailsDto}
     * @memberof CaseUpdateDto
     */
    companyDetails?: CompanyDetailsDto;
    /**
     * 
     * @type {string}
     * @memberof CaseUpdateDto
     */
    milestoneValues?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseUpdateDto
     */
    managerLeaderId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CaseUpdateDto
     */
    leadId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CaseUpdateDto
     */
    salesId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CaseUpdateDto
     */
    accountingTypeId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CaseUpdateDto
     */
    subscriptionId?: number | null;
    /**
     * 
     * @type {SubscriptionDto}
     * @memberof CaseUpdateDto
     */
    subscription?: SubscriptionDto;
    /**
     * 
     * @type {number}
     * @memberof CaseUpdateDto
     */
    marketingCampaignId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CaseUpdateDto
     */
    leadSourceId?: number | null;
    /**
     * 
     * @type {Array<CaseMeetingDto>}
     * @memberof CaseUpdateDto
     */
    meetings?: Array<CaseMeetingDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CaseUpdateDto
     */
    accountingAttachmentTypeId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CaseUpdateDto
     */
    isVatPayer?: boolean | null;
}
/**
 * 
 * @export
 * @interface CaseUpdateHistoryDetailsDto
 */
export interface CaseUpdateHistoryDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof CaseUpdateHistoryDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseUpdateHistoryDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CaseUpdateHistoryDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseUpdateHistoryDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseUpdateHistoryDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CaseUpdateHistoryDetailsDto
     */
    caseId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CaseUpdateHistoryDetailsDto
     */
    casePeriodDetailsId?: number | null;
    /**
     * 
     * @type {CaseHistoryValues}
     * @memberof CaseUpdateHistoryDetailsDto
     */
    changedValueName?: CaseHistoryValues;
    /**
     * 
     * @type {string}
     * @memberof CaseUpdateHistoryDetailsDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseUpdateHistoryDetailsDto
     */
    newValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseUpdateHistoryDetailsDto
     */
    milestoneName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseUpdateHistoryDetailsDto
     */
    updatedAttachmentFileName?: string | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof CaseUpdateHistoryDetailsDto
     */
    createdBy?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {CasePeriodDetailsMinimalDto}
     * @memberof CaseUpdateHistoryDetailsDto
     */
    casePeriodDetails?: CasePeriodDetailsMinimalDto;
    /**
     * 
     * @type {Array<CaseMessageRecipientDto>}
     * @memberof CaseUpdateHistoryDetailsDto
     */
    recipients?: Array<CaseMessageRecipientDto> | null;
    /**
     * 
     * @type {string}
     * @memberof CaseUpdateHistoryDetailsDto
     */
    caseName?: string | null;
}
/**
 * 
 * @export
 * @interface CaseUpdateHistoryDto
 */
export interface CaseUpdateHistoryDto {
    /**
     * 
     * @type {number}
     * @memberof CaseUpdateHistoryDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseUpdateHistoryDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CaseUpdateHistoryDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseUpdateHistoryDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseUpdateHistoryDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CaseUpdateHistoryDto
     */
    caseId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CaseUpdateHistoryDto
     */
    casePeriodDetailsId?: number | null;
    /**
     * 
     * @type {CaseHistoryValues}
     * @memberof CaseUpdateHistoryDto
     */
    changedValueName?: CaseHistoryValues;
    /**
     * 
     * @type {string}
     * @memberof CaseUpdateHistoryDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseUpdateHistoryDto
     */
    newValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseUpdateHistoryDto
     */
    milestoneName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseUpdateHistoryDto
     */
    updatedAttachmentFileName?: string | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof CaseUpdateHistoryDto
     */
    createdBy?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {CasePeriodDetailsMinimalDto}
     * @memberof CaseUpdateHistoryDto
     */
    casePeriodDetails?: CasePeriodDetailsMinimalDto;
    /**
     * 
     * @type {Array<CaseMessageRecipientDto>}
     * @memberof CaseUpdateHistoryDto
     */
    recipients?: Array<CaseMessageRecipientDto> | null;
}
/**
 * 
 * @export
 * @interface CaseWithoutMilestonesDto
 */
export interface CaseWithoutMilestonesDto {
    /**
     * 
     * @type {number}
     * @memberof CaseWithoutMilestonesDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseWithoutMilestonesDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CaseWithoutMilestonesDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CaseWithoutMilestonesDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseWithoutMilestonesDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CaseWithoutMilestonesDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseWithoutMilestonesDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CaseWithoutMilestonesDto
     */
    productId?: number;
    /**
     * 
     * @type {CaseStatus}
     * @memberof CaseWithoutMilestonesDto
     */
    status?: CaseStatus;
    /**
     * 
     * @type {number}
     * @memberof CaseWithoutMilestonesDto
     */
    clientId: number;
    /**
     * 
     * @type {number}
     * @memberof CaseWithoutMilestonesDto
     */
    expertId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CaseWithoutMilestonesDto
     */
    beginDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseWithoutMilestonesDto
     */
    deadline?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseWithoutMilestonesDto
     */
    clientCompany?: string | null;
    /**
     * 
     * @type {ClientMinimalDto}
     * @memberof CaseWithoutMilestonesDto
     */
    client?: ClientMinimalDto;
    /**
     * 
     * @type {ExpertEmployeeMinimalDto}
     * @memberof CaseWithoutMilestonesDto
     */
    expert?: ExpertEmployeeMinimalDto;
    /**
     * 
     * @type {Array<ManagerMinimalDto>}
     * @memberof CaseWithoutMilestonesDto
     */
    managers?: Array<ManagerMinimalDto> | null;
    /**
     * 
     * @type {number}
     * @memberof CaseWithoutMilestonesDto
     */
    managerLeaderId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CaseWithoutMilestonesDto
     */
    leadId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof CaseWithoutMilestonesDto
     */
    lead?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof CaseWithoutMilestonesDto
     */
    salesId?: number | null;
    /**
     * 
     * @type {ManagerMinimalDto}
     * @memberof CaseWithoutMilestonesDto
     */
    sales?: ManagerMinimalDto;
}
/**
 * 
 * @export
 * @interface ChangeEmailDto
 */
export interface ChangeEmailDto {
    /**
     * 
     * @type {string}
     * @memberof ChangeEmailDto
     */
    email?: string | null;
}
/**
 * 
 * @export
 * @interface ChangePatronDto
 */
export interface ChangePatronDto {
    /**
     * 
     * @type {number}
     * @memberof ChangePatronDto
     */
    patronId?: number | null;
}
/**
 * 
 * @export
 * @interface ClientInfoDto
 */
export interface ClientInfoDto {
    /**
     * 
     * @type {ClientNumber}
     * @memberof ClientInfoDto
     */
    number?: ClientNumber;
}
/**
 * 
 * @export
 * @interface ClientMinimalDto
 */
export interface ClientMinimalDto {
    /**
     * 
     * @type {number}
     * @memberof ClientMinimalDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientMinimalDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ClientMinimalDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientMinimalDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClientMinimalDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClientMinimalDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientMinimalDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClientMinimalDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClientMinimalDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientMinimalDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClientMinimalDto
     */
    profileImageId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClientMinimalDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClientMinimalDto
     */
    hasSavedCardInfo?: boolean;
    /**
     * 
     * @type {Array<ApplicationUserRole>}
     * @memberof ClientMinimalDto
     */
    roles?: Array<ApplicationUserRole> | null;
    /**
     * 
     * @type {string}
     * @memberof ClientMinimalDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {ProfessionDto}
     * @memberof ClientMinimalDto
     */
    profession?: ProfessionDto;
    /**
     * 
     * @type {string}
     * @memberof ClientMinimalDto
     */
    gwExpertsCooperationBeginDate?: string;
    /**
     * 
     * @type {number}
     * @memberof ClientMinimalDto
     */
    patronId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClientMinimalDto
     */
    hasSubscription?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ClientNumber {
    One = 'One',
    Between2And3 = 'Between2And3',
    Between4And5 = 'Between4And5',
    Between6And9 = 'Between6And9',
    Between10And50 = 'Between10And50',
    Over50 = 'Over50'
}

/**
 * 
 * @export
 * @interface ClientPotentialMessageDto
 */
export interface ClientPotentialMessageDto {
    /**
     * 
     * @type {number}
     * @memberof ClientPotentialMessageDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientPotentialMessageDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ClientPotentialMessageDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientPotentialMessageDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClientPotentialMessageDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClientPotentialMessageDto
     */
    content?: string | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof ClientPotentialMessageDto
     */
    createdBy?: ApplicationUserMinimalDto;
}
/**
 * 
 * @export
 * @interface ClientPotentialResultDto
 */
export interface ClientPotentialResultDto {
    /**
     * 
     * @type {number}
     * @memberof ClientPotentialResultDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientPotentialResultDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ClientPotentialResultDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientPotentialResultDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClientPotentialResultDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClientPotentialResultDto
     */
    clientPotential?: string | null;
    /**
     * 
     * @type {Array<ClientPotentialMessageDto>}
     * @memberof ClientPotentialResultDto
     */
    potentialMessages?: Array<ClientPotentialMessageDto> | null;
}
/**
 * 
 * @export
 * @interface ClientRankingDto
 */
export interface ClientRankingDto {
    /**
     * 
     * @type {number}
     * @memberof ClientRankingDto
     */
    income?: number;
    /**
     * 
     * @type {number}
     * @memberof ClientRankingDto
     */
    lead?: number;
    /**
     * 
     * @type {number}
     * @memberof ClientRankingDto
     */
    leader?: number;
    /**
     * 
     * @type {number}
     * @memberof ClientRankingDto
     */
    expert?: number;
    /**
     * 
     * @type {number}
     * @memberof ClientRankingDto
     */
    fixedCosts?: number;
    /**
     * 
     * @type {number}
     * @memberof ClientRankingDto
     */
    ebitda?: number;
    /**
     * 
     * @type {number}
     * @memberof ClientRankingDto
     */
    id?: number;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof ClientRankingDto
     */
    user?: ApplicationUserMinimalDto;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ClientSize {
    IndividualEmployee = 'IndividualEmployee',
    IndividualManager = 'IndividualManager',
    IndividualRetiree = 'IndividualRetiree',
    SelfEmployed = 'SelfEmployed',
    MicroEntrepreneur = 'MicroEntrepreneur',
    SmallEntrepreneur = 'SmallEntrepreneur',
    MediumEntrepreneur = 'MediumEntrepreneur',
    LargeEntrepreneur = 'LargeEntrepreneur'
}

/**
 * 
 * @export
 * @interface CompanyDetailsDto
 */
export interface CompanyDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyDetailsDto
     */
    vatId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDetailsDto
     */
    pesel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDetailsDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDetailsDto
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDetailsDto
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDetailsDto
     */
    street?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDetailsDto
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDetailsDto
     */
    buildingNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDetailsDto
     */
    apartmentNumber?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyInfoDto
 */
export interface CompanyInfoDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyInfoDto
     */
    businessActivityType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyInfoDto
     */
    actualActivityType?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyInfoDto
     */
    usesCash?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyInfoDto
     */
    storesAssets?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyInfoDto
     */
    paymentServices?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyInfoDto
     */
    presentOnStockMarket?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyInfoDto
     */
    stockOwnedByThirdParties?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyInfoDto
     */
    shareholderFromBlacklistedCountries?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyInfoDto
     */
    clientCountries?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyInfoDto
     */
    businessActivityCountries?: Array<string> | null;
    /**
     * 
     * @type {LeasingAccountingType}
     * @memberof CompanyInfoDto
     */
    leasingAccountingType?: LeasingAccountingType;
    /**
     * 
     * @type {number}
     * @memberof CompanyInfoDto
     */
    employeesCount?: number | null;
}
/**
 * 
 * @export
 * @interface ContactFormMessageDto
 */
export interface ContactFormMessageDto {
    /**
     * 
     * @type {string}
     * @memberof ContactFormMessageDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactFormMessageDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ContactFormMessageDto
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof ContactFormMessageDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof ContactFormMessageDto
     */
    ambassador?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DayOfWeek {
    Sunday = 'Sunday',
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday'
}

/**
 * 
 * @export
 * @interface DecisivePersonInfoDto
 */
export interface DecisivePersonInfoDto {
    /**
     * 
     * @type {number}
     * @memberof DecisivePersonInfoDto
     */
    experienceInYears?: number;
    /**
     * 
     * @type {EducationLevel}
     * @memberof DecisivePersonInfoDto
     */
    educationLevel?: EducationLevel;
}
/**
 * 
 * @export
 * @interface DefaultSummaryFieldDefinitionDto
 */
export interface DefaultSummaryFieldDefinitionDto {
    /**
     * 
     * @type {string}
     * @memberof DefaultSummaryFieldDefinitionDto
     */
    name?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DefaultSummaryFieldDefinitionDto
     */
    thresholds?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface DepartmentCreateDto
 */
export interface DepartmentCreateDto {
    /**
     * 
     * @type {number}
     * @memberof DepartmentCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DepartmentCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof DepartmentCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof DepartmentCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DepartmentCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DepartmentCreateDto
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface DepartmentDetailsDto
 */
export interface DepartmentDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof DepartmentDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof DepartmentDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DepartmentDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDetailsDto
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface DepartmentDto
 */
export interface DepartmentDto {
    /**
     * 
     * @type {number}
     * @memberof DepartmentDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof DepartmentDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DepartmentDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface DepartmentRankingDto
 */
export interface DepartmentRankingDto {
    /**
     * 
     * @type {number}
     * @memberof DepartmentRankingDto
     */
    income?: number;
    /**
     * 
     * @type {number}
     * @memberof DepartmentRankingDto
     */
    lead?: number;
    /**
     * 
     * @type {number}
     * @memberof DepartmentRankingDto
     */
    leader?: number;
    /**
     * 
     * @type {number}
     * @memberof DepartmentRankingDto
     */
    expert?: number;
    /**
     * 
     * @type {number}
     * @memberof DepartmentRankingDto
     */
    fixedCosts?: number;
    /**
     * 
     * @type {number}
     * @memberof DepartmentRankingDto
     */
    ebitda?: number;
    /**
     * 
     * @type {number}
     * @memberof DepartmentRankingDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof DepartmentRankingDto
     */
    departmentId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DepartmentRankingDto
     */
    department?: string | null;
}
/**
 * 
 * @export
 * @interface DepartmentUpdateDto
 */
export interface DepartmentUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof DepartmentUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DepartmentUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof DepartmentUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof DepartmentUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DepartmentUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DepartmentUpdateDto
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum EducationLevel {
    Elementary = 'Elementary',
    Vocational = 'Vocational',
    Secondary = 'Secondary',
    Bachelor = 'Bachelor',
    HigherEducation = 'HigherEducation'
}

/**
 * 
 * @export
 * @interface EndForgotPasswordDto
 */
export interface EndForgotPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof EndForgotPasswordDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EndForgotPasswordDto
     */
    token?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EndForgotPasswordDto
     */
    newPassword?: string | null;
}
/**
 * 
 * @export
 * @interface ExpertEmployeeDetailsDto
 */
export interface ExpertEmployeeDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ExpertEmployeeDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ExpertEmployeeDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ExpertEmployeeDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ExpertEmployeeDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExpertEmployeeDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ExpertEmployeeDetailsDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExpertEmployeeDetailsDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertEmployeeDetailsDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {Array<ApplicationUserRole>}
     * @memberof ExpertEmployeeDetailsDto
     */
    roles?: Array<ApplicationUserRole> | null;
    /**
     * 
     * @type {string}
     * @memberof ExpertEmployeeDetailsDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExpertEmployeeDetailsDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExpertEmployeeDetailsDto
     */
    companyName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExpertEmployeeDetailsDto
     */
    companyLogoId?: number | null;
    /**
     * 
     * @type {ManagerTitleDto}
     * @memberof ExpertEmployeeDetailsDto
     */
    managerTitle?: ManagerTitleDto;
    /**
     * 
     * @type {string}
     * @memberof ExpertEmployeeDetailsDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExpertEmployeeDetailsDto
     */
    profileImageId?: number | null;
    /**
     * 
     * @type {ProfessionDto}
     * @memberof ExpertEmployeeDetailsDto
     */
    profession?: ProfessionDto;
    /**
     * 
     * @type {string}
     * @memberof ExpertEmployeeDetailsDto
     */
    gwExpertsCooperationBeginDate?: string;
}
/**
 * 
 * @export
 * @interface ExpertEmployeeMinimalDto
 */
export interface ExpertEmployeeMinimalDto {
    /**
     * 
     * @type {number}
     * @memberof ExpertEmployeeMinimalDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ExpertEmployeeMinimalDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ExpertEmployeeMinimalDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ExpertEmployeeMinimalDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExpertEmployeeMinimalDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ExpertEmployeeMinimalDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExpertEmployeeMinimalDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertEmployeeMinimalDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExpertEmployeeMinimalDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExpertEmployeeMinimalDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExpertEmployeeMinimalDto
     */
    profileImageId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertEmployeeMinimalDto
     */
    hasSubscription?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertEmployeeMinimalDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExpertEmployeeMinimalDto
     */
    hasSavedCardInfo?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExpertEmployeeMinimalDto
     */
    companyName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExpertEmployeeMinimalDto
     */
    companyLogoId?: number | null;
}
/**
 * 
 * @export
 * @interface ExpertRankingDto
 */
export interface ExpertRankingDto {
    /**
     * 
     * @type {number}
     * @memberof ExpertRankingDto
     */
    income?: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertRankingDto
     */
    lead?: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertRankingDto
     */
    leader?: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertRankingDto
     */
    expert?: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertRankingDto
     */
    fixedCosts?: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertRankingDto
     */
    ebitda?: number;
    /**
     * 
     * @type {number}
     * @memberof ExpertRankingDto
     */
    id?: number;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof ExpertRankingDto
     */
    user?: ApplicationUserMinimalDto;
}
/**
 * 
 * @export
 * @interface FinancialInfoDto
 */
export interface FinancialInfoDto {
    /**
     * 
     * @type {AccountingBooksType}
     * @memberof FinancialInfoDto
     */
    previousYearAccountingBooksType?: AccountingBooksType;
    /**
     * 
     * @type {AccountingBooksType}
     * @memberof FinancialInfoDto
     */
    thisYearAccountingBooksType?: AccountingBooksType;
    /**
     * 
     * @type {number}
     * @memberof FinancialInfoDto
     */
    previousYear?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FinancialInfoDto
     */
    previousYearRevenue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FinancialInfoDto
     */
    previousYearIncome?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FinancialInfoDto
     */
    currentPeriod?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FinancialInfoDto
     */
    thisYearRevenue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FinancialInfoDto
     */
    thisYearIncome?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FontFamily {
    Arial = 'Arial',
    Verdana = 'Verdana',
    Tahoma = 'Tahoma',
    TrebuchetMs = 'TrebuchetMs',
    TimesNewRoman = 'TimesNewRoman',
    Georgia = 'Georgia',
    CourierNew = 'CourierNew',
    BrushScriptMs = 'BrushScriptMs',
    Impact = 'Impact'
}

/**
 * 
 * @export
 * @interface GwEmployeeCaseDto
 */
export interface GwEmployeeCaseDto {
    /**
     * 
     * @type {number}
     * @memberof GwEmployeeCaseDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GwEmployeeCaseDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof GwEmployeeCaseDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof GwEmployeeCaseDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GwEmployeeCaseDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GwEmployeeCaseDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GwEmployeeCaseDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GwEmployeeCaseDto
     */
    productId?: number;
    /**
     * 
     * @type {CaseStatus}
     * @memberof GwEmployeeCaseDto
     */
    status?: CaseStatus;
    /**
     * 
     * @type {number}
     * @memberof GwEmployeeCaseDto
     */
    clientId: number;
    /**
     * 
     * @type {number}
     * @memberof GwEmployeeCaseDto
     */
    expertId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GwEmployeeCaseDto
     */
    beginDate?: string;
    /**
     * 
     * @type {string}
     * @memberof GwEmployeeCaseDto
     */
    deadline?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GwEmployeeCaseDto
     */
    clientCompany?: string | null;
    /**
     * 
     * @type {ClientMinimalDto}
     * @memberof GwEmployeeCaseDto
     */
    client?: ClientMinimalDto;
    /**
     * 
     * @type {ExpertEmployeeMinimalDto}
     * @memberof GwEmployeeCaseDto
     */
    expert?: ExpertEmployeeMinimalDto;
    /**
     * 
     * @type {Array<ManagerMinimalDto>}
     * @memberof GwEmployeeCaseDto
     */
    managers?: Array<ManagerMinimalDto> | null;
    /**
     * 
     * @type {number}
     * @memberof GwEmployeeCaseDto
     */
    managerLeaderId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GwEmployeeCaseDto
     */
    leadId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof GwEmployeeCaseDto
     */
    lead?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof GwEmployeeCaseDto
     */
    salesId?: number | null;
    /**
     * 
     * @type {ManagerMinimalDto}
     * @memberof GwEmployeeCaseDto
     */
    sales?: ManagerMinimalDto;
    /**
     * 
     * @type {string}
     * @memberof GwEmployeeCaseDto
     */
    milestoneValues?: string | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof GwEmployeeCaseDto
     */
    milestoneValuesDictionary?: { [key: string]: any; } | null;
    /**
     * 
     * @type {number}
     * @memberof GwEmployeeCaseDto
     */
    amountDue?: number | null;
}
/**
 * 
 * @export
 * @interface InvoiceCreateDto
 */
export interface InvoiceCreateDto {
    /**
     * 
     * @type {number}
     * @memberof InvoiceCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {InvoiceType}
     * @memberof InvoiceCreateDto
     */
    type?: InvoiceType;
    /**
     * 
     * @type {number}
     * @memberof InvoiceCreateDto
     */
    caseId?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceCreateDto
     */
    parentInvoiceId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCreateDto
     */
    number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCreateDto
     */
    description?: string | null;
    /**
     * 
     * @type {CompanyDetailsDto}
     * @memberof InvoiceCreateDto
     */
    seller?: CompanyDetailsDto;
    /**
     * 
     * @type {CompanyDetailsDto}
     * @memberof InvoiceCreateDto
     */
    buyer?: CompanyDetailsDto;
    /**
     * 
     * @type {CompanyDetailsDto}
     * @memberof InvoiceCreateDto
     */
    recipient?: CompanyDetailsDto;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCreateDto
     */
    issueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCreateDto
     */
    soldDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCreateDto
     */
    paymentDueDate?: string | null;
    /**
     * 
     * @type {InvoiceStatus}
     * @memberof InvoiceCreateDto
     */
    status?: InvoiceStatus;
    /**
     * 
     * @type {number}
     * @memberof InvoiceCreateDto
     */
    grossValue?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceCreateDto
     */
    paid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceCreateDto
     */
    splitPayment?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceCreateDto
     */
    reverseCharge?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCreateDto
     */
    vatExemptionReason?: string | null;
    /**
     * 
     * @type {Array<InvoiceLineDto>}
     * @memberof InvoiceCreateDto
     */
    lines?: Array<InvoiceLineDto> | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCreateDto
     */
    numberScheme?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCreateDto
     */
    bank?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCreateDto
     */
    accountNumber?: string | null;
    /**
     * 
     * @type {Array<InvoicePaymentDto>}
     * @memberof InvoiceCreateDto
     */
    payments?: Array<InvoicePaymentDto> | null;
}
/**
 * 
 * @export
 * @interface InvoiceDetailsDto
 */
export interface InvoiceDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof InvoiceDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {InvoiceType}
     * @memberof InvoiceDetailsDto
     */
    type?: InvoiceType;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDetailsDto
     */
    caseId?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDetailsDto
     */
    parentInvoiceId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDetailsDto
     */
    number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDetailsDto
     */
    description?: string | null;
    /**
     * 
     * @type {CompanyDetailsDto}
     * @memberof InvoiceDetailsDto
     */
    seller?: CompanyDetailsDto;
    /**
     * 
     * @type {CompanyDetailsDto}
     * @memberof InvoiceDetailsDto
     */
    buyer?: CompanyDetailsDto;
    /**
     * 
     * @type {CompanyDetailsDto}
     * @memberof InvoiceDetailsDto
     */
    recipient?: CompanyDetailsDto;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDetailsDto
     */
    issueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDetailsDto
     */
    soldDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDetailsDto
     */
    paymentDueDate?: string | null;
    /**
     * 
     * @type {InvoiceStatus}
     * @memberof InvoiceDetailsDto
     */
    status?: InvoiceStatus;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDetailsDto
     */
    grossValue?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceDetailsDto
     */
    paid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceDetailsDto
     */
    splitPayment?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceDetailsDto
     */
    reverseCharge?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDetailsDto
     */
    vatExemptionReason?: string | null;
    /**
     * 
     * @type {Array<InvoiceLineDto>}
     * @memberof InvoiceDetailsDto
     */
    lines?: Array<InvoiceLineDto> | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDetailsDto
     */
    numberScheme?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDetailsDto
     */
    bank?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDetailsDto
     */
    accountNumber?: string | null;
    /**
     * 
     * @type {Array<InvoicePaymentDto>}
     * @memberof InvoiceDetailsDto
     */
    payments?: Array<InvoicePaymentDto> | null;
    /**
     * 
     * @type {InvoiceParentDto}
     * @memberof InvoiceDetailsDto
     */
    parentInvoice?: InvoiceParentDto;
}
/**
 * 
 * @export
 * @interface InvoiceDto
 */
export interface InvoiceDto {
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {InvoiceType}
     * @memberof InvoiceDto
     */
    type?: InvoiceType;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    caseId?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    parentInvoiceId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    description?: string | null;
    /**
     * 
     * @type {CompanyDetailsDto}
     * @memberof InvoiceDto
     */
    seller?: CompanyDetailsDto;
    /**
     * 
     * @type {CompanyDetailsDto}
     * @memberof InvoiceDto
     */
    buyer?: CompanyDetailsDto;
    /**
     * 
     * @type {CompanyDetailsDto}
     * @memberof InvoiceDto
     */
    recipient?: CompanyDetailsDto;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    issueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    soldDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    paymentDueDate?: string | null;
    /**
     * 
     * @type {InvoiceStatus}
     * @memberof InvoiceDto
     */
    status?: InvoiceStatus;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    grossValue?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceDto
     */
    paid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceDto
     */
    splitPayment?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceDto
     */
    reverseCharge?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    vatExemptionReason?: string | null;
}
/**
 * 
 * @export
 * @interface InvoiceInitialDto
 */
export interface InvoiceInitialDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof InvoiceInitialDto
     */
    availableSchemes?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceInitialDto
     */
    lastUsedScheme?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceInitialDto
     */
    bank?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceInitialDto
     */
    accountNumber?: string | null;
    /**
     * 
     * @type {Array<CompanyDetailsDto>}
     * @memberof InvoiceInitialDto
     */
    companies?: Array<CompanyDetailsDto> | null;
}
/**
 * 
 * @export
 * @interface InvoiceLineDto
 */
export interface InvoiceLineDto {
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    product?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineDto
     */
    unit?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    netUnitPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    netValue?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    vatValue?: number;
    /**
     * 
     * @type {VatRate}
     * @memberof InvoiceLineDto
     */
    vatRate?: VatRate;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineDto
     */
    grossValue?: number;
}
/**
 * 
 * @export
 * @interface InvoiceLogoDetailsDto
 */
export interface InvoiceLogoDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof InvoiceLogoDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLogoDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLogoDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLogoDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLogoDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLogoDetailsDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLogoDetailsDto
     */
    contentType?: string | null;
}
/**
 * 
 * @export
 * @interface InvoiceLogoDto
 */
export interface InvoiceLogoDto {
    /**
     * 
     * @type {number}
     * @memberof InvoiceLogoDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLogoDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLogoDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLogoDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLogoDto
     */
    updatedById?: number | null;
}
/**
 * 
 * @export
 * @interface InvoiceLogoUpdateDto
 */
export interface InvoiceLogoUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof InvoiceLogoUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLogoUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLogoUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLogoUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLogoUpdateDto
     */
    updatedById?: number | null;
}
/**
 * 
 * @export
 * @interface InvoiceNumberingDataRequestDto
 */
export interface InvoiceNumberingDataRequestDto {
    /**
     * 
     * @type {number}
     * @memberof InvoiceNumberingDataRequestDto
     */
    invoiceId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceNumberingDataRequestDto
     */
    caseId?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceNumberingDataRequestDto
     */
    numberingScheme?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceNumberingDataRequestDto
     */
    issueDate?: string;
}
/**
 * 
 * @export
 * @interface InvoiceNumberingDataResponseDto
 */
export interface InvoiceNumberingDataResponseDto {
    /**
     * 
     * @type {number}
     * @memberof InvoiceNumberingDataResponseDto
     */
    thisMonthCount?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceNumberingDataResponseDto
     */
    thisYearCount?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceNumberingDataResponseDto
     */
    year?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceNumberingDataResponseDto
     */
    month?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceNumberingDataResponseDto
     */
    invoiceNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceNumberingDataResponseDto
     */
    hasNewerDraftInThisScheme?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceNumberingDataResponseDto
     */
    hasNewerNonDraftInThisScheme?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceNumberingDataResponseDto
     */
    isDuplicate?: boolean;
}
/**
 * 
 * @export
 * @interface InvoiceParentDto
 */
export interface InvoiceParentDto {
    /**
     * 
     * @type {number}
     * @memberof InvoiceParentDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceParentDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceParentDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceParentDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceParentDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {InvoiceType}
     * @memberof InvoiceParentDto
     */
    type?: InvoiceType;
    /**
     * 
     * @type {number}
     * @memberof InvoiceParentDto
     */
    caseId?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceParentDto
     */
    parentInvoiceId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceParentDto
     */
    number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceParentDto
     */
    description?: string | null;
    /**
     * 
     * @type {CompanyDetailsDto}
     * @memberof InvoiceParentDto
     */
    seller?: CompanyDetailsDto;
    /**
     * 
     * @type {CompanyDetailsDto}
     * @memberof InvoiceParentDto
     */
    buyer?: CompanyDetailsDto;
    /**
     * 
     * @type {CompanyDetailsDto}
     * @memberof InvoiceParentDto
     */
    recipient?: CompanyDetailsDto;
    /**
     * 
     * @type {string}
     * @memberof InvoiceParentDto
     */
    issueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceParentDto
     */
    soldDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceParentDto
     */
    paymentDueDate?: string | null;
    /**
     * 
     * @type {InvoiceStatus}
     * @memberof InvoiceParentDto
     */
    status?: InvoiceStatus;
    /**
     * 
     * @type {number}
     * @memberof InvoiceParentDto
     */
    grossValue?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceParentDto
     */
    paid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceParentDto
     */
    splitPayment?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceParentDto
     */
    reverseCharge?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceParentDto
     */
    vatExemptionReason?: string | null;
    /**
     * 
     * @type {Array<InvoiceLineDto>}
     * @memberof InvoiceParentDto
     */
    lines?: Array<InvoiceLineDto> | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceParentDto
     */
    numberScheme?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceParentDto
     */
    bank?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceParentDto
     */
    accountNumber?: string | null;
    /**
     * 
     * @type {Array<InvoicePaymentDto>}
     * @memberof InvoiceParentDto
     */
    payments?: Array<InvoicePaymentDto> | null;
    /**
     * 
     * @type {InvoiceParentDto}
     * @memberof InvoiceParentDto
     */
    parentInvoice?: InvoiceParentDto;
}
/**
 * 
 * @export
 * @interface InvoicePaymentDto
 */
export interface InvoicePaymentDto {
    /**
     * 
     * @type {number}
     * @memberof InvoicePaymentDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoicePaymentDto
     */
    paymentDate?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoicePaymentDto
     */
    amount?: number;
}
/**
 * 
 * @export
 * @interface InvoicePrintoutSettingsDto
 */
export interface InvoicePrintoutSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof InvoicePrintoutSettingsDto
     */
    color?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InvoicePrintoutSettingsDto
     */
    invoiceLogoId?: number | null;
    /**
     * 
     * @type {FontFamily}
     * @memberof InvoicePrintoutSettingsDto
     */
    fontFamily?: FontFamily;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum InvoiceStatus {
    Draft = 'Draft',
    Published = 'Published',
    Accounted = 'Accounted'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum InvoiceType {
    Invoice = 'Invoice',
    CorrectionInvoice = 'CorrectionInvoice'
}

/**
 * 
 * @export
 * @interface InvoiceUpdateDto
 */
export interface InvoiceUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof InvoiceUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {InvoiceType}
     * @memberof InvoiceUpdateDto
     */
    type?: InvoiceType;
    /**
     * 
     * @type {number}
     * @memberof InvoiceUpdateDto
     */
    caseId?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceUpdateDto
     */
    parentInvoiceId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceUpdateDto
     */
    number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceUpdateDto
     */
    description?: string | null;
    /**
     * 
     * @type {CompanyDetailsDto}
     * @memberof InvoiceUpdateDto
     */
    seller?: CompanyDetailsDto;
    /**
     * 
     * @type {CompanyDetailsDto}
     * @memberof InvoiceUpdateDto
     */
    buyer?: CompanyDetailsDto;
    /**
     * 
     * @type {CompanyDetailsDto}
     * @memberof InvoiceUpdateDto
     */
    recipient?: CompanyDetailsDto;
    /**
     * 
     * @type {string}
     * @memberof InvoiceUpdateDto
     */
    issueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceUpdateDto
     */
    soldDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceUpdateDto
     */
    paymentDueDate?: string | null;
    /**
     * 
     * @type {InvoiceStatus}
     * @memberof InvoiceUpdateDto
     */
    status?: InvoiceStatus;
    /**
     * 
     * @type {number}
     * @memberof InvoiceUpdateDto
     */
    grossValue?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceUpdateDto
     */
    paid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceUpdateDto
     */
    splitPayment?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceUpdateDto
     */
    reverseCharge?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceUpdateDto
     */
    vatExemptionReason?: string | null;
    /**
     * 
     * @type {Array<InvoiceLineDto>}
     * @memberof InvoiceUpdateDto
     */
    lines?: Array<InvoiceLineDto> | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceUpdateDto
     */
    numberScheme?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceUpdateDto
     */
    bank?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceUpdateDto
     */
    accountNumber?: string | null;
}
/**
 * 
 * @export
 * @interface KpiAccessRuleDto
 */
export interface KpiAccessRuleDto {
    /**
     * 
     * @type {number}
     * @memberof KpiAccessRuleDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof KpiAccessRuleDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof KpiAccessRuleDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof KpiAccessRuleDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiAccessRuleDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {KpiType}
     * @memberof KpiAccessRuleDto
     */
    kpiType?: KpiType;
    /**
     * 
     * @type {Array<number>}
     * @memberof KpiAccessRuleDto
     */
    applicationUsersIds?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface KpiAllCasesResultDto
 */
export interface KpiAllCasesResultDto {
    /**
     * 
     * @type {number}
     * @memberof KpiAllCasesResultDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiAllCasesResultDto
     */
    productId?: number;
    /**
     * 
     * @type {string}
     * @memberof KpiAllCasesResultDto
     */
    name?: string | null;
    /**
     * 
     * @type {OfferDetailsDto}
     * @memberof KpiAllCasesResultDto
     */
    offer?: OfferDetailsDto;
    /**
     * 
     * @type {ProductMinimalWithDepartmentDto}
     * @memberof KpiAllCasesResultDto
     */
    product?: ProductMinimalWithDepartmentDto;
    /**
     * 
     * @type {number}
     * @memberof KpiAllCasesResultDto
     */
    clientId?: number;
    /**
     * 
     * @type {ClientMinimalDto}
     * @memberof KpiAllCasesResultDto
     */
    client?: ClientMinimalDto;
    /**
     * 
     * @type {string}
     * @memberof KpiAllCasesResultDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof KpiAllCasesResultDto
     */
    createdById?: number;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiAllCasesResultDto
     */
    createdBy?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {CaseStatus}
     * @memberof KpiAllCasesResultDto
     */
    status?: CaseStatus;
    /**
     * 
     * @type {number}
     * @memberof KpiAllCasesResultDto
     */
    leadId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiAllCasesResultDto
     */
    lead?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof KpiAllCasesResultDto
     */
    salesId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiAllCasesResultDto
     */
    sales?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {Array<ManagerMinimalDto>}
     * @memberof KpiAllCasesResultDto
     */
    managers?: Array<ManagerMinimalDto> | null;
    /**
     * 
     * @type {number}
     * @memberof KpiAllCasesResultDto
     */
    managerLeaderId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiAllCasesResultDto
     */
    managerLeader?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof KpiAllCasesResultDto
     */
    expertId?: number | null;
    /**
     * 
     * @type {ExpertEmployeeMinimalDto}
     * @memberof KpiAllCasesResultDto
     */
    expert?: ExpertEmployeeMinimalDto;
    /**
     * 
     * @type {string}
     * @memberof KpiAllCasesResultDto
     */
    rejectionReason?: string | null;
}
/**
 * 
 * @export
 * @interface KpiChfResultDto
 */
export interface KpiChfResultDto {
    /**
     * 
     * @type {number}
     * @memberof KpiChfResultDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiChfResultDto
     */
    clientId?: number;
    /**
     * 
     * @type {ClientMinimalDto}
     * @memberof KpiChfResultDto
     */
    client?: ClientMinimalDto;
    /**
     * 
     * @type {LawsuitCaseDto}
     * @memberof KpiChfResultDto
     */
    lawsuitDetails?: LawsuitCaseDto;
    /**
     * 
     * @type {string}
     * @memberof KpiChfResultDto
     */
    expectedEndDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KpiChfResultDto
     */
    milestoneValues?: string | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof KpiChfResultDto
     */
    milestoneValuesDictionary?: { [key: string]: any; } | null;
}
/**
 * 
 * @export
 * @interface KpiClientActivityResultDto
 */
export interface KpiClientActivityResultDto {
    /**
     * 
     * @type {number}
     * @memberof KpiClientActivityResultDto
     */
    id?: number;
    /**
     * 
     * @type {ClientMinimalDto}
     * @memberof KpiClientActivityResultDto
     */
    client?: ClientMinimalDto;
    /**
     * 
     * @type {string}
     * @memberof KpiClientActivityResultDto
     */
    createdCaseAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KpiClientActivityResultDto
     */
    caseUpdateAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KpiClientActivityResultDto
     */
    caseMessageAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KpiClientActivityResultDto
     */
    calendarEventAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KpiClientActivityResultDto
     */
    createdAt?: string | null;
}
/**
 * 
 * @export
 * @interface KpiComplianceResultDto
 */
export interface KpiComplianceResultDto {
    /**
     * 
     * @type {number}
     * @memberof KpiComplianceResultDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof KpiComplianceResultDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiComplianceResultDto
     */
    productId?: number;
    /**
     * 
     * @type {ProductDto}
     * @memberof KpiComplianceResultDto
     */
    product?: ProductDto;
    /**
     * 
     * @type {string}
     * @memberof KpiComplianceResultDto
     */
    createdAt?: string;
    /**
     * 
     * @type {CaseStatus}
     * @memberof KpiComplianceResultDto
     */
    status?: CaseStatus;
    /**
     * 
     * @type {string}
     * @memberof KpiComplianceResultDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiComplianceResultDto
     */
    managerLeaderId?: number | null;
    /**
     * 
     * @type {ManagerMinimalDto}
     * @memberof KpiComplianceResultDto
     */
    managerLeader?: ManagerMinimalDto;
}
/**
 * 
 * @export
 * @interface KpiEngineResultDto
 */
export interface KpiEngineResultDto {
    /**
     * 
     * @type {number}
     * @memberof KpiEngineResultDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiEngineResultDto
     */
    leadSourceId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KpiEngineResultDto
     */
    leadSourceName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiEngineResultDto
     */
    marketingCampaignId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KpiEngineResultDto
     */
    marketingCampaignName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiEngineResultDto
     */
    newCount?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiEngineResultDto
     */
    inProgressCount?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiEngineResultDto
     */
    doneCount?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiEngineResultDto
     */
    managerLeaderId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiEngineResultDto
     */
    managerLeader?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof KpiEngineResultDto
     */
    subscriptionCount?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiEngineResultDto
     */
    services?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiEngineResultDto
     */
    invest?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiEngineResultDto
     */
    leadId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiEngineResultDto
     */
    lead?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof KpiEngineResultDto
     */
    completedCount?: number;
}
/**
 * 
 * @export
 * @interface KpiExpertsResultDto
 */
export interface KpiExpertsResultDto {
    /**
     * 
     * @type {number}
     * @memberof KpiExpertsResultDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiExpertsResultDto
     */
    expertId?: number | null;
    /**
     * 
     * @type {ExpertEmployeeMinimalDto}
     * @memberof KpiExpertsResultDto
     */
    expert?: ExpertEmployeeMinimalDto;
    /**
     * 
     * @type {string}
     * @memberof KpiExpertsResultDto
     */
    speciality?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiExpertsResultDto
     */
    departmentId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KpiExpertsResultDto
     */
    milestoneValues?: string | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof KpiExpertsResultDto
     */
    milestoneValuesDictionary?: { [key: string]: any; } | null;
}
/**
 * 
 * @export
 * @interface KpiHellResultDto
 */
export interface KpiHellResultDto {
    /**
     * 
     * @type {number}
     * @memberof KpiHellResultDto
     */
    id?: number;
    /**
     * 
     * @type {ClientMinimalDto}
     * @memberof KpiHellResultDto
     */
    client?: ClientMinimalDto;
    /**
     * 
     * @type {string}
     * @memberof KpiHellResultDto
     */
    birthDate?: string | null;
    /**
     * 
     * @type {ClientSize}
     * @memberof KpiHellResultDto
     */
    clientSize?: ClientSize;
    /**
     * 
     * @type {string}
     * @memberof KpiHellResultDto
     */
    deletedAt?: string | null;
    /**
     * 
     * @type {ClientPotentialResultDto}
     * @memberof KpiHellResultDto
     */
    potential?: ClientPotentialResultDto;
    /**
     * 
     * @type {CaseDetailsDto}
     * @memberof KpiHellResultDto
     */
    subscriptionCase?: CaseDetailsDto;
}
/**
 * 
 * @export
 * @interface KpiInvestInProgressResultDto
 */
export interface KpiInvestInProgressResultDto {
    /**
     * 
     * @type {number}
     * @memberof KpiInvestInProgressResultDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof KpiInvestInProgressResultDto
     */
    productName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KpiInvestInProgressResultDto
     */
    caseName?: string | null;
    /**
     * 
     * @type {ClientMinimalDto}
     * @memberof KpiInvestInProgressResultDto
     */
    client?: ClientMinimalDto;
    /**
     * 
     * @type {string}
     * @memberof KpiInvestInProgressResultDto
     */
    startDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiInvestInProgressResultDto
     */
    amount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KpiInvestInProgressResultDto
     */
    endDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KpiInvestInProgressResultDto
     */
    buyoutMilestone?: string | null;
}
/**
 * 
 * @export
 * @interface KpiInvestPitchResultDto
 */
export interface KpiInvestPitchResultDto {
    /**
     * 
     * @type {number}
     * @memberof KpiInvestPitchResultDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof KpiInvestPitchResultDto
     */
    client?: string | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiInvestPitchResultDto
     */
    leader?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {string}
     * @memberof KpiInvestPitchResultDto
     */
    infoDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KpiInvestPitchResultDto
     */
    product?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiInvestPitchResultDto
     */
    amount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KpiInvestPitchResultDto
     */
    caseDescription?: string | null;
    /**
     * 
     * @type {CaseStatus}
     * @memberof KpiInvestPitchResultDto
     */
    caseStatus?: CaseStatus;
    /**
     * 
     * @type {string}
     * @memberof KpiInvestPitchResultDto
     */
    nextStep?: string | null;
}
/**
 * 
 * @export
 * @interface KpiInvestSalesResultDto
 */
export interface KpiInvestSalesResultDto {
    /**
     * 
     * @type {number}
     * @memberof KpiInvestSalesResultDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiInvestSalesResultDto
     */
    clientId?: number;
    /**
     * 
     * @type {ClientMinimalDto}
     * @memberof KpiInvestSalesResultDto
     */
    client?: ClientMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof KpiInvestSalesResultDto
     */
    leadSourceId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KpiInvestSalesResultDto
     */
    leadSourceName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiInvestSalesResultDto
     */
    marketingCampaignId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KpiInvestSalesResultDto
     */
    marketingCampaignName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiInvestSalesResultDto
     */
    managerLeaderId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiInvestSalesResultDto
     */
    managerLeader?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {MeetingCaseDto}
     * @memberof KpiInvestSalesResultDto
     */
    meetingDetails?: MeetingCaseDto;
    /**
     * 
     * @type {CaseStatus}
     * @memberof KpiInvestSalesResultDto
     */
    status?: CaseStatus;
}
/**
 * 
 * @export
 * @interface KpiLeadsResultDto
 */
export interface KpiLeadsResultDto {
    /**
     * 
     * @type {number}
     * @memberof KpiLeadsResultDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiLeadsResultDto
     */
    clientId?: number;
    /**
     * 
     * @type {ClientMinimalDto}
     * @memberof KpiLeadsResultDto
     */
    client?: ClientMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof KpiLeadsResultDto
     */
    leadSourceId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KpiLeadsResultDto
     */
    leadSourceName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiLeadsResultDto
     */
    marketingCampaignId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KpiLeadsResultDto
     */
    marketingCampaignName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KpiLeadsResultDto
     */
    meetingDate?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof KpiLeadsResultDto
     */
    completed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof KpiLeadsResultDto
     */
    leadId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiLeadsResultDto
     */
    lead?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof KpiLeadsResultDto
     */
    salesId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiLeadsResultDto
     */
    sales?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof KpiLeadsResultDto
     */
    managerLeaderId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiLeadsResultDto
     */
    managerLeader?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {CaseStatus}
     * @memberof KpiLeadsResultDto
     */
    status?: CaseStatus;
}
/**
 * 
 * @export
 * @interface KpiLegalResultDto
 */
export interface KpiLegalResultDto {
    /**
     * 
     * @type {number}
     * @memberof KpiLegalResultDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiLegalResultDto
     */
    clientId?: number;
    /**
     * 
     * @type {string}
     * @memberof KpiLegalResultDto
     */
    name?: string | null;
    /**
     * 
     * @type {ClientMinimalDto}
     * @memberof KpiLegalResultDto
     */
    client?: ClientMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof KpiLegalResultDto
     */
    productId?: number;
    /**
     * 
     * @type {ProductMinimalDto}
     * @memberof KpiLegalResultDto
     */
    product?: ProductMinimalDto;
    /**
     * 
     * @type {CaseStatus}
     * @memberof KpiLegalResultDto
     */
    status?: CaseStatus;
    /**
     * 
     * @type {number}
     * @memberof KpiLegalResultDto
     */
    managerLeaderId?: number | null;
    /**
     * 
     * @type {ManagerMinimalDto}
     * @memberof KpiLegalResultDto
     */
    managerLeader?: ManagerMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof KpiLegalResultDto
     */
    expertId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiLegalResultDto
     */
    expert?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof KpiLegalResultDto
     */
    publishedIncome?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiLegalResultDto
     */
    paidIncome?: number;
    /**
     * 
     * @type {Array<ApplicationUserMinimalDto>}
     * @memberof KpiLegalResultDto
     */
    managers?: Array<ApplicationUserMinimalDto> | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiLegalResultDto
     */
    lead?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof KpiLegalResultDto
     */
    salesId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiLegalResultDto
     */
    sales?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {Array<ApplicationUserMinimalDto>}
     * @memberof KpiLegalResultDto
     */
    members?: Array<ApplicationUserMinimalDto> | null;
}
/**
 * 
 * @export
 * @interface KpiManagersResultDto
 */
export interface KpiManagersResultDto {
    /**
     * 
     * @type {number}
     * @memberof KpiManagersResultDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiManagersResultDto
     */
    managerLeaderId?: number | null;
    /**
     * 
     * @type {ManagerMinimalDto}
     * @memberof KpiManagersResultDto
     */
    managerLeader?: ManagerMinimalDto;
    /**
     * 
     * @type {Array<KpiType>}
     * @memberof KpiManagersResultDto
     */
    kpiTypes?: Array<KpiType> | null;
    /**
     * 
     * @type {string}
     * @memberof KpiManagersResultDto
     */
    contractType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KpiManagersResultDto
     */
    startDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KpiManagersResultDto
     */
    endDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KpiManagersResultDto
     */
    vacationDays?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiManagersResultDto
     */
    netWage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KpiManagersResultDto
     */
    grossWage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KpiManagersResultDto
     */
    employerCost?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KpiManagersResultDto
     */
    computer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KpiManagersResultDto
     */
    display?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KpiManagersResultDto
     */
    phoneDevice?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KpiManagersResultDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KpiManagersResultDto
     */
    car?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KpiManagersResultDto
     */
    benefit?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KpiManagersResultDto
     */
    microsoftAccount?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KpiManagersResultDto
     */
    duties?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KpiManagersResultDto
     */
    milestoneValues?: string | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof KpiManagersResultDto
     */
    milestoneValuesDictionary?: { [key: string]: any; } | null;
}
/**
 * 
 * @export
 * @interface KpiMeetingResultDto
 */
export interface KpiMeetingResultDto {
    /**
     * 
     * @type {number}
     * @memberof KpiMeetingResultDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiMeetingResultDto
     */
    clientId?: number;
    /**
     * 
     * @type {ClientMinimalDto}
     * @memberof KpiMeetingResultDto
     */
    client?: ClientMinimalDto;
    /**
     * 
     * @type {ClientPotentialResultDto}
     * @memberof KpiMeetingResultDto
     */
    potential?: ClientPotentialResultDto;
    /**
     * 
     * @type {string}
     * @memberof KpiMeetingResultDto
     */
    leadSourceName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KpiMeetingResultDto
     */
    marketingCampaignName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiMeetingResultDto
     */
    leadId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiMeetingResultDto
     */
    lead?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof KpiMeetingResultDto
     */
    salesId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiMeetingResultDto
     */
    sales?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof KpiMeetingResultDto
     */
    managerLeaderId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiMeetingResultDto
     */
    managerLeader?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {string}
     * @memberof KpiMeetingResultDto
     */
    meetingDate?: string | null;
    /**
     * 
     * @type {MeetingType}
     * @memberof KpiMeetingResultDto
     */
    meetingType?: MeetingType;
    /**
     * 
     * @type {boolean}
     * @memberof KpiMeetingResultDto
     */
    completed?: boolean;
    /**
     * 
     * @type {CaseStatus}
     * @memberof KpiMeetingResultDto
     */
    status?: CaseStatus;
    /**
     * 
     * @type {string}
     * @memberof KpiMeetingResultDto
     */
    subscriptionName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiMeetingResultDto
     */
    invest?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KpiMeetingResultDto
     */
    additionalServices?: number | null;
    /**
     * 
     * @type {ClientSize}
     * @memberof KpiMeetingResultDto
     */
    clientSize?: ClientSize;
    /**
     * 
     * @type {string}
     * @memberof KpiMeetingResultDto
     */
    firstAdditionalServiceCaseProduct?: string | null;
    /**
     * 
     * @type {CaseStatus}
     * @memberof KpiMeetingResultDto
     */
    subscriptionStatus?: CaseStatus;
}
/**
 * 
 * @export
 * @interface KpiNewBusinessResultDto
 */
export interface KpiNewBusinessResultDto {
    /**
     * 
     * @type {number}
     * @memberof KpiNewBusinessResultDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiNewBusinessResultDto
     */
    totalLeadsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiNewBusinessResultDto
     */
    newLeadsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiNewBusinessResultDto
     */
    inProgressLeadsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiNewBusinessResultDto
     */
    completedLeadsCount?: number;
}
/**
 * 
 * @export
 * @interface KpiOverdueResultDto
 */
export interface KpiOverdueResultDto {
    /**
     * 
     * @type {number}
     * @memberof KpiOverdueResultDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiOverdueResultDto
     */
    selectedMonthAmountTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiOverdueResultDto
     */
    selectedMonthOverdueAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiOverdueResultDto
     */
    selectedMonthAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiOverdueResultDto
     */
    allTimeAmount?: number;
}
/**
 * 
 * @export
 * @interface KpiPurgatoryResultDto
 */
export interface KpiPurgatoryResultDto {
    /**
     * 
     * @type {number}
     * @memberof KpiPurgatoryResultDto
     */
    id?: number;
    /**
     * 
     * @type {ClientMinimalDto}
     * @memberof KpiPurgatoryResultDto
     */
    client?: ClientMinimalDto;
    /**
     * 
     * @type {ClientSize}
     * @memberof KpiPurgatoryResultDto
     */
    clientSize?: ClientSize;
    /**
     * 
     * @type {string}
     * @memberof KpiPurgatoryResultDto
     */
    birthDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KpiPurgatoryResultDto
     */
    lastMeetingDate?: string | null;
    /**
     * 
     * @type {Array<CaseStatus>}
     * @memberof KpiPurgatoryResultDto
     */
    taxStatuses?: Array<CaseStatus> | null;
    /**
     * 
     * @type {CaseStatus}
     * @memberof KpiPurgatoryResultDto
     */
    taxStatus?: CaseStatus;
    /**
     * 
     * @type {Array<CaseStatus>}
     * @memberof KpiPurgatoryResultDto
     */
    lawStatuses?: Array<CaseStatus> | null;
    /**
     * 
     * @type {CaseStatus}
     * @memberof KpiPurgatoryResultDto
     */
    lawStatus?: CaseStatus;
    /**
     * 
     * @type {number}
     * @memberof KpiPurgatoryResultDto
     */
    servicesAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiPurgatoryResultDto
     */
    investAmount?: number;
}
/**
 * 
 * @export
 * @interface KpiRecruitmentResultDto
 */
export interface KpiRecruitmentResultDto {
    /**
     * 
     * @type {number}
     * @memberof KpiRecruitmentResultDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiRecruitmentResultDto
     */
    clientId?: number;
    /**
     * 
     * @type {ClientMinimalDto}
     * @memberof KpiRecruitmentResultDto
     */
    client?: ClientMinimalDto;
    /**
     * 
     * @type {string}
     * @memberof KpiRecruitmentResultDto
     */
    clientCompany?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KpiRecruitmentResultDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof KpiRecruitmentResultDto
     */
    setToInProgressAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KpiRecruitmentResultDto
     */
    setToDoneAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiRecruitmentResultDto
     */
    recruitmentNewCount?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiRecruitmentResultDto
     */
    recruitmentInProgressCount?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiRecruitmentResultDto
     */
    recruitmentDoneCount?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiRecruitmentResultDto
     */
    income?: number;
}
/**
 * 
 * @export
 * @interface KpiSalesmanResultDto
 */
export interface KpiSalesmanResultDto {
    /**
     * 
     * @type {number}
     * @memberof KpiSalesmanResultDto
     */
    id?: number;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiSalesmanResultDto
     */
    manager?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof KpiSalesmanResultDto
     */
    subscriptionLeadAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiSalesmanResultDto
     */
    subscriptionLeaderAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiSalesmanResultDto
     */
    otherLeadAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiSalesmanResultDto
     */
    otherLeaderAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiSalesmanResultDto
     */
    investLeadAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiSalesmanResultDto
     */
    investLeaderAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiSalesmanResultDto
     */
    totalAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiSalesmanResultDto
     */
    subscriptionCount?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiSalesmanResultDto
     */
    subscriptionAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiSalesmanResultDto
     */
    totalInvestAllTime?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiSalesmanResultDto
     */
    totalServicesAllTime?: number;
}
/**
 * 
 * @export
 * @interface KpiSubscriptionsResultDto
 */
export interface KpiSubscriptionsResultDto {
    /**
     * 
     * @type {number}
     * @memberof KpiSubscriptionsResultDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiSubscriptionsResultDto
     */
    leadSourceId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KpiSubscriptionsResultDto
     */
    leadSourceName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiSubscriptionsResultDto
     */
    marketingCampaignId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KpiSubscriptionsResultDto
     */
    marketingCampaignName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiSubscriptionsResultDto
     */
    managerLeaderId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiSubscriptionsResultDto
     */
    managerLeader?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiSubscriptionsResultDto
     */
    lead?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof KpiSubscriptionsResultDto
     */
    salesId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiSubscriptionsResultDto
     */
    sales?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {string}
     * @memberof KpiSubscriptionsResultDto
     */
    beginDate?: string;
    /**
     * 
     * @type {string}
     * @memberof KpiSubscriptionsResultDto
     */
    subscriptionName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiSubscriptionsResultDto
     */
    netValue?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiSubscriptionsResultDto
     */
    leadId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KpiSubscriptionsResultDto
     */
    clientId?: number;
    /**
     * 
     * @type {ClientMinimalDto}
     * @memberof KpiSubscriptionsResultDto
     */
    client?: ClientMinimalDto;
    /**
     * 
     * @type {string}
     * @memberof KpiSubscriptionsResultDto
     */
    birthDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiSubscriptionsResultDto
     */
    servicesSum?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiSubscriptionsResultDto
     */
    investSum?: number;
    /**
     * 
     * @type {string}
     * @memberof KpiSubscriptionsResultDto
     */
    contractDate?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof KpiSubscriptionsResultDto
     */
    wasWelcomeEmailSent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KpiSubscriptionsResultDto
     */
    wasPatronAssignmentEmailSent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KpiSubscriptionsResultDto
     */
    hasSubscription?: boolean;
    /**
     * 
     * @type {ClientPotentialResultDto}
     * @memberof KpiSubscriptionsResultDto
     */
    potential?: ClientPotentialResultDto;
    /**
     * 
     * @type {string}
     * @memberof KpiSubscriptionsResultDto
     */
    customizationDate?: string | null;
    /**
     * 
     * @type {ClientSize}
     * @memberof KpiSubscriptionsResultDto
     */
    clientSize?: ClientSize;
    /**
     * 
     * @type {string}
     * @memberof KpiSubscriptionsResultDto
     */
    investInfoDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KpiSubscriptionsResultDto
     */
    callDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KpiSubscriptionsResultDto
     */
    milestoneValues?: string | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof KpiSubscriptionsResultDto
     */
    milestoneValuesDictionary?: { [key: string]: any; } | null;
    /**
     * 
     * @type {number}
     * @memberof KpiSubscriptionsResultDto
     */
    overdueSum?: number;
}
/**
 * 
 * @export
 * @interface KpiTaxInProgressResultDto
 */
export interface KpiTaxInProgressResultDto {
    /**
     * 
     * @type {number}
     * @memberof KpiTaxInProgressResultDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxInProgressResultDto
     */
    clientId?: number;
    /**
     * 
     * @type {ClientMinimalDto}
     * @memberof KpiTaxInProgressResultDto
     */
    client?: ClientMinimalDto;
    /**
     * 
     * @type {string}
     * @memberof KpiTaxInProgressResultDto
     */
    clientCompany?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxInProgressResultDto
     */
    leadSourceId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KpiTaxInProgressResultDto
     */
    leadSourceName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxInProgressResultDto
     */
    marketingCampaignId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KpiTaxInProgressResultDto
     */
    marketingCampaignName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxInProgressResultDto
     */
    leadId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiTaxInProgressResultDto
     */
    lead?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxInProgressResultDto
     */
    salesId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiTaxInProgressResultDto
     */
    sales?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxInProgressResultDto
     */
    managerLeaderId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiTaxInProgressResultDto
     */
    managerLeader?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {string}
     * @memberof KpiTaxInProgressResultDto
     */
    meetingDate?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof KpiTaxInProgressResultDto
     */
    completed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxInProgressResultDto
     */
    expertId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiTaxInProgressResultDto
     */
    expert?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {CaseStatus}
     * @memberof KpiTaxInProgressResultDto
     */
    status?: CaseStatus;
    /**
     * 
     * @type {string}
     * @memberof KpiTaxInProgressResultDto
     */
    milestoneValues?: string | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof KpiTaxInProgressResultDto
     */
    milestoneValuesDictionary?: { [key: string]: any; } | null;
    /**
     * 
     * @type {string}
     * @memberof KpiTaxInProgressResultDto
     */
    agreementDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxInProgressResultDto
     */
    gwNetAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxInProgressResultDto
     */
    gwExpertNetAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxInProgressResultDto
     */
    marginAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxInProgressResultDto
     */
    marginPercentage?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KpiTaxInProgressResultDto
     */
    accountingTypeName?: string | null;
    /**
     * 
     * @type {ClientSize}
     * @memberof KpiTaxInProgressResultDto
     */
    clientSize?: ClientSize;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxInProgressResultDto
     */
    summariesFilledCount?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxInProgressResultDto
     */
    overdueSum?: number;
}
/**
 * 
 * @export
 * @interface KpiTaxNewResultDto
 */
export interface KpiTaxNewResultDto {
    /**
     * 
     * @type {number}
     * @memberof KpiTaxNewResultDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxNewResultDto
     */
    clientId?: number;
    /**
     * 
     * @type {ClientMinimalDto}
     * @memberof KpiTaxNewResultDto
     */
    client?: ClientMinimalDto;
    /**
     * 
     * @type {string}
     * @memberof KpiTaxNewResultDto
     */
    clientCompany?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxNewResultDto
     */
    leadSourceId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KpiTaxNewResultDto
     */
    leadSourceName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxNewResultDto
     */
    marketingCampaignId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KpiTaxNewResultDto
     */
    marketingCampaignName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxNewResultDto
     */
    leadId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiTaxNewResultDto
     */
    lead?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxNewResultDto
     */
    salesId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiTaxNewResultDto
     */
    sales?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxNewResultDto
     */
    managerLeaderId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiTaxNewResultDto
     */
    managerLeader?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {string}
     * @memberof KpiTaxNewResultDto
     */
    meetingDate?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof KpiTaxNewResultDto
     */
    completed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxNewResultDto
     */
    expertId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiTaxNewResultDto
     */
    expert?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {CaseStatus}
     * @memberof KpiTaxNewResultDto
     */
    status?: CaseStatus;
    /**
     * 
     * @type {string}
     * @memberof KpiTaxNewResultDto
     */
    milestoneValues?: string | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof KpiTaxNewResultDto
     */
    milestoneValuesDictionary?: { [key: string]: any; } | null;
    /**
     * 
     * @type {string}
     * @memberof KpiTaxNewResultDto
     */
    agreementDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxNewResultDto
     */
    gwNetAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxNewResultDto
     */
    gwExpertNetAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxNewResultDto
     */
    marginAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxNewResultDto
     */
    marginPercentage?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KpiTaxNewResultDto
     */
    accountingTypeName?: string | null;
    /**
     * 
     * @type {ClientSize}
     * @memberof KpiTaxNewResultDto
     */
    clientSize?: ClientSize;
}
/**
 * 
 * @export
 * @interface KpiTaxResultDto
 */
export interface KpiTaxResultDto {
    /**
     * 
     * @type {number}
     * @memberof KpiTaxResultDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxResultDto
     */
    clientId?: number;
    /**
     * 
     * @type {ClientMinimalDto}
     * @memberof KpiTaxResultDto
     */
    client?: ClientMinimalDto;
    /**
     * 
     * @type {string}
     * @memberof KpiTaxResultDto
     */
    clientCompany?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxResultDto
     */
    leadSourceId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KpiTaxResultDto
     */
    leadSourceName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxResultDto
     */
    marketingCampaignId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KpiTaxResultDto
     */
    marketingCampaignName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxResultDto
     */
    leadId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiTaxResultDto
     */
    lead?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxResultDto
     */
    salesId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiTaxResultDto
     */
    sales?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxResultDto
     */
    managerLeaderId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiTaxResultDto
     */
    managerLeader?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {string}
     * @memberof KpiTaxResultDto
     */
    meetingDate?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof KpiTaxResultDto
     */
    completed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxResultDto
     */
    expertId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof KpiTaxResultDto
     */
    expert?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {CaseStatus}
     * @memberof KpiTaxResultDto
     */
    status?: CaseStatus;
    /**
     * 
     * @type {string}
     * @memberof KpiTaxResultDto
     */
    milestoneValues?: string | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof KpiTaxResultDto
     */
    milestoneValuesDictionary?: { [key: string]: any; } | null;
    /**
     * 
     * @type {string}
     * @memberof KpiTaxResultDto
     */
    agreementDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxResultDto
     */
    gwNetAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxResultDto
     */
    gwExpertNetAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxResultDto
     */
    marginAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KpiTaxResultDto
     */
    marginPercentage?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KpiTaxResultDto
     */
    accountingTypeName?: string | null;
    /**
     * 
     * @type {ClientSize}
     * @memberof KpiTaxResultDto
     */
    clientSize?: ClientSize;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum KpiType {
    Chf = 'Chf',
    Engine = 'Engine',
    Hell = 'Hell',
    InvestInProgress = 'InvestInProgress',
    InvestPitch = 'InvestPitch',
    Managers = 'Managers',
    NewBusinessMeeting = 'NewBusinessMeeting',
    ServiceMeeting = 'ServiceMeeting',
    NewBusiness = 'NewBusiness',
    Overdue = 'Overdue',
    Purgatory = 'Purgatory',
    Recruitment = 'Recruitment',
    Salesman = 'Salesman',
    SubscriptionsInProgress = 'SubscriptionsInProgress',
    TaxInProgress = 'TaxInProgress',
    Compliance = 'Compliance',
    ClientActivity = 'ClientActivity',
    InvestSales = 'InvestSales',
    Legal = 'Legal',
    TaxDone = 'TaxDone',
    TaxNew = 'TaxNew',
    Leads = 'Leads',
    LawSettlements = 'LawSettlements',
    TaxSettlements = 'TaxSettlements',
    MarketingNew = 'MarketingNew',
    MarketingInProgress = 'MarketingInProgress',
    MarketingDone = 'MarketingDone',
    SubscriptionsNew = 'SubscriptionsNew',
    SubscriptionsDone = 'SubscriptionsDone',
    Experts = 'Experts',
    AllCasesSales = 'AllCasesSales',
    AllCasesService = 'AllCasesService'
}

/**
 * 
 * @export
 * @interface LawsuitCaseDto
 */
export interface LawsuitCaseDto {
    /**
     * 
     * @type {string}
     * @memberof LawsuitCaseDto
     */
    plaintiff?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LawsuitCaseDto
     */
    defendant?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LawsuitCaseDto
     */
    value?: string | null;
    /**
     * 
     * @type {LawsuitStatus}
     * @memberof LawsuitCaseDto
     */
    firstInstance?: LawsuitStatus;
    /**
     * 
     * @type {string}
     * @memberof LawsuitCaseDto
     */
    firstInstanceJudgeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LawsuitCaseDto
     */
    firstInstanceCourt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LawsuitCaseDto
     */
    firstInstanceSignature?: string | null;
    /**
     * 
     * @type {LawsuitStatus}
     * @memberof LawsuitCaseDto
     */
    secondInstance?: LawsuitStatus;
    /**
     * 
     * @type {string}
     * @memberof LawsuitCaseDto
     */
    secondInstanceJudgeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LawsuitCaseDto
     */
    secondInstanceCourt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LawsuitCaseDto
     */
    secondInstanceSignature?: string | null;
    /**
     * 
     * @type {LawsuitStatus}
     * @memberof LawsuitCaseDto
     */
    cassation?: LawsuitStatus;
    /**
     * 
     * @type {string}
     * @memberof LawsuitCaseDto
     */
    cassationJudgeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LawsuitCaseDto
     */
    cassationCourt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LawsuitCaseDto
     */
    cassationSignature?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LawsuitCaseDto
     */
    registerRemoval?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof LawsuitCaseDto
     */
    bikRemoval?: boolean | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum LawsuitStatus {
    None = 'None',
    InProgress = 'InProgress',
    Won = 'Won',
    Lost = 'Lost'
}

/**
 * 
 * @export
 * @interface LeadCreateDto
 */
export interface LeadCreateDto {
    /**
     * 
     * @type {number}
     * @memberof LeadCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LeadCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LeadCreateDto
     */
    hubspotId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadCreateDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadCreateDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadCreateDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadCreateDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadCreateDto
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadCreateDto
     */
    campaign?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadCreateDto
     */
    dataSource?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LeadCreateDto
     */
    applicationUserId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof LeadCreateDto
     */
    isDeleted?: boolean;
}
/**
 * 
 * @export
 * @interface LeadDetailsDto
 */
export interface LeadDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof LeadDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LeadDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LeadDetailsDto
     */
    hubspotId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadDetailsDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadDetailsDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadDetailsDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadDetailsDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadDetailsDto
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadDetailsDto
     */
    campaign?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadDetailsDto
     */
    dataSource?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LeadDetailsDto
     */
    applicationUserId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof LeadDetailsDto
     */
    isDeleted?: boolean;
}
/**
 * 
 * @export
 * @interface LeadDto
 */
export interface LeadDto {
    /**
     * 
     * @type {number}
     * @memberof LeadDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LeadDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LeadDto
     */
    hubspotId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadDto
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadDto
     */
    campaign?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadDto
     */
    dataSource?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LeadDto
     */
    applicationUserId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof LeadDto
     */
    isDeleted?: boolean;
}
/**
 * 
 * @export
 * @interface LeadSourceCreateDto
 */
export interface LeadSourceCreateDto {
    /**
     * 
     * @type {number}
     * @memberof LeadSourceCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadSourceCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadSourceCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadSourceCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LeadSourceCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LeadSourceCreateDto
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface LeadSourceDetailsDto
 */
export interface LeadSourceDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof LeadSourceDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadSourceDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadSourceDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadSourceDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LeadSourceDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LeadSourceDetailsDto
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface LeadSourceDto
 */
export interface LeadSourceDto {
    /**
     * 
     * @type {number}
     * @memberof LeadSourceDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadSourceDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadSourceDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadSourceDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LeadSourceDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LeadSourceDto
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface LeadSourceUpdateDto
 */
export interface LeadSourceUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof LeadSourceUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadSourceUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadSourceUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadSourceUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LeadSourceUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LeadSourceUpdateDto
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface LeadUpdateDto
 */
export interface LeadUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof LeadUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LeadUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LeadUpdateDto
     */
    hubspotId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadUpdateDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadUpdateDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadUpdateDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadUpdateDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadUpdateDto
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadUpdateDto
     */
    campaign?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadUpdateDto
     */
    dataSource?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LeadUpdateDto
     */
    applicationUserId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof LeadUpdateDto
     */
    isDeleted?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum LeasingAccountingType {
    External = 'External',
    Personal = 'Personal',
    InHouse = 'InHouse',
    Other = 'Other'
}

/**
 * 
 * @export
 * @interface LeasingSurveyDto
 */
export interface LeasingSurveyDto {
    /**
     * 
     * @type {number}
     * @memberof LeasingSurveyDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LeasingSurveyDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof LeasingSurveyDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof LeasingSurveyDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LeasingSurveyDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LeasingSurveyDto
     */
    applicationUserId?: number;
    /**
     * 
     * @type {BasicInfoDto}
     * @memberof LeasingSurveyDto
     */
    basicInfo?: BasicInfoDto;
    /**
     * 
     * @type {Array<RepresentativeInfoDto>}
     * @memberof LeasingSurveyDto
     */
    representatives?: Array<RepresentativeInfoDto> | null;
    /**
     * 
     * @type {Array<ShareholderInfoDto>}
     * @memberof LeasingSurveyDto
     */
    shareholders?: Array<ShareholderInfoDto> | null;
    /**
     * 
     * @type {DecisivePersonInfoDto}
     * @memberof LeasingSurveyDto
     */
    decisivePerson?: DecisivePersonInfoDto;
    /**
     * 
     * @type {CompanyInfoDto}
     * @memberof LeasingSurveyDto
     */
    company?: CompanyInfoDto;
    /**
     * 
     * @type {ClientInfoDto}
     * @memberof LeasingSurveyDto
     */
    clients?: ClientInfoDto;
    /**
     * 
     * @type {FinancialInfoDto}
     * @memberof LeasingSurveyDto
     */
    finances?: FinancialInfoDto;
    /**
     * 
     * @type {ObligationsInfoDto}
     * @memberof LeasingSurveyDto
     */
    obligations?: ObligationsInfoDto;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum LegalFormOfBusiness {
    Other = 'Other',
    Jdg = 'JDG',
    SpC = 'SpC',
    SpP = 'SpP',
    SpK = 'SpK',
    SpKa = 'SpKA',
    SpJ = 'SpJ',
    SpZoo = 'SpZoo',
    Sa = 'SA',
    Psa = 'PSA',
    Foundation = 'Foundation',
    FamilyFoundation = 'FamilyFoundation'
}

/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    password?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LoginDto
     */
    rememberMe?: boolean;
}
/**
 * 
 * @export
 * @interface ManagerMinimalDto
 */
export interface ManagerMinimalDto {
    /**
     * 
     * @type {number}
     * @memberof ManagerMinimalDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagerMinimalDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ManagerMinimalDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagerMinimalDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ManagerMinimalDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ManagerMinimalDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManagerMinimalDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ManagerMinimalDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ManagerMinimalDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManagerMinimalDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ManagerMinimalDto
     */
    profileImageId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ManagerMinimalDto
     */
    hasSubscription?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ManagerMinimalDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ManagerMinimalDto
     */
    hasSavedCardInfo?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ManagerMinimalDto
     */
    managerTitleId?: number | null;
    /**
     * 
     * @type {ManagerTitleDto}
     * @memberof ManagerMinimalDto
     */
    managerTitle?: ManagerTitleDto;
    /**
     * 
     * @type {string}
     * @memberof ManagerMinimalDto
     */
    phoneNumber?: string | null;
}
/**
 * 
 * @export
 * @interface ManagerRankingDto
 */
export interface ManagerRankingDto {
    /**
     * 
     * @type {number}
     * @memberof ManagerRankingDto
     */
    id?: number;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof ManagerRankingDto
     */
    manager?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof ManagerRankingDto
     */
    subscriptionLeadAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ManagerRankingDto
     */
    subscriptionLeaderAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ManagerRankingDto
     */
    otherLeadAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ManagerRankingDto
     */
    otherLeaderAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ManagerRankingDto
     */
    investLeadAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ManagerRankingDto
     */
    investLeaderAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ManagerRankingDto
     */
    totalAmount?: number;
}
/**
 * 
 * @export
 * @interface ManagerReportCreateDto
 */
export interface ManagerReportCreateDto {
    /**
     * 
     * @type {number}
     * @memberof ManagerReportCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagerReportCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ManagerReportCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagerReportCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ManagerReportCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ManagerReportCreateDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManagerReportCreateDto
     */
    date?: string;
    /**
     * 
     * @type {ManagerReportType}
     * @memberof ManagerReportCreateDto
     */
    type?: ManagerReportType;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof ManagerReportCreateDto
     */
    createdBy?: ApplicationUserMinimalDto;
}
/**
 * 
 * @export
 * @interface ManagerReportDetailsDto
 */
export interface ManagerReportDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ManagerReportDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagerReportDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ManagerReportDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagerReportDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ManagerReportDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ManagerReportDetailsDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManagerReportDetailsDto
     */
    date?: string;
    /**
     * 
     * @type {ManagerReportType}
     * @memberof ManagerReportDetailsDto
     */
    type?: ManagerReportType;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof ManagerReportDetailsDto
     */
    createdBy?: ApplicationUserMinimalDto;
}
/**
 * 
 * @export
 * @interface ManagerReportDto
 */
export interface ManagerReportDto {
    /**
     * 
     * @type {number}
     * @memberof ManagerReportDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagerReportDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ManagerReportDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagerReportDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ManagerReportDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ManagerReportDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManagerReportDto
     */
    date?: string;
    /**
     * 
     * @type {ManagerReportType}
     * @memberof ManagerReportDto
     */
    type?: ManagerReportType;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof ManagerReportDto
     */
    createdBy?: ApplicationUserMinimalDto;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ManagerReportType {
    Planned = 'Planned',
    Actual = 'Actual'
}

/**
 * 
 * @export
 * @interface ManagerReportUpdateDto
 */
export interface ManagerReportUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ManagerReportUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagerReportUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ManagerReportUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagerReportUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ManagerReportUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ManagerReportUpdateDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManagerReportUpdateDto
     */
    date?: string;
    /**
     * 
     * @type {ManagerReportType}
     * @memberof ManagerReportUpdateDto
     */
    type?: ManagerReportType;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof ManagerReportUpdateDto
     */
    createdBy?: ApplicationUserMinimalDto;
}
/**
 * 
 * @export
 * @interface ManagerTitleCreateDto
 */
export interface ManagerTitleCreateDto {
    /**
     * 
     * @type {number}
     * @memberof ManagerTitleCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagerTitleCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ManagerTitleCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagerTitleCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ManagerTitleCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ManagerTitleCreateDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ManagerTitleCreateDto
     */
    departmentId?: number | null;
}
/**
 * 
 * @export
 * @interface ManagerTitleDetailsDto
 */
export interface ManagerTitleDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ManagerTitleDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagerTitleDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ManagerTitleDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagerTitleDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ManagerTitleDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ManagerTitleDetailsDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ManagerTitleDetailsDto
     */
    departmentId?: number | null;
}
/**
 * 
 * @export
 * @interface ManagerTitleDto
 */
export interface ManagerTitleDto {
    /**
     * 
     * @type {number}
     * @memberof ManagerTitleDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagerTitleDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ManagerTitleDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagerTitleDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ManagerTitleDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ManagerTitleDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ManagerTitleDto
     */
    departmentId?: number | null;
}
/**
 * 
 * @export
 * @interface ManagerTitleUpdateDto
 */
export interface ManagerTitleUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ManagerTitleUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagerTitleUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ManagerTitleUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ManagerTitleUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ManagerTitleUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ManagerTitleUpdateDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ManagerTitleUpdateDto
     */
    departmentId?: number | null;
}
/**
 * 
 * @export
 * @interface MarketingCampaignCreateDto
 */
export interface MarketingCampaignCreateDto {
    /**
     * 
     * @type {number}
     * @memberof MarketingCampaignCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketingCampaignCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketingCampaignCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketingCampaignCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MarketingCampaignCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarketingCampaignCreateDto
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface MarketingCampaignDetailsDto
 */
export interface MarketingCampaignDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof MarketingCampaignDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketingCampaignDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketingCampaignDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketingCampaignDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MarketingCampaignDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarketingCampaignDetailsDto
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface MarketingCampaignDto
 */
export interface MarketingCampaignDto {
    /**
     * 
     * @type {number}
     * @memberof MarketingCampaignDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketingCampaignDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketingCampaignDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketingCampaignDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MarketingCampaignDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarketingCampaignDto
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface MarketingCampaignUpdateDto
 */
export interface MarketingCampaignUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof MarketingCampaignUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketingCampaignUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketingCampaignUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketingCampaignUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MarketingCampaignUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MarketingCampaignUpdateDto
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface MeetingCaseDto
 */
export interface MeetingCaseDto {
    /**
     * 
     * @type {string}
     * @memberof MeetingCaseDto
     */
    startTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MeetingCaseDto
     */
    endTime?: string | null;
    /**
     * 
     * @type {MeetingType}
     * @memberof MeetingCaseDto
     */
    meetingType?: MeetingType;
    /**
     * 
     * @type {number}
     * @memberof MeetingCaseDto
     */
    subscriptionDefinitionId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MeetingCaseDto
     */
    subscriptionDescription?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MeetingCaseDto
     */
    investAmount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MeetingCaseDto
     */
    investDescription?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MeetingCaseDto
     */
    servicesAmount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MeetingCaseDto
     */
    servicesDescription?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingCaseDto
     */
    completed?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MeetingType {
    FaceToFaceAtGwe = 'FaceToFaceAtGWE',
    Remote = 'Remote',
    FaceToFaceAtClients = 'FaceToFaceAtClients'
}

/**
 * 
 * @export
 * @interface MilestoneCreateDto
 */
export interface MilestoneCreateDto {
    /**
     * 
     * @type {number}
     * @memberof MilestoneCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MilestoneCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof MilestoneCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof MilestoneCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MilestoneCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MilestoneCreateDto
     */
    name?: string | null;
    /**
     * 
     * @type {MilestoneType}
     * @memberof MilestoneCreateDto
     */
    type?: MilestoneType;
    /**
     * 
     * @type {number}
     * @memberof MilestoneCreateDto
     */
    productId?: number;
}
/**
 * 
 * @export
 * @interface MilestoneCreateOrUpdateDto
 */
export interface MilestoneCreateOrUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof MilestoneCreateOrUpdateDto
     */
    name?: string | null;
    /**
     * 
     * @type {MilestoneType}
     * @memberof MilestoneCreateOrUpdateDto
     */
    type?: MilestoneType;
    /**
     * 
     * @type {number}
     * @memberof MilestoneCreateOrUpdateDto
     */
    productId?: number;
    /**
     * 
     * @type {number}
     * @memberof MilestoneCreateOrUpdateDto
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface MilestoneDetailsDto
 */
export interface MilestoneDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof MilestoneDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MilestoneDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof MilestoneDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof MilestoneDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MilestoneDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MilestoneDetailsDto
     */
    name?: string | null;
    /**
     * 
     * @type {MilestoneType}
     * @memberof MilestoneDetailsDto
     */
    type?: MilestoneType;
    /**
     * 
     * @type {number}
     * @memberof MilestoneDetailsDto
     */
    productId?: number;
}
/**
 * 
 * @export
 * @interface MilestoneDto
 */
export interface MilestoneDto {
    /**
     * 
     * @type {number}
     * @memberof MilestoneDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MilestoneDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof MilestoneDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof MilestoneDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MilestoneDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MilestoneDto
     */
    name?: string | null;
    /**
     * 
     * @type {MilestoneType}
     * @memberof MilestoneDto
     */
    type?: MilestoneType;
    /**
     * 
     * @type {number}
     * @memberof MilestoneDto
     */
    productId?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MilestoneType {
    Text = 'Text',
    Number = 'Number',
    Date = 'Date',
    DateTime = 'DateTime',
    Department = 'Department'
}

/**
 * 
 * @export
 * @interface MilestoneUpdateDto
 */
export interface MilestoneUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof MilestoneUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MilestoneUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof MilestoneUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof MilestoneUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MilestoneUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MilestoneUpdateDto
     */
    name?: string | null;
    /**
     * 
     * @type {MilestoneType}
     * @memberof MilestoneUpdateDto
     */
    type?: MilestoneType;
    /**
     * 
     * @type {number}
     * @memberof MilestoneUpdateDto
     */
    productId?: number;
}
/**
 * 
 * @export
 * @interface NewsAttachmentDetailsDto
 */
export interface NewsAttachmentDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof NewsAttachmentDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsAttachmentDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof NewsAttachmentDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsAttachmentDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewsAttachmentDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof NewsAttachmentDetailsDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsAttachmentDetailsDto
     */
    contentType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsAttachmentDetailsDto
     */
    token?: string | null;
}
/**
 * 
 * @export
 * @interface NewsAttachmentDto
 */
export interface NewsAttachmentDto {
    /**
     * 
     * @type {number}
     * @memberof NewsAttachmentDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsAttachmentDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof NewsAttachmentDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsAttachmentDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewsAttachmentDto
     */
    updatedById?: number | null;
}
/**
 * 
 * @export
 * @interface NewsAttachmentTokenDto
 */
export interface NewsAttachmentTokenDto {
    /**
     * 
     * @type {number}
     * @memberof NewsAttachmentTokenDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof NewsAttachmentTokenDto
     */
    newsAttachmentId?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsAttachmentTokenDto
     */
    token?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsAttachmentTokenDto
     */
    attachmentFileName?: string | null;
}
/**
 * 
 * @export
 * @interface NewsAttachmentUpdateDto
 */
export interface NewsAttachmentUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof NewsAttachmentUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsAttachmentUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof NewsAttachmentUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsAttachmentUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewsAttachmentUpdateDto
     */
    updatedById?: number | null;
}
/**
 * 
 * @export
 * @interface NewsCreateDto
 */
export interface NewsCreateDto {
    /**
     * 
     * @type {number}
     * @memberof NewsCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof NewsCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewsCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof NewsCreateDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsCreateDto
     */
    introduction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsCreateDto
     */
    messageContent?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewsCreateDto
     */
    newsPictureId?: number | null;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof NewsCreateDto
     */
    createdBy?: ApplicationUserDto;
    /**
     * 
     * @type {string}
     * @memberof NewsCreateDto
     */
    newsPictureToken?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NewsCreateDto
     */
    isSubscriptionWelcomeMail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewsCreateDto
     */
    isPatronAssignmentMail?: boolean;
    /**
     * 
     * @type {Array<NewsAttachmentTokenDto>}
     * @memberof NewsCreateDto
     */
    newsAttachmentsTokens?: Array<NewsAttachmentTokenDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof NewsCreateDto
     */
    applicationUsersIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof NewsCreateDto
     */
    newsAttachmentsIds?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface NewsDetailsDto
 */
export interface NewsDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof NewsDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof NewsDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewsDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof NewsDetailsDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsDetailsDto
     */
    introduction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsDetailsDto
     */
    emailSentAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewsDetailsDto
     */
    newsPictureId?: number | null;
    /**
     * 
     * @type {ApplicationUserNameDto}
     * @memberof NewsDetailsDto
     */
    createdBy?: ApplicationUserNameDto;
    /**
     * 
     * @type {string}
     * @memberof NewsDetailsDto
     */
    newsPictureToken?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof NewsDetailsDto
     */
    applicationUsersIds?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof NewsDetailsDto
     */
    messageContent?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof NewsDetailsDto
     */
    newsAttachmentsIds?: Array<number> | null;
    /**
     * 
     * @type {Array<NewsAttachmentDetailsDto>}
     * @memberof NewsDetailsDto
     */
    newsAttachments?: Array<NewsAttachmentDetailsDto> | null;
    /**
     * 
     * @type {Array<NewsAttachmentTokenDto>}
     * @memberof NewsDetailsDto
     */
    newsAttachmentsTokens?: Array<NewsAttachmentTokenDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof NewsDetailsDto
     */
    isSubscriptionWelcomeMail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewsDetailsDto
     */
    isPatronAssignmentMail?: boolean;
}
/**
 * 
 * @export
 * @interface NewsDto
 */
export interface NewsDto {
    /**
     * 
     * @type {number}
     * @memberof NewsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof NewsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof NewsDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsDto
     */
    introduction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsDto
     */
    emailSentAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewsDto
     */
    newsPictureId?: number | null;
    /**
     * 
     * @type {ApplicationUserNameDto}
     * @memberof NewsDto
     */
    createdBy?: ApplicationUserNameDto;
    /**
     * 
     * @type {string}
     * @memberof NewsDto
     */
    newsPictureToken?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof NewsDto
     */
    applicationUsersIds?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface NewsEmailPreviewDto
 */
export interface NewsEmailPreviewDto {
    /**
     * 
     * @type {string}
     * @memberof NewsEmailPreviewDto
     */
    previewHtml?: string | null;
    /**
     * 
     * @type {Array<ApplicationUserDto>}
     * @memberof NewsEmailPreviewDto
     */
    applicationUsers?: Array<ApplicationUserDto> | null;
}
/**
 * 
 * @export
 * @interface NewsPictureDetailsDto
 */
export interface NewsPictureDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof NewsPictureDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsPictureDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof NewsPictureDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsPictureDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewsPictureDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof NewsPictureDetailsDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsPictureDetailsDto
     */
    contentType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsPictureDetailsDto
     */
    token?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NewsPictureDetailsDto
     */
    useToken?: boolean;
}
/**
 * 
 * @export
 * @interface NewsPictureDto
 */
export interface NewsPictureDto {
    /**
     * 
     * @type {number}
     * @memberof NewsPictureDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsPictureDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof NewsPictureDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsPictureDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewsPictureDto
     */
    updatedById?: number | null;
}
/**
 * 
 * @export
 * @interface NewsPictureUpdateDto
 */
export interface NewsPictureUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof NewsPictureUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsPictureUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof NewsPictureUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsPictureUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewsPictureUpdateDto
     */
    updatedById?: number | null;
}
/**
 * 
 * @export
 * @interface NewsUpdateDto
 */
export interface NewsUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof NewsUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof NewsUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof NewsUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewsUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof NewsUpdateDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsUpdateDto
     */
    introduction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsUpdateDto
     */
    messageContent?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof NewsUpdateDto
     */
    applicationUsersIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof NewsUpdateDto
     */
    newsAttachmentsIds?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof NewsUpdateDto
     */
    emailSentAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewsUpdateDto
     */
    newsPictureId?: number | null;
    /**
     * 
     * @type {ApplicationUserDto}
     * @memberof NewsUpdateDto
     */
    createdBy?: ApplicationUserDto;
    /**
     * 
     * @type {boolean}
     * @memberof NewsUpdateDto
     */
    isSubscriptionWelcomeMail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewsUpdateDto
     */
    isPatronAssignmentMail?: boolean;
}
/**
 * 
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
    /**
     * 
     * @type {number}
     * @memberof NotificationDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof NotificationDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotificationDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {CaseMinimalDto}
     * @memberof NotificationDto
     */
    _case?: CaseMinimalDto;
    /**
     * 
     * @type {NewsDto}
     * @memberof NotificationDto
     */
    news?: NewsDto;
    /**
     * 
     * @type {CaseMessageDetailsDto}
     * @memberof NotificationDto
     */
    caseMessage?: CaseMessageDetailsDto;
    /**
     * 
     * @type {CaseUpdateHistoryDetailsDto}
     * @memberof NotificationDto
     */
    caseUpdate?: CaseUpdateHistoryDetailsDto;
    /**
     * 
     * @type {ApplicationUserRole}
     * @memberof NotificationDto
     */
    role?: ApplicationUserRole;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationDto
     */
    isRead?: boolean;
}
/**
 * 
 * @export
 * @interface ObligationsInfoDto
 */
export interface ObligationsInfoDto {
    /**
     * 
     * @type {boolean}
     * @memberof ObligationsInfoDto
     */
    hasOverdueLoans?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ObligationsInfoDto
     */
    hasOverdueTaxes?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ObligationsInfoDto
     */
    hasOngoingCase?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ObligationsInfoDto
     */
    loanAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ObligationsInfoDto
     */
    leasingAmount?: number | null;
}
/**
 * 
 * @export
 * @interface OfferAttachmentDetailsDto
 */
export interface OfferAttachmentDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof OfferAttachmentDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferAttachmentDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof OfferAttachmentDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferAttachmentDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OfferAttachmentDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OfferAttachmentDetailsDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferAttachmentDetailsDto
     */
    contentType?: string | null;
}
/**
 * 
 * @export
 * @interface OfferAttachmentDto
 */
export interface OfferAttachmentDto {
    /**
     * 
     * @type {number}
     * @memberof OfferAttachmentDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferAttachmentDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof OfferAttachmentDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferAttachmentDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OfferAttachmentDto
     */
    updatedById?: number | null;
}
/**
 * 
 * @export
 * @interface OfferAttachmentUpdateDto
 */
export interface OfferAttachmentUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof OfferAttachmentUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferAttachmentUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof OfferAttachmentUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferAttachmentUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OfferAttachmentUpdateDto
     */
    updatedById?: number | null;
}
/**
 * 
 * @export
 * @interface OfferCreateDto
 */
export interface OfferCreateDto {
    /**
     * 
     * @type {number}
     * @memberof OfferCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof OfferCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OfferCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OfferCreateDto
     */
    description?: string | null;
    /**
     * 
     * @type {Array<ScheduleEntryDto>}
     * @memberof OfferCreateDto
     */
    schedule?: Array<ScheduleEntryDto> | null;
    /**
     * 
     * @type {Array<OfferPricingDto>}
     * @memberof OfferCreateDto
     */
    variants?: Array<OfferPricingDto> | null;
    /**
     * 
     * @type {number}
     * @memberof OfferCreateDto
     */
    caseId?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferCreateDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferCreateDto
     */
    validTo?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof OfferCreateDto
     */
    attachmentsIds?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface OfferDetailsDto
 */
export interface OfferDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof OfferDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof OfferDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OfferDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OfferDetailsDto
     */
    caseId?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferDetailsDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferDetailsDto
     */
    validTo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferDetailsDto
     */
    publishedAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferDetailsDto
     */
    description?: string | null;
    /**
     * 
     * @type {CaseMinimalDto}
     * @memberof OfferDetailsDto
     */
    _case?: CaseMinimalDto;
    /**
     * 
     * @type {ManagerMinimalDto}
     * @memberof OfferDetailsDto
     */
    contactPerson?: ManagerMinimalDto;
    /**
     * 
     * @type {Array<ScheduleEntryDto>}
     * @memberof OfferDetailsDto
     */
    schedule?: Array<ScheduleEntryDto> | null;
    /**
     * 
     * @type {Array<OfferPricingDto>}
     * @memberof OfferDetailsDto
     */
    variants?: Array<OfferPricingDto> | null;
    /**
     * 
     * @type {string}
     * @memberof OfferDetailsDto
     */
    publicationId?: string | null;
    /**
     * 
     * @type {Array<OfferAttachmentDetailsDto>}
     * @memberof OfferDetailsDto
     */
    attachments?: Array<OfferAttachmentDetailsDto> | null;
}
/**
 * 
 * @export
 * @interface OfferDto
 */
export interface OfferDto {
    /**
     * 
     * @type {number}
     * @memberof OfferDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof OfferDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OfferDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OfferDto
     */
    caseId?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    validTo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    publishedAt?: string | null;
}
/**
 * 
 * @export
 * @interface OfferPricingDto
 */
export interface OfferPricingDto {
    /**
     * 
     * @type {string}
     * @memberof OfferPricingDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferPricingDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OfferPricingDto
     */
    total?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferPricingDto
     */
    currency?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OfferPricingDto
     */
    discountPercent?: number;
}
/**
 * 
 * @export
 * @interface OfferUpdateDto
 */
export interface OfferUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof OfferUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof OfferUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OfferUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OfferUpdateDto
     */
    description?: string | null;
    /**
     * 
     * @type {Array<ScheduleEntryDto>}
     * @memberof OfferUpdateDto
     */
    schedule?: Array<ScheduleEntryDto> | null;
    /**
     * 
     * @type {Array<OfferPricingDto>}
     * @memberof OfferUpdateDto
     */
    variants?: Array<OfferPricingDto> | null;
    /**
     * 
     * @type {number}
     * @memberof OfferUpdateDto
     */
    caseId?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferUpdateDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferUpdateDto
     */
    validTo?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof OfferUpdateDto
     */
    attachmentsIds?: Array<number> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OperationType {
    Add = 'Add',
    Remove = 'Remove',
    Replace = 'Replace',
    Move = 'Move',
    Copy = 'Copy',
    Test = 'Test',
    Invalid = 'Invalid'
}

/**
 * 
 * @export
 * @interface OrganisationCreateDto
 */
export interface OrganisationCreateDto {
    /**
     * 
     * @type {number}
     * @memberof OrganisationCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganisationCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationCreateDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationCreateDto
     */
    leaderId?: number;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof OrganisationCreateDto
     */
    leader?: ApplicationUserMinimalDto;
}
/**
 * 
 * @export
 * @interface OrganisationDetailsDto
 */
export interface OrganisationDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof OrganisationDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganisationDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationDetailsDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationDetailsDto
     */
    leaderId?: number;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof OrganisationDetailsDto
     */
    leader?: ApplicationUserMinimalDto;
}
/**
 * 
 * @export
 * @interface OrganisationDto
 */
export interface OrganisationDto {
    /**
     * 
     * @type {number}
     * @memberof OrganisationDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganisationDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationDto
     */
    leaderId?: number;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof OrganisationDto
     */
    leader?: ApplicationUserMinimalDto;
}
/**
 * 
 * @export
 * @interface OrganisationUpdateDto
 */
export interface OrganisationUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof OrganisationUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUpdateDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUpdateDto
     */
    leaderId?: number;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof OrganisationUpdateDto
     */
    leader?: ApplicationUserMinimalDto;
}
/**
 * 
 * @export
 * @interface OrganisationUserCreateDto
 */
export interface OrganisationUserCreateDto {
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof OrganisationUserCreateDto
     */
    applicationUser?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserCreateDto
     */
    applicationUserId?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserCreateDto
     */
    organisationId?: number;
    /**
     * 
     * @type {Array<ApplicationUserRole>}
     * @memberof OrganisationUserCreateDto
     */
    roles?: Array<ApplicationUserRole> | null;
}
/**
 * 
 * @export
 * @interface OrganisationUserDetailsDto
 */
export interface OrganisationUserDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof OrganisationUserDetailsDto
     */
    applicationUser?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserDetailsDto
     */
    applicationUserId?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserDetailsDto
     */
    organisationId?: number;
    /**
     * 
     * @type {Array<ApplicationUserRole>}
     * @memberof OrganisationUserDetailsDto
     */
    roles?: Array<ApplicationUserRole> | null;
}
/**
 * 
 * @export
 * @interface OrganisationUserDto
 */
export interface OrganisationUserDto {
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof OrganisationUserDto
     */
    applicationUser?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserDto
     */
    applicationUserId?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserDto
     */
    organisationId?: number;
    /**
     * 
     * @type {Array<ApplicationUserRole>}
     * @memberof OrganisationUserDto
     */
    roles?: Array<ApplicationUserRole> | null;
}
/**
 * 
 * @export
 * @interface OrganisationUserUpdateDto
 */
export interface OrganisationUserUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganisationUserUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof OrganisationUserUpdateDto
     */
    applicationUser?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserUpdateDto
     */
    applicationUserId?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUserUpdateDto
     */
    organisationId?: number;
    /**
     * 
     * @type {Array<ApplicationUserRole>}
     * @memberof OrganisationUserUpdateDto
     */
    roles?: Array<ApplicationUserRole> | null;
}
/**
 * 
 * @export
 * @interface OverdueSettlementDto
 */
export interface OverdueSettlementDto {
    /**
     * 
     * @type {number}
     * @memberof OverdueSettlementDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OverdueSettlementDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof OverdueSettlementDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof OverdueSettlementDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OverdueSettlementDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {SettlementStatus}
     * @memberof OverdueSettlementDto
     */
    status?: SettlementStatus;
    /**
     * 
     * @type {string}
     * @memberof OverdueSettlementDto
     */
    issueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof OverdueSettlementDto
     */
    paymentDueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof OverdueSettlementDto
     */
    paymentDate?: string | null;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof OverdueSettlementDto
     */
    paymentStatus?: PaymentStatus;
    /**
     * 
     * @type {number}
     * @memberof OverdueSettlementDto
     */
    amountDue?: number;
    /**
     * 
     * @type {number}
     * @memberof OverdueSettlementDto
     */
    grossAmount?: number;
    /**
     * 
     * @type {PricingPolicyDto}
     * @memberof OverdueSettlementDto
     */
    pricingPolicy?: PricingPolicyDto;
    /**
     * 
     * @type {number}
     * @memberof OverdueSettlementDto
     */
    leadId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof OverdueSettlementDto
     */
    lead?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof OverdueSettlementDto
     */
    salesId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof OverdueSettlementDto
     */
    sales?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof OverdueSettlementDto
     */
    leaderId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof OverdueSettlementDto
     */
    leader?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof OverdueSettlementDto
     */
    expertId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof OverdueSettlementDto
     */
    expert?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof OverdueSettlementDto
     */
    leadPaymentStatus?: PaymentStatus;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof OverdueSettlementDto
     */
    salesPaymentStatus?: PaymentStatus;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof OverdueSettlementDto
     */
    leaderPaymentStatus?: PaymentStatus;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof OverdueSettlementDto
     */
    expertPaymentStatus?: PaymentStatus;
    /**
     * 
     * @type {number}
     * @memberof OverdueSettlementDto
     */
    leadAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof OverdueSettlementDto
     */
    salesAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof OverdueSettlementDto
     */
    leaderAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof OverdueSettlementDto
     */
    expertAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof OverdueSettlementDto
     */
    gweProfit?: number;
    /**
     * 
     * @type {number}
     * @memberof OverdueSettlementDto
     */
    expertInvoiceAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OverdueSettlementDto
     */
    caseId?: number;
    /**
     * 
     * @type {CaseMinimalDto}
     * @memberof OverdueSettlementDto
     */
    _case?: CaseMinimalDto;
    /**
     * 
     * @type {string}
     * @memberof OverdueSettlementDto
     */
    invoiceNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OverdueSettlementDto
     */
    expertInvoiceNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OverdueSettlementDto
     */
    payuStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OverdueSettlementDto
     */
    payUOrderId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OverdueSettlementDto
     */
    hasSavedCardInfo?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OverdueSettlementDto
     */
    notes?: string | null;
}
/**
 * 
 * @export
 * @interface PatronDetailsDto
 */
export interface PatronDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof PatronDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PatronDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof PatronDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof PatronDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatronDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PatronDetailsDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatronDetailsDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatronDetailsDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {Array<ApplicationUserRole>}
     * @memberof PatronDetailsDto
     */
    roles?: Array<ApplicationUserRole> | null;
    /**
     * 
     * @type {string}
     * @memberof PatronDetailsDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatronDetailsDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatronDetailsDto
     */
    companyName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatronDetailsDto
     */
    companyLogoId?: number | null;
    /**
     * 
     * @type {AddressDto}
     * @memberof PatronDetailsDto
     */
    addressOfResidence?: AddressDto;
    /**
     * 
     * @type {boolean}
     * @memberof PatronDetailsDto
     */
    mailingAddressDifferentThanResidence?: boolean;
    /**
     * 
     * @type {AddressDto}
     * @memberof PatronDetailsDto
     */
    mailingAddress?: AddressDto;
    /**
     * 
     * @type {string}
     * @memberof PatronDetailsDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatronDetailsDto
     */
    profileImageId?: number | null;
    /**
     * 
     * @type {ProfessionDto}
     * @memberof PatronDetailsDto
     */
    profession?: ProfessionDto;
    /**
     * 
     * @type {string}
     * @memberof PatronDetailsDto
     */
    gwExpertsCooperationBeginDate?: string;
    /**
     * 
     * @type {number}
     * @memberof PatronDetailsDto
     */
    managerTitleId?: number | null;
    /**
     * 
     * @type {ManagerTitleDto}
     * @memberof PatronDetailsDto
     */
    managerTitle?: ManagerTitleDto;
}
/**
 * 
 * @export
 * @interface PayByLink
 */
export interface PayByLink {
    /**
     * 
     * @type {string}
     * @memberof PayByLink
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayByLink
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayByLink
     */
    brandImageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayByLink
     */
    status?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PayByLink
     */
    minAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof PayByLink
     */
    maxAmount?: number;
}
/**
 * 
 * @export
 * @interface PayMethod
 */
export interface PayMethod {
    /**
     * 
     * @type {Card}
     * @memberof PayMethod
     */
    card?: Card;
    /**
     * 
     * @type {string}
     * @memberof PayMethod
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayMethod
     */
    value?: string | null;
}
/**
 * 
 * @export
 * @interface PayMethods
 */
export interface PayMethods {
    /**
     * 
     * @type {PayMethod}
     * @memberof PayMethods
     */
    payMethod?: PayMethod;
}
/**
 * 
 * @export
 * @interface PayUAvailablePayMethods
 */
export interface PayUAvailablePayMethods {
    /**
     * 
     * @type {Array<PayByLink>}
     * @memberof PayUAvailablePayMethods
     */
    payByLinks?: Array<PayByLink> | null;
    /**
     * 
     * @type {Array<CardToken>}
     * @memberof PayUAvailablePayMethods
     */
    cardTokens?: Array<CardToken> | null;
}
/**
 * 
 * @export
 * @interface PayUOrderNotification
 */
export interface PayUOrderNotification {
    /**
     * 
     * @type {PayUOrderNotificationOrder}
     * @memberof PayUOrderNotification
     */
    order?: PayUOrderNotificationOrder;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderNotification
     */
    localReceiptDateTime?: string;
    /**
     * 
     * @type {Array<PayUOrderNotificationProperties>}
     * @memberof PayUOrderNotification
     */
    properties?: Array<PayUOrderNotificationProperties> | null;
}
/**
 * 
 * @export
 * @interface PayUOrderNotificationOrder
 */
export interface PayUOrderNotificationOrder {
    /**
     * 
     * @type {string}
     * @memberof PayUOrderNotificationOrder
     */
    orderId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderNotificationOrder
     */
    extOrderId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderNotificationOrder
     */
    orderCreateDate?: string;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderNotificationOrder
     */
    notifyUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderNotificationOrder
     */
    customerIp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderNotificationOrder
     */
    merchantPosId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderNotificationOrder
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderNotificationOrder
     */
    currencyCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PayUOrderNotificationOrder
     */
    totalAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderNotificationOrder
     */
    status?: string | null;
    /**
     * 
     * @type {Array<PayUOrderProductRequest>}
     * @memberof PayUOrderNotificationOrder
     */
    products?: Array<PayUOrderProductRequest> | null;
}
/**
 * 
 * @export
 * @interface PayUOrderNotificationProperties
 */
export interface PayUOrderNotificationProperties {
    /**
     * 
     * @type {string}
     * @memberof PayUOrderNotificationProperties
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderNotificationProperties
     */
    value?: string | null;
}
/**
 * 
 * @export
 * @interface PayUOrderProductRequest
 */
export interface PayUOrderProductRequest {
    /**
     * 
     * @type {string}
     * @memberof PayUOrderProductRequest
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderProductRequest
     */
    unitPrice?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderProductRequest
     */
    quantity?: string | null;
}
/**
 * 
 * @export
 * @interface PayUOrderResponse
 */
export interface PayUOrderResponse {
    /**
     * 
     * @type {string}
     * @memberof PayUOrderResponse
     */
    notifyUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderResponse
     */
    redirectUri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderResponse
     */
    orderId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderResponse
     */
    extOrderId?: string | null;
    /**
     * 
     * @type {PayMethods}
     * @memberof PayUOrderResponse
     */
    payMethods?: PayMethods;
    /**
     * 
     * @type {PayUOrderResponseStatus}
     * @memberof PayUOrderResponse
     */
    status?: PayUOrderResponseStatus;
}
/**
 * 
 * @export
 * @interface PayUOrderResponseStatus
 */
export interface PayUOrderResponseStatus {
    /**
     * 
     * @type {string}
     * @memberof PayUOrderResponseStatus
     */
    statusCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PayUOrderResponseStatus
     */
    statusDesc?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum PaymentStatus {
    Forecast = 'Forecast',
    InvoiceIssued = 'InvoiceIssued',
    Paid = 'Paid'
}

/**
 * 
 * @export
 * @interface PeopleDetailsDto
 */
export interface PeopleDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof PeopleDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PeopleDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof PeopleDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof PeopleDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PeopleDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PeopleDetailsDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PeopleDetailsDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PeopleDetailsDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PeopleDetailsDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PeopleDetailsDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PeopleDetailsDto
     */
    profileImageId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PeopleDetailsDto
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {ProfessionDto}
     * @memberof PeopleDetailsDto
     */
    profession?: ProfessionDto;
    /**
     * 
     * @type {ManagerTitleDto}
     * @memberof PeopleDetailsDto
     */
    managerTitle?: ManagerTitleDto;
}
/**
 * 
 * @export
 * @interface PricingPolicyDto
 */
export interface PricingPolicyDto {
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyDto
     */
    leadCommission?: number;
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyDto
     */
    leadAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyDto
     */
    salesCommission?: number;
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyDto
     */
    salesAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyDto
     */
    leaderCommission?: number;
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyDto
     */
    leaderAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyDto
     */
    expertCommission?: number;
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyDto
     */
    expertAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyDto
     */
    fixedCosts?: number;
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyDto
     */
    fixedCostsAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyDto
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyDto
     */
    profit?: number;
    /**
     * 
     * @type {number}
     * @memberof PricingPolicyDto
     */
    profitAmount?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ProductCaseType {
    CourtCase = 'CourtCase',
    Loan = 'Loan',
    Monthly = 'Monthly',
    Accounting = 'Accounting',
    Other = 'Other',
    Meeting = 'Meeting'
}

/**
 * 
 * @export
 * @interface ProductCreateDto
 */
export interface ProductCreateDto {
    /**
     * 
     * @type {number}
     * @memberof ProductCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCreateDto
     */
    name?: string | null;
    /**
     * 
     * @type {ProductCaseType}
     * @memberof ProductCreateDto
     */
    caseType?: ProductCaseType;
    /**
     * 
     * @type {number}
     * @memberof ProductCreateDto
     */
    departmentId?: number | null;
    /**
     * 
     * @type {DepartmentDto}
     * @memberof ProductCreateDto
     */
    department?: DepartmentDto;
    /**
     * 
     * @type {SettlementRangeDto}
     * @memberof ProductCreateDto
     */
    amount?: SettlementRangeDto;
    /**
     * 
     * @type {SettlementRangeDto}
     * @memberof ProductCreateDto
     */
    lead?: SettlementRangeDto;
    /**
     * 
     * @type {SettlementRangeDto}
     * @memberof ProductCreateDto
     */
    sales?: SettlementRangeDto;
    /**
     * 
     * @type {SettlementRangeDto}
     * @memberof ProductCreateDto
     */
    leader?: SettlementRangeDto;
    /**
     * 
     * @type {SettlementRangeDto}
     * @memberof ProductCreateDto
     */
    expert?: SettlementRangeDto;
    /**
     * 
     * @type {SettlementRangeDto}
     * @memberof ProductCreateDto
     */
    fixedCosts?: SettlementRangeDto;
    /**
     * 
     * @type {Array<MilestoneDto>}
     * @memberof ProductCreateDto
     */
    milestones?: Array<MilestoneDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductCreateDto
     */
    milestonesIds?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCreateDto
     */
    canAmbassadorBeLead?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductCreateDto
     */
    leadManagerTitleIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductCreateDto
     */
    leaderManagerTitleIds?: Array<number> | null;
    /**
     * 
     * @type {SegmentType}
     * @memberof ProductCreateDto
     */
    segmentType?: SegmentType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCreateDto
     */
    isDeleted?: boolean;
}
/**
 * 
 * @export
 * @interface ProductDetailsDto
 */
export interface ProductDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ProductDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsDto
     */
    name?: string | null;
    /**
     * 
     * @type {ProductCaseType}
     * @memberof ProductDetailsDto
     */
    caseType?: ProductCaseType;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailsDto
     */
    departmentId?: number | null;
    /**
     * 
     * @type {DepartmentDto}
     * @memberof ProductDetailsDto
     */
    department?: DepartmentDto;
    /**
     * 
     * @type {SettlementRangeDto}
     * @memberof ProductDetailsDto
     */
    amount?: SettlementRangeDto;
    /**
     * 
     * @type {SettlementRangeDto}
     * @memberof ProductDetailsDto
     */
    lead?: SettlementRangeDto;
    /**
     * 
     * @type {SettlementRangeDto}
     * @memberof ProductDetailsDto
     */
    sales?: SettlementRangeDto;
    /**
     * 
     * @type {SettlementRangeDto}
     * @memberof ProductDetailsDto
     */
    leader?: SettlementRangeDto;
    /**
     * 
     * @type {SettlementRangeDto}
     * @memberof ProductDetailsDto
     */
    expert?: SettlementRangeDto;
    /**
     * 
     * @type {SettlementRangeDto}
     * @memberof ProductDetailsDto
     */
    fixedCosts?: SettlementRangeDto;
    /**
     * 
     * @type {Array<MilestoneDto>}
     * @memberof ProductDetailsDto
     */
    milestones?: Array<MilestoneDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductDetailsDto
     */
    milestonesIds?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDetailsDto
     */
    canAmbassadorBeLead?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductDetailsDto
     */
    leadManagerTitleIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductDetailsDto
     */
    leaderManagerTitleIds?: Array<number> | null;
    /**
     * 
     * @type {SegmentType}
     * @memberof ProductDetailsDto
     */
    segmentType?: SegmentType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDetailsDto
     */
    isDeleted?: boolean;
}
/**
 * 
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    name?: string | null;
    /**
     * 
     * @type {ProductCaseType}
     * @memberof ProductDto
     */
    caseType?: ProductCaseType;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    departmentId?: number | null;
    /**
     * 
     * @type {DepartmentDto}
     * @memberof ProductDto
     */
    department?: DepartmentDto;
    /**
     * 
     * @type {SettlementRangeDto}
     * @memberof ProductDto
     */
    amount?: SettlementRangeDto;
    /**
     * 
     * @type {SettlementRangeDto}
     * @memberof ProductDto
     */
    lead?: SettlementRangeDto;
    /**
     * 
     * @type {SettlementRangeDto}
     * @memberof ProductDto
     */
    sales?: SettlementRangeDto;
    /**
     * 
     * @type {SettlementRangeDto}
     * @memberof ProductDto
     */
    leader?: SettlementRangeDto;
    /**
     * 
     * @type {SettlementRangeDto}
     * @memberof ProductDto
     */
    expert?: SettlementRangeDto;
    /**
     * 
     * @type {SettlementRangeDto}
     * @memberof ProductDto
     */
    fixedCosts?: SettlementRangeDto;
    /**
     * 
     * @type {Array<MilestoneDto>}
     * @memberof ProductDto
     */
    milestones?: Array<MilestoneDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductDto
     */
    milestonesIds?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDto
     */
    canAmbassadorBeLead?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductDto
     */
    leadManagerTitleIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductDto
     */
    leaderManagerTitleIds?: Array<number> | null;
    /**
     * 
     * @type {SegmentType}
     * @memberof ProductDto
     */
    segmentType?: SegmentType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDto
     */
    isDeleted?: boolean;
}
/**
 * 
 * @export
 * @interface ProductMinimalDto
 */
export interface ProductMinimalDto {
    /**
     * 
     * @type {number}
     * @memberof ProductMinimalDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductMinimalDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductMinimalDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductMinimalDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductMinimalDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductMinimalDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductMinimalDto
     */
    departmentId?: number | null;
    /**
     * 
     * @type {ProductCaseType}
     * @memberof ProductMinimalDto
     */
    caseType?: ProductCaseType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductMinimalDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {Array<MilestoneDto>}
     * @memberof ProductMinimalDto
     */
    milestones?: Array<MilestoneDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductMinimalDto
     */
    canAmbassadorBeLead?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductMinimalDto
     */
    leadManagerTitleIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductMinimalDto
     */
    leaderManagerTitleIds?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface ProductMinimalWithDepartmentDto
 */
export interface ProductMinimalWithDepartmentDto {
    /**
     * 
     * @type {number}
     * @memberof ProductMinimalWithDepartmentDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductMinimalWithDepartmentDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductMinimalWithDepartmentDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductMinimalWithDepartmentDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductMinimalWithDepartmentDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductMinimalWithDepartmentDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductMinimalWithDepartmentDto
     */
    departmentId?: number | null;
    /**
     * 
     * @type {ProductCaseType}
     * @memberof ProductMinimalWithDepartmentDto
     */
    caseType?: ProductCaseType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductMinimalWithDepartmentDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {Array<MilestoneDto>}
     * @memberof ProductMinimalWithDepartmentDto
     */
    milestones?: Array<MilestoneDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductMinimalWithDepartmentDto
     */
    canAmbassadorBeLead?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductMinimalWithDepartmentDto
     */
    leadManagerTitleIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductMinimalWithDepartmentDto
     */
    leaderManagerTitleIds?: Array<number> | null;
    /**
     * 
     * @type {DepartmentDto}
     * @memberof ProductMinimalWithDepartmentDto
     */
    department?: DepartmentDto;
}
/**
 * 
 * @export
 * @interface ProductUpdateDto
 */
export interface ProductUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateDto
     */
    name?: string | null;
    /**
     * 
     * @type {ProductCaseType}
     * @memberof ProductUpdateDto
     */
    caseType?: ProductCaseType;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateDto
     */
    departmentId?: number | null;
    /**
     * 
     * @type {DepartmentDto}
     * @memberof ProductUpdateDto
     */
    department?: DepartmentDto;
    /**
     * 
     * @type {SettlementRangeDto}
     * @memberof ProductUpdateDto
     */
    amount?: SettlementRangeDto;
    /**
     * 
     * @type {SettlementRangeDto}
     * @memberof ProductUpdateDto
     */
    lead?: SettlementRangeDto;
    /**
     * 
     * @type {SettlementRangeDto}
     * @memberof ProductUpdateDto
     */
    sales?: SettlementRangeDto;
    /**
     * 
     * @type {SettlementRangeDto}
     * @memberof ProductUpdateDto
     */
    leader?: SettlementRangeDto;
    /**
     * 
     * @type {SettlementRangeDto}
     * @memberof ProductUpdateDto
     */
    expert?: SettlementRangeDto;
    /**
     * 
     * @type {SettlementRangeDto}
     * @memberof ProductUpdateDto
     */
    fixedCosts?: SettlementRangeDto;
    /**
     * 
     * @type {Array<MilestoneDto>}
     * @memberof ProductUpdateDto
     */
    milestones?: Array<MilestoneDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductUpdateDto
     */
    milestonesIds?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductUpdateDto
     */
    canAmbassadorBeLead?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductUpdateDto
     */
    leadManagerTitleIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductUpdateDto
     */
    leaderManagerTitleIds?: Array<number> | null;
    /**
     * 
     * @type {SegmentType}
     * @memberof ProductUpdateDto
     */
    segmentType?: SegmentType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductUpdateDto
     */
    isDeleted?: boolean;
}
/**
 * 
 * @export
 * @interface ProfessionCreateDto
 */
export interface ProfessionCreateDto {
    /**
     * 
     * @type {number}
     * @memberof ProfessionCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfessionCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfessionCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfessionCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProfessionCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProfessionCreateDto
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ProfessionDetailsDto
 */
export interface ProfessionDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ProfessionDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfessionDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfessionDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfessionDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProfessionDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProfessionDetailsDto
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ProfessionDto
 */
export interface ProfessionDto {
    /**
     * 
     * @type {number}
     * @memberof ProfessionDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfessionDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfessionDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfessionDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProfessionDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProfessionDto
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ProfessionUpdateDto
 */
export interface ProfessionUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ProfessionUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfessionUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfessionUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfessionUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProfessionUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProfessionUpdateDto
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ProfilePictureDetailsDto
 */
export interface ProfilePictureDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ProfilePictureDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfilePictureDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfilePictureDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfilePictureDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProfilePictureDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProfilePictureDetailsDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfilePictureDetailsDto
     */
    contentType?: string | null;
}
/**
 * 
 * @export
 * @interface ProfilePictureDto
 */
export interface ProfilePictureDto {
    /**
     * 
     * @type {number}
     * @memberof ProfilePictureDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfilePictureDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfilePictureDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfilePictureDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProfilePictureDto
     */
    updatedById?: number | null;
}
/**
 * 
 * @export
 * @interface ProfilePictureUpdateDto
 */
export interface ProfilePictureUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof ProfilePictureUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfilePictureUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfilePictureUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfilePictureUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProfilePictureUpdateDto
     */
    updatedById?: number | null;
}
/**
 * 
 * @export
 * @interface RecurringEventDefinitionDto
 */
export interface RecurringEventDefinitionDto {
    /**
     * 
     * @type {string}
     * @memberof RecurringEventDefinitionDto
     */
    cyclicalTo?: string | null;
    /**
     * 
     * @type {RecurringInterval}
     * @memberof RecurringEventDefinitionDto
     */
    interval?: RecurringInterval;
    /**
     * 
     * @type {Array<DayOfWeek>}
     * @memberof RecurringEventDefinitionDto
     */
    dailyDaysOfWeek?: Array<DayOfWeek> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum RecurringInterval {
    Daily = 'Daily',
    Weekly = 'Weekly',
    Biweekly = 'Biweekly',
    Monthly = 'Monthly',
    Quarterly = 'Quarterly',
    Yearly = 'Yearly'
}

/**
 * 
 * @export
 * @interface RepresentativeInfoDto
 */
export interface RepresentativeInfoDto {
    /**
     * 
     * @type {string}
     * @memberof RepresentativeInfoDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepresentativeInfoDto
     */
    idCardNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepresentativeInfoDto
     */
    pesel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepresentativeInfoDto
     */
    address?: string | null;
}
/**
 * 
 * @export
 * @interface ScheduleEntryDto
 */
export interface ScheduleEntryDto {
    /**
     * 
     * @type {string}
     * @memberof ScheduleEntryDto
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleEntryDto
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SegmentType {
    Subscription = 'Subscription',
    AdditionalService = 'AdditionalService',
    Invest = 'Invest'
}

/**
 * 
 * @export
 * @interface SetClientPotentialDto
 */
export interface SetClientPotentialDto {
    /**
     * 
     * @type {number}
     * @memberof SetClientPotentialDto
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof SetClientPotentialDto
     */
    clientPotential?: string | null;
}
/**
 * 
 * @export
 * @interface SettlementClientDto
 */
export interface SettlementClientDto {
    /**
     * 
     * @type {number}
     * @memberof SettlementClientDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SettlementClientDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SettlementClientDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SettlementClientDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SettlementClientDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {SettlementStatus}
     * @memberof SettlementClientDto
     */
    status?: SettlementStatus;
    /**
     * 
     * @type {string}
     * @memberof SettlementClientDto
     */
    issueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementClientDto
     */
    paymentDueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementClientDto
     */
    paymentDate?: string | null;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof SettlementClientDto
     */
    paymentStatus?: PaymentStatus;
    /**
     * 
     * @type {number}
     * @memberof SettlementClientDto
     */
    amountDue?: number;
    /**
     * 
     * @type {number}
     * @memberof SettlementClientDto
     */
    grossAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SettlementClientDto
     */
    caseId?: number;
    /**
     * 
     * @type {CaseDto}
     * @memberof SettlementClientDto
     */
    _case?: CaseDto;
    /**
     * 
     * @type {string}
     * @memberof SettlementClientDto
     */
    payuStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SettlementClientDto
     */
    invoiceNumber?: string | null;
}
/**
 * 
 * @export
 * @interface SettlementCreateDto
 */
export interface SettlementCreateDto {
    /**
     * 
     * @type {number}
     * @memberof SettlementCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SettlementCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SettlementCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SettlementCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SettlementCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SettlementCreateDto
     */
    issueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementCreateDto
     */
    paymentDueDate?: string;
    /**
     * 
     * @type {number}
     * @memberof SettlementCreateDto
     */
    amountDue?: number;
    /**
     * 
     * @type {number}
     * @memberof SettlementCreateDto
     */
    grossAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SettlementCreateDto
     */
    leadId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SettlementCreateDto
     */
    salesId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SettlementCreateDto
     */
    leaderId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SettlementCreateDto
     */
    expertId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SettlementCreateDto
     */
    caseId?: number;
    /**
     * 
     * @type {string}
     * @memberof SettlementCreateDto
     */
    invoiceNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SettlementCreateDto
     */
    expertInvoiceNumber?: string | null;
}
/**
 * 
 * @export
 * @interface SettlementDetailsDto
 */
export interface SettlementDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof SettlementDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SettlementDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SettlementDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SettlementDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SettlementDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {SettlementStatus}
     * @memberof SettlementDetailsDto
     */
    status?: SettlementStatus;
    /**
     * 
     * @type {string}
     * @memberof SettlementDetailsDto
     */
    issueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementDetailsDto
     */
    paymentDueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementDetailsDto
     */
    paymentDate?: string | null;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof SettlementDetailsDto
     */
    paymentStatus?: PaymentStatus;
    /**
     * 
     * @type {number}
     * @memberof SettlementDetailsDto
     */
    amountDue?: number;
    /**
     * 
     * @type {number}
     * @memberof SettlementDetailsDto
     */
    grossAmount?: number;
    /**
     * 
     * @type {PricingPolicyDto}
     * @memberof SettlementDetailsDto
     */
    pricingPolicy?: PricingPolicyDto;
    /**
     * 
     * @type {number}
     * @memberof SettlementDetailsDto
     */
    leadId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof SettlementDetailsDto
     */
    lead?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof SettlementDetailsDto
     */
    salesId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof SettlementDetailsDto
     */
    sales?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof SettlementDetailsDto
     */
    leaderId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof SettlementDetailsDto
     */
    leader?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof SettlementDetailsDto
     */
    expertId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof SettlementDetailsDto
     */
    expert?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof SettlementDetailsDto
     */
    leadPaymentStatus?: PaymentStatus;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof SettlementDetailsDto
     */
    salesPaymentStatus?: PaymentStatus;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof SettlementDetailsDto
     */
    leaderPaymentStatus?: PaymentStatus;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof SettlementDetailsDto
     */
    expertPaymentStatus?: PaymentStatus;
    /**
     * 
     * @type {number}
     * @memberof SettlementDetailsDto
     */
    leadAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SettlementDetailsDto
     */
    salesAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SettlementDetailsDto
     */
    leaderAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SettlementDetailsDto
     */
    expertAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SettlementDetailsDto
     */
    gweProfit?: number;
    /**
     * 
     * @type {number}
     * @memberof SettlementDetailsDto
     */
    expertInvoiceAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SettlementDetailsDto
     */
    caseId?: number;
    /**
     * 
     * @type {CaseMinimalDto}
     * @memberof SettlementDetailsDto
     */
    _case?: CaseMinimalDto;
    /**
     * 
     * @type {string}
     * @memberof SettlementDetailsDto
     */
    invoiceNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SettlementDetailsDto
     */
    expertInvoiceNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SettlementDetailsDto
     */
    payuStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SettlementDetailsDto
     */
    payUOrderId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SettlementDetailsDto
     */
    hasSavedCardInfo?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SettlementDetailsDto
     */
    notes?: string | null;
}
/**
 * 
 * @export
 * @interface SettlementDto
 */
export interface SettlementDto {
    /**
     * 
     * @type {number}
     * @memberof SettlementDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SettlementDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SettlementDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SettlementDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SettlementDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {SettlementStatus}
     * @memberof SettlementDto
     */
    status?: SettlementStatus;
    /**
     * 
     * @type {string}
     * @memberof SettlementDto
     */
    issueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementDto
     */
    paymentDueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementDto
     */
    paymentDate?: string | null;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof SettlementDto
     */
    paymentStatus?: PaymentStatus;
    /**
     * 
     * @type {number}
     * @memberof SettlementDto
     */
    amountDue?: number;
    /**
     * 
     * @type {number}
     * @memberof SettlementDto
     */
    grossAmount?: number;
    /**
     * 
     * @type {PricingPolicyDto}
     * @memberof SettlementDto
     */
    pricingPolicy?: PricingPolicyDto;
    /**
     * 
     * @type {number}
     * @memberof SettlementDto
     */
    leadId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof SettlementDto
     */
    lead?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof SettlementDto
     */
    salesId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof SettlementDto
     */
    sales?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof SettlementDto
     */
    leaderId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof SettlementDto
     */
    leader?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {number}
     * @memberof SettlementDto
     */
    expertId?: number | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof SettlementDto
     */
    expert?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof SettlementDto
     */
    leadPaymentStatus?: PaymentStatus;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof SettlementDto
     */
    salesPaymentStatus?: PaymentStatus;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof SettlementDto
     */
    leaderPaymentStatus?: PaymentStatus;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof SettlementDto
     */
    expertPaymentStatus?: PaymentStatus;
    /**
     * 
     * @type {number}
     * @memberof SettlementDto
     */
    leadAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SettlementDto
     */
    salesAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SettlementDto
     */
    leaderAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SettlementDto
     */
    expertAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SettlementDto
     */
    gweProfit?: number;
    /**
     * 
     * @type {number}
     * @memberof SettlementDto
     */
    expertInvoiceAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SettlementDto
     */
    caseId?: number;
    /**
     * 
     * @type {CaseMinimalDto}
     * @memberof SettlementDto
     */
    _case?: CaseMinimalDto;
    /**
     * 
     * @type {string}
     * @memberof SettlementDto
     */
    invoiceNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SettlementDto
     */
    expertInvoiceNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SettlementDto
     */
    payuStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SettlementDto
     */
    payUOrderId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SettlementDto
     */
    hasSavedCardInfo?: boolean;
}
/**
 * 
 * @export
 * @interface SettlementIncomeDto
 */
export interface SettlementIncomeDto {
    /**
     * 
     * @type {number}
     * @memberof SettlementIncomeDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SettlementIncomeDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SettlementIncomeDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SettlementIncomeDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SettlementIncomeDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SettlementIncomeDto
     */
    issueDate?: string;
    /**
     * 
     * @type {number}
     * @memberof SettlementIncomeDto
     */
    incomeAmount?: number;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof SettlementIncomeDto
     */
    incomePaymentStatus?: PaymentStatus;
    /**
     * 
     * @type {number}
     * @memberof SettlementIncomeDto
     */
    caseId?: number;
    /**
     * 
     * @type {CaseMinimalDto}
     * @memberof SettlementIncomeDto
     */
    _case?: CaseMinimalDto;
    /**
     * 
     * @type {SettlementRoleType}
     * @memberof SettlementIncomeDto
     */
    role?: SettlementRoleType;
}
/**
 * 
 * @export
 * @interface SettlementPaymentRequestDto
 */
export interface SettlementPaymentRequestDto {
    /**
     * 
     * @type {string}
     * @memberof SettlementPaymentRequestDto
     */
    currentUrl?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SettlementPaymentRequestDto
     */
    settlementId?: number;
}
/**
 * 
 * @export
 * @interface SettlementPaymentResponseDto
 */
export interface SettlementPaymentResponseDto {
    /**
     * 
     * @type {string}
     * @memberof SettlementPaymentResponseDto
     */
    paymentUrl?: string | null;
}
/**
 * 
 * @export
 * @interface SettlementRangeDto
 */
export interface SettlementRangeDto {
    /**
     * 
     * @type {number}
     * @memberof SettlementRangeDto
     */
    from?: number;
    /**
     * 
     * @type {number}
     * @memberof SettlementRangeDto
     */
    to?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SettlementRoleType {
    Lead = 'Lead',
    Sales = 'Sales',
    Leader = 'Leader',
    Expert = 'Expert'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum SettlementStatus {
    Draft = 'Draft',
    Published = 'Published'
}

/**
 * 
 * @export
 * @interface SettlementUpdateDto
 */
export interface SettlementUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof SettlementUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SettlementUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SettlementUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SettlementUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SettlementUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {SettlementStatus}
     * @memberof SettlementUpdateDto
     */
    status?: SettlementStatus;
    /**
     * 
     * @type {string}
     * @memberof SettlementUpdateDto
     */
    issueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementUpdateDto
     */
    paymentDueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementUpdateDto
     */
    paymentDate?: string | null;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof SettlementUpdateDto
     */
    paymentStatus?: PaymentStatus;
    /**
     * 
     * @type {number}
     * @memberof SettlementUpdateDto
     */
    amountDue?: number;
    /**
     * 
     * @type {number}
     * @memberof SettlementUpdateDto
     */
    grossAmount?: number;
    /**
     * 
     * @type {PricingPolicyDto}
     * @memberof SettlementUpdateDto
     */
    pricingPolicy?: PricingPolicyDto;
    /**
     * 
     * @type {number}
     * @memberof SettlementUpdateDto
     */
    leadId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SettlementUpdateDto
     */
    salesId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SettlementUpdateDto
     */
    leaderId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SettlementUpdateDto
     */
    expertId?: number | null;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof SettlementUpdateDto
     */
    leadPaymentStatus?: PaymentStatus;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof SettlementUpdateDto
     */
    salesPaymentStatus?: PaymentStatus;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof SettlementUpdateDto
     */
    leaderPaymentStatus?: PaymentStatus;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof SettlementUpdateDto
     */
    expertPaymentStatus?: PaymentStatus;
    /**
     * 
     * @type {number}
     * @memberof SettlementUpdateDto
     */
    leadAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SettlementUpdateDto
     */
    salesAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SettlementUpdateDto
     */
    leaderAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SettlementUpdateDto
     */
    expertAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SettlementUpdateDto
     */
    gweProfit?: number;
    /**
     * 
     * @type {number}
     * @memberof SettlementUpdateDto
     */
    caseId?: number;
    /**
     * 
     * @type {string}
     * @memberof SettlementUpdateDto
     */
    invoiceNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SettlementUpdateDto
     */
    expertInvoiceNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SettlementUpdateDto
     */
    expertInvoiceAmount?: number | null;
}
/**
 * 
 * @export
 * @interface ShareholderInfoDto
 */
export interface ShareholderInfoDto {
    /**
     * 
     * @type {string}
     * @memberof ShareholderInfoDto
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShareholderInfoDto
     */
    isMarried?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShareholderInfoDto
     */
    separationOfProperty?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ShareholderInfoDto
     */
    spouseName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShareholderInfoDto
     */
    spousePesel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShareholderInfoDto
     */
    spouseAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShareholderInfoDto
     */
    spouseIdCardNumber?: string | null;
}
/**
 * 
 * @export
 * @interface StartForgotPasswordDto
 */
export interface StartForgotPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof StartForgotPasswordDto
     */
    email?: string | null;
}
/**
 * 
 * @export
 * @interface SubscriptionCaseDto
 */
export interface SubscriptionCaseDto {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionCaseDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionCaseDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionCaseDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionCaseDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionCaseDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionCaseDto
     */
    milestoneValues?: string | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof SubscriptionCaseDto
     */
    milestoneValuesDictionary?: { [key: string]: any; } | null;
}
/**
 * 
 * @export
 * @interface SubscriptionDefinitionCreateDto
 */
export interface SubscriptionDefinitionCreateDto {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDefinitionCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDefinitionCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDefinitionCreateDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionCreateDto
     */
    paymentDueInDays?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionDefinitionCreateDto
     */
    paymentInAdvance?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionCreateDto
     */
    fixedAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionCreateDto
     */
    fixedAmountGross?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionCreateDto
     */
    perEmployeeAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionCreateDto
     */
    perEmployeeAmountGross?: number;
}
/**
 * 
 * @export
 * @interface SubscriptionDefinitionDetailsDto
 */
export interface SubscriptionDefinitionDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDefinitionDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDefinitionDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDefinitionDetailsDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionDetailsDto
     */
    paymentDueInDays?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionDefinitionDetailsDto
     */
    paymentInAdvance?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionDetailsDto
     */
    fixedAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionDetailsDto
     */
    fixedAmountGross?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionDetailsDto
     */
    perEmployeeAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionDetailsDto
     */
    perEmployeeAmountGross?: number;
}
/**
 * 
 * @export
 * @interface SubscriptionDefinitionDto
 */
export interface SubscriptionDefinitionDto {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDefinitionDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDefinitionDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDefinitionDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionDto
     */
    paymentDueInDays?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionDefinitionDto
     */
    paymentInAdvance?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionDto
     */
    fixedAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionDto
     */
    fixedAmountGross?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionDto
     */
    perEmployeeAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionDto
     */
    perEmployeeAmountGross?: number;
}
/**
 * 
 * @export
 * @interface SubscriptionDefinitionUpdateDto
 */
export interface SubscriptionDefinitionUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDefinitionUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDefinitionUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDefinitionUpdateDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionUpdateDto
     */
    paymentDueInDays?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionDefinitionUpdateDto
     */
    paymentInAdvance?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionUpdateDto
     */
    fixedAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionUpdateDto
     */
    fixedAmountGross?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionUpdateDto
     */
    perEmployeeAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDefinitionUpdateDto
     */
    perEmployeeAmountGross?: number;
}
/**
 * 
 * @export
 * @interface SubscriptionDetailsDto
 */
export interface SubscriptionDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDetailsDto
     */
    definitionId?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDetailsDto
     */
    employeeCount?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    subscriptionBeginDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    customizationDate?: string | null;
    /**
     * 
     * @type {SubscriptionDefinitionDto}
     * @memberof SubscriptionDetailsDto
     */
    definition?: SubscriptionDefinitionDto;
}
/**
 * 
 * @export
 * @interface SubscriptionDto
 */
export interface SubscriptionDto {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDto
     */
    definitionId?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionDto
     */
    employeeCount?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    subscriptionBeginDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    customizationDate?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TaxationForm {
    Other = 'Other',
    ProgressiveRate = 'ProgressiveRate',
    FlatRate = 'FlatRate',
    LumpSum = 'LumpSum',
    TaxCard = 'TaxCard',
    CitSmall = 'CitSmall',
    Cit = 'Cit'
}

/**
 * 
 * @export
 * @interface UserInvitationResultDto
 */
export interface UserInvitationResultDto {
    /**
     * 
     * @type {string}
     * @memberof UserInvitationResultDto
     */
    invitationUrl?: string | null;
}
/**
 * 
 * @export
 * @interface UserNewsDto
 */
export interface UserNewsDto {
    /**
     * 
     * @type {number}
     * @memberof UserNewsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserNewsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof UserNewsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof UserNewsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserNewsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserNewsDto
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserNewsDto
     */
    introduction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserNewsDto
     */
    emailSentAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserNewsDto
     */
    newsPictureId?: number | null;
    /**
     * 
     * @type {ApplicationUserNameDto}
     * @memberof UserNewsDto
     */
    createdBy?: ApplicationUserNameDto;
    /**
     * 
     * @type {string}
     * @memberof UserNewsDto
     */
    newsPictureToken?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserNewsDto
     */
    applicationUsersIds?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserNewsDto
     */
    isRead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserNewsDto
     */
    wasSent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserNewsDto
     */
    isDeleted?: boolean;
}
/**
 * 
 * @export
 * @interface UserUpdateHistoryDto
 */
export interface UserUpdateHistoryDto {
    /**
     * 
     * @type {number}
     * @memberof UserUpdateHistoryDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof UserUpdateHistoryDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateHistoryDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserUpdateHistoryDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserUpdateHistoryDto
     */
    caseId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserUpdateHistoryDto
     */
    casePeriodDetailsId?: number | null;
    /**
     * 
     * @type {CaseHistoryValues}
     * @memberof UserUpdateHistoryDto
     */
    changedValueName?: CaseHistoryValues;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateHistoryDto
     */
    oldValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateHistoryDto
     */
    newValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateHistoryDto
     */
    milestoneName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateHistoryDto
     */
    updatedAttachmentFileName?: string | null;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof UserUpdateHistoryDto
     */
    createdBy?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {CasePeriodDetailsMinimalDto}
     * @memberof UserUpdateHistoryDto
     */
    casePeriodDetails?: CasePeriodDetailsMinimalDto;
    /**
     * 
     * @type {Array<CaseMessageRecipientDto>}
     * @memberof UserUpdateHistoryDto
     */
    recipients?: Array<CaseMessageRecipientDto> | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateHistoryDto
     */
    createdAt?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum VatRate {
    Vat23 = 'VAT23',
    Vat8 = 'VAT8',
    Vat5 = 'VAT5',
    Zw = 'ZW',
    Np = 'NP',
    Oo = 'OO'
}


/**
 * AccountingAttachmentTypeApi - axios parameter creator
 * @export
 */
export const AccountingAttachmentTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountingAttachmentTypeGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AccountingAttachmentType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountingAttachmentTypeIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountingAttachmentTypeIdDelete', 'id', id)
            const localVarPath = `/api/AccountingAttachmentType/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountingAttachmentTypeIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountingAttachmentTypeIdGet', 'id', id)
            const localVarPath = `/api/AccountingAttachmentType/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AccountingAttachmentTypeUpdateDto} [accountingAttachmentTypeUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountingAttachmentTypeIdPatch: async (id: number, accountingAttachmentTypeUpdateDto?: AccountingAttachmentTypeUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountingAttachmentTypeIdPatch', 'id', id)
            const localVarPath = `/api/AccountingAttachmentType/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountingAttachmentTypeUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AccountingAttachmentTypeUpdateDto} [accountingAttachmentTypeUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountingAttachmentTypeIdPut: async (id: number, accountingAttachmentTypeUpdateDto?: AccountingAttachmentTypeUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountingAttachmentTypeIdPut', 'id', id)
            const localVarPath = `/api/AccountingAttachmentType/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountingAttachmentTypeUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccountingAttachmentTypeCreateDto} [accountingAttachmentTypeCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountingAttachmentTypePost: async (accountingAttachmentTypeCreateDto?: AccountingAttachmentTypeCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AccountingAttachmentType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountingAttachmentTypeCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountingAttachmentTypeApi - functional programming interface
 * @export
 */
export const AccountingAttachmentTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountingAttachmentTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountingAttachmentTypeGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountingAttachmentTypeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountingAttachmentTypeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountingAttachmentTypeIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountingAttachmentTypeIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountingAttachmentTypeIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountingAttachmentTypeDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountingAttachmentTypeIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AccountingAttachmentTypeUpdateDto} [accountingAttachmentTypeUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountingAttachmentTypeIdPatch(id: number, accountingAttachmentTypeUpdateDto?: AccountingAttachmentTypeUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountingAttachmentTypeDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountingAttachmentTypeIdPatch(id, accountingAttachmentTypeUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AccountingAttachmentTypeUpdateDto} [accountingAttachmentTypeUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountingAttachmentTypeIdPut(id: number, accountingAttachmentTypeUpdateDto?: AccountingAttachmentTypeUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountingAttachmentTypeDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountingAttachmentTypeIdPut(id, accountingAttachmentTypeUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AccountingAttachmentTypeCreateDto} [accountingAttachmentTypeCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountingAttachmentTypePost(accountingAttachmentTypeCreateDto?: AccountingAttachmentTypeCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountingAttachmentTypeDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountingAttachmentTypePost(accountingAttachmentTypeCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountingAttachmentTypeApi - factory interface
 * @export
 */
export const AccountingAttachmentTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountingAttachmentTypeApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountingAttachmentTypeGet(options?: any): AxiosPromise<Array<AccountingAttachmentTypeDto>> {
            return localVarFp.apiAccountingAttachmentTypeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountingAttachmentTypeIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountingAttachmentTypeIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountingAttachmentTypeIdGet(id: number, options?: any): AxiosPromise<AccountingAttachmentTypeDetailsDto> {
            return localVarFp.apiAccountingAttachmentTypeIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AccountingAttachmentTypeUpdateDto} [accountingAttachmentTypeUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountingAttachmentTypeIdPatch(id: number, accountingAttachmentTypeUpdateDto?: AccountingAttachmentTypeUpdateDto, options?: any): AxiosPromise<AccountingAttachmentTypeDetailsDto> {
            return localVarFp.apiAccountingAttachmentTypeIdPatch(id, accountingAttachmentTypeUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AccountingAttachmentTypeUpdateDto} [accountingAttachmentTypeUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountingAttachmentTypeIdPut(id: number, accountingAttachmentTypeUpdateDto?: AccountingAttachmentTypeUpdateDto, options?: any): AxiosPromise<AccountingAttachmentTypeDetailsDto> {
            return localVarFp.apiAccountingAttachmentTypeIdPut(id, accountingAttachmentTypeUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountingAttachmentTypeCreateDto} [accountingAttachmentTypeCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountingAttachmentTypePost(accountingAttachmentTypeCreateDto?: AccountingAttachmentTypeCreateDto, options?: any): AxiosPromise<AccountingAttachmentTypeDetailsDto> {
            return localVarFp.apiAccountingAttachmentTypePost(accountingAttachmentTypeCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountingAttachmentTypeApi - object-oriented interface
 * @export
 * @class AccountingAttachmentTypeApi
 * @extends {BaseAPI}
 */
export class AccountingAttachmentTypeApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountingAttachmentTypeApi
     */
    public apiAccountingAttachmentTypeGet(options?: any) {
        return AccountingAttachmentTypeApiFp(this.configuration).apiAccountingAttachmentTypeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountingAttachmentTypeApi
     */
    public apiAccountingAttachmentTypeIdDelete(id: number, options?: any) {
        return AccountingAttachmentTypeApiFp(this.configuration).apiAccountingAttachmentTypeIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountingAttachmentTypeApi
     */
    public apiAccountingAttachmentTypeIdGet(id: number, options?: any) {
        return AccountingAttachmentTypeApiFp(this.configuration).apiAccountingAttachmentTypeIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AccountingAttachmentTypeUpdateDto} [accountingAttachmentTypeUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountingAttachmentTypeApi
     */
    public apiAccountingAttachmentTypeIdPatch(id: number, accountingAttachmentTypeUpdateDto?: AccountingAttachmentTypeUpdateDto, options?: any) {
        return AccountingAttachmentTypeApiFp(this.configuration).apiAccountingAttachmentTypeIdPatch(id, accountingAttachmentTypeUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AccountingAttachmentTypeUpdateDto} [accountingAttachmentTypeUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountingAttachmentTypeApi
     */
    public apiAccountingAttachmentTypeIdPut(id: number, accountingAttachmentTypeUpdateDto?: AccountingAttachmentTypeUpdateDto, options?: any) {
        return AccountingAttachmentTypeApiFp(this.configuration).apiAccountingAttachmentTypeIdPut(id, accountingAttachmentTypeUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountingAttachmentTypeCreateDto} [accountingAttachmentTypeCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountingAttachmentTypeApi
     */
    public apiAccountingAttachmentTypePost(accountingAttachmentTypeCreateDto?: AccountingAttachmentTypeCreateDto, options?: any) {
        return AccountingAttachmentTypeApiFp(this.configuration).apiAccountingAttachmentTypePost(accountingAttachmentTypeCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AccountingTypeApi - axios parameter creator
 * @export
 */
export const AccountingTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountingTypeGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AccountingType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountingTypeIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountingTypeIdDelete', 'id', id)
            const localVarPath = `/api/AccountingType/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountingTypeIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountingTypeIdGet', 'id', id)
            const localVarPath = `/api/AccountingType/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AccountingTypeUpdateDto} [accountingTypeUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountingTypeIdPatch: async (id: number, accountingTypeUpdateDto?: AccountingTypeUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountingTypeIdPatch', 'id', id)
            const localVarPath = `/api/AccountingType/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountingTypeUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AccountingTypeUpdateDto} [accountingTypeUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountingTypeIdPut: async (id: number, accountingTypeUpdateDto?: AccountingTypeUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountingTypeIdPut', 'id', id)
            const localVarPath = `/api/AccountingType/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountingTypeUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccountingTypeCreateDto} [accountingTypeCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountingTypePost: async (accountingTypeCreateDto?: AccountingTypeCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AccountingType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountingTypeCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountingTypeApi - functional programming interface
 * @export
 */
export const AccountingTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountingTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountingTypeGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountingTypeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountingTypeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountingTypeIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountingTypeIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountingTypeIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountingTypeDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountingTypeIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AccountingTypeUpdateDto} [accountingTypeUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountingTypeIdPatch(id: number, accountingTypeUpdateDto?: AccountingTypeUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountingTypeDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountingTypeIdPatch(id, accountingTypeUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AccountingTypeUpdateDto} [accountingTypeUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountingTypeIdPut(id: number, accountingTypeUpdateDto?: AccountingTypeUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountingTypeDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountingTypeIdPut(id, accountingTypeUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AccountingTypeCreateDto} [accountingTypeCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountingTypePost(accountingTypeCreateDto?: AccountingTypeCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountingTypeDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountingTypePost(accountingTypeCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountingTypeApi - factory interface
 * @export
 */
export const AccountingTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountingTypeApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountingTypeGet(options?: any): AxiosPromise<Array<AccountingTypeDto>> {
            return localVarFp.apiAccountingTypeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountingTypeIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountingTypeIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountingTypeIdGet(id: number, options?: any): AxiosPromise<AccountingTypeDetailsDto> {
            return localVarFp.apiAccountingTypeIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AccountingTypeUpdateDto} [accountingTypeUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountingTypeIdPatch(id: number, accountingTypeUpdateDto?: AccountingTypeUpdateDto, options?: any): AxiosPromise<AccountingTypeDetailsDto> {
            return localVarFp.apiAccountingTypeIdPatch(id, accountingTypeUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AccountingTypeUpdateDto} [accountingTypeUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountingTypeIdPut(id: number, accountingTypeUpdateDto?: AccountingTypeUpdateDto, options?: any): AxiosPromise<AccountingTypeDetailsDto> {
            return localVarFp.apiAccountingTypeIdPut(id, accountingTypeUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountingTypeCreateDto} [accountingTypeCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountingTypePost(accountingTypeCreateDto?: AccountingTypeCreateDto, options?: any): AxiosPromise<AccountingTypeDetailsDto> {
            return localVarFp.apiAccountingTypePost(accountingTypeCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountingTypeApi - object-oriented interface
 * @export
 * @class AccountingTypeApi
 * @extends {BaseAPI}
 */
export class AccountingTypeApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountingTypeApi
     */
    public apiAccountingTypeGet(options?: any) {
        return AccountingTypeApiFp(this.configuration).apiAccountingTypeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountingTypeApi
     */
    public apiAccountingTypeIdDelete(id: number, options?: any) {
        return AccountingTypeApiFp(this.configuration).apiAccountingTypeIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountingTypeApi
     */
    public apiAccountingTypeIdGet(id: number, options?: any) {
        return AccountingTypeApiFp(this.configuration).apiAccountingTypeIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AccountingTypeUpdateDto} [accountingTypeUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountingTypeApi
     */
    public apiAccountingTypeIdPatch(id: number, accountingTypeUpdateDto?: AccountingTypeUpdateDto, options?: any) {
        return AccountingTypeApiFp(this.configuration).apiAccountingTypeIdPatch(id, accountingTypeUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AccountingTypeUpdateDto} [accountingTypeUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountingTypeApi
     */
    public apiAccountingTypeIdPut(id: number, accountingTypeUpdateDto?: AccountingTypeUpdateDto, options?: any) {
        return AccountingTypeApiFp(this.configuration).apiAccountingTypeIdPut(id, accountingTypeUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountingTypeCreateDto} [accountingTypeCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountingTypeApi
     */
    public apiAccountingTypePost(accountingTypeCreateDto?: AccountingTypeCreateDto, options?: any) {
        return AccountingTypeApiFp(this.configuration).apiAccountingTypePost(accountingTypeCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApplicationUserApi - axios parameter creator
 * @export
 */
export const ApplicationUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserAccountingPotentialIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserAccountingPotentialIdGet', 'id', id)
            const localVarPath = `/api/ApplicationUser/accountingPotential/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserAccountingPotentialIdPdfGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserAccountingPotentialIdPdfGet', 'id', id)
            const localVarPath = `/api/ApplicationUser/accountingPotential/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AccountingPotentialDto} [accountingPotentialDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserAccountingPotentialIdPost: async (id: number, accountingPotentialDto?: AccountingPotentialDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserAccountingPotentialIdPost', 'id', id)
            const localVarPath = `/api/ApplicationUser/accountingPotential/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountingPotentialDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserActivatePost: async (applicationUserActivationDto?: ApplicationUserActivationDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/activate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserActivationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StartForgotPasswordDto} [startForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserBeginForgotPasswordPost: async (startForgotPasswordDto?: StartForgotPasswordDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/beginForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startForgotPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationUserChangePasswordDto} [applicationUserChangePasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserChangePasswordPost: async (applicationUserChangePasswordDto?: ApplicationUserChangePasswordDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/changePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserChangePasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserClientExpertEmployeesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/clientExpertEmployees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserClientExpertEmployeesIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserClientExpertEmployeesIdGet', 'id', id)
            const localVarPath = `/api/ApplicationUser/clientExpertEmployees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserClientManagerIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserClientManagerIdGet', 'id', id)
            const localVarPath = `/api/ApplicationUser/clientManager/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserClientPotentialIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserClientPotentialIdGet', 'id', id)
            const localVarPath = `/api/ApplicationUser/clientPotential/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetClientPotentialDto} [setClientPotentialDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserClientPotentialPost: async (setClientPotentialDto?: SetClientPotentialDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/clientPotential`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setClientPotentialDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ClientSize} [clientSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserClientSizeIdPost: async (id: number, clientSize?: ClientSize, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserClientSizeIdPost', 'id', id)
            const localVarPath = `/api/ApplicationUser/clientSize/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (clientSize !== undefined) {
                localVarQueryParameter['clientSize'] = clientSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserClientsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserDepartmentsIdPost: async (id: number, requestBody?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserDepartmentsIdPost', 'id', id)
            const localVarPath = `/api/ApplicationUser/departments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EndForgotPasswordDto} [endForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserEndForgotPasswordPost: async (endForgotPasswordDto?: EndForgotPasswordDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/endForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(endForgotPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserExpertEmployeesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/expertEmployees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserExpertOwnersGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/expertOwners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserExportGet: async (id?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserForceResetPasswordGet: async (id?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/forceResetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserGetLinkGet: async (email?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/getLink`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ChangeEmailDto} [changeEmailDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdChangeEmailPut: async (id: number, changeEmailDto?: ChangeEmailDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdChangeEmailPut', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}/changeEmail`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeEmailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdDelete', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdGet', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdHardDeleteDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdHardDeleteDelete', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}/hardDelete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdMinimalGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdMinimalGet', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}/minimal`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPatch: async (id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdPatch', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ChangePatronDto} [changePatronDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPatronPut: async (id: number, changePatronDto?: ChangePatronDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdPatronPut', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}/patron`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePatronDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPut: async (id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdPut', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdRestorePut: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdRestorePut', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}/restore`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdRolesGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdRolesGet', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}/roles`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<ApplicationUserRole>} [applicationUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdRolesPost: async (id: number, applicationUserRole?: Array<ApplicationUserRole>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdRolesPost', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}/roles`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserLeadsForCaseCaseIdGet: async (caseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiApplicationUserLeadsForCaseCaseIdGet', 'caseId', caseId)
            const localVarPath = `/api/ApplicationUser/leadsForCase/{caseId}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {BasicInfoDto} [basicInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserLeasingSurveyIdBasicInfoPost: async (id: number, basicInfoDto?: BasicInfoDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserLeasingSurveyIdBasicInfoPost', 'id', id)
            const localVarPath = `/api/ApplicationUser/leasingSurvey/{id}/basicInfo`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(basicInfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ClientInfoDto} [clientInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserLeasingSurveyIdClientsPost: async (id: number, clientInfoDto?: ClientInfoDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserLeasingSurveyIdClientsPost', 'id', id)
            const localVarPath = `/api/ApplicationUser/leasingSurvey/{id}/clients`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientInfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CompanyInfoDto} [companyInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserLeasingSurveyIdCompanyPost: async (id: number, companyInfoDto?: CompanyInfoDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserLeasingSurveyIdCompanyPost', 'id', id)
            const localVarPath = `/api/ApplicationUser/leasingSurvey/{id}/company`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyInfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {DecisivePersonInfoDto} [decisivePersonInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserLeasingSurveyIdDecisivePersonPost: async (id: number, decisivePersonInfoDto?: DecisivePersonInfoDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserLeasingSurveyIdDecisivePersonPost', 'id', id)
            const localVarPath = `/api/ApplicationUser/leasingSurvey/{id}/decisivePerson`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(decisivePersonInfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {FinancialInfoDto} [financialInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserLeasingSurveyIdFinancesPost: async (id: number, financialInfoDto?: FinancialInfoDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserLeasingSurveyIdFinancesPost', 'id', id)
            const localVarPath = `/api/ApplicationUser/leasingSurvey/{id}/finances`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(financialInfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserLeasingSurveyIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserLeasingSurveyIdGet', 'id', id)
            const localVarPath = `/api/ApplicationUser/leasingSurvey/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ObligationsInfoDto} [obligationsInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserLeasingSurveyIdObligationsPost: async (id: number, obligationsInfoDto?: ObligationsInfoDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserLeasingSurveyIdObligationsPost', 'id', id)
            const localVarPath = `/api/ApplicationUser/leasingSurvey/{id}/obligations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(obligationsInfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserLeasingSurveyIdPdfGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserLeasingSurveyIdPdfGet', 'id', id)
            const localVarPath = `/api/ApplicationUser/leasingSurvey/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<RepresentativeInfoDto>} [representativeInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserLeasingSurveyIdRepresentativesPost: async (id: number, representativeInfoDto?: Array<RepresentativeInfoDto>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserLeasingSurveyIdRepresentativesPost', 'id', id)
            const localVarPath = `/api/ApplicationUser/leasingSurvey/{id}/representatives`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(representativeInfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<ShareholderInfoDto>} [shareholderInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserLeasingSurveyIdShareholdersPost: async (id: number, shareholderInfoDto?: Array<ShareholderInfoDto>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserLeasingSurveyIdShareholdersPost', 'id', id)
            const localVarPath = `/api/ApplicationUser/leasingSurvey/{id}/shareholders`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shareholderInfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserManagerClientsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/managerClients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserManagersAndAmbassadorsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/managersAndAmbassadors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserManagersGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/managers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserPatronClientsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/patronClients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserPatronsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/patrons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserPeopleGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/people`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserPost: async (applicationUserRegisterDto?: ApplicationUserRegisterDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserRegisterPost: async (applicationUserRegisterDto?: ApplicationUserRegisterDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserResendGet: async (email?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/resend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserYourExpertEmployeesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/yourExpertEmployees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserYourPatronGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/your-patron`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationUserApi - functional programming interface
 * @export
 */
export const ApplicationUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserAccountingPotentialIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountingPotentialDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserAccountingPotentialIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserAccountingPotentialIdPdfGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserAccountingPotentialIdPdfGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AccountingPotentialDto} [accountingPotentialDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserAccountingPotentialIdPost(id: number, accountingPotentialDto?: AccountingPotentialDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountingPotentialDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserAccountingPotentialIdPost(id, accountingPotentialDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserActivatePost(applicationUserActivationDto?: ApplicationUserActivationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserActivatePost(applicationUserActivationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StartForgotPasswordDto} [startForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto?: StartForgotPasswordDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationUserChangePasswordDto} [applicationUserChangePasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserChangePasswordPost(applicationUserChangePasswordDto?: ApplicationUserChangePasswordDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserChangePasswordPost(applicationUserChangePasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserClientExpertEmployeesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExpertEmployeeDetailsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserClientExpertEmployeesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserClientExpertEmployeesIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpertEmployeeDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserClientExpertEmployeesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserClientManagerIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpertEmployeeDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserClientManagerIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserClientPotentialIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientPotentialResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserClientPotentialIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SetClientPotentialDto} [setClientPotentialDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserClientPotentialPost(setClientPotentialDto?: SetClientPotentialDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientPotentialResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserClientPotentialPost(setClientPotentialDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ClientSize} [clientSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserClientSizeIdPost(id: number, clientSize?: ClientSize, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserClientSizeIdPost(id, clientSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserClientsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserClientsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserDepartmentsIdPost(id: number, requestBody?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserDepartmentsIdPost(id, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EndForgotPasswordDto} [endForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto?: EndForgotPasswordDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserExpertEmployeesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserExpertEmployeesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserExpertOwnersGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserExpertOwnersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserExportGet(id?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationUserForExportDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserExportGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserForceResetPasswordGet(id?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserForceResetPasswordGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserGetLinkGet(email?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInvitationResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserGetLinkGet(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ChangeEmailDto} [changeEmailDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdChangeEmailPut(id: number, changeEmailDto?: ChangeEmailDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdChangeEmailPut(id, changeEmailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdHardDeleteDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdHardDeleteDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdMinimalGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserMinimalDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdMinimalGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdPatch(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdPatch(id, applicationUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ChangePatronDto} [changePatronDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdPatronPut(id: number, changePatronDto?: ChangePatronDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdPatronPut(id, changePatronDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdPut(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdPut(id, applicationUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdRestorePut(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdRestorePut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdRolesGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationUserRole>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdRolesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<ApplicationUserRole>} [applicationUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdRolesPost(id: number, applicationUserRole?: Array<ApplicationUserRole>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdRolesPost(id, applicationUserRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserLeadsForCaseCaseIdGet(caseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserLeadsForCaseCaseIdGet(caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {BasicInfoDto} [basicInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserLeasingSurveyIdBasicInfoPost(id: number, basicInfoDto?: BasicInfoDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeasingSurveyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserLeasingSurveyIdBasicInfoPost(id, basicInfoDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ClientInfoDto} [clientInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserLeasingSurveyIdClientsPost(id: number, clientInfoDto?: ClientInfoDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeasingSurveyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserLeasingSurveyIdClientsPost(id, clientInfoDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CompanyInfoDto} [companyInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserLeasingSurveyIdCompanyPost(id: number, companyInfoDto?: CompanyInfoDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeasingSurveyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserLeasingSurveyIdCompanyPost(id, companyInfoDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {DecisivePersonInfoDto} [decisivePersonInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserLeasingSurveyIdDecisivePersonPost(id: number, decisivePersonInfoDto?: DecisivePersonInfoDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeasingSurveyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserLeasingSurveyIdDecisivePersonPost(id, decisivePersonInfoDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {FinancialInfoDto} [financialInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserLeasingSurveyIdFinancesPost(id: number, financialInfoDto?: FinancialInfoDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeasingSurveyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserLeasingSurveyIdFinancesPost(id, financialInfoDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserLeasingSurveyIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeasingSurveyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserLeasingSurveyIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ObligationsInfoDto} [obligationsInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserLeasingSurveyIdObligationsPost(id: number, obligationsInfoDto?: ObligationsInfoDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeasingSurveyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserLeasingSurveyIdObligationsPost(id, obligationsInfoDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserLeasingSurveyIdPdfGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserLeasingSurveyIdPdfGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<RepresentativeInfoDto>} [representativeInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserLeasingSurveyIdRepresentativesPost(id: number, representativeInfoDto?: Array<RepresentativeInfoDto>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeasingSurveyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserLeasingSurveyIdRepresentativesPost(id, representativeInfoDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<ShareholderInfoDto>} [shareholderInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserLeasingSurveyIdShareholdersPost(id: number, shareholderInfoDto?: Array<ShareholderInfoDto>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeasingSurveyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserLeasingSurveyIdShareholdersPost(id, shareholderInfoDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserManagerClientsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserManagerClientsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserManagersAndAmbassadorsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserManagersAndAmbassadorsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserManagersGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserManagersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserPatronClientsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserPatronClientsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserPatronsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserPatronsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserPeopleGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PeopleDetailsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserPeopleGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserPost(applicationUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserRegisterPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserRegisterPost(applicationUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserResendGet(email?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserResendGet(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserYourExpertEmployeesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserYourExpertEmployeesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserYourPatronGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatronDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserYourPatronGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationUserApi - factory interface
 * @export
 */
export const ApplicationUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationUserApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserAccountingPotentialIdGet(id: number, options?: any): AxiosPromise<AccountingPotentialDto> {
            return localVarFp.apiApplicationUserAccountingPotentialIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserAccountingPotentialIdPdfGet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserAccountingPotentialIdPdfGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AccountingPotentialDto} [accountingPotentialDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserAccountingPotentialIdPost(id: number, accountingPotentialDto?: AccountingPotentialDto, options?: any): AxiosPromise<AccountingPotentialDto> {
            return localVarFp.apiApplicationUserAccountingPotentialIdPost(id, accountingPotentialDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserActivatePost(applicationUserActivationDto?: ApplicationUserActivationDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserActivatePost(applicationUserActivationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StartForgotPasswordDto} [startForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto?: StartForgotPasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationUserChangePasswordDto} [applicationUserChangePasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserChangePasswordPost(applicationUserChangePasswordDto?: ApplicationUserChangePasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserChangePasswordPost(applicationUserChangePasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserClientExpertEmployeesGet(options?: any): AxiosPromise<Array<ExpertEmployeeDetailsDto>> {
            return localVarFp.apiApplicationUserClientExpertEmployeesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserClientExpertEmployeesIdGet(id: number, options?: any): AxiosPromise<ExpertEmployeeDetailsDto> {
            return localVarFp.apiApplicationUserClientExpertEmployeesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserClientManagerIdGet(id: number, options?: any): AxiosPromise<ExpertEmployeeDetailsDto> {
            return localVarFp.apiApplicationUserClientManagerIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserClientPotentialIdGet(id: number, options?: any): AxiosPromise<ClientPotentialResultDto> {
            return localVarFp.apiApplicationUserClientPotentialIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetClientPotentialDto} [setClientPotentialDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserClientPotentialPost(setClientPotentialDto?: SetClientPotentialDto, options?: any): AxiosPromise<ClientPotentialResultDto> {
            return localVarFp.apiApplicationUserClientPotentialPost(setClientPotentialDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ClientSize} [clientSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserClientSizeIdPost(id: number, clientSize?: ClientSize, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserClientSizeIdPost(id, clientSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserClientsGet(options?: any): AxiosPromise<Array<ApplicationUserDto>> {
            return localVarFp.apiApplicationUserClientsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserDepartmentsIdPost(id: number, requestBody?: Array<number>, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserDepartmentsIdPost(id, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EndForgotPasswordDto} [endForgotPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto?: EndForgotPasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserExpertEmployeesGet(options?: any): AxiosPromise<Array<ApplicationUserDto>> {
            return localVarFp.apiApplicationUserExpertEmployeesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserExpertOwnersGet(options?: any): AxiosPromise<Array<ApplicationUserDto>> {
            return localVarFp.apiApplicationUserExpertOwnersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserExportGet(id?: number, options?: any): AxiosPromise<Array<ApplicationUserForExportDto>> {
            return localVarFp.apiApplicationUserExportGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserForceResetPasswordGet(id?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserForceResetPasswordGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserGet(options?: any): AxiosPromise<Array<ApplicationUserDto>> {
            return localVarFp.apiApplicationUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserGetLinkGet(email?: string, options?: any): AxiosPromise<UserInvitationResultDto> {
            return localVarFp.apiApplicationUserGetLinkGet(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ChangeEmailDto} [changeEmailDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdChangeEmailPut(id: number, changeEmailDto?: ChangeEmailDto, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserIdChangeEmailPut(id, changeEmailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdGet(id: number, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdHardDeleteDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserIdHardDeleteDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdMinimalGet(id: number, options?: any): AxiosPromise<ApplicationUserMinimalDto> {
            return localVarFp.apiApplicationUserIdMinimalGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPatch(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserIdPatch(id, applicationUserRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ChangePatronDto} [changePatronDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPatronPut(id: number, changePatronDto?: ChangePatronDto, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserIdPatronPut(id, changePatronDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPut(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserIdPut(id, applicationUserRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdRestorePut(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserIdRestorePut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdRolesGet(id: number, options?: any): AxiosPromise<Array<ApplicationUserRole>> {
            return localVarFp.apiApplicationUserIdRolesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<ApplicationUserRole>} [applicationUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdRolesPost(id: number, applicationUserRole?: Array<ApplicationUserRole>, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserIdRolesPost(id, applicationUserRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserLeadsForCaseCaseIdGet(caseId: number, options?: any): AxiosPromise<Array<ApplicationUserDto>> {
            return localVarFp.apiApplicationUserLeadsForCaseCaseIdGet(caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {BasicInfoDto} [basicInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserLeasingSurveyIdBasicInfoPost(id: number, basicInfoDto?: BasicInfoDto, options?: any): AxiosPromise<LeasingSurveyDto> {
            return localVarFp.apiApplicationUserLeasingSurveyIdBasicInfoPost(id, basicInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ClientInfoDto} [clientInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserLeasingSurveyIdClientsPost(id: number, clientInfoDto?: ClientInfoDto, options?: any): AxiosPromise<LeasingSurveyDto> {
            return localVarFp.apiApplicationUserLeasingSurveyIdClientsPost(id, clientInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CompanyInfoDto} [companyInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserLeasingSurveyIdCompanyPost(id: number, companyInfoDto?: CompanyInfoDto, options?: any): AxiosPromise<LeasingSurveyDto> {
            return localVarFp.apiApplicationUserLeasingSurveyIdCompanyPost(id, companyInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {DecisivePersonInfoDto} [decisivePersonInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserLeasingSurveyIdDecisivePersonPost(id: number, decisivePersonInfoDto?: DecisivePersonInfoDto, options?: any): AxiosPromise<LeasingSurveyDto> {
            return localVarFp.apiApplicationUserLeasingSurveyIdDecisivePersonPost(id, decisivePersonInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {FinancialInfoDto} [financialInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserLeasingSurveyIdFinancesPost(id: number, financialInfoDto?: FinancialInfoDto, options?: any): AxiosPromise<LeasingSurveyDto> {
            return localVarFp.apiApplicationUserLeasingSurveyIdFinancesPost(id, financialInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserLeasingSurveyIdGet(id: number, options?: any): AxiosPromise<LeasingSurveyDto> {
            return localVarFp.apiApplicationUserLeasingSurveyIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ObligationsInfoDto} [obligationsInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserLeasingSurveyIdObligationsPost(id: number, obligationsInfoDto?: ObligationsInfoDto, options?: any): AxiosPromise<LeasingSurveyDto> {
            return localVarFp.apiApplicationUserLeasingSurveyIdObligationsPost(id, obligationsInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserLeasingSurveyIdPdfGet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserLeasingSurveyIdPdfGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<RepresentativeInfoDto>} [representativeInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserLeasingSurveyIdRepresentativesPost(id: number, representativeInfoDto?: Array<RepresentativeInfoDto>, options?: any): AxiosPromise<LeasingSurveyDto> {
            return localVarFp.apiApplicationUserLeasingSurveyIdRepresentativesPost(id, representativeInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<ShareholderInfoDto>} [shareholderInfoDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserLeasingSurveyIdShareholdersPost(id: number, shareholderInfoDto?: Array<ShareholderInfoDto>, options?: any): AxiosPromise<LeasingSurveyDto> {
            return localVarFp.apiApplicationUserLeasingSurveyIdShareholdersPost(id, shareholderInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserManagerClientsGet(options?: any): AxiosPromise<Array<ApplicationUserDto>> {
            return localVarFp.apiApplicationUserManagerClientsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserManagersAndAmbassadorsGet(options?: any): AxiosPromise<Array<ApplicationUserDto>> {
            return localVarFp.apiApplicationUserManagersAndAmbassadorsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserManagersGet(options?: any): AxiosPromise<Array<ApplicationUserDto>> {
            return localVarFp.apiApplicationUserManagersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserPatronClientsGet(options?: any): AxiosPromise<Array<ApplicationUserDto>> {
            return localVarFp.apiApplicationUserPatronClientsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserPatronsGet(options?: any): AxiosPromise<Array<ApplicationUserDto>> {
            return localVarFp.apiApplicationUserPatronsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserPeopleGet(options?: any): AxiosPromise<Array<PeopleDetailsDto>> {
            return localVarFp.apiApplicationUserPeopleGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserPost(applicationUserRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserRegisterPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserRegisterPost(applicationUserRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserResendGet(email?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserResendGet(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserYourExpertEmployeesGet(options?: any): AxiosPromise<Array<ApplicationUserDto>> {
            return localVarFp.apiApplicationUserYourExpertEmployeesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserYourPatronGet(options?: any): AxiosPromise<PatronDetailsDto> {
            return localVarFp.apiApplicationUserYourPatronGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationUserApi - object-oriented interface
 * @export
 * @class ApplicationUserApi
 * @extends {BaseAPI}
 */
export class ApplicationUserApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserAccountingPotentialIdGet(id: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserAccountingPotentialIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserAccountingPotentialIdPdfGet(id: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserAccountingPotentialIdPdfGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AccountingPotentialDto} [accountingPotentialDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserAccountingPotentialIdPost(id: number, accountingPotentialDto?: AccountingPotentialDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserAccountingPotentialIdPost(id, accountingPotentialDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserActivatePost(applicationUserActivationDto?: ApplicationUserActivationDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserActivatePost(applicationUserActivationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StartForgotPasswordDto} [startForgotPasswordDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto?: StartForgotPasswordDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserBeginForgotPasswordPost(startForgotPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationUserChangePasswordDto} [applicationUserChangePasswordDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserChangePasswordPost(applicationUserChangePasswordDto?: ApplicationUserChangePasswordDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserChangePasswordPost(applicationUserChangePasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserClientExpertEmployeesGet(options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserClientExpertEmployeesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserClientExpertEmployeesIdGet(id: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserClientExpertEmployeesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserClientManagerIdGet(id: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserClientManagerIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserClientPotentialIdGet(id: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserClientPotentialIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetClientPotentialDto} [setClientPotentialDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserClientPotentialPost(setClientPotentialDto?: SetClientPotentialDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserClientPotentialPost(setClientPotentialDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ClientSize} [clientSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserClientSizeIdPost(id: number, clientSize?: ClientSize, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserClientSizeIdPost(id, clientSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserClientsGet(options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserClientsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserDepartmentsIdPost(id: number, requestBody?: Array<number>, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserDepartmentsIdPost(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EndForgotPasswordDto} [endForgotPasswordDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto?: EndForgotPasswordDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserEndForgotPasswordPost(endForgotPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserExpertEmployeesGet(options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserExpertEmployeesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserExpertOwnersGet(options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserExpertOwnersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserExportGet(id?: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserExportGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserForceResetPasswordGet(id?: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserForceResetPasswordGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserGet(options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserGetLinkGet(email?: string, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserGetLinkGet(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ChangeEmailDto} [changeEmailDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdChangeEmailPut(id: number, changeEmailDto?: ChangeEmailDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdChangeEmailPut(id, changeEmailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdDelete(id: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdGet(id: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdHardDeleteDelete(id: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdHardDeleteDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdMinimalGet(id: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdMinimalGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdPatch(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdPatch(id, applicationUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ChangePatronDto} [changePatronDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdPatronPut(id: number, changePatronDto?: ChangePatronDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdPatronPut(id, changePatronDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdPut(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdPut(id, applicationUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdRestorePut(id: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdRestorePut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdRolesGet(id: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdRolesGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<ApplicationUserRole>} [applicationUserRole] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdRolesPost(id: number, applicationUserRole?: Array<ApplicationUserRole>, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdRolesPost(id, applicationUserRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} caseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserLeadsForCaseCaseIdGet(caseId: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserLeadsForCaseCaseIdGet(caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {BasicInfoDto} [basicInfoDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserLeasingSurveyIdBasicInfoPost(id: number, basicInfoDto?: BasicInfoDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserLeasingSurveyIdBasicInfoPost(id, basicInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ClientInfoDto} [clientInfoDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserLeasingSurveyIdClientsPost(id: number, clientInfoDto?: ClientInfoDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserLeasingSurveyIdClientsPost(id, clientInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CompanyInfoDto} [companyInfoDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserLeasingSurveyIdCompanyPost(id: number, companyInfoDto?: CompanyInfoDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserLeasingSurveyIdCompanyPost(id, companyInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {DecisivePersonInfoDto} [decisivePersonInfoDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserLeasingSurveyIdDecisivePersonPost(id: number, decisivePersonInfoDto?: DecisivePersonInfoDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserLeasingSurveyIdDecisivePersonPost(id, decisivePersonInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {FinancialInfoDto} [financialInfoDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserLeasingSurveyIdFinancesPost(id: number, financialInfoDto?: FinancialInfoDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserLeasingSurveyIdFinancesPost(id, financialInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserLeasingSurveyIdGet(id: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserLeasingSurveyIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ObligationsInfoDto} [obligationsInfoDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserLeasingSurveyIdObligationsPost(id: number, obligationsInfoDto?: ObligationsInfoDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserLeasingSurveyIdObligationsPost(id, obligationsInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserLeasingSurveyIdPdfGet(id: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserLeasingSurveyIdPdfGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<RepresentativeInfoDto>} [representativeInfoDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserLeasingSurveyIdRepresentativesPost(id: number, representativeInfoDto?: Array<RepresentativeInfoDto>, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserLeasingSurveyIdRepresentativesPost(id, representativeInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<ShareholderInfoDto>} [shareholderInfoDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserLeasingSurveyIdShareholdersPost(id: number, shareholderInfoDto?: Array<ShareholderInfoDto>, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserLeasingSurveyIdShareholdersPost(id, shareholderInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserManagerClientsGet(options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserManagerClientsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserManagersAndAmbassadorsGet(options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserManagersAndAmbassadorsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserManagersGet(options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserManagersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserPatronClientsGet(options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserPatronClientsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserPatronsGet(options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserPatronsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserPeopleGet(options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserPeopleGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserPost(applicationUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserRegisterPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserRegisterPost(applicationUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserResendGet(email?: string, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserResendGet(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserYourExpertEmployeesGet(options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserYourExpertEmployeesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserYourPatronGet(options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserYourPatronGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginCallbackGet: async (returnUrl?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/loginCallback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [loginPageUrl] 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginGet: async (loginPageUrl?: string, returnUrl?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (loginPageUrl !== undefined) {
                localVarQueryParameter['loginPageUrl'] = loginPageUrl;
            }

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {LoginDto} [loginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginPost: async (returnUrl?: string, loginDto?: LoginDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [postLogoutUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLogoutGet: async (postLogoutUrl?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (postLogoutUrl !== undefined) {
                localVarQueryParameter['postLogoutUrl'] = postLogoutUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationLoginCallbackGet(returnUrl?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticationLoginCallbackGet(returnUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [loginPageUrl] 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationLoginGet(loginPageUrl?: string, returnUrl?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticationLoginGet(loginPageUrl, returnUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {LoginDto} [loginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationLoginPost(returnUrl?: string, loginDto?: LoginDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticationLoginPost(returnUrl, loginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [postLogoutUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationLogoutGet(postLogoutUrl?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticationLogoutGet(postLogoutUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginCallbackGet(returnUrl?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthenticationLoginCallbackGet(returnUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [loginPageUrl] 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginGet(loginPageUrl?: string, returnUrl?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthenticationLoginGet(loginPageUrl, returnUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {LoginDto} [loginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginPost(returnUrl?: string, loginDto?: LoginDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthenticationLoginPost(returnUrl, loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [postLogoutUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLogoutGet(postLogoutUrl?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthenticationLogoutGet(postLogoutUrl, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @param {string} [returnUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationLoginCallbackGet(returnUrl?: string, options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationLoginCallbackGet(returnUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [loginPageUrl] 
     * @param {string} [returnUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationLoginGet(loginPageUrl?: string, returnUrl?: string, options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationLoginGet(loginPageUrl, returnUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [returnUrl] 
     * @param {LoginDto} [loginDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationLoginPost(returnUrl?: string, loginDto?: LoginDto, options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationLoginPost(returnUrl, loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [postLogoutUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationLogoutGet(postLogoutUrl?: string, options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationLogoutGet(postLogoutUrl, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CalendarEventApi - axios parameter creator
 * @export
 */
export const CalendarEventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalendarEventGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CalendarEvent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalendarEventIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCalendarEventIdDelete', 'id', id)
            const localVarPath = `/api/CalendarEvent/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalendarEventIdFutureDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCalendarEventIdFutureDelete', 'id', id)
            const localVarPath = `/api/CalendarEvent/{id}/future`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalendarEventIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCalendarEventIdGet', 'id', id)
            const localVarPath = `/api/CalendarEvent/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CalendarEventUpdateDto} [calendarEventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalendarEventIdPatch: async (id: number, calendarEventUpdateDto?: CalendarEventUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCalendarEventIdPatch', 'id', id)
            const localVarPath = `/api/CalendarEvent/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendarEventUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CalendarEventUpdateDto} [calendarEventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalendarEventIdPut: async (id: number, calendarEventUpdateDto?: CalendarEventUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCalendarEventIdPut', 'id', id)
            const localVarPath = `/api/CalendarEvent/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendarEventUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CalendarEventCreateDto} [calendarEventCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalendarEventPost: async (calendarEventCreateDto?: CalendarEventCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CalendarEvent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendarEventCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [date] 
         * @param {number} [caseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsForCaseGet: async (date?: string, caseId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/GetEventsForCase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (caseId !== undefined) {
                localVarQueryParameter['caseId'] = caseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsForCurrentUserGet: async (date?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/GetEventsForCurrentUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [date] 
         * @param {Array<number>} [organisations] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsForCurrentUserOrganisationGet: async (date?: string, organisations?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/GetEventsForCurrentUser/organisation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (organisations) {
                localVarQueryParameter['organisations'] = organisations;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [date] 
         * @param {number} [caseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsForUserGet: async (date?: string, caseId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/GetEventsForUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (caseId !== undefined) {
                localVarQueryParameter['caseId'] = caseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CalendarEventApplicationUserLinkUpdateDto} [calendarEventApplicationUserLinkUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserEventNotificationSettingsPut: async (calendarEventApplicationUserLinkUpdateDto?: CalendarEventApplicationUserLinkUpdateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/UpdateUserEventNotificationSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendarEventApplicationUserLinkUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalendarEventApi - functional programming interface
 * @export
 */
export const CalendarEventApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalendarEventApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCalendarEventGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEventDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCalendarEventGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCalendarEventIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCalendarEventIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCalendarEventIdFutureDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCalendarEventIdFutureDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCalendarEventIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEventDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCalendarEventIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CalendarEventUpdateDto} [calendarEventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCalendarEventIdPatch(id: number, calendarEventUpdateDto?: CalendarEventUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEventDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCalendarEventIdPatch(id, calendarEventUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CalendarEventUpdateDto} [calendarEventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCalendarEventIdPut(id: number, calendarEventUpdateDto?: CalendarEventUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEventDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCalendarEventIdPut(id, calendarEventUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CalendarEventCreateDto} [calendarEventCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCalendarEventPost(calendarEventCreateDto?: CalendarEventCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEventDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCalendarEventPost(calendarEventCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [date] 
         * @param {number} [caseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventsForCaseGet(date?: string, caseId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEventApplicationUserLinkUpdateDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventsForCaseGet(date, caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventsForCurrentUserGet(date?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEventApplicationUserLinkUpdateDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventsForCurrentUserGet(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [date] 
         * @param {Array<number>} [organisations] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventsForCurrentUserOrganisationGet(date?: string, organisations?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEventApplicationUserLinkWithOrganisationIdDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventsForCurrentUserOrganisationGet(date, organisations, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [date] 
         * @param {number} [caseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventsForUserGet(date?: string, caseId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEventApplicationUserLinkUpdateDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventsForUserGet(date, caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CalendarEventApplicationUserLinkUpdateDto} [calendarEventApplicationUserLinkUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserEventNotificationSettingsPut(calendarEventApplicationUserLinkUpdateDto?: CalendarEventApplicationUserLinkUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserEventNotificationSettingsPut(calendarEventApplicationUserLinkUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CalendarEventApi - factory interface
 * @export
 */
export const CalendarEventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalendarEventApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalendarEventGet(options?: any): AxiosPromise<Array<CalendarEventDto>> {
            return localVarFp.apiCalendarEventGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalendarEventIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCalendarEventIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalendarEventIdFutureDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCalendarEventIdFutureDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalendarEventIdGet(id: number, options?: any): AxiosPromise<CalendarEventDetailsDto> {
            return localVarFp.apiCalendarEventIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CalendarEventUpdateDto} [calendarEventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalendarEventIdPatch(id: number, calendarEventUpdateDto?: CalendarEventUpdateDto, options?: any): AxiosPromise<CalendarEventDetailsDto> {
            return localVarFp.apiCalendarEventIdPatch(id, calendarEventUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CalendarEventUpdateDto} [calendarEventUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalendarEventIdPut(id: number, calendarEventUpdateDto?: CalendarEventUpdateDto, options?: any): AxiosPromise<CalendarEventDetailsDto> {
            return localVarFp.apiCalendarEventIdPut(id, calendarEventUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CalendarEventCreateDto} [calendarEventCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalendarEventPost(calendarEventCreateDto?: CalendarEventCreateDto, options?: any): AxiosPromise<CalendarEventDetailsDto> {
            return localVarFp.apiCalendarEventPost(calendarEventCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [date] 
         * @param {number} [caseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsForCaseGet(date?: string, caseId?: number, options?: any): AxiosPromise<Array<CalendarEventApplicationUserLinkUpdateDto>> {
            return localVarFp.getEventsForCaseGet(date, caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsForCurrentUserGet(date?: string, options?: any): AxiosPromise<Array<CalendarEventApplicationUserLinkUpdateDto>> {
            return localVarFp.getEventsForCurrentUserGet(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [date] 
         * @param {Array<number>} [organisations] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsForCurrentUserOrganisationGet(date?: string, organisations?: Array<number>, options?: any): AxiosPromise<Array<CalendarEventApplicationUserLinkWithOrganisationIdDto>> {
            return localVarFp.getEventsForCurrentUserOrganisationGet(date, organisations, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [date] 
         * @param {number} [caseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsForUserGet(date?: string, caseId?: number, options?: any): AxiosPromise<Array<CalendarEventApplicationUserLinkUpdateDto>> {
            return localVarFp.getEventsForUserGet(date, caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CalendarEventApplicationUserLinkUpdateDto} [calendarEventApplicationUserLinkUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserEventNotificationSettingsPut(calendarEventApplicationUserLinkUpdateDto?: CalendarEventApplicationUserLinkUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserEventNotificationSettingsPut(calendarEventApplicationUserLinkUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CalendarEventApi - object-oriented interface
 * @export
 * @class CalendarEventApi
 * @extends {BaseAPI}
 */
export class CalendarEventApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventApi
     */
    public apiCalendarEventGet(options?: any) {
        return CalendarEventApiFp(this.configuration).apiCalendarEventGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventApi
     */
    public apiCalendarEventIdDelete(id: number, options?: any) {
        return CalendarEventApiFp(this.configuration).apiCalendarEventIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventApi
     */
    public apiCalendarEventIdFutureDelete(id: number, options?: any) {
        return CalendarEventApiFp(this.configuration).apiCalendarEventIdFutureDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventApi
     */
    public apiCalendarEventIdGet(id: number, options?: any) {
        return CalendarEventApiFp(this.configuration).apiCalendarEventIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CalendarEventUpdateDto} [calendarEventUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventApi
     */
    public apiCalendarEventIdPatch(id: number, calendarEventUpdateDto?: CalendarEventUpdateDto, options?: any) {
        return CalendarEventApiFp(this.configuration).apiCalendarEventIdPatch(id, calendarEventUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CalendarEventUpdateDto} [calendarEventUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventApi
     */
    public apiCalendarEventIdPut(id: number, calendarEventUpdateDto?: CalendarEventUpdateDto, options?: any) {
        return CalendarEventApiFp(this.configuration).apiCalendarEventIdPut(id, calendarEventUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalendarEventCreateDto} [calendarEventCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventApi
     */
    public apiCalendarEventPost(calendarEventCreateDto?: CalendarEventCreateDto, options?: any) {
        return CalendarEventApiFp(this.configuration).apiCalendarEventPost(calendarEventCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [date] 
     * @param {number} [caseId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventApi
     */
    public getEventsForCaseGet(date?: string, caseId?: number, options?: any) {
        return CalendarEventApiFp(this.configuration).getEventsForCaseGet(date, caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventApi
     */
    public getEventsForCurrentUserGet(date?: string, options?: any) {
        return CalendarEventApiFp(this.configuration).getEventsForCurrentUserGet(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [date] 
     * @param {Array<number>} [organisations] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventApi
     */
    public getEventsForCurrentUserOrganisationGet(date?: string, organisations?: Array<number>, options?: any) {
        return CalendarEventApiFp(this.configuration).getEventsForCurrentUserOrganisationGet(date, organisations, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [date] 
     * @param {number} [caseId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventApi
     */
    public getEventsForUserGet(date?: string, caseId?: number, options?: any) {
        return CalendarEventApiFp(this.configuration).getEventsForUserGet(date, caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalendarEventApplicationUserLinkUpdateDto} [calendarEventApplicationUserLinkUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventApi
     */
    public updateUserEventNotificationSettingsPut(calendarEventApplicationUserLinkUpdateDto?: CalendarEventApplicationUserLinkUpdateDto, options?: any) {
        return CalendarEventApiFp(this.configuration).updateUserEventNotificationSettingsPut(calendarEventApplicationUserLinkUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CaseApi - axios parameter creator
 * @export
 */
export const CaseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAllGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Case/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} caseId 
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseCaseIdClientAttachmentAttachmentIdDelete: async (caseId: number, attachmentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiCaseCaseIdClientAttachmentAttachmentIdDelete', 'caseId', caseId)
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('apiCaseCaseIdClientAttachmentAttachmentIdDelete', 'attachmentId', attachmentId)
            const localVarPath = `/api/Case/{caseId}/clientAttachment/{attachmentId}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)))
                .replace(`{${"attachmentId"}}`, encodeURIComponent(String(attachmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} caseId 
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseCaseIdExpertAttachmentAttachmentIdDelete: async (caseId: number, attachmentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiCaseCaseIdExpertAttachmentAttachmentIdDelete', 'caseId', caseId)
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('apiCaseCaseIdExpertAttachmentAttachmentIdDelete', 'attachmentId', attachmentId)
            const localVarPath = `/api/Case/{caseId}/expertAttachment/{attachmentId}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)))
                .replace(`{${"attachmentId"}}`, encodeURIComponent(String(attachmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} caseId 
         * @param {number} [summaryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseCaseIdPeriodSummaryDelete: async (caseId: number, summaryId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiCaseCaseIdPeriodSummaryDelete', 'caseId', caseId)
            const localVarPath = `/api/Case/{caseId}/period/summary`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (summaryId !== undefined) {
                localVarQueryParameter['summaryId'] = summaryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseCasePeriodsSummaryGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Case/casePeriodsSummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseClientCasesUserIdGet: async (userId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiCaseClientCasesUserIdGet', 'userId', userId)
            const localVarPath = `/api/Case/clientCases/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseExpertClientCasesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Case/expertClientCases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseExpertOwnerEmployeesCasesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Case/expertOwnerEmployeesCases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseExpertOwnerEmployeesCasesIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseExpertOwnerEmployeesCasesIdGet', 'id', id)
            const localVarPath = `/api/Case/expertOwnerEmployeesCases/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Case`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [accountingAttachmentTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdAccountingAttachmentTypePut: async (id: number, accountingAttachmentTypeId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdAccountingAttachmentTypePut', 'id', id)
            const localVarPath = `/api/Case/{id}/accountingAttachmentType`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (accountingAttachmentTypeId !== undefined) {
                localVarQueryParameter['accountingAttachmentTypeId'] = accountingAttachmentTypeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [accountingTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdAccountingTypePut: async (id: number, accountingTypeId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdAccountingTypePut', 'id', id)
            const localVarPath = `/api/Case/{id}/accountingType`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (accountingTypeId !== undefined) {
                localVarQueryParameter['accountingTypeId'] = accountingTypeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdAgreementDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdAgreementDelete', 'id', id)
            const localVarPath = `/api/Case/{id}/agreement`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [description] 
         * @param {AttachmentType} [type] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id2] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdAgreementPost: async (id: number, description?: string, type?: AttachmentType, casePeriodAttachmentType?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id2?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdAgreementPost', 'id', id)
            const localVarPath = `/api/Case/{id}/agreement`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


            if (description !== undefined) { 
                localVarFormParams.append('Description', description as any);
            }
    
            if (type !== undefined) { 
                localVarFormParams.append('Type', new Blob([JSON.stringify(type)], { type: "application/json", }));
            }
    
            if (casePeriodAttachmentType !== undefined) { 
                localVarFormParams.append('CasePeriodAttachmentType', casePeriodAttachmentType as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (blobName !== undefined) { 
                localVarFormParams.append('BlobName', blobName as any);
            }
    
            if (id2 !== undefined) { 
                localVarFormParams.append('Id', id2 as any);
            }
    
            if (createdAt !== undefined) { 
                localVarFormParams.append('CreatedAt', createdAt as any);
            }
    
            if (createdById !== undefined) { 
                localVarFormParams.append('CreatedById', createdById as any);
            }
    
            if (updatedAt !== undefined) { 
                localVarFormParams.append('UpdatedAt', updatedAt as any);
            }
    
            if (updatedById !== undefined) { 
                localVarFormParams.append('UpdatedById', updatedById as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<any>} [files] 
         * @param {Array<string>} [descriptions] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdClientAttachmentMultiplePost: async (id: number, files?: Array<any>, descriptions?: Array<string>, casePeriodAttachmentType?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdClientAttachmentMultiplePost', 'id', id)
            const localVarPath = `/api/Case/{id}/clientAttachmentMultiple`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('Files', element as any);
                })
            }

                if (descriptions) {
                localVarFormParams.append('Descriptions', descriptions.join(COLLECTION_FORMATS.csv));
            }

    
            if (casePeriodAttachmentType !== undefined) { 
                localVarFormParams.append('CasePeriodAttachmentType', casePeriodAttachmentType as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [description] 
         * @param {AttachmentType} [type] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id2] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdClientAttachmentPost: async (id: number, description?: string, type?: AttachmentType, casePeriodAttachmentType?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id2?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdClientAttachmentPost', 'id', id)
            const localVarPath = `/api/Case/{id}/clientAttachment`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


            if (description !== undefined) { 
                localVarFormParams.append('Description', description as any);
            }
    
            if (type !== undefined) { 
                localVarFormParams.append('Type', new Blob([JSON.stringify(type)], { type: "application/json", }));
            }
    
            if (casePeriodAttachmentType !== undefined) { 
                localVarFormParams.append('CasePeriodAttachmentType', casePeriodAttachmentType as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (blobName !== undefined) { 
                localVarFormParams.append('BlobName', blobName as any);
            }
    
            if (id2 !== undefined) { 
                localVarFormParams.append('Id', id2 as any);
            }
    
            if (createdAt !== undefined) { 
                localVarFormParams.append('CreatedAt', createdAt as any);
            }
    
            if (createdById !== undefined) { 
                localVarFormParams.append('CreatedById', createdById as any);
            }
    
            if (updatedAt !== undefined) { 
                localVarFormParams.append('UpdatedAt', updatedAt as any);
            }
    
            if (updatedById !== undefined) { 
                localVarFormParams.append('UpdatedById', updatedById as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [companyName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdClientCompanyPut: async (id: number, companyName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdClientCompanyPut', 'id', id)
            const localVarPath = `/api/Case/{id}/clientCompany`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (companyName !== undefined) {
                localVarQueryParameter['companyName'] = companyName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CompanyDetailsDto} [companyDetailsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdCompanyDetailsPut: async (id: number, companyDetailsDto?: CompanyDetailsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdCompanyDetailsPut', 'id', id)
            const localVarPath = `/api/Case/{id}/companyDetails`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyDetailsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdDelete', 'id', id)
            const localVarPath = `/api/Case/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<any>} [files] 
         * @param {Array<string>} [descriptions] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdExpertAttachmentMultiplePost: async (id: number, files?: Array<any>, descriptions?: Array<string>, casePeriodAttachmentType?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdExpertAttachmentMultiplePost', 'id', id)
            const localVarPath = `/api/Case/{id}/expertAttachmentMultiple`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('Files', element as any);
                })
            }

                if (descriptions) {
                localVarFormParams.append('Descriptions', descriptions.join(COLLECTION_FORMATS.csv));
            }

    
            if (casePeriodAttachmentType !== undefined) { 
                localVarFormParams.append('CasePeriodAttachmentType', casePeriodAttachmentType as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [description] 
         * @param {AttachmentType} [type] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id2] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdExpertAttachmentPost: async (id: number, description?: string, type?: AttachmentType, casePeriodAttachmentType?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id2?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdExpertAttachmentPost', 'id', id)
            const localVarPath = `/api/Case/{id}/expertAttachment`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


            if (description !== undefined) { 
                localVarFormParams.append('Description', description as any);
            }
    
            if (type !== undefined) { 
                localVarFormParams.append('Type', new Blob([JSON.stringify(type)], { type: "application/json", }));
            }
    
            if (casePeriodAttachmentType !== undefined) { 
                localVarFormParams.append('CasePeriodAttachmentType', casePeriodAttachmentType as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (blobName !== undefined) { 
                localVarFormParams.append('BlobName', blobName as any);
            }
    
            if (id2 !== undefined) { 
                localVarFormParams.append('Id', id2 as any);
            }
    
            if (createdAt !== undefined) { 
                localVarFormParams.append('CreatedAt', createdAt as any);
            }
    
            if (createdById !== undefined) { 
                localVarFormParams.append('CreatedById', createdById as any);
            }
    
            if (updatedAt !== undefined) { 
                localVarFormParams.append('UpdatedAt', updatedAt as any);
            }
    
            if (updatedById !== undefined) { 
                localVarFormParams.append('UpdatedById', updatedById as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [expertId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdExpertPut: async (id: number, expertId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdExpertPut', 'id', id)
            const localVarPath = `/api/Case/{id}/expert`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (expertId !== undefined) {
                localVarQueryParameter['expertId'] = expertId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseGeneralInfoUpdateDto} [caseGeneralInfoUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdGeneralInfoPut: async (id: number, caseGeneralInfoUpdateDto?: CaseGeneralInfoUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdGeneralInfoPut', 'id', id)
            const localVarPath = `/api/Case/{id}/generalInfo`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caseGeneralInfoUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdGet', 'id', id)
            const localVarPath = `/api/Case/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdInvoiceSettingsGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdInvoiceSettingsGet', 'id', id)
            const localVarPath = `/api/Case/{id}/invoiceSettings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {InvoicePrintoutSettingsDto} [invoicePrintoutSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdInvoiceSettingsPut: async (id: number, invoicePrintoutSettingsDto?: InvoicePrintoutSettingsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdInvoiceSettingsPut', 'id', id)
            const localVarPath = `/api/Case/{id}/invoiceSettings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoicePrintoutSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [isVatPayer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdIsVatPayerPut: async (id: number, isVatPayer?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdIsVatPayerPut', 'id', id)
            const localVarPath = `/api/Case/{id}/isVatPayer`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (isVatPayer !== undefined) {
                localVarQueryParameter['isVatPayer'] = isVatPayer;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LawsuitCaseDto} [lawsuitCaseDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdLawsuitPut: async (id: number, lawsuitCaseDto?: LawsuitCaseDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdLawsuitPut', 'id', id)
            const localVarPath = `/api/Case/{id}/lawsuit`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lawsuitCaseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [leadId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdLeadPut: async (id: number, leadId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdLeadPut', 'id', id)
            const localVarPath = `/api/Case/{id}/lead`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (leadId !== undefined) {
                localVarQueryParameter['leadId'] = leadId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdManagerPut: async (id: number, requestBody?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdManagerPut', 'id', id)
            const localVarPath = `/api/Case/{id}/manager`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [leaderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdManagerleaderPut: async (id: number, leaderId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdManagerleaderPut', 'id', id)
            const localVarPath = `/api/Case/{id}/managerleader`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (leaderId !== undefined) {
                localVarQueryParameter['leaderId'] = leaderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdMeetingMeetingIdDelete: async (id: number, meetingId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdMeetingMeetingIdDelete', 'id', id)
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('apiCaseIdMeetingMeetingIdDelete', 'meetingId', meetingId)
            const localVarPath = `/api/Case/{id}/meeting/{meetingId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"meetingId"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} meetingId 
         * @param {CaseMeetingDto} [caseMeetingDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdMeetingMeetingIdPut: async (id: number, meetingId: number, caseMeetingDto?: CaseMeetingDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdMeetingMeetingIdPut', 'id', id)
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('apiCaseIdMeetingMeetingIdPut', 'meetingId', meetingId)
            const localVarPath = `/api/Case/{id}/meeting/{meetingId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"meetingId"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caseMeetingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseMeetingDto} [caseMeetingDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdMeetingPost: async (id: number, caseMeetingDto?: CaseMeetingDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdMeetingPost', 'id', id)
            const localVarPath = `/api/Case/{id}/meeting`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caseMeetingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MeetingCaseDto} [meetingCaseDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdMeetingPut: async (id: number, meetingCaseDto?: MeetingCaseDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdMeetingPut', 'id', id)
            const localVarPath = `/api/Case/{id}/meeting`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(meetingCaseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [values] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdMilestonesPut: async (id: number, values?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdMilestonesPut', 'id', id)
            const localVarPath = `/api/Case/{id}/milestones`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (values !== undefined) {
                localVarQueryParameter['values'] = values;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseUpdateDto} [caseUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdPatch: async (id: number, caseUpdateDto?: CaseUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdPatch', 'id', id)
            const localVarPath = `/api/Case/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caseUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} attachmentId 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdPeriodClientAttachmentAttachmentIdDelete: async (id: number, attachmentId: number, month?: number, year?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdPeriodClientAttachmentAttachmentIdDelete', 'id', id)
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('apiCaseIdPeriodClientAttachmentAttachmentIdDelete', 'attachmentId', attachmentId)
            const localVarPath = `/api/Case/{id}/period/clientAttachment/{attachmentId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"attachmentId"}}`, encodeURIComponent(String(attachmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {Array<any>} [files] 
         * @param {Array<string>} [descriptions] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdPeriodClientAttachmentMultiplePost: async (id: number, month?: number, year?: number, files?: Array<any>, descriptions?: Array<string>, casePeriodAttachmentType?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdPeriodClientAttachmentMultiplePost', 'id', id)
            const localVarPath = `/api/Case/{id}/period/clientAttachmentMultiple`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('Files', element as any);
                })
            }

                if (descriptions) {
                localVarFormParams.append('Descriptions', descriptions.join(COLLECTION_FORMATS.csv));
            }

    
            if (casePeriodAttachmentType !== undefined) { 
                localVarFormParams.append('CasePeriodAttachmentType', casePeriodAttachmentType as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {string} [description] 
         * @param {AttachmentType} [type] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id2] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdPeriodClientAttachmentPost: async (id: number, month?: number, year?: number, description?: string, type?: AttachmentType, casePeriodAttachmentType?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id2?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdPeriodClientAttachmentPost', 'id', id)
            const localVarPath = `/api/Case/{id}/period/clientAttachment`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


            if (description !== undefined) { 
                localVarFormParams.append('Description', description as any);
            }
    
            if (type !== undefined) { 
                localVarFormParams.append('Type', new Blob([JSON.stringify(type)], { type: "application/json", }));
            }
    
            if (casePeriodAttachmentType !== undefined) { 
                localVarFormParams.append('CasePeriodAttachmentType', casePeriodAttachmentType as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (blobName !== undefined) { 
                localVarFormParams.append('BlobName', blobName as any);
            }
    
            if (id2 !== undefined) { 
                localVarFormParams.append('Id', id2 as any);
            }
    
            if (createdAt !== undefined) { 
                localVarFormParams.append('CreatedAt', createdAt as any);
            }
    
            if (createdById !== undefined) { 
                localVarFormParams.append('CreatedById', createdById as any);
            }
    
            if (updatedAt !== undefined) { 
                localVarFormParams.append('UpdatedAt', updatedAt as any);
            }
    
            if (updatedById !== undefined) { 
                localVarFormParams.append('UpdatedById', updatedById as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} attachmentId 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdPeriodExpertAttachmentAttachmentIdDelete: async (id: number, attachmentId: number, month?: number, year?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdPeriodExpertAttachmentAttachmentIdDelete', 'id', id)
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('apiCaseIdPeriodExpertAttachmentAttachmentIdDelete', 'attachmentId', attachmentId)
            const localVarPath = `/api/Case/{id}/period/expertAttachment/{attachmentId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"attachmentId"}}`, encodeURIComponent(String(attachmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {Array<any>} [files] 
         * @param {Array<string>} [descriptions] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdPeriodExpertAttachmentMultiplePost: async (id: number, month?: number, year?: number, files?: Array<any>, descriptions?: Array<string>, casePeriodAttachmentType?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdPeriodExpertAttachmentMultiplePost', 'id', id)
            const localVarPath = `/api/Case/{id}/period/expertAttachmentMultiple`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('Files', element as any);
                })
            }

                if (descriptions) {
                localVarFormParams.append('Descriptions', descriptions.join(COLLECTION_FORMATS.csv));
            }

    
            if (casePeriodAttachmentType !== undefined) { 
                localVarFormParams.append('CasePeriodAttachmentType', casePeriodAttachmentType as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {string} [description] 
         * @param {AttachmentType} [type] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id2] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdPeriodExpertAttachmentPost: async (id: number, month?: number, year?: number, description?: string, type?: AttachmentType, casePeriodAttachmentType?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id2?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdPeriodExpertAttachmentPost', 'id', id)
            const localVarPath = `/api/Case/{id}/period/expertAttachment`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


            if (description !== undefined) { 
                localVarFormParams.append('Description', description as any);
            }
    
            if (type !== undefined) { 
                localVarFormParams.append('Type', new Blob([JSON.stringify(type)], { type: "application/json", }));
            }
    
            if (casePeriodAttachmentType !== undefined) { 
                localVarFormParams.append('CasePeriodAttachmentType', casePeriodAttachmentType as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (blobName !== undefined) { 
                localVarFormParams.append('BlobName', blobName as any);
            }
    
            if (id2 !== undefined) { 
                localVarFormParams.append('Id', id2 as any);
            }
    
            if (createdAt !== undefined) { 
                localVarFormParams.append('CreatedAt', createdAt as any);
            }
    
            if (createdById !== undefined) { 
                localVarFormParams.append('CreatedById', createdById as any);
            }
    
            if (updatedAt !== undefined) { 
                localVarFormParams.append('UpdatedAt', updatedAt as any);
            }
    
            if (updatedById !== undefined) { 
                localVarFormParams.append('UpdatedById', updatedById as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdPeriodSendSummaryPut: async (id: number, month?: number, year?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdPeriodSendSummaryPut', 'id', id)
            const localVarPath = `/api/Case/{id}/period/sendSummary`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {CasePeriodStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdPeriodStatusPut: async (id: number, month?: number, year?: number, status?: CasePeriodStatus, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdPeriodStatusPut', 'id', id)
            const localVarPath = `/api/Case/{id}/period/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {number} [summaryFieldId] 
         * @param {CasePeriodSummaryFieldUpdateDto} [casePeriodSummaryFieldUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdPeriodSummaryPut: async (id: number, month?: number, year?: number, summaryFieldId?: number, casePeriodSummaryFieldUpdateDto?: CasePeriodSummaryFieldUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdPeriodSummaryPut', 'id', id)
            const localVarPath = `/api/Case/{id}/period/summary`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (summaryFieldId !== undefined) {
                localVarQueryParameter['summaryFieldId'] = summaryFieldId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(casePeriodSummaryFieldUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdPricingPolicyDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdPricingPolicyDelete', 'id', id)
            const localVarPath = `/api/Case/{id}/pricingPolicy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PricingPolicyDto} [pricingPolicyDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdPricingPolicyPut: async (id: number, pricingPolicyDto?: PricingPolicyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdPricingPolicyPut', 'id', id)
            const localVarPath = `/api/Case/{id}/pricingPolicy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pricingPolicyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [productId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdProductPut: async (id: number, productId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdProductPut', 'id', id)
            const localVarPath = `/api/Case/{id}/product`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseUpdateDto} [caseUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdPut: async (id: number, caseUpdateDto?: CaseUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdPut', 'id', id)
            const localVarPath = `/api/Case/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caseUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [salesId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdSalesPut: async (id: number, salesId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdSalesPut', 'id', id)
            const localVarPath = `/api/Case/{id}/sales`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (salesId !== undefined) {
                localVarQueryParameter['salesId'] = salesId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdStatusPut: async (id: number, status?: CaseStatus, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdStatusPut', 'id', id)
            const localVarPath = `/api/Case/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdSubscriptionDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdSubscriptionDelete', 'id', id)
            const localVarPath = `/api/Case/{id}/subscription`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SubscriptionDto} [subscriptionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdSubscriptionPut: async (id: number, subscriptionDto?: SubscriptionDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseIdSubscriptionPut', 'id', id)
            const localVarPath = `/api/Case/{id}/subscription`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscriptionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseManagerClientCasesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Case/managerClientCases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCasePatronClientCasesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Case/patronClientCases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CaseCreateDto} [caseCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCasePost: async (caseCreateDto?: CaseCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Case`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caseCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CaseApi - functional programming interface
 * @export
 */
export const CaseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CaseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseAllGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CaseWithoutMilestonesDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseAllGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} caseId 
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseCaseIdClientAttachmentAttachmentIdDelete(caseId: number, attachmentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseCaseIdClientAttachmentAttachmentIdDelete(caseId, attachmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} caseId 
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseCaseIdExpertAttachmentAttachmentIdDelete(caseId: number, attachmentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseCaseIdExpertAttachmentAttachmentIdDelete(caseId, attachmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} caseId 
         * @param {number} [summaryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseCaseIdPeriodSummaryDelete(caseId: number, summaryId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseCaseIdPeriodSummaryDelete(caseId, summaryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseCasePeriodsSummaryGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CasePeriodNewsDetailsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseCasePeriodsSummaryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseClientCasesUserIdGet(userId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GwEmployeeCaseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseClientCasesUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseExpertClientCasesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CaseWithoutMilestonesDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseExpertClientCasesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseExpertOwnerEmployeesCasesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CaseWithoutMilestonesDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseExpertOwnerEmployeesCasesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseExpertOwnerEmployeesCasesIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CaseWithoutMilestonesDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseExpertOwnerEmployeesCasesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GwEmployeeCaseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [accountingAttachmentTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdAccountingAttachmentTypePut(id: number, accountingAttachmentTypeId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdAccountingAttachmentTypePut(id, accountingAttachmentTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [accountingTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdAccountingTypePut(id: number, accountingTypeId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdAccountingTypePut(id, accountingTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdAgreementDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdAgreementDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [description] 
         * @param {AttachmentType} [type] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id2] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdAgreementPost(id: number, description?: string, type?: AttachmentType, casePeriodAttachmentType?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id2?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdAgreementPost(id, description, type, casePeriodAttachmentType, file, fileName, contentType, blobName, id2, createdAt, createdById, updatedAt, updatedById, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<any>} [files] 
         * @param {Array<string>} [descriptions] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdClientAttachmentMultiplePost(id: number, files?: Array<any>, descriptions?: Array<string>, casePeriodAttachmentType?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdClientAttachmentMultiplePost(id, files, descriptions, casePeriodAttachmentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [description] 
         * @param {AttachmentType} [type] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id2] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdClientAttachmentPost(id: number, description?: string, type?: AttachmentType, casePeriodAttachmentType?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id2?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdClientAttachmentPost(id, description, type, casePeriodAttachmentType, file, fileName, contentType, blobName, id2, createdAt, createdById, updatedAt, updatedById, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [companyName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdClientCompanyPut(id: number, companyName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdClientCompanyPut(id, companyName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CompanyDetailsDto} [companyDetailsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdCompanyDetailsPut(id: number, companyDetailsDto?: CompanyDetailsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdCompanyDetailsPut(id, companyDetailsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<any>} [files] 
         * @param {Array<string>} [descriptions] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdExpertAttachmentMultiplePost(id: number, files?: Array<any>, descriptions?: Array<string>, casePeriodAttachmentType?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdExpertAttachmentMultiplePost(id, files, descriptions, casePeriodAttachmentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [description] 
         * @param {AttachmentType} [type] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id2] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdExpertAttachmentPost(id: number, description?: string, type?: AttachmentType, casePeriodAttachmentType?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id2?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdExpertAttachmentPost(id, description, type, casePeriodAttachmentType, file, fileName, contentType, blobName, id2, createdAt, createdById, updatedAt, updatedById, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [expertId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdExpertPut(id: number, expertId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdExpertPut(id, expertId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseGeneralInfoUpdateDto} [caseGeneralInfoUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdGeneralInfoPut(id: number, caseGeneralInfoUpdateDto?: CaseGeneralInfoUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdGeneralInfoPut(id, caseGeneralInfoUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdInvoiceSettingsGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoicePrintoutSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdInvoiceSettingsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {InvoicePrintoutSettingsDto} [invoicePrintoutSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdInvoiceSettingsPut(id: number, invoicePrintoutSettingsDto?: InvoicePrintoutSettingsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdInvoiceSettingsPut(id, invoicePrintoutSettingsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [isVatPayer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdIsVatPayerPut(id: number, isVatPayer?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdIsVatPayerPut(id, isVatPayer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LawsuitCaseDto} [lawsuitCaseDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdLawsuitPut(id: number, lawsuitCaseDto?: LawsuitCaseDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdLawsuitPut(id, lawsuitCaseDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [leadId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdLeadPut(id: number, leadId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdLeadPut(id, leadId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdManagerPut(id: number, requestBody?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdManagerPut(id, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [leaderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdManagerleaderPut(id: number, leaderId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdManagerleaderPut(id, leaderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdMeetingMeetingIdDelete(id: number, meetingId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdMeetingMeetingIdDelete(id, meetingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} meetingId 
         * @param {CaseMeetingDto} [caseMeetingDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdMeetingMeetingIdPut(id: number, meetingId: number, caseMeetingDto?: CaseMeetingDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdMeetingMeetingIdPut(id, meetingId, caseMeetingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseMeetingDto} [caseMeetingDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdMeetingPost(id: number, caseMeetingDto?: CaseMeetingDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdMeetingPost(id, caseMeetingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MeetingCaseDto} [meetingCaseDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdMeetingPut(id: number, meetingCaseDto?: MeetingCaseDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdMeetingPut(id, meetingCaseDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [values] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdMilestonesPut(id: number, values?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdMilestonesPut(id, values, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseUpdateDto} [caseUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdPatch(id: number, caseUpdateDto?: CaseUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdPatch(id, caseUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} attachmentId 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdPeriodClientAttachmentAttachmentIdDelete(id: number, attachmentId: number, month?: number, year?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdPeriodClientAttachmentAttachmentIdDelete(id, attachmentId, month, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {Array<any>} [files] 
         * @param {Array<string>} [descriptions] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdPeriodClientAttachmentMultiplePost(id: number, month?: number, year?: number, files?: Array<any>, descriptions?: Array<string>, casePeriodAttachmentType?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdPeriodClientAttachmentMultiplePost(id, month, year, files, descriptions, casePeriodAttachmentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {string} [description] 
         * @param {AttachmentType} [type] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id2] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdPeriodClientAttachmentPost(id: number, month?: number, year?: number, description?: string, type?: AttachmentType, casePeriodAttachmentType?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id2?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdPeriodClientAttachmentPost(id, month, year, description, type, casePeriodAttachmentType, file, fileName, contentType, blobName, id2, createdAt, createdById, updatedAt, updatedById, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} attachmentId 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdPeriodExpertAttachmentAttachmentIdDelete(id: number, attachmentId: number, month?: number, year?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdPeriodExpertAttachmentAttachmentIdDelete(id, attachmentId, month, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {Array<any>} [files] 
         * @param {Array<string>} [descriptions] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdPeriodExpertAttachmentMultiplePost(id: number, month?: number, year?: number, files?: Array<any>, descriptions?: Array<string>, casePeriodAttachmentType?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdPeriodExpertAttachmentMultiplePost(id, month, year, files, descriptions, casePeriodAttachmentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {string} [description] 
         * @param {AttachmentType} [type] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id2] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdPeriodExpertAttachmentPost(id: number, month?: number, year?: number, description?: string, type?: AttachmentType, casePeriodAttachmentType?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id2?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdPeriodExpertAttachmentPost(id, month, year, description, type, casePeriodAttachmentType, file, fileName, contentType, blobName, id2, createdAt, createdById, updatedAt, updatedById, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdPeriodSendSummaryPut(id: number, month?: number, year?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdPeriodSendSummaryPut(id, month, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {CasePeriodStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdPeriodStatusPut(id: number, month?: number, year?: number, status?: CasePeriodStatus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdPeriodStatusPut(id, month, year, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {number} [summaryFieldId] 
         * @param {CasePeriodSummaryFieldUpdateDto} [casePeriodSummaryFieldUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdPeriodSummaryPut(id: number, month?: number, year?: number, summaryFieldId?: number, casePeriodSummaryFieldUpdateDto?: CasePeriodSummaryFieldUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdPeriodSummaryPut(id, month, year, summaryFieldId, casePeriodSummaryFieldUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdPricingPolicyDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdPricingPolicyDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PricingPolicyDto} [pricingPolicyDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdPricingPolicyPut(id: number, pricingPolicyDto?: PricingPolicyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdPricingPolicyPut(id, pricingPolicyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [productId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdProductPut(id: number, productId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdProductPut(id, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseUpdateDto} [caseUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdPut(id: number, caseUpdateDto?: CaseUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdPut(id, caseUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [salesId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdSalesPut(id: number, salesId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdSalesPut(id, salesId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdStatusPut(id: number, status?: CaseStatus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdStatusPut(id, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdSubscriptionDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdSubscriptionDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SubscriptionDto} [subscriptionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseIdSubscriptionPut(id: number, subscriptionDto?: SubscriptionDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseIdSubscriptionPut(id, subscriptionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseManagerClientCasesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GwEmployeeCaseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseManagerClientCasesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCasePatronClientCasesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GwEmployeeCaseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCasePatronClientCasesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CaseCreateDto} [caseCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCasePost(caseCreateDto?: CaseCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCasePost(caseCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CaseApi - factory interface
 * @export
 */
export const CaseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CaseApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAllGet(options?: any): AxiosPromise<Array<CaseWithoutMilestonesDto>> {
            return localVarFp.apiCaseAllGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} caseId 
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseCaseIdClientAttachmentAttachmentIdDelete(caseId: number, attachmentId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCaseCaseIdClientAttachmentAttachmentIdDelete(caseId, attachmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} caseId 
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseCaseIdExpertAttachmentAttachmentIdDelete(caseId: number, attachmentId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCaseCaseIdExpertAttachmentAttachmentIdDelete(caseId, attachmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} caseId 
         * @param {number} [summaryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseCaseIdPeriodSummaryDelete(caseId: number, summaryId?: number, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseCaseIdPeriodSummaryDelete(caseId, summaryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseCasePeriodsSummaryGet(options?: any): AxiosPromise<Array<CasePeriodNewsDetailsDto>> {
            return localVarFp.apiCaseCasePeriodsSummaryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseClientCasesUserIdGet(userId: number, options?: any): AxiosPromise<Array<GwEmployeeCaseDto>> {
            return localVarFp.apiCaseClientCasesUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseExpertClientCasesGet(options?: any): AxiosPromise<Array<CaseWithoutMilestonesDto>> {
            return localVarFp.apiCaseExpertClientCasesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseExpertOwnerEmployeesCasesGet(options?: any): AxiosPromise<Array<CaseWithoutMilestonesDto>> {
            return localVarFp.apiCaseExpertOwnerEmployeesCasesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseExpertOwnerEmployeesCasesIdGet(id: number, options?: any): AxiosPromise<Array<CaseWithoutMilestonesDto>> {
            return localVarFp.apiCaseExpertOwnerEmployeesCasesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseGet(options?: any): AxiosPromise<Array<GwEmployeeCaseDto>> {
            return localVarFp.apiCaseGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [accountingAttachmentTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdAccountingAttachmentTypePut(id: number, accountingAttachmentTypeId?: number, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdAccountingAttachmentTypePut(id, accountingAttachmentTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [accountingTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdAccountingTypePut(id: number, accountingTypeId?: number, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdAccountingTypePut(id, accountingTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdAgreementDelete(id: number, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdAgreementDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [description] 
         * @param {AttachmentType} [type] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id2] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdAgreementPost(id: number, description?: string, type?: AttachmentType, casePeriodAttachmentType?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id2?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdAgreementPost(id, description, type, casePeriodAttachmentType, file, fileName, contentType, blobName, id2, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<any>} [files] 
         * @param {Array<string>} [descriptions] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdClientAttachmentMultiplePost(id: number, files?: Array<any>, descriptions?: Array<string>, casePeriodAttachmentType?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCaseIdClientAttachmentMultiplePost(id, files, descriptions, casePeriodAttachmentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [description] 
         * @param {AttachmentType} [type] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id2] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdClientAttachmentPost(id: number, description?: string, type?: AttachmentType, casePeriodAttachmentType?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id2?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCaseIdClientAttachmentPost(id, description, type, casePeriodAttachmentType, file, fileName, contentType, blobName, id2, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [companyName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdClientCompanyPut(id: number, companyName?: string, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdClientCompanyPut(id, companyName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CompanyDetailsDto} [companyDetailsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdCompanyDetailsPut(id: number, companyDetailsDto?: CompanyDetailsDto, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdCompanyDetailsPut(id, companyDetailsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCaseIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<any>} [files] 
         * @param {Array<string>} [descriptions] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdExpertAttachmentMultiplePost(id: number, files?: Array<any>, descriptions?: Array<string>, casePeriodAttachmentType?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCaseIdExpertAttachmentMultiplePost(id, files, descriptions, casePeriodAttachmentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [description] 
         * @param {AttachmentType} [type] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id2] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdExpertAttachmentPost(id: number, description?: string, type?: AttachmentType, casePeriodAttachmentType?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id2?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCaseIdExpertAttachmentPost(id, description, type, casePeriodAttachmentType, file, fileName, contentType, blobName, id2, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [expertId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdExpertPut(id: number, expertId?: number, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdExpertPut(id, expertId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseGeneralInfoUpdateDto} [caseGeneralInfoUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdGeneralInfoPut(id: number, caseGeneralInfoUpdateDto?: CaseGeneralInfoUpdateDto, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdGeneralInfoPut(id, caseGeneralInfoUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdGet(id: number, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdInvoiceSettingsGet(id: number, options?: any): AxiosPromise<InvoicePrintoutSettingsDto> {
            return localVarFp.apiCaseIdInvoiceSettingsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {InvoicePrintoutSettingsDto} [invoicePrintoutSettingsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdInvoiceSettingsPut(id: number, invoicePrintoutSettingsDto?: InvoicePrintoutSettingsDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiCaseIdInvoiceSettingsPut(id, invoicePrintoutSettingsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [isVatPayer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdIsVatPayerPut(id: number, isVatPayer?: boolean, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdIsVatPayerPut(id, isVatPayer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {LawsuitCaseDto} [lawsuitCaseDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdLawsuitPut(id: number, lawsuitCaseDto?: LawsuitCaseDto, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdLawsuitPut(id, lawsuitCaseDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [leadId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdLeadPut(id: number, leadId?: number, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdLeadPut(id, leadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdManagerPut(id: number, requestBody?: Array<number>, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdManagerPut(id, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [leaderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdManagerleaderPut(id: number, leaderId?: number, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdManagerleaderPut(id, leaderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdMeetingMeetingIdDelete(id: number, meetingId: number, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdMeetingMeetingIdDelete(id, meetingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} meetingId 
         * @param {CaseMeetingDto} [caseMeetingDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdMeetingMeetingIdPut(id: number, meetingId: number, caseMeetingDto?: CaseMeetingDto, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdMeetingMeetingIdPut(id, meetingId, caseMeetingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseMeetingDto} [caseMeetingDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdMeetingPost(id: number, caseMeetingDto?: CaseMeetingDto, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdMeetingPost(id, caseMeetingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {MeetingCaseDto} [meetingCaseDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdMeetingPut(id: number, meetingCaseDto?: MeetingCaseDto, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdMeetingPut(id, meetingCaseDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [values] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdMilestonesPut(id: number, values?: string, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdMilestonesPut(id, values, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseUpdateDto} [caseUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdPatch(id: number, caseUpdateDto?: CaseUpdateDto, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdPatch(id, caseUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} attachmentId 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdPeriodClientAttachmentAttachmentIdDelete(id: number, attachmentId: number, month?: number, year?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCaseIdPeriodClientAttachmentAttachmentIdDelete(id, attachmentId, month, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {Array<any>} [files] 
         * @param {Array<string>} [descriptions] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdPeriodClientAttachmentMultiplePost(id: number, month?: number, year?: number, files?: Array<any>, descriptions?: Array<string>, casePeriodAttachmentType?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCaseIdPeriodClientAttachmentMultiplePost(id, month, year, files, descriptions, casePeriodAttachmentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {string} [description] 
         * @param {AttachmentType} [type] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id2] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdPeriodClientAttachmentPost(id: number, month?: number, year?: number, description?: string, type?: AttachmentType, casePeriodAttachmentType?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id2?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCaseIdPeriodClientAttachmentPost(id, month, year, description, type, casePeriodAttachmentType, file, fileName, contentType, blobName, id2, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} attachmentId 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdPeriodExpertAttachmentAttachmentIdDelete(id: number, attachmentId: number, month?: number, year?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCaseIdPeriodExpertAttachmentAttachmentIdDelete(id, attachmentId, month, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {Array<any>} [files] 
         * @param {Array<string>} [descriptions] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdPeriodExpertAttachmentMultiplePost(id: number, month?: number, year?: number, files?: Array<any>, descriptions?: Array<string>, casePeriodAttachmentType?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCaseIdPeriodExpertAttachmentMultiplePost(id, month, year, files, descriptions, casePeriodAttachmentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {string} [description] 
         * @param {AttachmentType} [type] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id2] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdPeriodExpertAttachmentPost(id: number, month?: number, year?: number, description?: string, type?: AttachmentType, casePeriodAttachmentType?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id2?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCaseIdPeriodExpertAttachmentPost(id, month, year, description, type, casePeriodAttachmentType, file, fileName, contentType, blobName, id2, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdPeriodSendSummaryPut(id: number, month?: number, year?: number, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdPeriodSendSummaryPut(id, month, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {CasePeriodStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdPeriodStatusPut(id: number, month?: number, year?: number, status?: CasePeriodStatus, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdPeriodStatusPut(id, month, year, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {number} [summaryFieldId] 
         * @param {CasePeriodSummaryFieldUpdateDto} [casePeriodSummaryFieldUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdPeriodSummaryPut(id: number, month?: number, year?: number, summaryFieldId?: number, casePeriodSummaryFieldUpdateDto?: CasePeriodSummaryFieldUpdateDto, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdPeriodSummaryPut(id, month, year, summaryFieldId, casePeriodSummaryFieldUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdPricingPolicyDelete(id: number, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdPricingPolicyDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PricingPolicyDto} [pricingPolicyDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdPricingPolicyPut(id: number, pricingPolicyDto?: PricingPolicyDto, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdPricingPolicyPut(id, pricingPolicyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [productId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdProductPut(id: number, productId?: number, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdProductPut(id, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseUpdateDto} [caseUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdPut(id: number, caseUpdateDto?: CaseUpdateDto, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdPut(id, caseUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [salesId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdSalesPut(id: number, salesId?: number, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdSalesPut(id, salesId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdStatusPut(id: number, status?: CaseStatus, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdStatusPut(id, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdSubscriptionDelete(id: number, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdSubscriptionDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SubscriptionDto} [subscriptionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseIdSubscriptionPut(id: number, subscriptionDto?: SubscriptionDto, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCaseIdSubscriptionPut(id, subscriptionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseManagerClientCasesGet(options?: any): AxiosPromise<Array<GwEmployeeCaseDto>> {
            return localVarFp.apiCaseManagerClientCasesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCasePatronClientCasesGet(options?: any): AxiosPromise<Array<GwEmployeeCaseDto>> {
            return localVarFp.apiCasePatronClientCasesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CaseCreateDto} [caseCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCasePost(caseCreateDto?: CaseCreateDto, options?: any): AxiosPromise<CaseDetailsDto> {
            return localVarFp.apiCasePost(caseCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CaseApi - object-oriented interface
 * @export
 * @class CaseApi
 * @extends {BaseAPI}
 */
export class CaseApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseAllGet(options?: any) {
        return CaseApiFp(this.configuration).apiCaseAllGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} caseId 
     * @param {number} attachmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseCaseIdClientAttachmentAttachmentIdDelete(caseId: number, attachmentId: number, options?: any) {
        return CaseApiFp(this.configuration).apiCaseCaseIdClientAttachmentAttachmentIdDelete(caseId, attachmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} caseId 
     * @param {number} attachmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseCaseIdExpertAttachmentAttachmentIdDelete(caseId: number, attachmentId: number, options?: any) {
        return CaseApiFp(this.configuration).apiCaseCaseIdExpertAttachmentAttachmentIdDelete(caseId, attachmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} caseId 
     * @param {number} [summaryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseCaseIdPeriodSummaryDelete(caseId: number, summaryId?: number, options?: any) {
        return CaseApiFp(this.configuration).apiCaseCaseIdPeriodSummaryDelete(caseId, summaryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseCasePeriodsSummaryGet(options?: any) {
        return CaseApiFp(this.configuration).apiCaseCasePeriodsSummaryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseClientCasesUserIdGet(userId: number, options?: any) {
        return CaseApiFp(this.configuration).apiCaseClientCasesUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseExpertClientCasesGet(options?: any) {
        return CaseApiFp(this.configuration).apiCaseExpertClientCasesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseExpertOwnerEmployeesCasesGet(options?: any) {
        return CaseApiFp(this.configuration).apiCaseExpertOwnerEmployeesCasesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseExpertOwnerEmployeesCasesIdGet(id: number, options?: any) {
        return CaseApiFp(this.configuration).apiCaseExpertOwnerEmployeesCasesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseGet(options?: any) {
        return CaseApiFp(this.configuration).apiCaseGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [accountingAttachmentTypeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdAccountingAttachmentTypePut(id: number, accountingAttachmentTypeId?: number, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdAccountingAttachmentTypePut(id, accountingAttachmentTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [accountingTypeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdAccountingTypePut(id: number, accountingTypeId?: number, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdAccountingTypePut(id, accountingTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdAgreementDelete(id: number, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdAgreementDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [description] 
     * @param {AttachmentType} [type] 
     * @param {string} [casePeriodAttachmentType] 
     * @param {any} [file] 
     * @param {string} [fileName] 
     * @param {string} [contentType] 
     * @param {string} [blobName] 
     * @param {number} [id2] 
     * @param {string} [createdAt] 
     * @param {number} [createdById] 
     * @param {string} [updatedAt] 
     * @param {number} [updatedById] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdAgreementPost(id: number, description?: string, type?: AttachmentType, casePeriodAttachmentType?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id2?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdAgreementPost(id, description, type, casePeriodAttachmentType, file, fileName, contentType, blobName, id2, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<any>} [files] 
     * @param {Array<string>} [descriptions] 
     * @param {string} [casePeriodAttachmentType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdClientAttachmentMultiplePost(id: number, files?: Array<any>, descriptions?: Array<string>, casePeriodAttachmentType?: string, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdClientAttachmentMultiplePost(id, files, descriptions, casePeriodAttachmentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [description] 
     * @param {AttachmentType} [type] 
     * @param {string} [casePeriodAttachmentType] 
     * @param {any} [file] 
     * @param {string} [fileName] 
     * @param {string} [contentType] 
     * @param {string} [blobName] 
     * @param {number} [id2] 
     * @param {string} [createdAt] 
     * @param {number} [createdById] 
     * @param {string} [updatedAt] 
     * @param {number} [updatedById] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdClientAttachmentPost(id: number, description?: string, type?: AttachmentType, casePeriodAttachmentType?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id2?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdClientAttachmentPost(id, description, type, casePeriodAttachmentType, file, fileName, contentType, blobName, id2, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [companyName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdClientCompanyPut(id: number, companyName?: string, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdClientCompanyPut(id, companyName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CompanyDetailsDto} [companyDetailsDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdCompanyDetailsPut(id: number, companyDetailsDto?: CompanyDetailsDto, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdCompanyDetailsPut(id, companyDetailsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdDelete(id: number, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<any>} [files] 
     * @param {Array<string>} [descriptions] 
     * @param {string} [casePeriodAttachmentType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdExpertAttachmentMultiplePost(id: number, files?: Array<any>, descriptions?: Array<string>, casePeriodAttachmentType?: string, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdExpertAttachmentMultiplePost(id, files, descriptions, casePeriodAttachmentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [description] 
     * @param {AttachmentType} [type] 
     * @param {string} [casePeriodAttachmentType] 
     * @param {any} [file] 
     * @param {string} [fileName] 
     * @param {string} [contentType] 
     * @param {string} [blobName] 
     * @param {number} [id2] 
     * @param {string} [createdAt] 
     * @param {number} [createdById] 
     * @param {string} [updatedAt] 
     * @param {number} [updatedById] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdExpertAttachmentPost(id: number, description?: string, type?: AttachmentType, casePeriodAttachmentType?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id2?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdExpertAttachmentPost(id, description, type, casePeriodAttachmentType, file, fileName, contentType, blobName, id2, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [expertId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdExpertPut(id: number, expertId?: number, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdExpertPut(id, expertId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CaseGeneralInfoUpdateDto} [caseGeneralInfoUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdGeneralInfoPut(id: number, caseGeneralInfoUpdateDto?: CaseGeneralInfoUpdateDto, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdGeneralInfoPut(id, caseGeneralInfoUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdGet(id: number, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdInvoiceSettingsGet(id: number, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdInvoiceSettingsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {InvoicePrintoutSettingsDto} [invoicePrintoutSettingsDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdInvoiceSettingsPut(id: number, invoicePrintoutSettingsDto?: InvoicePrintoutSettingsDto, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdInvoiceSettingsPut(id, invoicePrintoutSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {boolean} [isVatPayer] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdIsVatPayerPut(id: number, isVatPayer?: boolean, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdIsVatPayerPut(id, isVatPayer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {LawsuitCaseDto} [lawsuitCaseDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdLawsuitPut(id: number, lawsuitCaseDto?: LawsuitCaseDto, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdLawsuitPut(id, lawsuitCaseDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [leadId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdLeadPut(id: number, leadId?: number, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdLeadPut(id, leadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdManagerPut(id: number, requestBody?: Array<number>, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdManagerPut(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [leaderId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdManagerleaderPut(id: number, leaderId?: number, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdManagerleaderPut(id, leaderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} meetingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdMeetingMeetingIdDelete(id: number, meetingId: number, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdMeetingMeetingIdDelete(id, meetingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} meetingId 
     * @param {CaseMeetingDto} [caseMeetingDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdMeetingMeetingIdPut(id: number, meetingId: number, caseMeetingDto?: CaseMeetingDto, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdMeetingMeetingIdPut(id, meetingId, caseMeetingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CaseMeetingDto} [caseMeetingDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdMeetingPost(id: number, caseMeetingDto?: CaseMeetingDto, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdMeetingPost(id, caseMeetingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {MeetingCaseDto} [meetingCaseDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdMeetingPut(id: number, meetingCaseDto?: MeetingCaseDto, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdMeetingPut(id, meetingCaseDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [values] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdMilestonesPut(id: number, values?: string, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdMilestonesPut(id, values, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CaseUpdateDto} [caseUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdPatch(id: number, caseUpdateDto?: CaseUpdateDto, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdPatch(id, caseUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} attachmentId 
     * @param {number} [month] 
     * @param {number} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdPeriodClientAttachmentAttachmentIdDelete(id: number, attachmentId: number, month?: number, year?: number, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdPeriodClientAttachmentAttachmentIdDelete(id, attachmentId, month, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [month] 
     * @param {number} [year] 
     * @param {Array<any>} [files] 
     * @param {Array<string>} [descriptions] 
     * @param {string} [casePeriodAttachmentType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdPeriodClientAttachmentMultiplePost(id: number, month?: number, year?: number, files?: Array<any>, descriptions?: Array<string>, casePeriodAttachmentType?: string, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdPeriodClientAttachmentMultiplePost(id, month, year, files, descriptions, casePeriodAttachmentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [month] 
     * @param {number} [year] 
     * @param {string} [description] 
     * @param {AttachmentType} [type] 
     * @param {string} [casePeriodAttachmentType] 
     * @param {any} [file] 
     * @param {string} [fileName] 
     * @param {string} [contentType] 
     * @param {string} [blobName] 
     * @param {number} [id2] 
     * @param {string} [createdAt] 
     * @param {number} [createdById] 
     * @param {string} [updatedAt] 
     * @param {number} [updatedById] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdPeriodClientAttachmentPost(id: number, month?: number, year?: number, description?: string, type?: AttachmentType, casePeriodAttachmentType?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id2?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdPeriodClientAttachmentPost(id, month, year, description, type, casePeriodAttachmentType, file, fileName, contentType, blobName, id2, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} attachmentId 
     * @param {number} [month] 
     * @param {number} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdPeriodExpertAttachmentAttachmentIdDelete(id: number, attachmentId: number, month?: number, year?: number, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdPeriodExpertAttachmentAttachmentIdDelete(id, attachmentId, month, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [month] 
     * @param {number} [year] 
     * @param {Array<any>} [files] 
     * @param {Array<string>} [descriptions] 
     * @param {string} [casePeriodAttachmentType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdPeriodExpertAttachmentMultiplePost(id: number, month?: number, year?: number, files?: Array<any>, descriptions?: Array<string>, casePeriodAttachmentType?: string, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdPeriodExpertAttachmentMultiplePost(id, month, year, files, descriptions, casePeriodAttachmentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [month] 
     * @param {number} [year] 
     * @param {string} [description] 
     * @param {AttachmentType} [type] 
     * @param {string} [casePeriodAttachmentType] 
     * @param {any} [file] 
     * @param {string} [fileName] 
     * @param {string} [contentType] 
     * @param {string} [blobName] 
     * @param {number} [id2] 
     * @param {string} [createdAt] 
     * @param {number} [createdById] 
     * @param {string} [updatedAt] 
     * @param {number} [updatedById] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdPeriodExpertAttachmentPost(id: number, month?: number, year?: number, description?: string, type?: AttachmentType, casePeriodAttachmentType?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id2?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdPeriodExpertAttachmentPost(id, month, year, description, type, casePeriodAttachmentType, file, fileName, contentType, blobName, id2, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [month] 
     * @param {number} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdPeriodSendSummaryPut(id: number, month?: number, year?: number, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdPeriodSendSummaryPut(id, month, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [month] 
     * @param {number} [year] 
     * @param {CasePeriodStatus} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdPeriodStatusPut(id: number, month?: number, year?: number, status?: CasePeriodStatus, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdPeriodStatusPut(id, month, year, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [month] 
     * @param {number} [year] 
     * @param {number} [summaryFieldId] 
     * @param {CasePeriodSummaryFieldUpdateDto} [casePeriodSummaryFieldUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdPeriodSummaryPut(id: number, month?: number, year?: number, summaryFieldId?: number, casePeriodSummaryFieldUpdateDto?: CasePeriodSummaryFieldUpdateDto, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdPeriodSummaryPut(id, month, year, summaryFieldId, casePeriodSummaryFieldUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdPricingPolicyDelete(id: number, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdPricingPolicyDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PricingPolicyDto} [pricingPolicyDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdPricingPolicyPut(id: number, pricingPolicyDto?: PricingPolicyDto, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdPricingPolicyPut(id, pricingPolicyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [productId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdProductPut(id: number, productId?: number, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdProductPut(id, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CaseUpdateDto} [caseUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdPut(id: number, caseUpdateDto?: CaseUpdateDto, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdPut(id, caseUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [salesId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdSalesPut(id: number, salesId?: number, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdSalesPut(id, salesId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CaseStatus} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdStatusPut(id: number, status?: CaseStatus, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdStatusPut(id, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdSubscriptionDelete(id: number, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdSubscriptionDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SubscriptionDto} [subscriptionDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseIdSubscriptionPut(id: number, subscriptionDto?: SubscriptionDto, options?: any) {
        return CaseApiFp(this.configuration).apiCaseIdSubscriptionPut(id, subscriptionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCaseManagerClientCasesGet(options?: any) {
        return CaseApiFp(this.configuration).apiCaseManagerClientCasesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCasePatronClientCasesGet(options?: any) {
        return CaseApiFp(this.configuration).apiCasePatronClientCasesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CaseCreateDto} [caseCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public apiCasePost(caseCreateDto?: CaseCreateDto, options?: any) {
        return CaseApiFp(this.configuration).apiCasePost(caseCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CaseAttachmentApi - axios parameter creator
 * @export
 */
export const CaseAttachmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} attachmentId 
         * @param {CaseAttachmentUpdateTypeDto} [caseAttachmentUpdateTypeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAttachmentAttachmentIdTypePut: async (attachmentId: number, caseAttachmentUpdateTypeDto?: CaseAttachmentUpdateTypeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('apiCaseAttachmentAttachmentIdTypePut', 'attachmentId', attachmentId)
            const localVarPath = `/api/CaseAttachment/{attachmentId}/type`
                .replace(`{${"attachmentId"}}`, encodeURIComponent(String(attachmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caseAttachmentUpdateTypeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} caseId 
         * @param {boolean} [newOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAttachmentCaseIdClientAttachmentsGet: async (caseId: number, newOnly?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiCaseAttachmentCaseIdClientAttachmentsGet', 'caseId', caseId)
            const localVarPath = `/api/CaseAttachment/{caseId}/clientAttachments`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (newOnly !== undefined) {
                localVarQueryParameter['newOnly'] = newOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} caseId 
         * @param {boolean} [newOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAttachmentCaseIdExpertAttachmentsGet: async (caseId: number, newOnly?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiCaseAttachmentCaseIdExpertAttachmentsGet', 'caseId', caseId)
            const localVarPath = `/api/CaseAttachment/{caseId}/expertAttachments`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (newOnly !== undefined) {
                localVarQueryParameter['newOnly'] = newOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAttachmentGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CaseAttachment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAttachmentIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseAttachmentIdDelete', 'id', id)
            const localVarPath = `/api/CaseAttachment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAttachmentIdFileStreamGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseAttachmentIdFileStreamGet', 'id', id)
            const localVarPath = `/api/CaseAttachment/{id}/FileStream`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAttachmentIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseAttachmentIdGet', 'id', id)
            const localVarPath = `/api/CaseAttachment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseAttachmentUpdatePaidStatusDto} [caseAttachmentUpdatePaidStatusDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAttachmentIdPaidPut: async (id: number, caseAttachmentUpdatePaidStatusDto?: CaseAttachmentUpdatePaidStatusDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseAttachmentIdPaidPut', 'id', id)
            const localVarPath = `/api/CaseAttachment/{id}/paid`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caseAttachmentUpdatePaidStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseAttachmentUpdateDto} [caseAttachmentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAttachmentIdPatch: async (id: number, caseAttachmentUpdateDto?: CaseAttachmentUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseAttachmentIdPatch', 'id', id)
            const localVarPath = `/api/CaseAttachment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caseAttachmentUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseAttachmentUpdateDto} [caseAttachmentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAttachmentIdPut: async (id: number, caseAttachmentUpdateDto?: CaseAttachmentUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseAttachmentIdPut', 'id', id)
            const localVarPath = `/api/CaseAttachment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caseAttachmentUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<any>} [files] 
         * @param {Array<string>} [descriptions] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAttachmentMultiplePost: async (files?: Array<any>, descriptions?: Array<string>, casePeriodAttachmentType?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CaseAttachment/multiple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('Files', element as any);
                })
            }

                if (descriptions) {
                localVarFormParams.append('Descriptions', descriptions.join(COLLECTION_FORMATS.csv));
            }

    
            if (casePeriodAttachmentType !== undefined) { 
                localVarFormParams.append('CasePeriodAttachmentType', casePeriodAttachmentType as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} casePeriodId 
         * @param {string} [attachmentType] 
         * @param {boolean} [newOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAttachmentPeriodCasePeriodIdClientAttachmentsGet: async (casePeriodId: number, attachmentType?: string, newOnly?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'casePeriodId' is not null or undefined
            assertParamExists('apiCaseAttachmentPeriodCasePeriodIdClientAttachmentsGet', 'casePeriodId', casePeriodId)
            const localVarPath = `/api/CaseAttachment/period/{casePeriodId}/clientAttachments`
                .replace(`{${"casePeriodId"}}`, encodeURIComponent(String(casePeriodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (attachmentType !== undefined) {
                localVarQueryParameter['attachmentType'] = attachmentType;
            }

            if (newOnly !== undefined) {
                localVarQueryParameter['newOnly'] = newOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} casePeriodId 
         * @param {boolean} [newOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAttachmentPeriodCasePeriodIdExpertAttachmentsGet: async (casePeriodId: number, newOnly?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'casePeriodId' is not null or undefined
            assertParamExists('apiCaseAttachmentPeriodCasePeriodIdExpertAttachmentsGet', 'casePeriodId', casePeriodId)
            const localVarPath = `/api/CaseAttachment/period/{casePeriodId}/expertAttachments`
                .replace(`{${"casePeriodId"}}`, encodeURIComponent(String(casePeriodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (newOnly !== undefined) {
                localVarQueryParameter['newOnly'] = newOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [description] 
         * @param {AttachmentType} [type] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAttachmentPost: async (description?: string, type?: AttachmentType, casePeriodAttachmentType?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CaseAttachment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


            if (description !== undefined) { 
                localVarFormParams.append('Description', description as any);
            }
    
            if (type !== undefined) { 
                localVarFormParams.append('Type', new Blob([JSON.stringify(type)], { type: "application/json", }));
            }
    
            if (casePeriodAttachmentType !== undefined) { 
                localVarFormParams.append('CasePeriodAttachmentType', casePeriodAttachmentType as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (blobName !== undefined) { 
                localVarFormParams.append('BlobName', blobName as any);
            }
    
            if (id !== undefined) { 
                localVarFormParams.append('Id', id as any);
            }
    
            if (createdAt !== undefined) { 
                localVarFormParams.append('CreatedAt', createdAt as any);
            }
    
            if (createdById !== undefined) { 
                localVarFormParams.append('CreatedById', createdById as any);
            }
    
            if (updatedAt !== undefined) { 
                localVarFormParams.append('UpdatedAt', updatedAt as any);
            }
    
            if (updatedById !== undefined) { 
                localVarFormParams.append('UpdatedById', updatedById as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CaseAttachmentApi - functional programming interface
 * @export
 */
export const CaseAttachmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CaseAttachmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} attachmentId 
         * @param {CaseAttachmentUpdateTypeDto} [caseAttachmentUpdateTypeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseAttachmentAttachmentIdTypePut(attachmentId: number, caseAttachmentUpdateTypeDto?: CaseAttachmentUpdateTypeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseAttachmentDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseAttachmentAttachmentIdTypePut(attachmentId, caseAttachmentUpdateTypeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} caseId 
         * @param {boolean} [newOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseAttachmentCaseIdClientAttachmentsGet(caseId: number, newOnly?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseAttachmentCaseIdClientAttachmentsGet(caseId, newOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} caseId 
         * @param {boolean} [newOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseAttachmentCaseIdExpertAttachmentsGet(caseId: number, newOnly?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseAttachmentCaseIdExpertAttachmentsGet(caseId, newOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseAttachmentGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CaseAttachmentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseAttachmentGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseAttachmentIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseAttachmentIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseAttachmentIdFileStreamGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseAttachmentIdFileStreamGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseAttachmentIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseAttachmentDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseAttachmentIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseAttachmentUpdatePaidStatusDto} [caseAttachmentUpdatePaidStatusDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseAttachmentIdPaidPut(id: number, caseAttachmentUpdatePaidStatusDto?: CaseAttachmentUpdatePaidStatusDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseAttachmentDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseAttachmentIdPaidPut(id, caseAttachmentUpdatePaidStatusDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseAttachmentUpdateDto} [caseAttachmentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseAttachmentIdPatch(id: number, caseAttachmentUpdateDto?: CaseAttachmentUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseAttachmentDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseAttachmentIdPatch(id, caseAttachmentUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseAttachmentUpdateDto} [caseAttachmentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseAttachmentIdPut(id: number, caseAttachmentUpdateDto?: CaseAttachmentUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseAttachmentDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseAttachmentIdPut(id, caseAttachmentUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<any>} [files] 
         * @param {Array<string>} [descriptions] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseAttachmentMultiplePost(files?: Array<any>, descriptions?: Array<string>, casePeriodAttachmentType?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CaseAttachmentDetailsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseAttachmentMultiplePost(files, descriptions, casePeriodAttachmentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} casePeriodId 
         * @param {string} [attachmentType] 
         * @param {boolean} [newOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseAttachmentPeriodCasePeriodIdClientAttachmentsGet(casePeriodId: number, attachmentType?: string, newOnly?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseAttachmentPeriodCasePeriodIdClientAttachmentsGet(casePeriodId, attachmentType, newOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} casePeriodId 
         * @param {boolean} [newOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseAttachmentPeriodCasePeriodIdExpertAttachmentsGet(casePeriodId: number, newOnly?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseAttachmentPeriodCasePeriodIdExpertAttachmentsGet(casePeriodId, newOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [description] 
         * @param {AttachmentType} [type] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseAttachmentPost(description?: string, type?: AttachmentType, casePeriodAttachmentType?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseAttachmentDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseAttachmentPost(description, type, casePeriodAttachmentType, file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CaseAttachmentApi - factory interface
 * @export
 */
export const CaseAttachmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CaseAttachmentApiFp(configuration)
    return {
        /**
         * 
         * @param {number} attachmentId 
         * @param {CaseAttachmentUpdateTypeDto} [caseAttachmentUpdateTypeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAttachmentAttachmentIdTypePut(attachmentId: number, caseAttachmentUpdateTypeDto?: CaseAttachmentUpdateTypeDto, options?: any): AxiosPromise<CaseAttachmentDetailsDto> {
            return localVarFp.apiCaseAttachmentAttachmentIdTypePut(attachmentId, caseAttachmentUpdateTypeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} caseId 
         * @param {boolean} [newOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAttachmentCaseIdClientAttachmentsGet(caseId: number, newOnly?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiCaseAttachmentCaseIdClientAttachmentsGet(caseId, newOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} caseId 
         * @param {boolean} [newOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAttachmentCaseIdExpertAttachmentsGet(caseId: number, newOnly?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiCaseAttachmentCaseIdExpertAttachmentsGet(caseId, newOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAttachmentGet(options?: any): AxiosPromise<Array<CaseAttachmentDto>> {
            return localVarFp.apiCaseAttachmentGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAttachmentIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCaseAttachmentIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAttachmentIdFileStreamGet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCaseAttachmentIdFileStreamGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAttachmentIdGet(id: number, options?: any): AxiosPromise<CaseAttachmentDetailsDto> {
            return localVarFp.apiCaseAttachmentIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseAttachmentUpdatePaidStatusDto} [caseAttachmentUpdatePaidStatusDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAttachmentIdPaidPut(id: number, caseAttachmentUpdatePaidStatusDto?: CaseAttachmentUpdatePaidStatusDto, options?: any): AxiosPromise<CaseAttachmentDetailsDto> {
            return localVarFp.apiCaseAttachmentIdPaidPut(id, caseAttachmentUpdatePaidStatusDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseAttachmentUpdateDto} [caseAttachmentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAttachmentIdPatch(id: number, caseAttachmentUpdateDto?: CaseAttachmentUpdateDto, options?: any): AxiosPromise<CaseAttachmentDetailsDto> {
            return localVarFp.apiCaseAttachmentIdPatch(id, caseAttachmentUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseAttachmentUpdateDto} [caseAttachmentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAttachmentIdPut(id: number, caseAttachmentUpdateDto?: CaseAttachmentUpdateDto, options?: any): AxiosPromise<CaseAttachmentDetailsDto> {
            return localVarFp.apiCaseAttachmentIdPut(id, caseAttachmentUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<any>} [files] 
         * @param {Array<string>} [descriptions] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAttachmentMultiplePost(files?: Array<any>, descriptions?: Array<string>, casePeriodAttachmentType?: string, options?: any): AxiosPromise<Array<CaseAttachmentDetailsDto>> {
            return localVarFp.apiCaseAttachmentMultiplePost(files, descriptions, casePeriodAttachmentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} casePeriodId 
         * @param {string} [attachmentType] 
         * @param {boolean} [newOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAttachmentPeriodCasePeriodIdClientAttachmentsGet(casePeriodId: number, attachmentType?: string, newOnly?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiCaseAttachmentPeriodCasePeriodIdClientAttachmentsGet(casePeriodId, attachmentType, newOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} casePeriodId 
         * @param {boolean} [newOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAttachmentPeriodCasePeriodIdExpertAttachmentsGet(casePeriodId: number, newOnly?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiCaseAttachmentPeriodCasePeriodIdExpertAttachmentsGet(casePeriodId, newOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [description] 
         * @param {AttachmentType} [type] 
         * @param {string} [casePeriodAttachmentType] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseAttachmentPost(description?: string, type?: AttachmentType, casePeriodAttachmentType?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): AxiosPromise<CaseAttachmentDetailsDto> {
            return localVarFp.apiCaseAttachmentPost(description, type, casePeriodAttachmentType, file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CaseAttachmentApi - object-oriented interface
 * @export
 * @class CaseAttachmentApi
 * @extends {BaseAPI}
 */
export class CaseAttachmentApi extends BaseAPI {
    /**
     * 
     * @param {number} attachmentId 
     * @param {CaseAttachmentUpdateTypeDto} [caseAttachmentUpdateTypeDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseAttachmentApi
     */
    public apiCaseAttachmentAttachmentIdTypePut(attachmentId: number, caseAttachmentUpdateTypeDto?: CaseAttachmentUpdateTypeDto, options?: any) {
        return CaseAttachmentApiFp(this.configuration).apiCaseAttachmentAttachmentIdTypePut(attachmentId, caseAttachmentUpdateTypeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} caseId 
     * @param {boolean} [newOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseAttachmentApi
     */
    public apiCaseAttachmentCaseIdClientAttachmentsGet(caseId: number, newOnly?: boolean, options?: any) {
        return CaseAttachmentApiFp(this.configuration).apiCaseAttachmentCaseIdClientAttachmentsGet(caseId, newOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} caseId 
     * @param {boolean} [newOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseAttachmentApi
     */
    public apiCaseAttachmentCaseIdExpertAttachmentsGet(caseId: number, newOnly?: boolean, options?: any) {
        return CaseAttachmentApiFp(this.configuration).apiCaseAttachmentCaseIdExpertAttachmentsGet(caseId, newOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseAttachmentApi
     */
    public apiCaseAttachmentGet(options?: any) {
        return CaseAttachmentApiFp(this.configuration).apiCaseAttachmentGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseAttachmentApi
     */
    public apiCaseAttachmentIdDelete(id: number, options?: any) {
        return CaseAttachmentApiFp(this.configuration).apiCaseAttachmentIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseAttachmentApi
     */
    public apiCaseAttachmentIdFileStreamGet(id: number, options?: any) {
        return CaseAttachmentApiFp(this.configuration).apiCaseAttachmentIdFileStreamGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseAttachmentApi
     */
    public apiCaseAttachmentIdGet(id: number, options?: any) {
        return CaseAttachmentApiFp(this.configuration).apiCaseAttachmentIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CaseAttachmentUpdatePaidStatusDto} [caseAttachmentUpdatePaidStatusDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseAttachmentApi
     */
    public apiCaseAttachmentIdPaidPut(id: number, caseAttachmentUpdatePaidStatusDto?: CaseAttachmentUpdatePaidStatusDto, options?: any) {
        return CaseAttachmentApiFp(this.configuration).apiCaseAttachmentIdPaidPut(id, caseAttachmentUpdatePaidStatusDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CaseAttachmentUpdateDto} [caseAttachmentUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseAttachmentApi
     */
    public apiCaseAttachmentIdPatch(id: number, caseAttachmentUpdateDto?: CaseAttachmentUpdateDto, options?: any) {
        return CaseAttachmentApiFp(this.configuration).apiCaseAttachmentIdPatch(id, caseAttachmentUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CaseAttachmentUpdateDto} [caseAttachmentUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseAttachmentApi
     */
    public apiCaseAttachmentIdPut(id: number, caseAttachmentUpdateDto?: CaseAttachmentUpdateDto, options?: any) {
        return CaseAttachmentApiFp(this.configuration).apiCaseAttachmentIdPut(id, caseAttachmentUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<any>} [files] 
     * @param {Array<string>} [descriptions] 
     * @param {string} [casePeriodAttachmentType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseAttachmentApi
     */
    public apiCaseAttachmentMultiplePost(files?: Array<any>, descriptions?: Array<string>, casePeriodAttachmentType?: string, options?: any) {
        return CaseAttachmentApiFp(this.configuration).apiCaseAttachmentMultiplePost(files, descriptions, casePeriodAttachmentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} casePeriodId 
     * @param {string} [attachmentType] 
     * @param {boolean} [newOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseAttachmentApi
     */
    public apiCaseAttachmentPeriodCasePeriodIdClientAttachmentsGet(casePeriodId: number, attachmentType?: string, newOnly?: boolean, options?: any) {
        return CaseAttachmentApiFp(this.configuration).apiCaseAttachmentPeriodCasePeriodIdClientAttachmentsGet(casePeriodId, attachmentType, newOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} casePeriodId 
     * @param {boolean} [newOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseAttachmentApi
     */
    public apiCaseAttachmentPeriodCasePeriodIdExpertAttachmentsGet(casePeriodId: number, newOnly?: boolean, options?: any) {
        return CaseAttachmentApiFp(this.configuration).apiCaseAttachmentPeriodCasePeriodIdExpertAttachmentsGet(casePeriodId, newOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [description] 
     * @param {AttachmentType} [type] 
     * @param {string} [casePeriodAttachmentType] 
     * @param {any} [file] 
     * @param {string} [fileName] 
     * @param {string} [contentType] 
     * @param {string} [blobName] 
     * @param {number} [id] 
     * @param {string} [createdAt] 
     * @param {number} [createdById] 
     * @param {string} [updatedAt] 
     * @param {number} [updatedById] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseAttachmentApi
     */
    public apiCaseAttachmentPost(description?: string, type?: AttachmentType, casePeriodAttachmentType?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any) {
        return CaseAttachmentApiFp(this.configuration).apiCaseAttachmentPost(description, type, casePeriodAttachmentType, file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CaseMessageApi - axios parameter creator
 * @export
 */
export const CaseMessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseMessageCaseCaseIdGet: async (caseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiCaseMessageCaseCaseIdGet', 'caseId', caseId)
            const localVarPath = `/api/CaseMessage/case/{caseId}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseMessageGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CaseMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseMessageIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseMessageIdDelete', 'id', id)
            const localVarPath = `/api/CaseMessage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseMessageIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseMessageIdGet', 'id', id)
            const localVarPath = `/api/CaseMessage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseMessageUpdateDto} [caseMessageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseMessageIdPatch: async (id: number, caseMessageUpdateDto?: CaseMessageUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseMessageIdPatch', 'id', id)
            const localVarPath = `/api/CaseMessage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caseMessageUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseMessageUpdateDto} [caseMessageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseMessageIdPut: async (id: number, caseMessageUpdateDto?: CaseMessageUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCaseMessageIdPut', 'id', id)
            const localVarPath = `/api/CaseMessage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caseMessageUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CaseMessageCreateDto} [caseMessageCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseMessagePost: async (caseMessageCreateDto?: CaseMessageCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CaseMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(caseMessageCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CaseMessageApi - functional programming interface
 * @export
 */
export const CaseMessageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CaseMessageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseMessageCaseCaseIdGet(caseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CaseMessageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseMessageCaseCaseIdGet(caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseMessageGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CaseMessageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseMessageGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseMessageIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseMessageIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseMessageIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseMessageDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseMessageIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseMessageUpdateDto} [caseMessageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseMessageIdPatch(id: number, caseMessageUpdateDto?: CaseMessageUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseMessageDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseMessageIdPatch(id, caseMessageUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseMessageUpdateDto} [caseMessageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseMessageIdPut(id: number, caseMessageUpdateDto?: CaseMessageUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseMessageDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseMessageIdPut(id, caseMessageUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CaseMessageCreateDto} [caseMessageCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseMessagePost(caseMessageCreateDto?: CaseMessageCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseMessageDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseMessagePost(caseMessageCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CaseMessageApi - factory interface
 * @export
 */
export const CaseMessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CaseMessageApiFp(configuration)
    return {
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseMessageCaseCaseIdGet(caseId: number, options?: any): AxiosPromise<Array<CaseMessageDto>> {
            return localVarFp.apiCaseMessageCaseCaseIdGet(caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseMessageGet(options?: any): AxiosPromise<Array<CaseMessageDto>> {
            return localVarFp.apiCaseMessageGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseMessageIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCaseMessageIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseMessageIdGet(id: number, options?: any): AxiosPromise<CaseMessageDetailsDto> {
            return localVarFp.apiCaseMessageIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseMessageUpdateDto} [caseMessageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseMessageIdPatch(id: number, caseMessageUpdateDto?: CaseMessageUpdateDto, options?: any): AxiosPromise<CaseMessageDetailsDto> {
            return localVarFp.apiCaseMessageIdPatch(id, caseMessageUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CaseMessageUpdateDto} [caseMessageUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseMessageIdPut(id: number, caseMessageUpdateDto?: CaseMessageUpdateDto, options?: any): AxiosPromise<CaseMessageDetailsDto> {
            return localVarFp.apiCaseMessageIdPut(id, caseMessageUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CaseMessageCreateDto} [caseMessageCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseMessagePost(caseMessageCreateDto?: CaseMessageCreateDto, options?: any): AxiosPromise<CaseMessageDetailsDto> {
            return localVarFp.apiCaseMessagePost(caseMessageCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CaseMessageApi - object-oriented interface
 * @export
 * @class CaseMessageApi
 * @extends {BaseAPI}
 */
export class CaseMessageApi extends BaseAPI {
    /**
     * 
     * @param {number} caseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseMessageApi
     */
    public apiCaseMessageCaseCaseIdGet(caseId: number, options?: any) {
        return CaseMessageApiFp(this.configuration).apiCaseMessageCaseCaseIdGet(caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseMessageApi
     */
    public apiCaseMessageGet(options?: any) {
        return CaseMessageApiFp(this.configuration).apiCaseMessageGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseMessageApi
     */
    public apiCaseMessageIdDelete(id: number, options?: any) {
        return CaseMessageApiFp(this.configuration).apiCaseMessageIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseMessageApi
     */
    public apiCaseMessageIdGet(id: number, options?: any) {
        return CaseMessageApiFp(this.configuration).apiCaseMessageIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CaseMessageUpdateDto} [caseMessageUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseMessageApi
     */
    public apiCaseMessageIdPatch(id: number, caseMessageUpdateDto?: CaseMessageUpdateDto, options?: any) {
        return CaseMessageApiFp(this.configuration).apiCaseMessageIdPatch(id, caseMessageUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CaseMessageUpdateDto} [caseMessageUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseMessageApi
     */
    public apiCaseMessageIdPut(id: number, caseMessageUpdateDto?: CaseMessageUpdateDto, options?: any) {
        return CaseMessageApiFp(this.configuration).apiCaseMessageIdPut(id, caseMessageUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CaseMessageCreateDto} [caseMessageCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseMessageApi
     */
    public apiCaseMessagePost(caseMessageCreateDto?: CaseMessageCreateDto, options?: any) {
        return CaseMessageApiFp(this.configuration).apiCaseMessagePost(caseMessageCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CaseUpdateHistoryApi - axios parameter creator
 * @export
 */
export const CaseUpdateHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseUpdateHistoryCaseIdGet: async (caseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiCaseUpdateHistoryCaseIdGet', 'caseId', caseId)
            const localVarPath = `/api/CaseUpdateHistory/{caseId}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseUpdateHistoryUserUserIdGet: async (userId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiCaseUpdateHistoryUserUserIdGet', 'userId', userId)
            const localVarPath = `/api/CaseUpdateHistory/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CaseUpdateHistoryApi - functional programming interface
 * @export
 */
export const CaseUpdateHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CaseUpdateHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseUpdateHistoryCaseIdGet(caseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CaseUpdateHistoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseUpdateHistoryCaseIdGet(caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCaseUpdateHistoryUserUserIdGet(userId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserUpdateHistoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCaseUpdateHistoryUserUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CaseUpdateHistoryApi - factory interface
 * @export
 */
export const CaseUpdateHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CaseUpdateHistoryApiFp(configuration)
    return {
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseUpdateHistoryCaseIdGet(caseId: number, options?: any): AxiosPromise<Array<CaseUpdateHistoryDto>> {
            return localVarFp.apiCaseUpdateHistoryCaseIdGet(caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCaseUpdateHistoryUserUserIdGet(userId: number, options?: any): AxiosPromise<Array<UserUpdateHistoryDto>> {
            return localVarFp.apiCaseUpdateHistoryUserUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CaseUpdateHistoryApi - object-oriented interface
 * @export
 * @class CaseUpdateHistoryApi
 * @extends {BaseAPI}
 */
export class CaseUpdateHistoryApi extends BaseAPI {
    /**
     * 
     * @param {number} caseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseUpdateHistoryApi
     */
    public apiCaseUpdateHistoryCaseIdGet(caseId: number, options?: any) {
        return CaseUpdateHistoryApiFp(this.configuration).apiCaseUpdateHistoryCaseIdGet(caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseUpdateHistoryApi
     */
    public apiCaseUpdateHistoryUserUserIdGet(userId: number, options?: any) {
        return CaseUpdateHistoryApiFp(this.configuration).apiCaseUpdateHistoryUserUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContactFormApi - axios parameter creator
 * @export
 */
export const ContactFormApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ContactFormMessageDto} [contactFormMessageDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactFormPost: async (contactFormMessageDto?: ContactFormMessageDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ContactForm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactFormMessageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactFormApi - functional programming interface
 * @export
 */
export const ContactFormApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactFormApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ContactFormMessageDto} [contactFormMessageDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContactFormPost(contactFormMessageDto?: ContactFormMessageDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContactFormPost(contactFormMessageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactFormApi - factory interface
 * @export
 */
export const ContactFormApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactFormApiFp(configuration)
    return {
        /**
         * 
         * @param {ContactFormMessageDto} [contactFormMessageDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactFormPost(contactFormMessageDto?: ContactFormMessageDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiContactFormPost(contactFormMessageDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactFormApi - object-oriented interface
 * @export
 * @class ContactFormApi
 * @extends {BaseAPI}
 */
export class ContactFormApi extends BaseAPI {
    /**
     * 
     * @param {ContactFormMessageDto} [contactFormMessageDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactFormApi
     */
    public apiContactFormPost(contactFormMessageDto?: ContactFormMessageDto, options?: any) {
        return ContactFormApiFp(this.configuration).apiContactFormPost(contactFormMessageDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DepartmentApi - axios parameter creator
 * @export
 */
export const DepartmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDepartmentGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Department`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDepartmentIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDepartmentIdDelete', 'id', id)
            const localVarPath = `/api/Department/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDepartmentIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDepartmentIdGet', 'id', id)
            const localVarPath = `/api/Department/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {DepartmentUpdateDto} [departmentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDepartmentIdPatch: async (id: number, departmentUpdateDto?: DepartmentUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDepartmentIdPatch', 'id', id)
            const localVarPath = `/api/Department/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(departmentUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {DepartmentUpdateDto} [departmentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDepartmentIdPut: async (id: number, departmentUpdateDto?: DepartmentUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDepartmentIdPut', 'id', id)
            const localVarPath = `/api/Department/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(departmentUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DepartmentCreateDto} [departmentCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDepartmentPost: async (departmentCreateDto?: DepartmentCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Department`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(departmentCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepartmentApi - functional programming interface
 * @export
 */
export const DepartmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepartmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDepartmentGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DepartmentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDepartmentGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDepartmentIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDepartmentIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDepartmentIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDepartmentIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {DepartmentUpdateDto} [departmentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDepartmentIdPatch(id: number, departmentUpdateDto?: DepartmentUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDepartmentIdPatch(id, departmentUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {DepartmentUpdateDto} [departmentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDepartmentIdPut(id: number, departmentUpdateDto?: DepartmentUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDepartmentIdPut(id, departmentUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DepartmentCreateDto} [departmentCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDepartmentPost(departmentCreateDto?: DepartmentCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDepartmentPost(departmentCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DepartmentApi - factory interface
 * @export
 */
export const DepartmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepartmentApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDepartmentGet(options?: any): AxiosPromise<Array<DepartmentDto>> {
            return localVarFp.apiDepartmentGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDepartmentIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiDepartmentIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDepartmentIdGet(id: number, options?: any): AxiosPromise<DepartmentDetailsDto> {
            return localVarFp.apiDepartmentIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {DepartmentUpdateDto} [departmentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDepartmentIdPatch(id: number, departmentUpdateDto?: DepartmentUpdateDto, options?: any): AxiosPromise<DepartmentDetailsDto> {
            return localVarFp.apiDepartmentIdPatch(id, departmentUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {DepartmentUpdateDto} [departmentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDepartmentIdPut(id: number, departmentUpdateDto?: DepartmentUpdateDto, options?: any): AxiosPromise<DepartmentDetailsDto> {
            return localVarFp.apiDepartmentIdPut(id, departmentUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DepartmentCreateDto} [departmentCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDepartmentPost(departmentCreateDto?: DepartmentCreateDto, options?: any): AxiosPromise<DepartmentDetailsDto> {
            return localVarFp.apiDepartmentPost(departmentCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DepartmentApi - object-oriented interface
 * @export
 * @class DepartmentApi
 * @extends {BaseAPI}
 */
export class DepartmentApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public apiDepartmentGet(options?: any) {
        return DepartmentApiFp(this.configuration).apiDepartmentGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public apiDepartmentIdDelete(id: number, options?: any) {
        return DepartmentApiFp(this.configuration).apiDepartmentIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public apiDepartmentIdGet(id: number, options?: any) {
        return DepartmentApiFp(this.configuration).apiDepartmentIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {DepartmentUpdateDto} [departmentUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public apiDepartmentIdPatch(id: number, departmentUpdateDto?: DepartmentUpdateDto, options?: any) {
        return DepartmentApiFp(this.configuration).apiDepartmentIdPatch(id, departmentUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {DepartmentUpdateDto} [departmentUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public apiDepartmentIdPut(id: number, departmentUpdateDto?: DepartmentUpdateDto, options?: any) {
        return DepartmentApiFp(this.configuration).apiDepartmentIdPut(id, departmentUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DepartmentCreateDto} [departmentCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentApi
     */
    public apiDepartmentPost(departmentCreateDto?: DepartmentCreateDto, options?: any) {
        return DepartmentApiFp(this.configuration).apiDepartmentPost(departmentCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvoiceApi - axios parameter creator
 * @export
 */
export const InvoiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceCaseCaseIdGet: async (caseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiInvoiceCaseCaseIdGet', 'caseId', caseId)
            const localVarPath = `/api/Invoice/case/{caseId}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceCaseCaseIdInitialGet: async (caseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiInvoiceCaseCaseIdInitialGet', 'caseId', caseId)
            const localVarPath = `/api/Invoice/case/{caseId}/initial`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceCaseIdPublishedGet: async (caseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiInvoiceCaseIdPublishedGet', 'caseId', caseId)
            const localVarPath = `/api/Invoice/{caseId}/published`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiInvoiceIdDelete', 'id', id)
            const localVarPath = `/api/Invoice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiInvoiceIdGet', 'id', id)
            const localVarPath = `/api/Invoice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {InvoiceUpdateDto} [invoiceUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceIdPatch: async (id: number, invoiceUpdateDto?: InvoiceUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiInvoiceIdPatch', 'id', id)
            const localVarPath = `/api/Invoice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoiceUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceIdPaymentPaymentIdDelete: async (id: number, paymentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiInvoiceIdPaymentPaymentIdDelete', 'id', id)
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('apiInvoiceIdPaymentPaymentIdDelete', 'paymentId', paymentId)
            const localVarPath = `/api/Invoice/{id}/payment/{paymentId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {InvoicePaymentDto} [invoicePaymentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceIdPaymentPost: async (id: number, invoicePaymentDto?: InvoicePaymentDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiInvoiceIdPaymentPost', 'id', id)
            const localVarPath = `/api/Invoice/{id}/payment`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoicePaymentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {InvoiceUpdateDto} [invoiceUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceIdPut: async (id: number, invoiceUpdateDto?: InvoiceUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiInvoiceIdPut', 'id', id)
            const localVarPath = `/api/Invoice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoiceUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {InvoiceStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceIdStatusPost: async (id: number, status?: InvoiceStatus, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiInvoiceIdStatusPost', 'id', id)
            const localVarPath = `/api/Invoice/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceInvoiceIdPdfGet: async (invoiceId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('apiInvoiceInvoiceIdPdfGet', 'invoiceId', invoiceId)
            const localVarPath = `/api/Invoice/{invoiceId}/pdf`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InvoiceNumberingDataRequestDto} [invoiceNumberingDataRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceNumberingPost: async (invoiceNumberingDataRequestDto?: InvoiceNumberingDataRequestDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Invoice/numbering`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoiceNumberingDataRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InvoiceCreateDto} [invoiceCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoicePost: async (invoiceCreateDto?: InvoiceCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoiceCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceApi - functional programming interface
 * @export
 */
export const InvoiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceCaseCaseIdGet(caseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoiceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceCaseCaseIdGet(caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceCaseCaseIdInitialGet(caseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceInitialDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceCaseCaseIdInitialGet(caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceCaseIdPublishedGet(caseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceCaseIdPublishedGet(caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoiceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {InvoiceUpdateDto} [invoiceUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceIdPatch(id: number, invoiceUpdateDto?: InvoiceUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceIdPatch(id, invoiceUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceIdPaymentPaymentIdDelete(id: number, paymentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceIdPaymentPaymentIdDelete(id, paymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {InvoicePaymentDto} [invoicePaymentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceIdPaymentPost(id: number, invoicePaymentDto?: InvoicePaymentDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceIdPaymentPost(id, invoicePaymentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {InvoiceUpdateDto} [invoiceUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceIdPut(id: number, invoiceUpdateDto?: InvoiceUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceIdPut(id, invoiceUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {InvoiceStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceIdStatusPost(id: number, status?: InvoiceStatus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceIdStatusPost(id, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceInvoiceIdPdfGet(invoiceId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceInvoiceIdPdfGet(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InvoiceNumberingDataRequestDto} [invoiceNumberingDataRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceNumberingPost(invoiceNumberingDataRequestDto?: InvoiceNumberingDataRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceNumberingDataResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceNumberingPost(invoiceNumberingDataRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InvoiceCreateDto} [invoiceCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoicePost(invoiceCreateDto?: InvoiceCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoicePost(invoiceCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvoiceApi - factory interface
 * @export
 */
export const InvoiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceApiFp(configuration)
    return {
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceCaseCaseIdGet(caseId: number, options?: any): AxiosPromise<Array<InvoiceDto>> {
            return localVarFp.apiInvoiceCaseCaseIdGet(caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceCaseCaseIdInitialGet(caseId: number, options?: any): AxiosPromise<InvoiceInitialDto> {
            return localVarFp.apiInvoiceCaseCaseIdInitialGet(caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceCaseIdPublishedGet(caseId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiInvoiceCaseIdPublishedGet(caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceGet(options?: any): AxiosPromise<Array<InvoiceDto>> {
            return localVarFp.apiInvoiceGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiInvoiceIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceIdGet(id: number, options?: any): AxiosPromise<InvoiceDetailsDto> {
            return localVarFp.apiInvoiceIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {InvoiceUpdateDto} [invoiceUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceIdPatch(id: number, invoiceUpdateDto?: InvoiceUpdateDto, options?: any): AxiosPromise<InvoiceDetailsDto> {
            return localVarFp.apiInvoiceIdPatch(id, invoiceUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceIdPaymentPaymentIdDelete(id: number, paymentId: number, options?: any): AxiosPromise<InvoiceDetailsDto> {
            return localVarFp.apiInvoiceIdPaymentPaymentIdDelete(id, paymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {InvoicePaymentDto} [invoicePaymentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceIdPaymentPost(id: number, invoicePaymentDto?: InvoicePaymentDto, options?: any): AxiosPromise<InvoiceDetailsDto> {
            return localVarFp.apiInvoiceIdPaymentPost(id, invoicePaymentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {InvoiceUpdateDto} [invoiceUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceIdPut(id: number, invoiceUpdateDto?: InvoiceUpdateDto, options?: any): AxiosPromise<InvoiceDetailsDto> {
            return localVarFp.apiInvoiceIdPut(id, invoiceUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {InvoiceStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceIdStatusPost(id: number, status?: InvoiceStatus, options?: any): AxiosPromise<InvoiceDetailsDto> {
            return localVarFp.apiInvoiceIdStatusPost(id, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceInvoiceIdPdfGet(invoiceId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiInvoiceInvoiceIdPdfGet(invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InvoiceNumberingDataRequestDto} [invoiceNumberingDataRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceNumberingPost(invoiceNumberingDataRequestDto?: InvoiceNumberingDataRequestDto, options?: any): AxiosPromise<InvoiceNumberingDataResponseDto> {
            return localVarFp.apiInvoiceNumberingPost(invoiceNumberingDataRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InvoiceCreateDto} [invoiceCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoicePost(invoiceCreateDto?: InvoiceCreateDto, options?: any): AxiosPromise<InvoiceDetailsDto> {
            return localVarFp.apiInvoicePost(invoiceCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoiceApi - object-oriented interface
 * @export
 * @class InvoiceApi
 * @extends {BaseAPI}
 */
export class InvoiceApi extends BaseAPI {
    /**
     * 
     * @param {number} caseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceCaseCaseIdGet(caseId: number, options?: any) {
        return InvoiceApiFp(this.configuration).apiInvoiceCaseCaseIdGet(caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} caseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceCaseCaseIdInitialGet(caseId: number, options?: any) {
        return InvoiceApiFp(this.configuration).apiInvoiceCaseCaseIdInitialGet(caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} caseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceCaseIdPublishedGet(caseId: number, options?: any) {
        return InvoiceApiFp(this.configuration).apiInvoiceCaseIdPublishedGet(caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceGet(options?: any) {
        return InvoiceApiFp(this.configuration).apiInvoiceGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceIdDelete(id: number, options?: any) {
        return InvoiceApiFp(this.configuration).apiInvoiceIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceIdGet(id: number, options?: any) {
        return InvoiceApiFp(this.configuration).apiInvoiceIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {InvoiceUpdateDto} [invoiceUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceIdPatch(id: number, invoiceUpdateDto?: InvoiceUpdateDto, options?: any) {
        return InvoiceApiFp(this.configuration).apiInvoiceIdPatch(id, invoiceUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} paymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceIdPaymentPaymentIdDelete(id: number, paymentId: number, options?: any) {
        return InvoiceApiFp(this.configuration).apiInvoiceIdPaymentPaymentIdDelete(id, paymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {InvoicePaymentDto} [invoicePaymentDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceIdPaymentPost(id: number, invoicePaymentDto?: InvoicePaymentDto, options?: any) {
        return InvoiceApiFp(this.configuration).apiInvoiceIdPaymentPost(id, invoicePaymentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {InvoiceUpdateDto} [invoiceUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceIdPut(id: number, invoiceUpdateDto?: InvoiceUpdateDto, options?: any) {
        return InvoiceApiFp(this.configuration).apiInvoiceIdPut(id, invoiceUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {InvoiceStatus} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceIdStatusPost(id: number, status?: InvoiceStatus, options?: any) {
        return InvoiceApiFp(this.configuration).apiInvoiceIdStatusPost(id, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} invoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceInvoiceIdPdfGet(invoiceId: number, options?: any) {
        return InvoiceApiFp(this.configuration).apiInvoiceInvoiceIdPdfGet(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvoiceNumberingDataRequestDto} [invoiceNumberingDataRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceNumberingPost(invoiceNumberingDataRequestDto?: InvoiceNumberingDataRequestDto, options?: any) {
        return InvoiceApiFp(this.configuration).apiInvoiceNumberingPost(invoiceNumberingDataRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvoiceCreateDto} [invoiceCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoicePost(invoiceCreateDto?: InvoiceCreateDto, options?: any) {
        return InvoiceApiFp(this.configuration).apiInvoicePost(invoiceCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvoiceLogoApi - axios parameter creator
 * @export
 */
export const InvoiceLogoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceLogoGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InvoiceLogo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceLogoIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiInvoiceLogoIdDelete', 'id', id)
            const localVarPath = `/api/InvoiceLogo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceLogoIdFileStreamGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiInvoiceLogoIdFileStreamGet', 'id', id)
            const localVarPath = `/api/InvoiceLogo/{id}/FileStream`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceLogoIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiInvoiceLogoIdGet', 'id', id)
            const localVarPath = `/api/InvoiceLogo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {InvoiceLogoUpdateDto} [invoiceLogoUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceLogoIdPatch: async (id: number, invoiceLogoUpdateDto?: InvoiceLogoUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiInvoiceLogoIdPatch', 'id', id)
            const localVarPath = `/api/InvoiceLogo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoiceLogoUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {InvoiceLogoUpdateDto} [invoiceLogoUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceLogoIdPut: async (id: number, invoiceLogoUpdateDto?: InvoiceLogoUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiInvoiceLogoIdPut', 'id', id)
            const localVarPath = `/api/InvoiceLogo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoiceLogoUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceLogoPost: async (file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/InvoiceLogo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (blobName !== undefined) { 
                localVarFormParams.append('BlobName', blobName as any);
            }
    
            if (id !== undefined) { 
                localVarFormParams.append('Id', id as any);
            }
    
            if (createdAt !== undefined) { 
                localVarFormParams.append('CreatedAt', createdAt as any);
            }
    
            if (createdById !== undefined) { 
                localVarFormParams.append('CreatedById', createdById as any);
            }
    
            if (updatedAt !== undefined) { 
                localVarFormParams.append('UpdatedAt', updatedAt as any);
            }
    
            if (updatedById !== undefined) { 
                localVarFormParams.append('UpdatedById', updatedById as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceLogoApi - functional programming interface
 * @export
 */
export const InvoiceLogoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceLogoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceLogoGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoiceLogoDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceLogoGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceLogoIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceLogoIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceLogoIdFileStreamGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceLogoIdFileStreamGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceLogoIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceLogoDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceLogoIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {InvoiceLogoUpdateDto} [invoiceLogoUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceLogoIdPatch(id: number, invoiceLogoUpdateDto?: InvoiceLogoUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceLogoDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceLogoIdPatch(id, invoiceLogoUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {InvoiceLogoUpdateDto} [invoiceLogoUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceLogoIdPut(id: number, invoiceLogoUpdateDto?: InvoiceLogoUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceLogoDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceLogoIdPut(id, invoiceLogoUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceLogoPost(file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceLogoDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceLogoPost(file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvoiceLogoApi - factory interface
 * @export
 */
export const InvoiceLogoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceLogoApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceLogoGet(options?: any): AxiosPromise<Array<InvoiceLogoDto>> {
            return localVarFp.apiInvoiceLogoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceLogoIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiInvoiceLogoIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceLogoIdFileStreamGet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiInvoiceLogoIdFileStreamGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceLogoIdGet(id: number, options?: any): AxiosPromise<InvoiceLogoDetailsDto> {
            return localVarFp.apiInvoiceLogoIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {InvoiceLogoUpdateDto} [invoiceLogoUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceLogoIdPatch(id: number, invoiceLogoUpdateDto?: InvoiceLogoUpdateDto, options?: any): AxiosPromise<InvoiceLogoDetailsDto> {
            return localVarFp.apiInvoiceLogoIdPatch(id, invoiceLogoUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {InvoiceLogoUpdateDto} [invoiceLogoUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceLogoIdPut(id: number, invoiceLogoUpdateDto?: InvoiceLogoUpdateDto, options?: any): AxiosPromise<InvoiceLogoDetailsDto> {
            return localVarFp.apiInvoiceLogoIdPut(id, invoiceLogoUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceLogoPost(file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): AxiosPromise<InvoiceLogoDetailsDto> {
            return localVarFp.apiInvoiceLogoPost(file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoiceLogoApi - object-oriented interface
 * @export
 * @class InvoiceLogoApi
 * @extends {BaseAPI}
 */
export class InvoiceLogoApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceLogoApi
     */
    public apiInvoiceLogoGet(options?: any) {
        return InvoiceLogoApiFp(this.configuration).apiInvoiceLogoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceLogoApi
     */
    public apiInvoiceLogoIdDelete(id: number, options?: any) {
        return InvoiceLogoApiFp(this.configuration).apiInvoiceLogoIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceLogoApi
     */
    public apiInvoiceLogoIdFileStreamGet(id: number, options?: any) {
        return InvoiceLogoApiFp(this.configuration).apiInvoiceLogoIdFileStreamGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceLogoApi
     */
    public apiInvoiceLogoIdGet(id: number, options?: any) {
        return InvoiceLogoApiFp(this.configuration).apiInvoiceLogoIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {InvoiceLogoUpdateDto} [invoiceLogoUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceLogoApi
     */
    public apiInvoiceLogoIdPatch(id: number, invoiceLogoUpdateDto?: InvoiceLogoUpdateDto, options?: any) {
        return InvoiceLogoApiFp(this.configuration).apiInvoiceLogoIdPatch(id, invoiceLogoUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {InvoiceLogoUpdateDto} [invoiceLogoUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceLogoApi
     */
    public apiInvoiceLogoIdPut(id: number, invoiceLogoUpdateDto?: InvoiceLogoUpdateDto, options?: any) {
        return InvoiceLogoApiFp(this.configuration).apiInvoiceLogoIdPut(id, invoiceLogoUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [file] 
     * @param {string} [fileName] 
     * @param {string} [contentType] 
     * @param {string} [blobName] 
     * @param {number} [id] 
     * @param {string} [createdAt] 
     * @param {number} [createdById] 
     * @param {string} [updatedAt] 
     * @param {number} [updatedById] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceLogoApi
     */
    public apiInvoiceLogoPost(file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any) {
        return InvoiceLogoApiFp(this.configuration).apiInvoiceLogoPost(file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KpiApi - axios parameter creator
 * @export
 */
export const KpiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {KpiType} [kpiType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiAccessRulesGet: async (kpiType?: KpiType, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/accessRules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (kpiType !== undefined) {
                localVarQueryParameter['kpiType'] = kpiType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KpiAccessRuleDto} [kpiAccessRuleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiAccessRulesPost: async (kpiAccessRuleDto?: KpiAccessRuleDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/accessRules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kpiAccessRuleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [memberId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiAllCasesGet: async (from?: string, to?: string, memberId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/allCases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiChfGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/chf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiClientActivityGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/clientActivity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiComplianceGet: async (from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/compliance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiEngineGet: async (from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/engine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiExpertsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/experts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiHellGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/hell`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiInvestInProgressGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/investInProgress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiInvestPitchGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/investPitch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiInvestSalesGet: async (from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/investSales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiLeadsGet: async (from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/leads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiLegalGet: async (from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/legal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiManagersGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/managers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiMarketingDoneGet: async (from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/marketing/done`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiMarketingInProgressGet: async (from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/marketing/inProgress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiMarketingNewGet: async (from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/marketing/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiNewBusinessGet: async (from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/newBusiness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiNewBusinessMeetingGet: async (from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/newBusinessMeeting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiOverdueGet: async (date?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/overdue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiPurgatoryGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/purgatory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiRecruitmentGet: async (from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/recruitment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiSalesmanGet: async (date?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/salesman`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiServiceMeetingGet: async (from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/serviceMeeting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiSettlementsLawGet: async (date?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/settlements/law`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiSettlementsTaxGet: async (date?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/settlements/tax`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiSubscriptionsDoneGet: async (from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/subscriptions/done`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiSubscriptionsInProgressGet: async (from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/subscriptions/inProgress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiSubscriptionsNewGet: async (from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/subscriptions/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiTaxDoneGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/tax/done`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiTaxInProgressGet: async (from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/tax/inProgress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiTaxNewGet: async (from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/tax/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiTypesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Kpi/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KpiApi - functional programming interface
 * @export
 */
export const KpiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KpiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {KpiType} [kpiType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiAccessRulesGet(kpiType?: KpiType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KpiAccessRuleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiAccessRulesGet(kpiType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {KpiAccessRuleDto} [kpiAccessRuleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiAccessRulesPost(kpiAccessRuleDto?: KpiAccessRuleDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KpiAccessRuleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiAccessRulesPost(kpiAccessRuleDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [memberId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiAllCasesGet(from?: string, to?: string, memberId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiAllCasesResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiAllCasesGet(from, to, memberId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiChfGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiChfResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiChfGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiClientActivityGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiClientActivityResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiClientActivityGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiComplianceGet(from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiComplianceResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiComplianceGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiEngineGet(from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiEngineResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiEngineGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiExpertsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiExpertsResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiExpertsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiHellGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiHellResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiHellGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiInvestInProgressGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiInvestInProgressResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiInvestInProgressGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiInvestPitchGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiInvestPitchResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiInvestPitchGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiInvestSalesGet(from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiInvestSalesResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiInvestSalesGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiLeadsGet(from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiLeadsResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiLeadsGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiLegalGet(from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiLegalResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiLegalGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiManagersGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiManagersResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiManagersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiMarketingDoneGet(from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiLegalResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiMarketingDoneGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiMarketingInProgressGet(from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiLegalResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiMarketingInProgressGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiMarketingNewGet(from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiLegalResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiMarketingNewGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiNewBusinessGet(from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiNewBusinessResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiNewBusinessGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiNewBusinessMeetingGet(from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiMeetingResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiNewBusinessMeetingGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiOverdueGet(date?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiOverdueResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiOverdueGet(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiPurgatoryGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiPurgatoryResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiPurgatoryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiRecruitmentGet(from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiRecruitmentResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiRecruitmentGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiSalesmanGet(date?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiSalesmanResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiSalesmanGet(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiServiceMeetingGet(from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiMeetingResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiServiceMeetingGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiSettlementsLawGet(date?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SettlementDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiSettlementsLawGet(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiSettlementsTaxGet(date?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SettlementDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiSettlementsTaxGet(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiSubscriptionsDoneGet(from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiSubscriptionsResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiSubscriptionsDoneGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiSubscriptionsInProgressGet(from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiSubscriptionsResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiSubscriptionsInProgressGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiSubscriptionsNewGet(from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiSubscriptionsResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiSubscriptionsNewGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiTaxDoneGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiTaxResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiTaxDoneGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiTaxInProgressGet(from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiTaxInProgressResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiTaxInProgressGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiTaxNewGet(from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiTaxNewResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiTaxNewGet(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKpiTypesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KpiType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKpiTypesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KpiApi - factory interface
 * @export
 */
export const KpiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KpiApiFp(configuration)
    return {
        /**
         * 
         * @param {KpiType} [kpiType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiAccessRulesGet(kpiType?: KpiType, options?: any): AxiosPromise<KpiAccessRuleDto> {
            return localVarFp.apiKpiAccessRulesGet(kpiType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {KpiAccessRuleDto} [kpiAccessRuleDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiAccessRulesPost(kpiAccessRuleDto?: KpiAccessRuleDto, options?: any): AxiosPromise<KpiAccessRuleDto> {
            return localVarFp.apiKpiAccessRulesPost(kpiAccessRuleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [memberId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiAllCasesGet(from?: string, to?: string, memberId?: number, options?: any): AxiosPromise<Array<KpiAllCasesResultDto>> {
            return localVarFp.apiKpiAllCasesGet(from, to, memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiChfGet(options?: any): AxiosPromise<Array<KpiChfResultDto>> {
            return localVarFp.apiKpiChfGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiClientActivityGet(options?: any): AxiosPromise<Array<KpiClientActivityResultDto>> {
            return localVarFp.apiKpiClientActivityGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiComplianceGet(from?: string, to?: string, options?: any): AxiosPromise<Array<KpiComplianceResultDto>> {
            return localVarFp.apiKpiComplianceGet(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiEngineGet(from?: string, to?: string, options?: any): AxiosPromise<Array<KpiEngineResultDto>> {
            return localVarFp.apiKpiEngineGet(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiExpertsGet(options?: any): AxiosPromise<Array<KpiExpertsResultDto>> {
            return localVarFp.apiKpiExpertsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiHellGet(options?: any): AxiosPromise<Array<KpiHellResultDto>> {
            return localVarFp.apiKpiHellGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiInvestInProgressGet(options?: any): AxiosPromise<Array<KpiInvestInProgressResultDto>> {
            return localVarFp.apiKpiInvestInProgressGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiInvestPitchGet(options?: any): AxiosPromise<Array<KpiInvestPitchResultDto>> {
            return localVarFp.apiKpiInvestPitchGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiInvestSalesGet(from?: string, to?: string, options?: any): AxiosPromise<Array<KpiInvestSalesResultDto>> {
            return localVarFp.apiKpiInvestSalesGet(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiLeadsGet(from?: string, to?: string, options?: any): AxiosPromise<Array<KpiLeadsResultDto>> {
            return localVarFp.apiKpiLeadsGet(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiLegalGet(from?: string, to?: string, options?: any): AxiosPromise<Array<KpiLegalResultDto>> {
            return localVarFp.apiKpiLegalGet(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiManagersGet(options?: any): AxiosPromise<Array<KpiManagersResultDto>> {
            return localVarFp.apiKpiManagersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiMarketingDoneGet(from?: string, to?: string, options?: any): AxiosPromise<Array<KpiLegalResultDto>> {
            return localVarFp.apiKpiMarketingDoneGet(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiMarketingInProgressGet(from?: string, to?: string, options?: any): AxiosPromise<Array<KpiLegalResultDto>> {
            return localVarFp.apiKpiMarketingInProgressGet(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiMarketingNewGet(from?: string, to?: string, options?: any): AxiosPromise<Array<KpiLegalResultDto>> {
            return localVarFp.apiKpiMarketingNewGet(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiNewBusinessGet(from?: string, to?: string, options?: any): AxiosPromise<Array<KpiNewBusinessResultDto>> {
            return localVarFp.apiKpiNewBusinessGet(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiNewBusinessMeetingGet(from?: string, to?: string, options?: any): AxiosPromise<Array<KpiMeetingResultDto>> {
            return localVarFp.apiKpiNewBusinessMeetingGet(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiOverdueGet(date?: string, options?: any): AxiosPromise<Array<KpiOverdueResultDto>> {
            return localVarFp.apiKpiOverdueGet(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiPurgatoryGet(options?: any): AxiosPromise<Array<KpiPurgatoryResultDto>> {
            return localVarFp.apiKpiPurgatoryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiRecruitmentGet(from?: string, to?: string, options?: any): AxiosPromise<Array<KpiRecruitmentResultDto>> {
            return localVarFp.apiKpiRecruitmentGet(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiSalesmanGet(date?: string, options?: any): AxiosPromise<Array<KpiSalesmanResultDto>> {
            return localVarFp.apiKpiSalesmanGet(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiServiceMeetingGet(from?: string, to?: string, options?: any): AxiosPromise<Array<KpiMeetingResultDto>> {
            return localVarFp.apiKpiServiceMeetingGet(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiSettlementsLawGet(date?: string, options?: any): AxiosPromise<Array<SettlementDto>> {
            return localVarFp.apiKpiSettlementsLawGet(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiSettlementsTaxGet(date?: string, options?: any): AxiosPromise<Array<SettlementDto>> {
            return localVarFp.apiKpiSettlementsTaxGet(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiSubscriptionsDoneGet(from?: string, to?: string, options?: any): AxiosPromise<Array<KpiSubscriptionsResultDto>> {
            return localVarFp.apiKpiSubscriptionsDoneGet(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiSubscriptionsInProgressGet(from?: string, to?: string, options?: any): AxiosPromise<Array<KpiSubscriptionsResultDto>> {
            return localVarFp.apiKpiSubscriptionsInProgressGet(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiSubscriptionsNewGet(from?: string, to?: string, options?: any): AxiosPromise<Array<KpiSubscriptionsResultDto>> {
            return localVarFp.apiKpiSubscriptionsNewGet(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiTaxDoneGet(options?: any): AxiosPromise<Array<KpiTaxResultDto>> {
            return localVarFp.apiKpiTaxDoneGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiTaxInProgressGet(from?: string, to?: string, options?: any): AxiosPromise<Array<KpiTaxInProgressResultDto>> {
            return localVarFp.apiKpiTaxInProgressGet(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiTaxNewGet(from?: string, to?: string, options?: any): AxiosPromise<Array<KpiTaxNewResultDto>> {
            return localVarFp.apiKpiTaxNewGet(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKpiTypesGet(options?: any): AxiosPromise<Array<KpiType>> {
            return localVarFp.apiKpiTypesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KpiApi - object-oriented interface
 * @export
 * @class KpiApi
 * @extends {BaseAPI}
 */
export class KpiApi extends BaseAPI {
    /**
     * 
     * @param {KpiType} [kpiType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiAccessRulesGet(kpiType?: KpiType, options?: any) {
        return KpiApiFp(this.configuration).apiKpiAccessRulesGet(kpiType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {KpiAccessRuleDto} [kpiAccessRuleDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiAccessRulesPost(kpiAccessRuleDto?: KpiAccessRuleDto, options?: any) {
        return KpiApiFp(this.configuration).apiKpiAccessRulesPost(kpiAccessRuleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {number} [memberId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiAllCasesGet(from?: string, to?: string, memberId?: number, options?: any) {
        return KpiApiFp(this.configuration).apiKpiAllCasesGet(from, to, memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiChfGet(options?: any) {
        return KpiApiFp(this.configuration).apiKpiChfGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiClientActivityGet(options?: any) {
        return KpiApiFp(this.configuration).apiKpiClientActivityGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiComplianceGet(from?: string, to?: string, options?: any) {
        return KpiApiFp(this.configuration).apiKpiComplianceGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiEngineGet(from?: string, to?: string, options?: any) {
        return KpiApiFp(this.configuration).apiKpiEngineGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiExpertsGet(options?: any) {
        return KpiApiFp(this.configuration).apiKpiExpertsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiHellGet(options?: any) {
        return KpiApiFp(this.configuration).apiKpiHellGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiInvestInProgressGet(options?: any) {
        return KpiApiFp(this.configuration).apiKpiInvestInProgressGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiInvestPitchGet(options?: any) {
        return KpiApiFp(this.configuration).apiKpiInvestPitchGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiInvestSalesGet(from?: string, to?: string, options?: any) {
        return KpiApiFp(this.configuration).apiKpiInvestSalesGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiLeadsGet(from?: string, to?: string, options?: any) {
        return KpiApiFp(this.configuration).apiKpiLeadsGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiLegalGet(from?: string, to?: string, options?: any) {
        return KpiApiFp(this.configuration).apiKpiLegalGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiManagersGet(options?: any) {
        return KpiApiFp(this.configuration).apiKpiManagersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiMarketingDoneGet(from?: string, to?: string, options?: any) {
        return KpiApiFp(this.configuration).apiKpiMarketingDoneGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiMarketingInProgressGet(from?: string, to?: string, options?: any) {
        return KpiApiFp(this.configuration).apiKpiMarketingInProgressGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiMarketingNewGet(from?: string, to?: string, options?: any) {
        return KpiApiFp(this.configuration).apiKpiMarketingNewGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiNewBusinessGet(from?: string, to?: string, options?: any) {
        return KpiApiFp(this.configuration).apiKpiNewBusinessGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiNewBusinessMeetingGet(from?: string, to?: string, options?: any) {
        return KpiApiFp(this.configuration).apiKpiNewBusinessMeetingGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiOverdueGet(date?: string, options?: any) {
        return KpiApiFp(this.configuration).apiKpiOverdueGet(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiPurgatoryGet(options?: any) {
        return KpiApiFp(this.configuration).apiKpiPurgatoryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiRecruitmentGet(from?: string, to?: string, options?: any) {
        return KpiApiFp(this.configuration).apiKpiRecruitmentGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiSalesmanGet(date?: string, options?: any) {
        return KpiApiFp(this.configuration).apiKpiSalesmanGet(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiServiceMeetingGet(from?: string, to?: string, options?: any) {
        return KpiApiFp(this.configuration).apiKpiServiceMeetingGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiSettlementsLawGet(date?: string, options?: any) {
        return KpiApiFp(this.configuration).apiKpiSettlementsLawGet(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiSettlementsTaxGet(date?: string, options?: any) {
        return KpiApiFp(this.configuration).apiKpiSettlementsTaxGet(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiSubscriptionsDoneGet(from?: string, to?: string, options?: any) {
        return KpiApiFp(this.configuration).apiKpiSubscriptionsDoneGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiSubscriptionsInProgressGet(from?: string, to?: string, options?: any) {
        return KpiApiFp(this.configuration).apiKpiSubscriptionsInProgressGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiSubscriptionsNewGet(from?: string, to?: string, options?: any) {
        return KpiApiFp(this.configuration).apiKpiSubscriptionsNewGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiTaxDoneGet(options?: any) {
        return KpiApiFp(this.configuration).apiKpiTaxDoneGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiTaxInProgressGet(from?: string, to?: string, options?: any) {
        return KpiApiFp(this.configuration).apiKpiTaxInProgressGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiTaxNewGet(from?: string, to?: string, options?: any) {
        return KpiApiFp(this.configuration).apiKpiTaxNewGet(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KpiApi
     */
    public apiKpiTypesGet(options?: any) {
        return KpiApiFp(this.configuration).apiKpiTypesGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LeadApi - axios parameter creator
 * @export
 */
export const LeadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Lead`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeadIdDelete', 'id', id)
            const localVarPath = `/api/Lead/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeadIdGet', 'id', id)
            const localVarPath = `/api/Lead/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LeadUpdateDto} [leadUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadIdPatch: async (id: number, leadUpdateDto?: LeadUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeadIdPatch', 'id', id)
            const localVarPath = `/api/Lead/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(leadUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LeadUpdateDto} [leadUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadIdPut: async (id: number, leadUpdateDto?: LeadUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeadIdPut', 'id', id)
            const localVarPath = `/api/Lead/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(leadUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LeadCreateDto} [leadCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadPost: async (leadCreateDto?: LeadCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Lead`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(leadCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeadApi - functional programming interface
 * @export
 */
export const LeadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LeadApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeadGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LeadDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeadGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeadIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeadIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeadIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeadIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LeadUpdateDto} [leadUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeadIdPatch(id: number, leadUpdateDto?: LeadUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeadIdPatch(id, leadUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LeadUpdateDto} [leadUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeadIdPut(id: number, leadUpdateDto?: LeadUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeadIdPut(id, leadUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LeadCreateDto} [leadCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeadPost(leadCreateDto?: LeadCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeadPost(leadCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LeadApi - factory interface
 * @export
 */
export const LeadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LeadApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadGet(options?: any): AxiosPromise<Array<LeadDto>> {
            return localVarFp.apiLeadGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiLeadIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadIdGet(id: number, options?: any): AxiosPromise<LeadDetailsDto> {
            return localVarFp.apiLeadIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {LeadUpdateDto} [leadUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadIdPatch(id: number, leadUpdateDto?: LeadUpdateDto, options?: any): AxiosPromise<LeadDetailsDto> {
            return localVarFp.apiLeadIdPatch(id, leadUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {LeadUpdateDto} [leadUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadIdPut(id: number, leadUpdateDto?: LeadUpdateDto, options?: any): AxiosPromise<LeadDetailsDto> {
            return localVarFp.apiLeadIdPut(id, leadUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LeadCreateDto} [leadCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadPost(leadCreateDto?: LeadCreateDto, options?: any): AxiosPromise<LeadDetailsDto> {
            return localVarFp.apiLeadPost(leadCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LeadApi - object-oriented interface
 * @export
 * @class LeadApi
 * @extends {BaseAPI}
 */
export class LeadApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadApi
     */
    public apiLeadGet(options?: any) {
        return LeadApiFp(this.configuration).apiLeadGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadApi
     */
    public apiLeadIdDelete(id: number, options?: any) {
        return LeadApiFp(this.configuration).apiLeadIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadApi
     */
    public apiLeadIdGet(id: number, options?: any) {
        return LeadApiFp(this.configuration).apiLeadIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {LeadUpdateDto} [leadUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadApi
     */
    public apiLeadIdPatch(id: number, leadUpdateDto?: LeadUpdateDto, options?: any) {
        return LeadApiFp(this.configuration).apiLeadIdPatch(id, leadUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {LeadUpdateDto} [leadUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadApi
     */
    public apiLeadIdPut(id: number, leadUpdateDto?: LeadUpdateDto, options?: any) {
        return LeadApiFp(this.configuration).apiLeadIdPut(id, leadUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LeadCreateDto} [leadCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadApi
     */
    public apiLeadPost(leadCreateDto?: LeadCreateDto, options?: any) {
        return LeadApiFp(this.configuration).apiLeadPost(leadCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LeadSourceApi - axios parameter creator
 * @export
 */
export const LeadSourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadSourceGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LeadSource`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadSourceIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeadSourceIdDelete', 'id', id)
            const localVarPath = `/api/LeadSource/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadSourceIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeadSourceIdGet', 'id', id)
            const localVarPath = `/api/LeadSource/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LeadSourceUpdateDto} [leadSourceUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadSourceIdPatch: async (id: number, leadSourceUpdateDto?: LeadSourceUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeadSourceIdPatch', 'id', id)
            const localVarPath = `/api/LeadSource/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(leadSourceUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LeadSourceUpdateDto} [leadSourceUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadSourceIdPut: async (id: number, leadSourceUpdateDto?: LeadSourceUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeadSourceIdPut', 'id', id)
            const localVarPath = `/api/LeadSource/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(leadSourceUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LeadSourceCreateDto} [leadSourceCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadSourcePost: async (leadSourceCreateDto?: LeadSourceCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LeadSource`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(leadSourceCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeadSourceApi - functional programming interface
 * @export
 */
export const LeadSourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LeadSourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeadSourceGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LeadSourceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeadSourceGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeadSourceIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeadSourceIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeadSourceIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadSourceDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeadSourceIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LeadSourceUpdateDto} [leadSourceUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeadSourceIdPatch(id: number, leadSourceUpdateDto?: LeadSourceUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadSourceDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeadSourceIdPatch(id, leadSourceUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LeadSourceUpdateDto} [leadSourceUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeadSourceIdPut(id: number, leadSourceUpdateDto?: LeadSourceUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadSourceDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeadSourceIdPut(id, leadSourceUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LeadSourceCreateDto} [leadSourceCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeadSourcePost(leadSourceCreateDto?: LeadSourceCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadSourceDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeadSourcePost(leadSourceCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LeadSourceApi - factory interface
 * @export
 */
export const LeadSourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LeadSourceApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadSourceGet(options?: any): AxiosPromise<Array<LeadSourceDto>> {
            return localVarFp.apiLeadSourceGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadSourceIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiLeadSourceIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadSourceIdGet(id: number, options?: any): AxiosPromise<LeadSourceDetailsDto> {
            return localVarFp.apiLeadSourceIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {LeadSourceUpdateDto} [leadSourceUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadSourceIdPatch(id: number, leadSourceUpdateDto?: LeadSourceUpdateDto, options?: any): AxiosPromise<LeadSourceDetailsDto> {
            return localVarFp.apiLeadSourceIdPatch(id, leadSourceUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {LeadSourceUpdateDto} [leadSourceUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadSourceIdPut(id: number, leadSourceUpdateDto?: LeadSourceUpdateDto, options?: any): AxiosPromise<LeadSourceDetailsDto> {
            return localVarFp.apiLeadSourceIdPut(id, leadSourceUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LeadSourceCreateDto} [leadSourceCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadSourcePost(leadSourceCreateDto?: LeadSourceCreateDto, options?: any): AxiosPromise<LeadSourceDetailsDto> {
            return localVarFp.apiLeadSourcePost(leadSourceCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LeadSourceApi - object-oriented interface
 * @export
 * @class LeadSourceApi
 * @extends {BaseAPI}
 */
export class LeadSourceApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadSourceApi
     */
    public apiLeadSourceGet(options?: any) {
        return LeadSourceApiFp(this.configuration).apiLeadSourceGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadSourceApi
     */
    public apiLeadSourceIdDelete(id: number, options?: any) {
        return LeadSourceApiFp(this.configuration).apiLeadSourceIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadSourceApi
     */
    public apiLeadSourceIdGet(id: number, options?: any) {
        return LeadSourceApiFp(this.configuration).apiLeadSourceIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {LeadSourceUpdateDto} [leadSourceUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadSourceApi
     */
    public apiLeadSourceIdPatch(id: number, leadSourceUpdateDto?: LeadSourceUpdateDto, options?: any) {
        return LeadSourceApiFp(this.configuration).apiLeadSourceIdPatch(id, leadSourceUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {LeadSourceUpdateDto} [leadSourceUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadSourceApi
     */
    public apiLeadSourceIdPut(id: number, leadSourceUpdateDto?: LeadSourceUpdateDto, options?: any) {
        return LeadSourceApiFp(this.configuration).apiLeadSourceIdPut(id, leadSourceUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LeadSourceCreateDto} [leadSourceCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadSourceApi
     */
    public apiLeadSourcePost(leadSourceCreateDto?: LeadSourceCreateDto, options?: any) {
        return LeadSourceApiFp(this.configuration).apiLeadSourcePost(leadSourceCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ManagerReportApi - axios parameter creator
 * @export
 */
export const ManagerReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerReportDateDateCurrentUserGet: async (date: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('apiManagerReportDateDateCurrentUserGet', 'date', date)
            const localVarPath = `/api/ManagerReport/date/{date}/currentUser`
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerReportDateDateGet: async (date: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('apiManagerReportDateDateGet', 'date', date)
            const localVarPath = `/api/ManagerReport/date/{date}`
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerReportGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ManagerReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerReportIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiManagerReportIdDelete', 'id', id)
            const localVarPath = `/api/ManagerReport/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerReportIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiManagerReportIdGet', 'id', id)
            const localVarPath = `/api/ManagerReport/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ManagerReportUpdateDto} [managerReportUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerReportIdPatch: async (id: number, managerReportUpdateDto?: ManagerReportUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiManagerReportIdPatch', 'id', id)
            const localVarPath = `/api/ManagerReport/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(managerReportUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ManagerReportUpdateDto} [managerReportUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerReportIdPut: async (id: number, managerReportUpdateDto?: ManagerReportUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiManagerReportIdPut', 'id', id)
            const localVarPath = `/api/ManagerReport/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(managerReportUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ManagerReportCreateDto} [managerReportCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerReportPost: async (managerReportCreateDto?: ManagerReportCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ManagerReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(managerReportCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManagerReportApi - functional programming interface
 * @export
 */
export const ManagerReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManagerReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerReportDateDateCurrentUserGet(date: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ManagerReportDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiManagerReportDateDateCurrentUserGet(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerReportDateDateGet(date: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ManagerReportDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiManagerReportDateDateGet(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerReportGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ManagerReportDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiManagerReportGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerReportIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiManagerReportIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerReportIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManagerReportDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiManagerReportIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ManagerReportUpdateDto} [managerReportUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerReportIdPatch(id: number, managerReportUpdateDto?: ManagerReportUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManagerReportDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiManagerReportIdPatch(id, managerReportUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ManagerReportUpdateDto} [managerReportUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerReportIdPut(id: number, managerReportUpdateDto?: ManagerReportUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManagerReportDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiManagerReportIdPut(id, managerReportUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ManagerReportCreateDto} [managerReportCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerReportPost(managerReportCreateDto?: ManagerReportCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManagerReportDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiManagerReportPost(managerReportCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManagerReportApi - factory interface
 * @export
 */
export const ManagerReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManagerReportApiFp(configuration)
    return {
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerReportDateDateCurrentUserGet(date: string, options?: any): AxiosPromise<Array<ManagerReportDto>> {
            return localVarFp.apiManagerReportDateDateCurrentUserGet(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerReportDateDateGet(date: string, options?: any): AxiosPromise<Array<ManagerReportDto>> {
            return localVarFp.apiManagerReportDateDateGet(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerReportGet(options?: any): AxiosPromise<Array<ManagerReportDto>> {
            return localVarFp.apiManagerReportGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerReportIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiManagerReportIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerReportIdGet(id: number, options?: any): AxiosPromise<ManagerReportDetailsDto> {
            return localVarFp.apiManagerReportIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ManagerReportUpdateDto} [managerReportUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerReportIdPatch(id: number, managerReportUpdateDto?: ManagerReportUpdateDto, options?: any): AxiosPromise<ManagerReportDetailsDto> {
            return localVarFp.apiManagerReportIdPatch(id, managerReportUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ManagerReportUpdateDto} [managerReportUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerReportIdPut(id: number, managerReportUpdateDto?: ManagerReportUpdateDto, options?: any): AxiosPromise<ManagerReportDetailsDto> {
            return localVarFp.apiManagerReportIdPut(id, managerReportUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ManagerReportCreateDto} [managerReportCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerReportPost(managerReportCreateDto?: ManagerReportCreateDto, options?: any): AxiosPromise<ManagerReportDetailsDto> {
            return localVarFp.apiManagerReportPost(managerReportCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManagerReportApi - object-oriented interface
 * @export
 * @class ManagerReportApi
 * @extends {BaseAPI}
 */
export class ManagerReportApi extends BaseAPI {
    /**
     * 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagerReportApi
     */
    public apiManagerReportDateDateCurrentUserGet(date: string, options?: any) {
        return ManagerReportApiFp(this.configuration).apiManagerReportDateDateCurrentUserGet(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagerReportApi
     */
    public apiManagerReportDateDateGet(date: string, options?: any) {
        return ManagerReportApiFp(this.configuration).apiManagerReportDateDateGet(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagerReportApi
     */
    public apiManagerReportGet(options?: any) {
        return ManagerReportApiFp(this.configuration).apiManagerReportGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagerReportApi
     */
    public apiManagerReportIdDelete(id: number, options?: any) {
        return ManagerReportApiFp(this.configuration).apiManagerReportIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagerReportApi
     */
    public apiManagerReportIdGet(id: number, options?: any) {
        return ManagerReportApiFp(this.configuration).apiManagerReportIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ManagerReportUpdateDto} [managerReportUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagerReportApi
     */
    public apiManagerReportIdPatch(id: number, managerReportUpdateDto?: ManagerReportUpdateDto, options?: any) {
        return ManagerReportApiFp(this.configuration).apiManagerReportIdPatch(id, managerReportUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ManagerReportUpdateDto} [managerReportUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagerReportApi
     */
    public apiManagerReportIdPut(id: number, managerReportUpdateDto?: ManagerReportUpdateDto, options?: any) {
        return ManagerReportApiFp(this.configuration).apiManagerReportIdPut(id, managerReportUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ManagerReportCreateDto} [managerReportCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagerReportApi
     */
    public apiManagerReportPost(managerReportCreateDto?: ManagerReportCreateDto, options?: any) {
        return ManagerReportApiFp(this.configuration).apiManagerReportPost(managerReportCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ManagerTitleApi - axios parameter creator
 * @export
 */
export const ManagerTitleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerTitleGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ManagerTitle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerTitleIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiManagerTitleIdDelete', 'id', id)
            const localVarPath = `/api/ManagerTitle/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerTitleIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiManagerTitleIdGet', 'id', id)
            const localVarPath = `/api/ManagerTitle/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ManagerTitleUpdateDto} [managerTitleUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerTitleIdPatch: async (id: number, managerTitleUpdateDto?: ManagerTitleUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiManagerTitleIdPatch', 'id', id)
            const localVarPath = `/api/ManagerTitle/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(managerTitleUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ManagerTitleUpdateDto} [managerTitleUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerTitleIdPut: async (id: number, managerTitleUpdateDto?: ManagerTitleUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiManagerTitleIdPut', 'id', id)
            const localVarPath = `/api/ManagerTitle/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(managerTitleUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ManagerTitleCreateDto} [managerTitleCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerTitlePost: async (managerTitleCreateDto?: ManagerTitleCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ManagerTitle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(managerTitleCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManagerTitleApi - functional programming interface
 * @export
 */
export const ManagerTitleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManagerTitleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerTitleGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ManagerTitleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiManagerTitleGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerTitleIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiManagerTitleIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerTitleIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManagerTitleDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiManagerTitleIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ManagerTitleUpdateDto} [managerTitleUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerTitleIdPatch(id: number, managerTitleUpdateDto?: ManagerTitleUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManagerTitleDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiManagerTitleIdPatch(id, managerTitleUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ManagerTitleUpdateDto} [managerTitleUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerTitleIdPut(id: number, managerTitleUpdateDto?: ManagerTitleUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManagerTitleDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiManagerTitleIdPut(id, managerTitleUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ManagerTitleCreateDto} [managerTitleCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerTitlePost(managerTitleCreateDto?: ManagerTitleCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManagerTitleDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiManagerTitlePost(managerTitleCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManagerTitleApi - factory interface
 * @export
 */
export const ManagerTitleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManagerTitleApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerTitleGet(options?: any): AxiosPromise<Array<ManagerTitleDto>> {
            return localVarFp.apiManagerTitleGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerTitleIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiManagerTitleIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerTitleIdGet(id: number, options?: any): AxiosPromise<ManagerTitleDetailsDto> {
            return localVarFp.apiManagerTitleIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ManagerTitleUpdateDto} [managerTitleUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerTitleIdPatch(id: number, managerTitleUpdateDto?: ManagerTitleUpdateDto, options?: any): AxiosPromise<ManagerTitleDetailsDto> {
            return localVarFp.apiManagerTitleIdPatch(id, managerTitleUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ManagerTitleUpdateDto} [managerTitleUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerTitleIdPut(id: number, managerTitleUpdateDto?: ManagerTitleUpdateDto, options?: any): AxiosPromise<ManagerTitleDetailsDto> {
            return localVarFp.apiManagerTitleIdPut(id, managerTitleUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ManagerTitleCreateDto} [managerTitleCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerTitlePost(managerTitleCreateDto?: ManagerTitleCreateDto, options?: any): AxiosPromise<ManagerTitleDetailsDto> {
            return localVarFp.apiManagerTitlePost(managerTitleCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManagerTitleApi - object-oriented interface
 * @export
 * @class ManagerTitleApi
 * @extends {BaseAPI}
 */
export class ManagerTitleApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagerTitleApi
     */
    public apiManagerTitleGet(options?: any) {
        return ManagerTitleApiFp(this.configuration).apiManagerTitleGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagerTitleApi
     */
    public apiManagerTitleIdDelete(id: number, options?: any) {
        return ManagerTitleApiFp(this.configuration).apiManagerTitleIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagerTitleApi
     */
    public apiManagerTitleIdGet(id: number, options?: any) {
        return ManagerTitleApiFp(this.configuration).apiManagerTitleIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ManagerTitleUpdateDto} [managerTitleUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagerTitleApi
     */
    public apiManagerTitleIdPatch(id: number, managerTitleUpdateDto?: ManagerTitleUpdateDto, options?: any) {
        return ManagerTitleApiFp(this.configuration).apiManagerTitleIdPatch(id, managerTitleUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ManagerTitleUpdateDto} [managerTitleUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagerTitleApi
     */
    public apiManagerTitleIdPut(id: number, managerTitleUpdateDto?: ManagerTitleUpdateDto, options?: any) {
        return ManagerTitleApiFp(this.configuration).apiManagerTitleIdPut(id, managerTitleUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ManagerTitleCreateDto} [managerTitleCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagerTitleApi
     */
    public apiManagerTitlePost(managerTitleCreateDto?: ManagerTitleCreateDto, options?: any) {
        return ManagerTitleApiFp(this.configuration).apiManagerTitlePost(managerTitleCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MarketingCampaignApi - axios parameter creator
 * @export
 */
export const MarketingCampaignApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketingCampaignGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MarketingCampaign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketingCampaignIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMarketingCampaignIdDelete', 'id', id)
            const localVarPath = `/api/MarketingCampaign/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketingCampaignIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMarketingCampaignIdGet', 'id', id)
            const localVarPath = `/api/MarketingCampaign/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MarketingCampaignUpdateDto} [marketingCampaignUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketingCampaignIdPatch: async (id: number, marketingCampaignUpdateDto?: MarketingCampaignUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMarketingCampaignIdPatch', 'id', id)
            const localVarPath = `/api/MarketingCampaign/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(marketingCampaignUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MarketingCampaignUpdateDto} [marketingCampaignUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketingCampaignIdPut: async (id: number, marketingCampaignUpdateDto?: MarketingCampaignUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMarketingCampaignIdPut', 'id', id)
            const localVarPath = `/api/MarketingCampaign/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(marketingCampaignUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MarketingCampaignCreateDto} [marketingCampaignCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketingCampaignPost: async (marketingCampaignCreateDto?: MarketingCampaignCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MarketingCampaign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(marketingCampaignCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketingCampaignApi - functional programming interface
 * @export
 */
export const MarketingCampaignApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarketingCampaignApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMarketingCampaignGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MarketingCampaignDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarketingCampaignGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMarketingCampaignIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarketingCampaignIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMarketingCampaignIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketingCampaignDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarketingCampaignIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MarketingCampaignUpdateDto} [marketingCampaignUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMarketingCampaignIdPatch(id: number, marketingCampaignUpdateDto?: MarketingCampaignUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketingCampaignDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarketingCampaignIdPatch(id, marketingCampaignUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MarketingCampaignUpdateDto} [marketingCampaignUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMarketingCampaignIdPut(id: number, marketingCampaignUpdateDto?: MarketingCampaignUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketingCampaignDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarketingCampaignIdPut(id, marketingCampaignUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MarketingCampaignCreateDto} [marketingCampaignCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMarketingCampaignPost(marketingCampaignCreateDto?: MarketingCampaignCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketingCampaignDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarketingCampaignPost(marketingCampaignCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarketingCampaignApi - factory interface
 * @export
 */
export const MarketingCampaignApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarketingCampaignApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketingCampaignGet(options?: any): AxiosPromise<Array<MarketingCampaignDto>> {
            return localVarFp.apiMarketingCampaignGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketingCampaignIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiMarketingCampaignIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketingCampaignIdGet(id: number, options?: any): AxiosPromise<MarketingCampaignDetailsDto> {
            return localVarFp.apiMarketingCampaignIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {MarketingCampaignUpdateDto} [marketingCampaignUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketingCampaignIdPatch(id: number, marketingCampaignUpdateDto?: MarketingCampaignUpdateDto, options?: any): AxiosPromise<MarketingCampaignDetailsDto> {
            return localVarFp.apiMarketingCampaignIdPatch(id, marketingCampaignUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {MarketingCampaignUpdateDto} [marketingCampaignUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketingCampaignIdPut(id: number, marketingCampaignUpdateDto?: MarketingCampaignUpdateDto, options?: any): AxiosPromise<MarketingCampaignDetailsDto> {
            return localVarFp.apiMarketingCampaignIdPut(id, marketingCampaignUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MarketingCampaignCreateDto} [marketingCampaignCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketingCampaignPost(marketingCampaignCreateDto?: MarketingCampaignCreateDto, options?: any): AxiosPromise<MarketingCampaignDetailsDto> {
            return localVarFp.apiMarketingCampaignPost(marketingCampaignCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MarketingCampaignApi - object-oriented interface
 * @export
 * @class MarketingCampaignApi
 * @extends {BaseAPI}
 */
export class MarketingCampaignApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketingCampaignApi
     */
    public apiMarketingCampaignGet(options?: any) {
        return MarketingCampaignApiFp(this.configuration).apiMarketingCampaignGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketingCampaignApi
     */
    public apiMarketingCampaignIdDelete(id: number, options?: any) {
        return MarketingCampaignApiFp(this.configuration).apiMarketingCampaignIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketingCampaignApi
     */
    public apiMarketingCampaignIdGet(id: number, options?: any) {
        return MarketingCampaignApiFp(this.configuration).apiMarketingCampaignIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {MarketingCampaignUpdateDto} [marketingCampaignUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketingCampaignApi
     */
    public apiMarketingCampaignIdPatch(id: number, marketingCampaignUpdateDto?: MarketingCampaignUpdateDto, options?: any) {
        return MarketingCampaignApiFp(this.configuration).apiMarketingCampaignIdPatch(id, marketingCampaignUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {MarketingCampaignUpdateDto} [marketingCampaignUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketingCampaignApi
     */
    public apiMarketingCampaignIdPut(id: number, marketingCampaignUpdateDto?: MarketingCampaignUpdateDto, options?: any) {
        return MarketingCampaignApiFp(this.configuration).apiMarketingCampaignIdPut(id, marketingCampaignUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MarketingCampaignCreateDto} [marketingCampaignCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketingCampaignApi
     */
    public apiMarketingCampaignPost(marketingCampaignCreateDto?: MarketingCampaignCreateDto, options?: any) {
        return MarketingCampaignApiFp(this.configuration).apiMarketingCampaignPost(marketingCampaignCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MilestoneApi - axios parameter creator
 * @export
 */
export const MilestoneApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} productId 
         * @param {Array<MilestoneCreateOrUpdateDto>} [milestoneCreateOrUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMilestoneCreateOrUpdateForProductProductIdPost: async (productId: number, milestoneCreateOrUpdateDto?: Array<MilestoneCreateOrUpdateDto>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('apiMilestoneCreateOrUpdateForProductProductIdPost', 'productId', productId)
            const localVarPath = `/api/Milestone/CreateOrUpdateForProduct/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(milestoneCreateOrUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMilestoneGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Milestone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMilestoneIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMilestoneIdDelete', 'id', id)
            const localVarPath = `/api/Milestone/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMilestoneIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMilestoneIdGet', 'id', id)
            const localVarPath = `/api/Milestone/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MilestoneUpdateDto} [milestoneUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMilestoneIdPatch: async (id: number, milestoneUpdateDto?: MilestoneUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMilestoneIdPatch', 'id', id)
            const localVarPath = `/api/Milestone/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(milestoneUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {MilestoneUpdateDto} [milestoneUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMilestoneIdPut: async (id: number, milestoneUpdateDto?: MilestoneUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMilestoneIdPut', 'id', id)
            const localVarPath = `/api/Milestone/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(milestoneUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MilestoneCreateDto} [milestoneCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMilestonePost: async (milestoneCreateDto?: MilestoneCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Milestone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(milestoneCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MilestoneApi - functional programming interface
 * @export
 */
export const MilestoneApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MilestoneApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} productId 
         * @param {Array<MilestoneCreateOrUpdateDto>} [milestoneCreateOrUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMilestoneCreateOrUpdateForProductProductIdPost(productId: number, milestoneCreateOrUpdateDto?: Array<MilestoneCreateOrUpdateDto>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MilestoneDetailsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMilestoneCreateOrUpdateForProductProductIdPost(productId, milestoneCreateOrUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMilestoneGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MilestoneDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMilestoneGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMilestoneIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMilestoneIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMilestoneIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MilestoneDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMilestoneIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MilestoneUpdateDto} [milestoneUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMilestoneIdPatch(id: number, milestoneUpdateDto?: MilestoneUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MilestoneDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMilestoneIdPatch(id, milestoneUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {MilestoneUpdateDto} [milestoneUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMilestoneIdPut(id: number, milestoneUpdateDto?: MilestoneUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MilestoneDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMilestoneIdPut(id, milestoneUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MilestoneCreateDto} [milestoneCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMilestonePost(milestoneCreateDto?: MilestoneCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MilestoneDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMilestonePost(milestoneCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MilestoneApi - factory interface
 * @export
 */
export const MilestoneApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MilestoneApiFp(configuration)
    return {
        /**
         * 
         * @param {number} productId 
         * @param {Array<MilestoneCreateOrUpdateDto>} [milestoneCreateOrUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMilestoneCreateOrUpdateForProductProductIdPost(productId: number, milestoneCreateOrUpdateDto?: Array<MilestoneCreateOrUpdateDto>, options?: any): AxiosPromise<Array<MilestoneDetailsDto>> {
            return localVarFp.apiMilestoneCreateOrUpdateForProductProductIdPost(productId, milestoneCreateOrUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMilestoneGet(options?: any): AxiosPromise<Array<MilestoneDto>> {
            return localVarFp.apiMilestoneGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMilestoneIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiMilestoneIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMilestoneIdGet(id: number, options?: any): AxiosPromise<MilestoneDetailsDto> {
            return localVarFp.apiMilestoneIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {MilestoneUpdateDto} [milestoneUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMilestoneIdPatch(id: number, milestoneUpdateDto?: MilestoneUpdateDto, options?: any): AxiosPromise<MilestoneDetailsDto> {
            return localVarFp.apiMilestoneIdPatch(id, milestoneUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {MilestoneUpdateDto} [milestoneUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMilestoneIdPut(id: number, milestoneUpdateDto?: MilestoneUpdateDto, options?: any): AxiosPromise<MilestoneDetailsDto> {
            return localVarFp.apiMilestoneIdPut(id, milestoneUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MilestoneCreateDto} [milestoneCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMilestonePost(milestoneCreateDto?: MilestoneCreateDto, options?: any): AxiosPromise<MilestoneDetailsDto> {
            return localVarFp.apiMilestonePost(milestoneCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MilestoneApi - object-oriented interface
 * @export
 * @class MilestoneApi
 * @extends {BaseAPI}
 */
export class MilestoneApi extends BaseAPI {
    /**
     * 
     * @param {number} productId 
     * @param {Array<MilestoneCreateOrUpdateDto>} [milestoneCreateOrUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilestoneApi
     */
    public apiMilestoneCreateOrUpdateForProductProductIdPost(productId: number, milestoneCreateOrUpdateDto?: Array<MilestoneCreateOrUpdateDto>, options?: any) {
        return MilestoneApiFp(this.configuration).apiMilestoneCreateOrUpdateForProductProductIdPost(productId, milestoneCreateOrUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilestoneApi
     */
    public apiMilestoneGet(options?: any) {
        return MilestoneApiFp(this.configuration).apiMilestoneGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilestoneApi
     */
    public apiMilestoneIdDelete(id: number, options?: any) {
        return MilestoneApiFp(this.configuration).apiMilestoneIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilestoneApi
     */
    public apiMilestoneIdGet(id: number, options?: any) {
        return MilestoneApiFp(this.configuration).apiMilestoneIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {MilestoneUpdateDto} [milestoneUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilestoneApi
     */
    public apiMilestoneIdPatch(id: number, milestoneUpdateDto?: MilestoneUpdateDto, options?: any) {
        return MilestoneApiFp(this.configuration).apiMilestoneIdPatch(id, milestoneUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {MilestoneUpdateDto} [milestoneUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilestoneApi
     */
    public apiMilestoneIdPut(id: number, milestoneUpdateDto?: MilestoneUpdateDto, options?: any) {
        return MilestoneApiFp(this.configuration).apiMilestoneIdPut(id, milestoneUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MilestoneCreateDto} [milestoneCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilestoneApi
     */
    public apiMilestonePost(milestoneCreateDto?: MilestoneCreateDto, options?: any) {
        return MilestoneApiFp(this.configuration).apiMilestonePost(milestoneCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NewsApi - axios parameter creator
 * @export
 */
export const NewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsAllGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/News/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/News`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNewsIdDelete', 'id', id)
            const localVarPath = `/api/News/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNewsIdGet', 'id', id)
            const localVarPath = `/api/News/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {NewsUpdateDto} [newsUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsIdPatch: async (id: number, newsUpdateDto?: NewsUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNewsIdPatch', 'id', id)
            const localVarPath = `/api/News/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newsUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsIdPreviewGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNewsIdPreviewGet', 'id', id)
            const localVarPath = `/api/News/{id}/preview`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {NewsUpdateDto} [newsUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsIdPut: async (id: number, newsUpdateDto?: NewsUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNewsIdPut', 'id', id)
            const localVarPath = `/api/News/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newsUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsIdSendGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNewsIdSendGet', 'id', id)
            const localVarPath = `/api/News/{id}/send`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsIdUserIdsGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNewsIdUserIdsGet', 'id', id)
            const localVarPath = `/api/News/{id}/userIds`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewsCreateDto} [newsCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPost: async (newsCreateDto?: NewsCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/News`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newsCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsSaasLandingGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/News/saasLanding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsApi - functional programming interface
 * @export
 */
export const NewsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsAllGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserNewsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsAllGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NewsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {NewsUpdateDto} [newsUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsIdPatch(id: number, newsUpdateDto?: NewsUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsIdPatch(id, newsUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsIdPreviewGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsEmailPreviewDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsIdPreviewGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {NewsUpdateDto} [newsUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsIdPut(id: number, newsUpdateDto?: NewsUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsIdPut(id, newsUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsIdSendGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsIdSendGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsIdUserIdsGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsIdUserIdsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewsCreateDto} [newsCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsPost(newsCreateDto?: NewsCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsPost(newsCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsSaasLandingGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsSaasLandingGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NewsApi - factory interface
 * @export
 */
export const NewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsAllGet(options?: any): AxiosPromise<Array<UserNewsDto>> {
            return localVarFp.apiNewsAllGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsGet(options?: any): AxiosPromise<Array<NewsDto>> {
            return localVarFp.apiNewsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiNewsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsIdGet(id: number, options?: any): AxiosPromise<NewsDetailsDto> {
            return localVarFp.apiNewsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {NewsUpdateDto} [newsUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsIdPatch(id: number, newsUpdateDto?: NewsUpdateDto, options?: any): AxiosPromise<NewsDetailsDto> {
            return localVarFp.apiNewsIdPatch(id, newsUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsIdPreviewGet(id: number, options?: any): AxiosPromise<NewsEmailPreviewDto> {
            return localVarFp.apiNewsIdPreviewGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {NewsUpdateDto} [newsUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsIdPut(id: number, newsUpdateDto?: NewsUpdateDto, options?: any): AxiosPromise<NewsDetailsDto> {
            return localVarFp.apiNewsIdPut(id, newsUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsIdSendGet(id: number, options?: any): AxiosPromise<number> {
            return localVarFp.apiNewsIdSendGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsIdUserIdsGet(id: number, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.apiNewsIdUserIdsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewsCreateDto} [newsCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPost(newsCreateDto?: NewsCreateDto, options?: any): AxiosPromise<NewsDetailsDto> {
            return localVarFp.apiNewsPost(newsCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsSaasLandingGet(options?: any): AxiosPromise<NewsDetailsDto> {
            return localVarFp.apiNewsSaasLandingGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NewsApi - object-oriented interface
 * @export
 * @class NewsApi
 * @extends {BaseAPI}
 */
export class NewsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public apiNewsAllGet(options?: any) {
        return NewsApiFp(this.configuration).apiNewsAllGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public apiNewsGet(options?: any) {
        return NewsApiFp(this.configuration).apiNewsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public apiNewsIdDelete(id: number, options?: any) {
        return NewsApiFp(this.configuration).apiNewsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public apiNewsIdGet(id: number, options?: any) {
        return NewsApiFp(this.configuration).apiNewsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {NewsUpdateDto} [newsUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public apiNewsIdPatch(id: number, newsUpdateDto?: NewsUpdateDto, options?: any) {
        return NewsApiFp(this.configuration).apiNewsIdPatch(id, newsUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public apiNewsIdPreviewGet(id: number, options?: any) {
        return NewsApiFp(this.configuration).apiNewsIdPreviewGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {NewsUpdateDto} [newsUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public apiNewsIdPut(id: number, newsUpdateDto?: NewsUpdateDto, options?: any) {
        return NewsApiFp(this.configuration).apiNewsIdPut(id, newsUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public apiNewsIdSendGet(id: number, options?: any) {
        return NewsApiFp(this.configuration).apiNewsIdSendGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public apiNewsIdUserIdsGet(id: number, options?: any) {
        return NewsApiFp(this.configuration).apiNewsIdUserIdsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewsCreateDto} [newsCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public apiNewsPost(newsCreateDto?: NewsCreateDto, options?: any) {
        return NewsApiFp(this.configuration).apiNewsPost(newsCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public apiNewsSaasLandingGet(options?: any) {
        return NewsApiFp(this.configuration).apiNewsSaasLandingGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NewsAttachmentApi - axios parameter creator
 * @export
 */
export const NewsAttachmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsAttachmentGet: async (token?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/NewsAttachment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (token !== undefined) {
                localVarQueryParameter['Token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsAttachmentIdDelete: async (id: number, token?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNewsAttachmentIdDelete', 'id', id)
            const localVarPath = `/api/NewsAttachment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (token !== undefined) {
                localVarQueryParameter['Token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsAttachmentIdFileStreamGet: async (id: number, token?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNewsAttachmentIdFileStreamGet', 'id', id)
            const localVarPath = `/api/NewsAttachment/{id}/FileStream`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['Token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsAttachmentIdGet: async (id: number, token?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNewsAttachmentIdGet', 'id', id)
            const localVarPath = `/api/NewsAttachment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (token !== undefined) {
                localVarQueryParameter['Token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {NewsAttachmentUpdateDto} [newsAttachmentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsAttachmentIdPatch: async (id: number, token?: string, newsAttachmentUpdateDto?: NewsAttachmentUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNewsAttachmentIdPatch', 'id', id)
            const localVarPath = `/api/NewsAttachment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (token !== undefined) {
                localVarQueryParameter['Token'] = token;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newsAttachmentUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {NewsAttachmentUpdateDto} [newsAttachmentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsAttachmentIdPut: async (id: number, token?: string, newsAttachmentUpdateDto?: NewsAttachmentUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNewsAttachmentIdPut', 'id', id)
            const localVarPath = `/api/NewsAttachment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (token !== undefined) {
                localVarQueryParameter['Token'] = token;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newsAttachmentUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [token] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsAttachmentPost: async (token?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/NewsAttachment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (token !== undefined) {
                localVarQueryParameter['Token'] = token;
            }


            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (blobName !== undefined) { 
                localVarFormParams.append('BlobName', blobName as any);
            }
    
            if (id !== undefined) { 
                localVarFormParams.append('Id', id as any);
            }
    
            if (createdAt !== undefined) { 
                localVarFormParams.append('CreatedAt', createdAt as any);
            }
    
            if (createdById !== undefined) { 
                localVarFormParams.append('CreatedById', createdById as any);
            }
    
            if (updatedAt !== undefined) { 
                localVarFormParams.append('UpdatedAt', updatedAt as any);
            }
    
            if (updatedById !== undefined) { 
                localVarFormParams.append('UpdatedById', updatedById as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsAttachmentApi - functional programming interface
 * @export
 */
export const NewsAttachmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewsAttachmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsAttachmentGet(token?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NewsAttachmentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsAttachmentGet(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsAttachmentIdDelete(id: number, token?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsAttachmentIdDelete(id, token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsAttachmentIdFileStreamGet(id: number, token?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsAttachmentIdFileStreamGet(id, token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsAttachmentIdGet(id: number, token?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsAttachmentDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsAttachmentIdGet(id, token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {NewsAttachmentUpdateDto} [newsAttachmentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsAttachmentIdPatch(id: number, token?: string, newsAttachmentUpdateDto?: NewsAttachmentUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsAttachmentDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsAttachmentIdPatch(id, token, newsAttachmentUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {NewsAttachmentUpdateDto} [newsAttachmentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsAttachmentIdPut(id: number, token?: string, newsAttachmentUpdateDto?: NewsAttachmentUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsAttachmentDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsAttachmentIdPut(id, token, newsAttachmentUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [token] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsAttachmentPost(token?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsAttachmentDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsAttachmentPost(token, file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NewsAttachmentApi - factory interface
 * @export
 */
export const NewsAttachmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewsAttachmentApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsAttachmentGet(token?: string, options?: any): AxiosPromise<Array<NewsAttachmentDto>> {
            return localVarFp.apiNewsAttachmentGet(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsAttachmentIdDelete(id: number, token?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiNewsAttachmentIdDelete(id, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsAttachmentIdFileStreamGet(id: number, token?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiNewsAttachmentIdFileStreamGet(id, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsAttachmentIdGet(id: number, token?: string, options?: any): AxiosPromise<NewsAttachmentDetailsDto> {
            return localVarFp.apiNewsAttachmentIdGet(id, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {NewsAttachmentUpdateDto} [newsAttachmentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsAttachmentIdPatch(id: number, token?: string, newsAttachmentUpdateDto?: NewsAttachmentUpdateDto, options?: any): AxiosPromise<NewsAttachmentDetailsDto> {
            return localVarFp.apiNewsAttachmentIdPatch(id, token, newsAttachmentUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {NewsAttachmentUpdateDto} [newsAttachmentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsAttachmentIdPut(id: number, token?: string, newsAttachmentUpdateDto?: NewsAttachmentUpdateDto, options?: any): AxiosPromise<NewsAttachmentDetailsDto> {
            return localVarFp.apiNewsAttachmentIdPut(id, token, newsAttachmentUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [token] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsAttachmentPost(token?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): AxiosPromise<NewsAttachmentDetailsDto> {
            return localVarFp.apiNewsAttachmentPost(token, file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NewsAttachmentApi - object-oriented interface
 * @export
 * @class NewsAttachmentApi
 * @extends {BaseAPI}
 */
export class NewsAttachmentApi extends BaseAPI {
    /**
     * 
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsAttachmentApi
     */
    public apiNewsAttachmentGet(token?: string, options?: any) {
        return NewsAttachmentApiFp(this.configuration).apiNewsAttachmentGet(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsAttachmentApi
     */
    public apiNewsAttachmentIdDelete(id: number, token?: string, options?: any) {
        return NewsAttachmentApiFp(this.configuration).apiNewsAttachmentIdDelete(id, token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsAttachmentApi
     */
    public apiNewsAttachmentIdFileStreamGet(id: number, token?: string, options?: any) {
        return NewsAttachmentApiFp(this.configuration).apiNewsAttachmentIdFileStreamGet(id, token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsAttachmentApi
     */
    public apiNewsAttachmentIdGet(id: number, token?: string, options?: any) {
        return NewsAttachmentApiFp(this.configuration).apiNewsAttachmentIdGet(id, token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [token] 
     * @param {NewsAttachmentUpdateDto} [newsAttachmentUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsAttachmentApi
     */
    public apiNewsAttachmentIdPatch(id: number, token?: string, newsAttachmentUpdateDto?: NewsAttachmentUpdateDto, options?: any) {
        return NewsAttachmentApiFp(this.configuration).apiNewsAttachmentIdPatch(id, token, newsAttachmentUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [token] 
     * @param {NewsAttachmentUpdateDto} [newsAttachmentUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsAttachmentApi
     */
    public apiNewsAttachmentIdPut(id: number, token?: string, newsAttachmentUpdateDto?: NewsAttachmentUpdateDto, options?: any) {
        return NewsAttachmentApiFp(this.configuration).apiNewsAttachmentIdPut(id, token, newsAttachmentUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [token] 
     * @param {any} [file] 
     * @param {string} [fileName] 
     * @param {string} [contentType] 
     * @param {string} [blobName] 
     * @param {number} [id] 
     * @param {string} [createdAt] 
     * @param {number} [createdById] 
     * @param {string} [updatedAt] 
     * @param {number} [updatedById] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsAttachmentApi
     */
    public apiNewsAttachmentPost(token?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any) {
        return NewsAttachmentApiFp(this.configuration).apiNewsAttachmentPost(token, file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NewsPictureApi - axios parameter creator
 * @export
 */
export const NewsPictureApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPictureGet: async (token?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/NewsPicture`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (token !== undefined) {
                localVarQueryParameter['Token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPictureIdDelete: async (id: number, token?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNewsPictureIdDelete', 'id', id)
            const localVarPath = `/api/NewsPicture/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (token !== undefined) {
                localVarQueryParameter['Token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPictureIdFileStreamGet: async (id: number, token?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNewsPictureIdFileStreamGet', 'id', id)
            const localVarPath = `/api/NewsPicture/{id}/FileStream`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['Token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPictureIdGet: async (id: number, token?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNewsPictureIdGet', 'id', id)
            const localVarPath = `/api/NewsPicture/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (token !== undefined) {
                localVarQueryParameter['Token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {NewsPictureUpdateDto} [newsPictureUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPictureIdPatch: async (id: number, token?: string, newsPictureUpdateDto?: NewsPictureUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNewsPictureIdPatch', 'id', id)
            const localVarPath = `/api/NewsPicture/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (token !== undefined) {
                localVarQueryParameter['Token'] = token;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newsPictureUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {NewsPictureUpdateDto} [newsPictureUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPictureIdPut: async (id: number, token?: string, newsPictureUpdateDto?: NewsPictureUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNewsPictureIdPut', 'id', id)
            const localVarPath = `/api/NewsPicture/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (token !== undefined) {
                localVarQueryParameter['Token'] = token;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newsPictureUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPictureIdThumbnailGet: async (id: number, token?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNewsPictureIdThumbnailGet', 'id', id)
            const localVarPath = `/api/NewsPicture/{id}/thumbnail`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (token !== undefined) {
                localVarQueryParameter['Token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [reducedFileBlobName] 
         * @param {boolean} [useToken] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPicturePost: async (token?: string, reducedFileBlobName?: string, useToken?: boolean, file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/NewsPicture`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (token !== undefined) {
                localVarQueryParameter['Token'] = token;
            }


            if (reducedFileBlobName !== undefined) { 
                localVarFormParams.append('ReducedFileBlobName', reducedFileBlobName as any);
            }
    
            if (useToken !== undefined) { 
                localVarFormParams.append('UseToken', useToken as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (blobName !== undefined) { 
                localVarFormParams.append('BlobName', blobName as any);
            }
    
            if (id !== undefined) { 
                localVarFormParams.append('Id', id as any);
            }
    
            if (createdAt !== undefined) { 
                localVarFormParams.append('CreatedAt', createdAt as any);
            }
    
            if (createdById !== undefined) { 
                localVarFormParams.append('CreatedById', createdById as any);
            }
    
            if (updatedAt !== undefined) { 
                localVarFormParams.append('UpdatedAt', updatedAt as any);
            }
    
            if (updatedById !== undefined) { 
                localVarFormParams.append('UpdatedById', updatedById as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsPictureApi - functional programming interface
 * @export
 */
export const NewsPictureApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewsPictureApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsPictureGet(token?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NewsPictureDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsPictureGet(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsPictureIdDelete(id: number, token?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsPictureIdDelete(id, token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsPictureIdFileStreamGet(id: number, token?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsPictureIdFileStreamGet(id, token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsPictureIdGet(id: number, token?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsPictureDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsPictureIdGet(id, token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {NewsPictureUpdateDto} [newsPictureUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsPictureIdPatch(id: number, token?: string, newsPictureUpdateDto?: NewsPictureUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsPictureDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsPictureIdPatch(id, token, newsPictureUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {NewsPictureUpdateDto} [newsPictureUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsPictureIdPut(id: number, token?: string, newsPictureUpdateDto?: NewsPictureUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsPictureDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsPictureIdPut(id, token, newsPictureUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsPictureIdThumbnailGet(id: number, token?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsPictureIdThumbnailGet(id, token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [reducedFileBlobName] 
         * @param {boolean} [useToken] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNewsPicturePost(token?: string, reducedFileBlobName?: string, useToken?: boolean, file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsPictureDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNewsPicturePost(token, reducedFileBlobName, useToken, file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NewsPictureApi - factory interface
 * @export
 */
export const NewsPictureApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewsPictureApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPictureGet(token?: string, options?: any): AxiosPromise<Array<NewsPictureDto>> {
            return localVarFp.apiNewsPictureGet(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPictureIdDelete(id: number, token?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiNewsPictureIdDelete(id, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPictureIdFileStreamGet(id: number, token?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiNewsPictureIdFileStreamGet(id, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPictureIdGet(id: number, token?: string, options?: any): AxiosPromise<NewsPictureDetailsDto> {
            return localVarFp.apiNewsPictureIdGet(id, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {NewsPictureUpdateDto} [newsPictureUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPictureIdPatch(id: number, token?: string, newsPictureUpdateDto?: NewsPictureUpdateDto, options?: any): AxiosPromise<NewsPictureDetailsDto> {
            return localVarFp.apiNewsPictureIdPatch(id, token, newsPictureUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {NewsPictureUpdateDto} [newsPictureUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPictureIdPut(id: number, token?: string, newsPictureUpdateDto?: NewsPictureUpdateDto, options?: any): AxiosPromise<NewsPictureDetailsDto> {
            return localVarFp.apiNewsPictureIdPut(id, token, newsPictureUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPictureIdThumbnailGet(id: number, token?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiNewsPictureIdThumbnailGet(id, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [reducedFileBlobName] 
         * @param {boolean} [useToken] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNewsPicturePost(token?: string, reducedFileBlobName?: string, useToken?: boolean, file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): AxiosPromise<NewsPictureDetailsDto> {
            return localVarFp.apiNewsPicturePost(token, reducedFileBlobName, useToken, file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NewsPictureApi - object-oriented interface
 * @export
 * @class NewsPictureApi
 * @extends {BaseAPI}
 */
export class NewsPictureApi extends BaseAPI {
    /**
     * 
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsPictureApi
     */
    public apiNewsPictureGet(token?: string, options?: any) {
        return NewsPictureApiFp(this.configuration).apiNewsPictureGet(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsPictureApi
     */
    public apiNewsPictureIdDelete(id: number, token?: string, options?: any) {
        return NewsPictureApiFp(this.configuration).apiNewsPictureIdDelete(id, token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsPictureApi
     */
    public apiNewsPictureIdFileStreamGet(id: number, token?: string, options?: any) {
        return NewsPictureApiFp(this.configuration).apiNewsPictureIdFileStreamGet(id, token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsPictureApi
     */
    public apiNewsPictureIdGet(id: number, token?: string, options?: any) {
        return NewsPictureApiFp(this.configuration).apiNewsPictureIdGet(id, token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [token] 
     * @param {NewsPictureUpdateDto} [newsPictureUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsPictureApi
     */
    public apiNewsPictureIdPatch(id: number, token?: string, newsPictureUpdateDto?: NewsPictureUpdateDto, options?: any) {
        return NewsPictureApiFp(this.configuration).apiNewsPictureIdPatch(id, token, newsPictureUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [token] 
     * @param {NewsPictureUpdateDto} [newsPictureUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsPictureApi
     */
    public apiNewsPictureIdPut(id: number, token?: string, newsPictureUpdateDto?: NewsPictureUpdateDto, options?: any) {
        return NewsPictureApiFp(this.configuration).apiNewsPictureIdPut(id, token, newsPictureUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsPictureApi
     */
    public apiNewsPictureIdThumbnailGet(id: number, token?: string, options?: any) {
        return NewsPictureApiFp(this.configuration).apiNewsPictureIdThumbnailGet(id, token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [token] 
     * @param {string} [reducedFileBlobName] 
     * @param {boolean} [useToken] 
     * @param {any} [file] 
     * @param {string} [fileName] 
     * @param {string} [contentType] 
     * @param {string} [blobName] 
     * @param {number} [id] 
     * @param {string} [createdAt] 
     * @param {number} [createdById] 
     * @param {string} [updatedAt] 
     * @param {number} [updatedById] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsPictureApi
     */
    public apiNewsPicturePost(token?: string, reducedFileBlobName?: string, useToken?: boolean, file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any) {
        return NewsPictureApiFp(this.configuration).apiNewsPicturePost(token, reducedFileBlobName, useToken, file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsClearPost: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Notifications/clear`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotificationsClearPost(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationsClearPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotificationsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsClearPost(options?: any): AxiosPromise<Array<NotificationDto>> {
            return localVarFp.apiNotificationsClearPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsGet(options?: any): AxiosPromise<Array<NotificationDto>> {
            return localVarFp.apiNotificationsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public apiNotificationsClearPost(options?: any) {
        return NotificationsApiFp(this.configuration).apiNotificationsClearPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public apiNotificationsGet(options?: any) {
        return NotificationsApiFp(this.configuration).apiNotificationsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OfferApi - axios parameter creator
 * @export
 */
export const OfferApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferCaseCaseIdGet: async (caseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiOfferCaseCaseIdGet', 'caseId', caseId)
            const localVarPath = `/api/Offer/case/{caseId}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Offer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOfferIdDelete', 'id', id)
            const localVarPath = `/api/Offer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOfferIdGet', 'id', id)
            const localVarPath = `/api/Offer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OfferUpdateDto} [offerUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferIdPatch: async (id: number, offerUpdateDto?: OfferUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOfferIdPatch', 'id', id)
            const localVarPath = `/api/Offer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offerUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferIdPdfGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOfferIdPdfGet', 'id', id)
            const localVarPath = `/api/Offer/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferIdPublishPost: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOfferIdPublishPost', 'id', id)
            const localVarPath = `/api/Offer/{id}/publish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OfferUpdateDto} [offerUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferIdPut: async (id: number, offerUpdateDto?: OfferUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOfferIdPut', 'id', id)
            const localVarPath = `/api/Offer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offerUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OfferCreateDto} [offerCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferPost: async (offerCreateDto?: OfferCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Offer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offerCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferPublishedPublicationIdPdfGet: async (publicationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicationId' is not null or undefined
            assertParamExists('apiOfferPublishedPublicationIdPdfGet', 'publicationId', publicationId)
            const localVarPath = `/api/Offer/published/{publicationId}/pdf`
                .replace(`{${"publicationId"}}`, encodeURIComponent(String(publicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfferApi - functional programming interface
 * @export
 */
export const OfferApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfferApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOfferCaseCaseIdGet(caseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOfferCaseCaseIdGet(caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOfferGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OfferDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOfferGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOfferIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOfferIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOfferIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOfferIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {OfferUpdateDto} [offerUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOfferIdPatch(id: number, offerUpdateDto?: OfferUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOfferIdPatch(id, offerUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOfferIdPdfGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOfferIdPdfGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOfferIdPublishPost(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOfferIdPublishPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {OfferUpdateDto} [offerUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOfferIdPut(id: number, offerUpdateDto?: OfferUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOfferIdPut(id, offerUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OfferCreateDto} [offerCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOfferPost(offerCreateDto?: OfferCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOfferPost(offerCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} publicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOfferPublishedPublicationIdPdfGet(publicationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOfferPublishedPublicationIdPdfGet(publicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OfferApi - factory interface
 * @export
 */
export const OfferApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfferApiFp(configuration)
    return {
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferCaseCaseIdGet(caseId: number, options?: any): AxiosPromise<OfferDetailsDto> {
            return localVarFp.apiOfferCaseCaseIdGet(caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferGet(options?: any): AxiosPromise<Array<OfferDto>> {
            return localVarFp.apiOfferGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiOfferIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferIdGet(id: number, options?: any): AxiosPromise<OfferDetailsDto> {
            return localVarFp.apiOfferIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {OfferUpdateDto} [offerUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferIdPatch(id: number, offerUpdateDto?: OfferUpdateDto, options?: any): AxiosPromise<OfferDetailsDto> {
            return localVarFp.apiOfferIdPatch(id, offerUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferIdPdfGet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiOfferIdPdfGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferIdPublishPost(id: number, options?: any): AxiosPromise<OfferDetailsDto> {
            return localVarFp.apiOfferIdPublishPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {OfferUpdateDto} [offerUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferIdPut(id: number, offerUpdateDto?: OfferUpdateDto, options?: any): AxiosPromise<OfferDetailsDto> {
            return localVarFp.apiOfferIdPut(id, offerUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OfferCreateDto} [offerCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferPost(offerCreateDto?: OfferCreateDto, options?: any): AxiosPromise<OfferDetailsDto> {
            return localVarFp.apiOfferPost(offerCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} publicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferPublishedPublicationIdPdfGet(publicationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiOfferPublishedPublicationIdPdfGet(publicationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OfferApi - object-oriented interface
 * @export
 * @class OfferApi
 * @extends {BaseAPI}
 */
export class OfferApi extends BaseAPI {
    /**
     * 
     * @param {number} caseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public apiOfferCaseCaseIdGet(caseId: number, options?: any) {
        return OfferApiFp(this.configuration).apiOfferCaseCaseIdGet(caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public apiOfferGet(options?: any) {
        return OfferApiFp(this.configuration).apiOfferGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public apiOfferIdDelete(id: number, options?: any) {
        return OfferApiFp(this.configuration).apiOfferIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public apiOfferIdGet(id: number, options?: any) {
        return OfferApiFp(this.configuration).apiOfferIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {OfferUpdateDto} [offerUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public apiOfferIdPatch(id: number, offerUpdateDto?: OfferUpdateDto, options?: any) {
        return OfferApiFp(this.configuration).apiOfferIdPatch(id, offerUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public apiOfferIdPdfGet(id: number, options?: any) {
        return OfferApiFp(this.configuration).apiOfferIdPdfGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public apiOfferIdPublishPost(id: number, options?: any) {
        return OfferApiFp(this.configuration).apiOfferIdPublishPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {OfferUpdateDto} [offerUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public apiOfferIdPut(id: number, offerUpdateDto?: OfferUpdateDto, options?: any) {
        return OfferApiFp(this.configuration).apiOfferIdPut(id, offerUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OfferCreateDto} [offerCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public apiOfferPost(offerCreateDto?: OfferCreateDto, options?: any) {
        return OfferApiFp(this.configuration).apiOfferPost(offerCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} publicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public apiOfferPublishedPublicationIdPdfGet(publicationId: string, options?: any) {
        return OfferApiFp(this.configuration).apiOfferPublishedPublicationIdPdfGet(publicationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OfferAttachmentApi - axios parameter creator
 * @export
 */
export const OfferAttachmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferAttachmentGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OfferAttachment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferAttachmentIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOfferAttachmentIdDelete', 'id', id)
            const localVarPath = `/api/OfferAttachment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferAttachmentIdFileStreamGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOfferAttachmentIdFileStreamGet', 'id', id)
            const localVarPath = `/api/OfferAttachment/{id}/FileStream`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferAttachmentIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOfferAttachmentIdGet', 'id', id)
            const localVarPath = `/api/OfferAttachment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OfferAttachmentUpdateDto} [offerAttachmentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferAttachmentIdPatch: async (id: number, offerAttachmentUpdateDto?: OfferAttachmentUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOfferAttachmentIdPatch', 'id', id)
            const localVarPath = `/api/OfferAttachment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offerAttachmentUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} publicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferAttachmentIdPublicPublicationIdGet: async (id: number, publicationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOfferAttachmentIdPublicPublicationIdGet', 'id', id)
            // verify required parameter 'publicationId' is not null or undefined
            assertParamExists('apiOfferAttachmentIdPublicPublicationIdGet', 'publicationId', publicationId)
            const localVarPath = `/api/OfferAttachment/{id}/public/{publicationId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"publicationId"}}`, encodeURIComponent(String(publicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OfferAttachmentUpdateDto} [offerAttachmentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferAttachmentIdPut: async (id: number, offerAttachmentUpdateDto?: OfferAttachmentUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOfferAttachmentIdPut', 'id', id)
            const localVarPath = `/api/OfferAttachment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offerAttachmentUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferAttachmentPost: async (file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OfferAttachment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (blobName !== undefined) { 
                localVarFormParams.append('BlobName', blobName as any);
            }
    
            if (id !== undefined) { 
                localVarFormParams.append('Id', id as any);
            }
    
            if (createdAt !== undefined) { 
                localVarFormParams.append('CreatedAt', createdAt as any);
            }
    
            if (createdById !== undefined) { 
                localVarFormParams.append('CreatedById', createdById as any);
            }
    
            if (updatedAt !== undefined) { 
                localVarFormParams.append('UpdatedAt', updatedAt as any);
            }
    
            if (updatedById !== undefined) { 
                localVarFormParams.append('UpdatedById', updatedById as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfferAttachmentApi - functional programming interface
 * @export
 */
export const OfferAttachmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfferAttachmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOfferAttachmentGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OfferAttachmentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOfferAttachmentGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOfferAttachmentIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOfferAttachmentIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOfferAttachmentIdFileStreamGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOfferAttachmentIdFileStreamGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOfferAttachmentIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferAttachmentDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOfferAttachmentIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {OfferAttachmentUpdateDto} [offerAttachmentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOfferAttachmentIdPatch(id: number, offerAttachmentUpdateDto?: OfferAttachmentUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferAttachmentDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOfferAttachmentIdPatch(id, offerAttachmentUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} publicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOfferAttachmentIdPublicPublicationIdGet(id: number, publicationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOfferAttachmentIdPublicPublicationIdGet(id, publicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {OfferAttachmentUpdateDto} [offerAttachmentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOfferAttachmentIdPut(id: number, offerAttachmentUpdateDto?: OfferAttachmentUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferAttachmentDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOfferAttachmentIdPut(id, offerAttachmentUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOfferAttachmentPost(file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferAttachmentDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOfferAttachmentPost(file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OfferAttachmentApi - factory interface
 * @export
 */
export const OfferAttachmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfferAttachmentApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferAttachmentGet(options?: any): AxiosPromise<Array<OfferAttachmentDto>> {
            return localVarFp.apiOfferAttachmentGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferAttachmentIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiOfferAttachmentIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferAttachmentIdFileStreamGet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiOfferAttachmentIdFileStreamGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferAttachmentIdGet(id: number, options?: any): AxiosPromise<OfferAttachmentDetailsDto> {
            return localVarFp.apiOfferAttachmentIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {OfferAttachmentUpdateDto} [offerAttachmentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferAttachmentIdPatch(id: number, offerAttachmentUpdateDto?: OfferAttachmentUpdateDto, options?: any): AxiosPromise<OfferAttachmentDetailsDto> {
            return localVarFp.apiOfferAttachmentIdPatch(id, offerAttachmentUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} publicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferAttachmentIdPublicPublicationIdGet(id: number, publicationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiOfferAttachmentIdPublicPublicationIdGet(id, publicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {OfferAttachmentUpdateDto} [offerAttachmentUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferAttachmentIdPut(id: number, offerAttachmentUpdateDto?: OfferAttachmentUpdateDto, options?: any): AxiosPromise<OfferAttachmentDetailsDto> {
            return localVarFp.apiOfferAttachmentIdPut(id, offerAttachmentUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOfferAttachmentPost(file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): AxiosPromise<OfferAttachmentDetailsDto> {
            return localVarFp.apiOfferAttachmentPost(file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OfferAttachmentApi - object-oriented interface
 * @export
 * @class OfferAttachmentApi
 * @extends {BaseAPI}
 */
export class OfferAttachmentApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferAttachmentApi
     */
    public apiOfferAttachmentGet(options?: any) {
        return OfferAttachmentApiFp(this.configuration).apiOfferAttachmentGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferAttachmentApi
     */
    public apiOfferAttachmentIdDelete(id: number, options?: any) {
        return OfferAttachmentApiFp(this.configuration).apiOfferAttachmentIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferAttachmentApi
     */
    public apiOfferAttachmentIdFileStreamGet(id: number, options?: any) {
        return OfferAttachmentApiFp(this.configuration).apiOfferAttachmentIdFileStreamGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferAttachmentApi
     */
    public apiOfferAttachmentIdGet(id: number, options?: any) {
        return OfferAttachmentApiFp(this.configuration).apiOfferAttachmentIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {OfferAttachmentUpdateDto} [offerAttachmentUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferAttachmentApi
     */
    public apiOfferAttachmentIdPatch(id: number, offerAttachmentUpdateDto?: OfferAttachmentUpdateDto, options?: any) {
        return OfferAttachmentApiFp(this.configuration).apiOfferAttachmentIdPatch(id, offerAttachmentUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} publicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferAttachmentApi
     */
    public apiOfferAttachmentIdPublicPublicationIdGet(id: number, publicationId: string, options?: any) {
        return OfferAttachmentApiFp(this.configuration).apiOfferAttachmentIdPublicPublicationIdGet(id, publicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {OfferAttachmentUpdateDto} [offerAttachmentUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferAttachmentApi
     */
    public apiOfferAttachmentIdPut(id: number, offerAttachmentUpdateDto?: OfferAttachmentUpdateDto, options?: any) {
        return OfferAttachmentApiFp(this.configuration).apiOfferAttachmentIdPut(id, offerAttachmentUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [file] 
     * @param {string} [fileName] 
     * @param {string} [contentType] 
     * @param {string} [blobName] 
     * @param {number} [id] 
     * @param {string} [createdAt] 
     * @param {number} [createdById] 
     * @param {string} [updatedAt] 
     * @param {number} [updatedById] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferAttachmentApi
     */
    public apiOfferAttachmentPost(file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any) {
        return OfferAttachmentApiFp(this.configuration).apiOfferAttachmentPost(file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganisationApi - axios parameter creator
 * @export
 */
export const OrganisationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationContextUserGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organisation/contextUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organisation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrganisationIdDelete', 'id', id)
            const localVarPath = `/api/Organisation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrganisationIdGet', 'id', id)
            const localVarPath = `/api/Organisation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OrganisationUpdateDto} [organisationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationIdPatch: async (id: number, organisationUpdateDto?: OrganisationUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrganisationIdPatch', 'id', id)
            const localVarPath = `/api/Organisation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organisationUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OrganisationUpdateDto} [organisationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationIdPut: async (id: number, organisationUpdateDto?: OrganisationUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrganisationIdPut', 'id', id)
            const localVarPath = `/api/Organisation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organisationUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrganisationCreateDto} [organisationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationPost: async (organisationCreateDto?: OrganisationCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organisation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organisationCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationApi - functional programming interface
 * @export
 */
export const OrganisationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationContextUserGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganisationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationContextUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganisationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {OrganisationUpdateDto} [organisationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationIdPatch(id: number, organisationUpdateDto?: OrganisationUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationIdPatch(id, organisationUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {OrganisationUpdateDto} [organisationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationIdPut(id: number, organisationUpdateDto?: OrganisationUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationIdPut(id, organisationUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OrganisationCreateDto} [organisationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationPost(organisationCreateDto?: OrganisationCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationPost(organisationCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganisationApi - factory interface
 * @export
 */
export const OrganisationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationContextUserGet(options?: any): AxiosPromise<Array<OrganisationDto>> {
            return localVarFp.apiOrganisationContextUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationGet(options?: any): AxiosPromise<Array<OrganisationDto>> {
            return localVarFp.apiOrganisationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrganisationIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationIdGet(id: number, options?: any): AxiosPromise<OrganisationDetailsDto> {
            return localVarFp.apiOrganisationIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {OrganisationUpdateDto} [organisationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationIdPatch(id: number, organisationUpdateDto?: OrganisationUpdateDto, options?: any): AxiosPromise<OrganisationDetailsDto> {
            return localVarFp.apiOrganisationIdPatch(id, organisationUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {OrganisationUpdateDto} [organisationUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationIdPut(id: number, organisationUpdateDto?: OrganisationUpdateDto, options?: any): AxiosPromise<OrganisationDetailsDto> {
            return localVarFp.apiOrganisationIdPut(id, organisationUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrganisationCreateDto} [organisationCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationPost(organisationCreateDto?: OrganisationCreateDto, options?: any): AxiosPromise<OrganisationDetailsDto> {
            return localVarFp.apiOrganisationPost(organisationCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganisationApi - object-oriented interface
 * @export
 * @class OrganisationApi
 * @extends {BaseAPI}
 */
export class OrganisationApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationContextUserGet(options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationContextUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationGet(options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationIdDelete(id: number, options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationIdGet(id: number, options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {OrganisationUpdateDto} [organisationUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationIdPatch(id: number, organisationUpdateDto?: OrganisationUpdateDto, options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationIdPatch(id, organisationUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {OrganisationUpdateDto} [organisationUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationIdPut(id: number, organisationUpdateDto?: OrganisationUpdateDto, options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationIdPut(id, organisationUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrganisationCreateDto} [organisationCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationPost(organisationCreateDto?: OrganisationCreateDto, options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationPost(organisationCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganisationUserApi - axios parameter creator
 * @export
 */
export const OrganisationUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrganisationUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrganisationUserIdDelete', 'id', id)
            const localVarPath = `/api/OrganisationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrganisationUserIdGet', 'id', id)
            const localVarPath = `/api/OrganisationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OrganisationUserUpdateDto} [organisationUserUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserIdPatch: async (id: number, organisationUserUpdateDto?: OrganisationUserUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrganisationUserIdPatch', 'id', id)
            const localVarPath = `/api/OrganisationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organisationUserUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OrganisationUserUpdateDto} [organisationUserUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserIdPut: async (id: number, organisationUserUpdateDto?: OrganisationUserUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrganisationUserIdPut', 'id', id)
            const localVarPath = `/api/OrganisationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organisationUserUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserOrganisationOrganisationIdGet: async (organisationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('apiOrganisationUserOrganisationOrganisationIdGet', 'organisationId', organisationId)
            const localVarPath = `/api/OrganisationUser/organisation/{organisationId}`
                .replace(`{${"organisationId"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrganisationUserCreateDto} [organisationUserCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserPost: async (organisationUserCreateDto?: OrganisationUserCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/OrganisationUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organisationUserCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationUserApi - functional programming interface
 * @export
 */
export const OrganisationUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationUserGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganisationUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationUserIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationUserIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationUserIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationUserIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {OrganisationUserUpdateDto} [organisationUserUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationUserIdPatch(id: number, organisationUserUpdateDto?: OrganisationUserUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationUserIdPatch(id, organisationUserUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {OrganisationUserUpdateDto} [organisationUserUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationUserIdPut(id: number, organisationUserUpdateDto?: OrganisationUserUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationUserIdPut(id, organisationUserUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationUserOrganisationOrganisationIdGet(organisationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganisationUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationUserOrganisationOrganisationIdGet(organisationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OrganisationUserCreateDto} [organisationUserCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationUserPost(organisationUserCreateDto?: OrganisationUserCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationUserPost(organisationUserCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganisationUserApi - factory interface
 * @export
 */
export const OrganisationUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationUserApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserGet(options?: any): AxiosPromise<Array<OrganisationUserDto>> {
            return localVarFp.apiOrganisationUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrganisationUserIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserIdGet(id: number, options?: any): AxiosPromise<OrganisationUserDetailsDto> {
            return localVarFp.apiOrganisationUserIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {OrganisationUserUpdateDto} [organisationUserUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserIdPatch(id: number, organisationUserUpdateDto?: OrganisationUserUpdateDto, options?: any): AxiosPromise<OrganisationUserDetailsDto> {
            return localVarFp.apiOrganisationUserIdPatch(id, organisationUserUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {OrganisationUserUpdateDto} [organisationUserUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserIdPut(id: number, organisationUserUpdateDto?: OrganisationUserUpdateDto, options?: any): AxiosPromise<OrganisationUserDetailsDto> {
            return localVarFp.apiOrganisationUserIdPut(id, organisationUserUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserOrganisationOrganisationIdGet(organisationId: number, options?: any): AxiosPromise<Array<OrganisationUserDto>> {
            return localVarFp.apiOrganisationUserOrganisationOrganisationIdGet(organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrganisationUserCreateDto} [organisationUserCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationUserPost(organisationUserCreateDto?: OrganisationUserCreateDto, options?: any): AxiosPromise<OrganisationUserDetailsDto> {
            return localVarFp.apiOrganisationUserPost(organisationUserCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganisationUserApi - object-oriented interface
 * @export
 * @class OrganisationUserApi
 * @extends {BaseAPI}
 */
export class OrganisationUserApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationUserApi
     */
    public apiOrganisationUserGet(options?: any) {
        return OrganisationUserApiFp(this.configuration).apiOrganisationUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationUserApi
     */
    public apiOrganisationUserIdDelete(id: number, options?: any) {
        return OrganisationUserApiFp(this.configuration).apiOrganisationUserIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationUserApi
     */
    public apiOrganisationUserIdGet(id: number, options?: any) {
        return OrganisationUserApiFp(this.configuration).apiOrganisationUserIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {OrganisationUserUpdateDto} [organisationUserUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationUserApi
     */
    public apiOrganisationUserIdPatch(id: number, organisationUserUpdateDto?: OrganisationUserUpdateDto, options?: any) {
        return OrganisationUserApiFp(this.configuration).apiOrganisationUserIdPatch(id, organisationUserUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {OrganisationUserUpdateDto} [organisationUserUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationUserApi
     */
    public apiOrganisationUserIdPut(id: number, organisationUserUpdateDto?: OrganisationUserUpdateDto, options?: any) {
        return OrganisationUserApiFp(this.configuration).apiOrganisationUserIdPut(id, organisationUserUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} organisationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationUserApi
     */
    public apiOrganisationUserOrganisationOrganisationIdGet(organisationId: number, options?: any) {
        return OrganisationUserApiFp(this.configuration).apiOrganisationUserOrganisationOrganisationIdGet(organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrganisationUserCreateDto} [organisationUserCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationUserApi
     */
    public apiOrganisationUserPost(organisationUserCreateDto?: OrganisationUserCreateDto, options?: any) {
        return OrganisationUserApiFp(this.configuration).apiOrganisationUserPost(organisationUserCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PayUNotificationApi - axios parameter creator
 * @export
 */
export const PayUNotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PayUOrderNotification} [payUOrderNotification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPayUNotificationPost: async (payUOrderNotification?: PayUOrderNotification, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PayUNotification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payUOrderNotification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PayUNotificationApi - functional programming interface
 * @export
 */
export const PayUNotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PayUNotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PayUOrderNotification} [payUOrderNotification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPayUNotificationPost(payUOrderNotification?: PayUOrderNotification, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPayUNotificationPost(payUOrderNotification, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PayUNotificationApi - factory interface
 * @export
 */
export const PayUNotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PayUNotificationApiFp(configuration)
    return {
        /**
         * 
         * @param {PayUOrderNotification} [payUOrderNotification] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPayUNotificationPost(payUOrderNotification?: PayUOrderNotification, options?: any): AxiosPromise<void> {
            return localVarFp.apiPayUNotificationPost(payUOrderNotification, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PayUNotificationApi - object-oriented interface
 * @export
 * @class PayUNotificationApi
 * @extends {BaseAPI}
 */
export class PayUNotificationApi extends BaseAPI {
    /**
     * 
     * @param {PayUOrderNotification} [payUOrderNotification] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayUNotificationApi
     */
    public apiPayUNotificationPost(payUOrderNotification?: PayUOrderNotification, options?: any) {
        return PayUNotificationApiFp(this.configuration).apiPayUNotificationPost(payUOrderNotification, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductIdDelete', 'id', id)
            const localVarPath = `/api/Product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductIdGet', 'id', id)
            const localVarPath = `/api/Product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductUpdateDto} [productUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdPatch: async (id: number, productUpdateDto?: ProductUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductIdPatch', 'id', id)
            const localVarPath = `/api/Product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductUpdateDto} [productUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdPut: async (id: number, productUpdateDto?: ProductUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductIdPut', 'id', id)
            const localVarPath = `/api/Product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductMinimalGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Product/minimal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductCreateDto} [productCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductPost: async (productCreateDto?: ProductCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductUpdateDto} [productUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductIdPatch(id: number, productUpdateDto?: ProductUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductIdPatch(id, productUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductUpdateDto} [productUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductIdPut(id: number, productUpdateDto?: ProductUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductIdPut(id, productUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductMinimalGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductMinimalDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductMinimalGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductCreateDto} [productCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductPost(productCreateDto?: ProductCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductPost(productCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductGet(options?: any): AxiosPromise<Array<ProductDto>> {
            return localVarFp.apiProductGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdGet(id: number, options?: any): AxiosPromise<ProductDetailsDto> {
            return localVarFp.apiProductIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductUpdateDto} [productUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdPatch(id: number, productUpdateDto?: ProductUpdateDto, options?: any): AxiosPromise<ProductDetailsDto> {
            return localVarFp.apiProductIdPatch(id, productUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductUpdateDto} [productUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdPut(id: number, productUpdateDto?: ProductUpdateDto, options?: any): AxiosPromise<ProductDetailsDto> {
            return localVarFp.apiProductIdPut(id, productUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductMinimalGet(options?: any): AxiosPromise<Array<ProductMinimalDto>> {
            return localVarFp.apiProductMinimalGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductCreateDto} [productCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductPost(productCreateDto?: ProductCreateDto, options?: any): AxiosPromise<ProductDetailsDto> {
            return localVarFp.apiProductPost(productCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductGet(options?: any) {
        return ProductApiFp(this.configuration).apiProductGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductIdDelete(id: number, options?: any) {
        return ProductApiFp(this.configuration).apiProductIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductIdGet(id: number, options?: any) {
        return ProductApiFp(this.configuration).apiProductIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductUpdateDto} [productUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductIdPatch(id: number, productUpdateDto?: ProductUpdateDto, options?: any) {
        return ProductApiFp(this.configuration).apiProductIdPatch(id, productUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductUpdateDto} [productUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductIdPut(id: number, productUpdateDto?: ProductUpdateDto, options?: any) {
        return ProductApiFp(this.configuration).apiProductIdPut(id, productUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductMinimalGet(options?: any) {
        return ProductApiFp(this.configuration).apiProductMinimalGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductCreateDto} [productCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiProductPost(productCreateDto?: ProductCreateDto, options?: any) {
        return ProductApiFp(this.configuration).apiProductPost(productCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProfessionApi - axios parameter creator
 * @export
 */
export const ProfessionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfessionGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Profession`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfessionIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProfessionIdDelete', 'id', id)
            const localVarPath = `/api/Profession/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfessionIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProfessionIdGet', 'id', id)
            const localVarPath = `/api/Profession/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProfessionUpdateDto} [professionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfessionIdPatch: async (id: number, professionUpdateDto?: ProfessionUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProfessionIdPatch', 'id', id)
            const localVarPath = `/api/Profession/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(professionUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProfessionUpdateDto} [professionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfessionIdPut: async (id: number, professionUpdateDto?: ProfessionUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProfessionIdPut', 'id', id)
            const localVarPath = `/api/Profession/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(professionUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProfessionCreateDto} [professionCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfessionPost: async (professionCreateDto?: ProfessionCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Profession`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(professionCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfessionApi - functional programming interface
 * @export
 */
export const ProfessionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfessionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProfessionGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProfessionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProfessionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProfessionIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProfessionIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProfessionIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfessionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProfessionIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProfessionUpdateDto} [professionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProfessionIdPatch(id: number, professionUpdateDto?: ProfessionUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfessionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProfessionIdPatch(id, professionUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProfessionUpdateDto} [professionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProfessionIdPut(id: number, professionUpdateDto?: ProfessionUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfessionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProfessionIdPut(id, professionUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProfessionCreateDto} [professionCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProfessionPost(professionCreateDto?: ProfessionCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfessionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProfessionPost(professionCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfessionApi - factory interface
 * @export
 */
export const ProfessionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfessionApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfessionGet(options?: any): AxiosPromise<Array<ProfessionDto>> {
            return localVarFp.apiProfessionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfessionIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiProfessionIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfessionIdGet(id: number, options?: any): AxiosPromise<ProfessionDetailsDto> {
            return localVarFp.apiProfessionIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProfessionUpdateDto} [professionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfessionIdPatch(id: number, professionUpdateDto?: ProfessionUpdateDto, options?: any): AxiosPromise<ProfessionDetailsDto> {
            return localVarFp.apiProfessionIdPatch(id, professionUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProfessionUpdateDto} [professionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfessionIdPut(id: number, professionUpdateDto?: ProfessionUpdateDto, options?: any): AxiosPromise<ProfessionDetailsDto> {
            return localVarFp.apiProfessionIdPut(id, professionUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProfessionCreateDto} [professionCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfessionPost(professionCreateDto?: ProfessionCreateDto, options?: any): AxiosPromise<ProfessionDetailsDto> {
            return localVarFp.apiProfessionPost(professionCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfessionApi - object-oriented interface
 * @export
 * @class ProfessionApi
 * @extends {BaseAPI}
 */
export class ProfessionApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessionApi
     */
    public apiProfessionGet(options?: any) {
        return ProfessionApiFp(this.configuration).apiProfessionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessionApi
     */
    public apiProfessionIdDelete(id: number, options?: any) {
        return ProfessionApiFp(this.configuration).apiProfessionIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessionApi
     */
    public apiProfessionIdGet(id: number, options?: any) {
        return ProfessionApiFp(this.configuration).apiProfessionIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProfessionUpdateDto} [professionUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessionApi
     */
    public apiProfessionIdPatch(id: number, professionUpdateDto?: ProfessionUpdateDto, options?: any) {
        return ProfessionApiFp(this.configuration).apiProfessionIdPatch(id, professionUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProfessionUpdateDto} [professionUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessionApi
     */
    public apiProfessionIdPut(id: number, professionUpdateDto?: ProfessionUpdateDto, options?: any) {
        return ProfessionApiFp(this.configuration).apiProfessionIdPut(id, professionUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfessionCreateDto} [professionCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessionApi
     */
    public apiProfessionPost(professionCreateDto?: ProfessionCreateDto, options?: any) {
        return ProfessionApiFp(this.configuration).apiProfessionPost(professionCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProfilePictureApi - axios parameter creator
 * @export
 */
export const ProfilePictureApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfilePictureGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProfilePicture`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfilePictureIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProfilePictureIdDelete', 'id', id)
            const localVarPath = `/api/ProfilePicture/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfilePictureIdFileStreamGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProfilePictureIdFileStreamGet', 'id', id)
            const localVarPath = `/api/ProfilePicture/{id}/FileStream`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfilePictureIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProfilePictureIdGet', 'id', id)
            const localVarPath = `/api/ProfilePicture/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProfilePictureUpdateDto} [profilePictureUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfilePictureIdPatch: async (id: number, profilePictureUpdateDto?: ProfilePictureUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProfilePictureIdPatch', 'id', id)
            const localVarPath = `/api/ProfilePicture/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profilePictureUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProfilePictureUpdateDto} [profilePictureUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfilePictureIdPut: async (id: number, profilePictureUpdateDto?: ProfilePictureUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProfilePictureIdPut', 'id', id)
            const localVarPath = `/api/ProfilePicture/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profilePictureUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfilePictureIdThumbnailGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProfilePictureIdThumbnailGet', 'id', id)
            const localVarPath = `/api/ProfilePicture/{id}/thumbnail`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [reducedFileBlobName] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfilePicturePost: async (reducedFileBlobName?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProfilePicture`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


            if (reducedFileBlobName !== undefined) { 
                localVarFormParams.append('ReducedFileBlobName', reducedFileBlobName as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (blobName !== undefined) { 
                localVarFormParams.append('BlobName', blobName as any);
            }
    
            if (id !== undefined) { 
                localVarFormParams.append('Id', id as any);
            }
    
            if (createdAt !== undefined) { 
                localVarFormParams.append('CreatedAt', createdAt as any);
            }
    
            if (createdById !== undefined) { 
                localVarFormParams.append('CreatedById', createdById as any);
            }
    
            if (updatedAt !== undefined) { 
                localVarFormParams.append('UpdatedAt', updatedAt as any);
            }
    
            if (updatedById !== undefined) { 
                localVarFormParams.append('UpdatedById', updatedById as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfilePictureApi - functional programming interface
 * @export
 */
export const ProfilePictureApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfilePictureApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProfilePictureGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProfilePictureDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProfilePictureGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProfilePictureIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProfilePictureIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProfilePictureIdFileStreamGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProfilePictureIdFileStreamGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProfilePictureIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfilePictureDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProfilePictureIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProfilePictureUpdateDto} [profilePictureUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProfilePictureIdPatch(id: number, profilePictureUpdateDto?: ProfilePictureUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfilePictureDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProfilePictureIdPatch(id, profilePictureUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProfilePictureUpdateDto} [profilePictureUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProfilePictureIdPut(id: number, profilePictureUpdateDto?: ProfilePictureUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfilePictureDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProfilePictureIdPut(id, profilePictureUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProfilePictureIdThumbnailGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProfilePictureIdThumbnailGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [reducedFileBlobName] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProfilePicturePost(reducedFileBlobName?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfilePictureDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProfilePicturePost(reducedFileBlobName, file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfilePictureApi - factory interface
 * @export
 */
export const ProfilePictureApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfilePictureApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfilePictureGet(options?: any): AxiosPromise<Array<ProfilePictureDto>> {
            return localVarFp.apiProfilePictureGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfilePictureIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiProfilePictureIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfilePictureIdFileStreamGet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiProfilePictureIdFileStreamGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfilePictureIdGet(id: number, options?: any): AxiosPromise<ProfilePictureDetailsDto> {
            return localVarFp.apiProfilePictureIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProfilePictureUpdateDto} [profilePictureUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfilePictureIdPatch(id: number, profilePictureUpdateDto?: ProfilePictureUpdateDto, options?: any): AxiosPromise<ProfilePictureDetailsDto> {
            return localVarFp.apiProfilePictureIdPatch(id, profilePictureUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProfilePictureUpdateDto} [profilePictureUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfilePictureIdPut(id: number, profilePictureUpdateDto?: ProfilePictureUpdateDto, options?: any): AxiosPromise<ProfilePictureDetailsDto> {
            return localVarFp.apiProfilePictureIdPut(id, profilePictureUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfilePictureIdThumbnailGet(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.apiProfilePictureIdThumbnailGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [reducedFileBlobName] 
         * @param {any} [file] 
         * @param {string} [fileName] 
         * @param {string} [contentType] 
         * @param {string} [blobName] 
         * @param {number} [id] 
         * @param {string} [createdAt] 
         * @param {number} [createdById] 
         * @param {string} [updatedAt] 
         * @param {number} [updatedById] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProfilePicturePost(reducedFileBlobName?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any): AxiosPromise<ProfilePictureDetailsDto> {
            return localVarFp.apiProfilePicturePost(reducedFileBlobName, file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfilePictureApi - object-oriented interface
 * @export
 * @class ProfilePictureApi
 * @extends {BaseAPI}
 */
export class ProfilePictureApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfilePictureApi
     */
    public apiProfilePictureGet(options?: any) {
        return ProfilePictureApiFp(this.configuration).apiProfilePictureGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfilePictureApi
     */
    public apiProfilePictureIdDelete(id: number, options?: any) {
        return ProfilePictureApiFp(this.configuration).apiProfilePictureIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfilePictureApi
     */
    public apiProfilePictureIdFileStreamGet(id: number, options?: any) {
        return ProfilePictureApiFp(this.configuration).apiProfilePictureIdFileStreamGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfilePictureApi
     */
    public apiProfilePictureIdGet(id: number, options?: any) {
        return ProfilePictureApiFp(this.configuration).apiProfilePictureIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProfilePictureUpdateDto} [profilePictureUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfilePictureApi
     */
    public apiProfilePictureIdPatch(id: number, profilePictureUpdateDto?: ProfilePictureUpdateDto, options?: any) {
        return ProfilePictureApiFp(this.configuration).apiProfilePictureIdPatch(id, profilePictureUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProfilePictureUpdateDto} [profilePictureUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfilePictureApi
     */
    public apiProfilePictureIdPut(id: number, profilePictureUpdateDto?: ProfilePictureUpdateDto, options?: any) {
        return ProfilePictureApiFp(this.configuration).apiProfilePictureIdPut(id, profilePictureUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfilePictureApi
     */
    public apiProfilePictureIdThumbnailGet(id: number, options?: any) {
        return ProfilePictureApiFp(this.configuration).apiProfilePictureIdThumbnailGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [reducedFileBlobName] 
     * @param {any} [file] 
     * @param {string} [fileName] 
     * @param {string} [contentType] 
     * @param {string} [blobName] 
     * @param {number} [id] 
     * @param {string} [createdAt] 
     * @param {number} [createdById] 
     * @param {string} [updatedAt] 
     * @param {number} [updatedById] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfilePictureApi
     */
    public apiProfilePicturePost(reducedFileBlobName?: string, file?: any, fileName?: string, contentType?: string, blobName?: string, id?: number, createdAt?: string, createdById?: number, updatedAt?: string, updatedById?: number, options?: any) {
        return ProfilePictureApiFp(this.configuration).apiProfilePicturePost(reducedFileBlobName, file, fileName, contentType, blobName, id, createdAt, createdById, updatedAt, updatedById, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RankingApi - axios parameter creator
 * @export
 */
export const RankingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRankingAmbassadorGet: async (date?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Ranking/ambassador`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRankingClientGet: async (date?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Ranking/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRankingDepartmentGet: async (date?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Ranking/department`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRankingExpertGet: async (date?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Ranking/expert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRankingManagerGet: async (date?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Ranking/manager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RankingApi - functional programming interface
 * @export
 */
export const RankingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RankingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRankingAmbassadorGet(date?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AmbasadorRankingDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRankingAmbassadorGet(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRankingClientGet(date?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientRankingDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRankingClientGet(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRankingDepartmentGet(date?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DepartmentRankingDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRankingDepartmentGet(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRankingExpertGet(date?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExpertRankingDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRankingExpertGet(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRankingManagerGet(date?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ManagerRankingDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRankingManagerGet(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RankingApi - factory interface
 * @export
 */
export const RankingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RankingApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRankingAmbassadorGet(date?: string, options?: any): AxiosPromise<Array<AmbasadorRankingDto>> {
            return localVarFp.apiRankingAmbassadorGet(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRankingClientGet(date?: string, options?: any): AxiosPromise<Array<ClientRankingDto>> {
            return localVarFp.apiRankingClientGet(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRankingDepartmentGet(date?: string, options?: any): AxiosPromise<Array<DepartmentRankingDto>> {
            return localVarFp.apiRankingDepartmentGet(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRankingExpertGet(date?: string, options?: any): AxiosPromise<Array<ExpertRankingDto>> {
            return localVarFp.apiRankingExpertGet(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRankingManagerGet(date?: string, options?: any): AxiosPromise<Array<ManagerRankingDto>> {
            return localVarFp.apiRankingManagerGet(date, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RankingApi - object-oriented interface
 * @export
 * @class RankingApi
 * @extends {BaseAPI}
 */
export class RankingApi extends BaseAPI {
    /**
     * 
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RankingApi
     */
    public apiRankingAmbassadorGet(date?: string, options?: any) {
        return RankingApiFp(this.configuration).apiRankingAmbassadorGet(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RankingApi
     */
    public apiRankingClientGet(date?: string, options?: any) {
        return RankingApiFp(this.configuration).apiRankingClientGet(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RankingApi
     */
    public apiRankingDepartmentGet(date?: string, options?: any) {
        return RankingApiFp(this.configuration).apiRankingDepartmentGet(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RankingApi
     */
    public apiRankingExpertGet(date?: string, options?: any) {
        return RankingApiFp(this.configuration).apiRankingExpertGet(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RankingApi
     */
    public apiRankingManagerGet(date?: string, options?: any) {
        return RankingApiFp(this.configuration).apiRankingManagerGet(date, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RegonApi - axios parameter creator
 * @export
 */
export const RegonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [nip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegonNipGet: async (nip?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Regon/nip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (nip !== undefined) {
                localVarQueryParameter['nip'] = nip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegonApi - functional programming interface
 * @export
 */
export const RegonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [nip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRegonNipGet(nip?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRegonNipGet(nip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RegonApi - factory interface
 * @export
 */
export const RegonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegonApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [nip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRegonNipGet(nip?: string, options?: any): AxiosPromise<CompanyDetailsDto> {
            return localVarFp.apiRegonNipGet(nip, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegonApi - object-oriented interface
 * @export
 * @class RegonApi
 * @extends {BaseAPI}
 */
export class RegonApi extends BaseAPI {
    /**
     * 
     * @param {string} [nip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegonApi
     */
    public apiRegonNipGet(nip?: string, options?: any) {
        return RegonApiFp(this.configuration).apiRegonNipGet(nip, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettlementApi - axios parameter creator
 * @export
 */
export const SettlementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementClientCaseIdGet: async (caseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiSettlementClientCaseIdGet', 'caseId', caseId)
            const localVarPath = `/api/Settlement/client/{caseId}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementClientGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Settlement/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementDeleteCardDelete: async (token?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Settlement/deleteCard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Settlement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSettlementIdDelete', 'id', id)
            const localVarPath = `/api/Settlement/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [amount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdExpertInvoiceAmountPut: async (id: number, amount?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSettlementIdExpertInvoiceAmountPut', 'id', id)
            const localVarPath = `/api/Settlement/{id}/expertInvoiceAmount`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [invoiceNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdExpertInvoicePut: async (id: number, invoiceNumber?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSettlementIdExpertInvoicePut', 'id', id)
            const localVarPath = `/api/Settlement/{id}/expertInvoice`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (invoiceNumber !== undefined) {
                localVarQueryParameter['invoiceNumber'] = invoiceNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSettlementIdGet', 'id', id)
            const localVarPath = `/api/Settlement/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SettlementCreateDto} [settlementCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdMinimaleditPut: async (id: number, settlementCreateDto?: SettlementCreateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSettlementIdMinimaleditPut', 'id', id)
            const localVarPath = `/api/Settlement/{id}/minimaledit`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settlementCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [notes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdNotesPut: async (id: number, notes?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSettlementIdNotesPut', 'id', id)
            const localVarPath = `/api/Settlement/{id}/notes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (notes !== undefined) {
                localVarQueryParameter['notes'] = notes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SettlementUpdateDto} [settlementUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdPatch: async (id: number, settlementUpdateDto?: SettlementUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSettlementIdPatch', 'id', id)
            const localVarPath = `/api/Settlement/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settlementUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdPayBySavedCardPut: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSettlementIdPayBySavedCardPut', 'id', id)
            const localVarPath = `/api/Settlement/{id}/payBySavedCard`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PaymentStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdPaymentClientPut: async (id: number, status?: PaymentStatus, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSettlementIdPaymentClientPut', 'id', id)
            const localVarPath = `/api/Settlement/{id}/payment/client`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdPaymentDatePut: async (id: number, date?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSettlementIdPaymentDatePut', 'id', id)
            const localVarPath = `/api/Settlement/{id}/payment/date`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PaymentStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdPaymentExpertPut: async (id: number, status?: PaymentStatus, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSettlementIdPaymentExpertPut', 'id', id)
            const localVarPath = `/api/Settlement/{id}/payment/expert`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PaymentStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdPaymentLeadPut: async (id: number, status?: PaymentStatus, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSettlementIdPaymentLeadPut', 'id', id)
            const localVarPath = `/api/Settlement/{id}/payment/lead`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PaymentStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdPaymentLeaderPut: async (id: number, status?: PaymentStatus, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSettlementIdPaymentLeaderPut', 'id', id)
            const localVarPath = `/api/Settlement/{id}/payment/leader`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdPaymentLinkGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSettlementIdPaymentLinkGet', 'id', id)
            const localVarPath = `/api/Settlement/{id}/paymentLink`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PaymentStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdPaymentSalesPut: async (id: number, status?: PaymentStatus, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSettlementIdPaymentSalesPut', 'id', id)
            const localVarPath = `/api/Settlement/{id}/payment/sales`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SettlementUpdateDto} [settlementUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdPut: async (id: number, settlementUpdateDto?: SettlementUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSettlementIdPut', 'id', id)
            const localVarPath = `/api/Settlement/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settlementUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdSendNotificationPut: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSettlementIdSendNotificationPut', 'id', id)
            const localVarPath = `/api/Settlement/{id}/sendNotification`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIncomeCaseIdGet: async (caseId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('apiSettlementIncomeCaseIdGet', 'caseId', caseId)
            const localVarPath = `/api/Settlement/income/{caseId}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIncomeGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Settlement/income`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementOverdueGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Settlement/overdue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementPaymentMethodsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Settlement/paymentMethods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SettlementPaymentRequestDto} [settlementPaymentRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementPaymentPost: async (settlementPaymentRequestDto?: SettlementPaymentRequestDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Settlement/payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settlementPaymentRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SettlementCreateDto} [settlementCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementPost: async (settlementCreateDto?: SettlementCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Settlement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settlementCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [fingerprint] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementSaveCardPost: async (token?: string, fingerprint?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Settlement/saveCard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (fingerprint !== undefined) {
                localVarQueryParameter['fingerprint'] = fingerprint;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SettlementStatus} [status] 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementSettlementStatusPut: async (status?: SettlementStatus, id?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Settlement/SettlementStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [caseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementSettlementsForCaseCaseIdGet: async (caseId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Settlement/settlementsForCase/caseId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (caseId !== undefined) {
                localVarQueryParameter['caseId'] = caseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementSettlementsForContextUserGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Settlement/settlementsForContextUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementUnpaidGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Settlement/unpaid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettlementApi - functional programming interface
 * @export
 */
export const SettlementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettlementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementClientCaseIdGet(caseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SettlementClientDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementClientCaseIdGet(caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementClientGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SettlementClientDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementClientGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementDeleteCardDelete(token?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementDeleteCardDelete(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SettlementDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [amount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementIdExpertInvoiceAmountPut(id: number, amount?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettlementDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementIdExpertInvoiceAmountPut(id, amount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [invoiceNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementIdExpertInvoicePut(id: number, invoiceNumber?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettlementDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementIdExpertInvoicePut(id, invoiceNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettlementDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SettlementCreateDto} [settlementCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementIdMinimaleditPut(id: number, settlementCreateDto?: SettlementCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettlementDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementIdMinimaleditPut(id, settlementCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [notes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementIdNotesPut(id: number, notes?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettlementDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementIdNotesPut(id, notes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SettlementUpdateDto} [settlementUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementIdPatch(id: number, settlementUpdateDto?: SettlementUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettlementDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementIdPatch(id, settlementUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementIdPayBySavedCardPut(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettlementDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementIdPayBySavedCardPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PaymentStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementIdPaymentClientPut(id: number, status?: PaymentStatus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettlementDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementIdPaymentClientPut(id, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementIdPaymentDatePut(id: number, date?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettlementDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementIdPaymentDatePut(id, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PaymentStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementIdPaymentExpertPut(id: number, status?: PaymentStatus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettlementDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementIdPaymentExpertPut(id, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PaymentStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementIdPaymentLeadPut(id: number, status?: PaymentStatus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettlementDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementIdPaymentLeadPut(id, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PaymentStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementIdPaymentLeaderPut(id: number, status?: PaymentStatus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettlementDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementIdPaymentLeaderPut(id, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementIdPaymentLinkGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementIdPaymentLinkGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PaymentStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementIdPaymentSalesPut(id: number, status?: PaymentStatus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettlementDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementIdPaymentSalesPut(id, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SettlementUpdateDto} [settlementUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementIdPut(id: number, settlementUpdateDto?: SettlementUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettlementDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementIdPut(id, settlementUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementIdSendNotificationPut(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettlementDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementIdSendNotificationPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementIncomeCaseIdGet(caseId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SettlementIncomeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementIncomeCaseIdGet(caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementIncomeGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SettlementIncomeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementIncomeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementOverdueGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OverdueSettlementDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementOverdueGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementPaymentMethodsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PayUAvailablePayMethods>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementPaymentMethodsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SettlementPaymentRequestDto} [settlementPaymentRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementPaymentPost(settlementPaymentRequestDto?: SettlementPaymentRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettlementPaymentResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementPaymentPost(settlementPaymentRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SettlementCreateDto} [settlementCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementPost(settlementCreateDto?: SettlementCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettlementDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementPost(settlementCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [fingerprint] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementSaveCardPost(token?: string, fingerprint?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PayUOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementSaveCardPost(token, fingerprint, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SettlementStatus} [status] 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementSettlementStatusPut(status?: SettlementStatus, id?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SettlementDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementSettlementStatusPut(status, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [caseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementSettlementsForCaseCaseIdGet(caseId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SettlementDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementSettlementsForCaseCaseIdGet(caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementSettlementsForContextUserGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SettlementDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementSettlementsForContextUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettlementUnpaidGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SettlementDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettlementUnpaidGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettlementApi - factory interface
 * @export
 */
export const SettlementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettlementApiFp(configuration)
    return {
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementClientCaseIdGet(caseId: number, options?: any): AxiosPromise<Array<SettlementClientDto>> {
            return localVarFp.apiSettlementClientCaseIdGet(caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementClientGet(options?: any): AxiosPromise<Array<SettlementClientDto>> {
            return localVarFp.apiSettlementClientGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementDeleteCardDelete(token?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiSettlementDeleteCardDelete(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementGet(options?: any): AxiosPromise<Array<SettlementDto>> {
            return localVarFp.apiSettlementGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiSettlementIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [amount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdExpertInvoiceAmountPut(id: number, amount?: number, options?: any): AxiosPromise<SettlementDto> {
            return localVarFp.apiSettlementIdExpertInvoiceAmountPut(id, amount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [invoiceNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdExpertInvoicePut(id: number, invoiceNumber?: string, options?: any): AxiosPromise<SettlementDto> {
            return localVarFp.apiSettlementIdExpertInvoicePut(id, invoiceNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdGet(id: number, options?: any): AxiosPromise<SettlementDetailsDto> {
            return localVarFp.apiSettlementIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SettlementCreateDto} [settlementCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdMinimaleditPut(id: number, settlementCreateDto?: SettlementCreateDto, options?: any): AxiosPromise<SettlementDetailsDto> {
            return localVarFp.apiSettlementIdMinimaleditPut(id, settlementCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [notes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdNotesPut(id: number, notes?: string, options?: any): AxiosPromise<SettlementDto> {
            return localVarFp.apiSettlementIdNotesPut(id, notes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SettlementUpdateDto} [settlementUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdPatch(id: number, settlementUpdateDto?: SettlementUpdateDto, options?: any): AxiosPromise<SettlementDetailsDto> {
            return localVarFp.apiSettlementIdPatch(id, settlementUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdPayBySavedCardPut(id: number, options?: any): AxiosPromise<SettlementDetailsDto> {
            return localVarFp.apiSettlementIdPayBySavedCardPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PaymentStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdPaymentClientPut(id: number, status?: PaymentStatus, options?: any): AxiosPromise<SettlementDto> {
            return localVarFp.apiSettlementIdPaymentClientPut(id, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdPaymentDatePut(id: number, date?: string, options?: any): AxiosPromise<SettlementDetailsDto> {
            return localVarFp.apiSettlementIdPaymentDatePut(id, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PaymentStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdPaymentExpertPut(id: number, status?: PaymentStatus, options?: any): AxiosPromise<SettlementDetailsDto> {
            return localVarFp.apiSettlementIdPaymentExpertPut(id, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PaymentStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdPaymentLeadPut(id: number, status?: PaymentStatus, options?: any): AxiosPromise<SettlementDetailsDto> {
            return localVarFp.apiSettlementIdPaymentLeadPut(id, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PaymentStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdPaymentLeaderPut(id: number, status?: PaymentStatus, options?: any): AxiosPromise<SettlementDetailsDto> {
            return localVarFp.apiSettlementIdPaymentLeaderPut(id, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdPaymentLinkGet(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.apiSettlementIdPaymentLinkGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PaymentStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdPaymentSalesPut(id: number, status?: PaymentStatus, options?: any): AxiosPromise<SettlementDetailsDto> {
            return localVarFp.apiSettlementIdPaymentSalesPut(id, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SettlementUpdateDto} [settlementUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdPut(id: number, settlementUpdateDto?: SettlementUpdateDto, options?: any): AxiosPromise<SettlementDetailsDto> {
            return localVarFp.apiSettlementIdPut(id, settlementUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIdSendNotificationPut(id: number, options?: any): AxiosPromise<SettlementDto> {
            return localVarFp.apiSettlementIdSendNotificationPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIncomeCaseIdGet(caseId: number, options?: any): AxiosPromise<Array<SettlementIncomeDto>> {
            return localVarFp.apiSettlementIncomeCaseIdGet(caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementIncomeGet(options?: any): AxiosPromise<Array<SettlementIncomeDto>> {
            return localVarFp.apiSettlementIncomeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementOverdueGet(options?: any): AxiosPromise<Array<OverdueSettlementDto>> {
            return localVarFp.apiSettlementOverdueGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementPaymentMethodsGet(options?: any): AxiosPromise<PayUAvailablePayMethods> {
            return localVarFp.apiSettlementPaymentMethodsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SettlementPaymentRequestDto} [settlementPaymentRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementPaymentPost(settlementPaymentRequestDto?: SettlementPaymentRequestDto, options?: any): AxiosPromise<SettlementPaymentResponseDto> {
            return localVarFp.apiSettlementPaymentPost(settlementPaymentRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SettlementCreateDto} [settlementCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementPost(settlementCreateDto?: SettlementCreateDto, options?: any): AxiosPromise<SettlementDetailsDto> {
            return localVarFp.apiSettlementPost(settlementCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [fingerprint] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementSaveCardPost(token?: string, fingerprint?: string, options?: any): AxiosPromise<PayUOrderResponse> {
            return localVarFp.apiSettlementSaveCardPost(token, fingerprint, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SettlementStatus} [status] 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementSettlementStatusPut(status?: SettlementStatus, id?: number, options?: any): AxiosPromise<SettlementDto> {
            return localVarFp.apiSettlementSettlementStatusPut(status, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [caseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementSettlementsForCaseCaseIdGet(caseId?: number, options?: any): AxiosPromise<Array<SettlementDto>> {
            return localVarFp.apiSettlementSettlementsForCaseCaseIdGet(caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementSettlementsForContextUserGet(options?: any): AxiosPromise<Array<SettlementDto>> {
            return localVarFp.apiSettlementSettlementsForContextUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettlementUnpaidGet(options?: any): AxiosPromise<Array<SettlementDto>> {
            return localVarFp.apiSettlementUnpaidGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettlementApi - object-oriented interface
 * @export
 * @class SettlementApi
 * @extends {BaseAPI}
 */
export class SettlementApi extends BaseAPI {
    /**
     * 
     * @param {number} caseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementClientCaseIdGet(caseId: number, options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementClientCaseIdGet(caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementClientGet(options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementClientGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementDeleteCardDelete(token?: string, options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementDeleteCardDelete(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementGet(options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementIdDelete(id: number, options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [amount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementIdExpertInvoiceAmountPut(id: number, amount?: number, options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementIdExpertInvoiceAmountPut(id, amount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [invoiceNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementIdExpertInvoicePut(id: number, invoiceNumber?: string, options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementIdExpertInvoicePut(id, invoiceNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementIdGet(id: number, options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SettlementCreateDto} [settlementCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementIdMinimaleditPut(id: number, settlementCreateDto?: SettlementCreateDto, options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementIdMinimaleditPut(id, settlementCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [notes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementIdNotesPut(id: number, notes?: string, options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementIdNotesPut(id, notes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SettlementUpdateDto} [settlementUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementIdPatch(id: number, settlementUpdateDto?: SettlementUpdateDto, options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementIdPatch(id, settlementUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementIdPayBySavedCardPut(id: number, options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementIdPayBySavedCardPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PaymentStatus} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementIdPaymentClientPut(id: number, status?: PaymentStatus, options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementIdPaymentClientPut(id, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementIdPaymentDatePut(id: number, date?: string, options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementIdPaymentDatePut(id, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PaymentStatus} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementIdPaymentExpertPut(id: number, status?: PaymentStatus, options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementIdPaymentExpertPut(id, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PaymentStatus} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementIdPaymentLeadPut(id: number, status?: PaymentStatus, options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementIdPaymentLeadPut(id, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PaymentStatus} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementIdPaymentLeaderPut(id: number, status?: PaymentStatus, options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementIdPaymentLeaderPut(id, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementIdPaymentLinkGet(id: number, options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementIdPaymentLinkGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PaymentStatus} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementIdPaymentSalesPut(id: number, status?: PaymentStatus, options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementIdPaymentSalesPut(id, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SettlementUpdateDto} [settlementUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementIdPut(id: number, settlementUpdateDto?: SettlementUpdateDto, options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementIdPut(id, settlementUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementIdSendNotificationPut(id: number, options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementIdSendNotificationPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} caseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementIncomeCaseIdGet(caseId: number, options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementIncomeCaseIdGet(caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementIncomeGet(options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementIncomeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementOverdueGet(options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementOverdueGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementPaymentMethodsGet(options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementPaymentMethodsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SettlementPaymentRequestDto} [settlementPaymentRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementPaymentPost(settlementPaymentRequestDto?: SettlementPaymentRequestDto, options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementPaymentPost(settlementPaymentRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SettlementCreateDto} [settlementCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementPost(settlementCreateDto?: SettlementCreateDto, options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementPost(settlementCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [token] 
     * @param {string} [fingerprint] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementSaveCardPost(token?: string, fingerprint?: string, options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementSaveCardPost(token, fingerprint, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SettlementStatus} [status] 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementSettlementStatusPut(status?: SettlementStatus, id?: number, options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementSettlementStatusPut(status, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [caseId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementSettlementsForCaseCaseIdGet(caseId?: number, options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementSettlementsForCaseCaseIdGet(caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementSettlementsForContextUserGet(options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementSettlementsForContextUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    public apiSettlementUnpaidGet(options?: any) {
        return SettlementApiFp(this.configuration).apiSettlementUnpaidGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StaticFilesApi - axios parameter creator
 * @export
 */
export const StaticFilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaticFilesPremieOd14032024PdfGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StaticFiles/premie-od-14.03.2024.pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StaticFilesApi - functional programming interface
 * @export
 */
export const StaticFilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StaticFilesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStaticFilesPremieOd14032024PdfGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStaticFilesPremieOd14032024PdfGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StaticFilesApi - factory interface
 * @export
 */
export const StaticFilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StaticFilesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaticFilesPremieOd14032024PdfGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiStaticFilesPremieOd14032024PdfGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StaticFilesApi - object-oriented interface
 * @export
 * @class StaticFilesApi
 * @extends {BaseAPI}
 */
export class StaticFilesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaticFilesApi
     */
    public apiStaticFilesPremieOd14032024PdfGet(options?: any) {
        return StaticFilesApiFp(this.configuration).apiStaticFilesPremieOd14032024PdfGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubscriptionDefinitionApi - axios parameter creator
 * @export
 */
export const SubscriptionDefinitionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionDefinitionGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SubscriptionDefinition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionDefinitionIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSubscriptionDefinitionIdDelete', 'id', id)
            const localVarPath = `/api/SubscriptionDefinition/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionDefinitionIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSubscriptionDefinitionIdGet', 'id', id)
            const localVarPath = `/api/SubscriptionDefinition/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SubscriptionDefinitionUpdateDto} [subscriptionDefinitionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionDefinitionIdPatch: async (id: number, subscriptionDefinitionUpdateDto?: SubscriptionDefinitionUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSubscriptionDefinitionIdPatch', 'id', id)
            const localVarPath = `/api/SubscriptionDefinition/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscriptionDefinitionUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SubscriptionDefinitionUpdateDto} [subscriptionDefinitionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionDefinitionIdPut: async (id: number, subscriptionDefinitionUpdateDto?: SubscriptionDefinitionUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSubscriptionDefinitionIdPut', 'id', id)
            const localVarPath = `/api/SubscriptionDefinition/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscriptionDefinitionUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SubscriptionDefinitionCreateDto} [subscriptionDefinitionCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionDefinitionPost: async (subscriptionDefinitionCreateDto?: SubscriptionDefinitionCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SubscriptionDefinition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscriptionDefinitionCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionDefinitionApi - functional programming interface
 * @export
 */
export const SubscriptionDefinitionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionDefinitionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionDefinitionGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubscriptionDefinitionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscriptionDefinitionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionDefinitionIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscriptionDefinitionIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionDefinitionIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionDefinitionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscriptionDefinitionIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SubscriptionDefinitionUpdateDto} [subscriptionDefinitionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionDefinitionIdPatch(id: number, subscriptionDefinitionUpdateDto?: SubscriptionDefinitionUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionDefinitionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscriptionDefinitionIdPatch(id, subscriptionDefinitionUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SubscriptionDefinitionUpdateDto} [subscriptionDefinitionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionDefinitionIdPut(id: number, subscriptionDefinitionUpdateDto?: SubscriptionDefinitionUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionDefinitionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscriptionDefinitionIdPut(id, subscriptionDefinitionUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SubscriptionDefinitionCreateDto} [subscriptionDefinitionCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionDefinitionPost(subscriptionDefinitionCreateDto?: SubscriptionDefinitionCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionDefinitionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscriptionDefinitionPost(subscriptionDefinitionCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubscriptionDefinitionApi - factory interface
 * @export
 */
export const SubscriptionDefinitionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionDefinitionApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionDefinitionGet(options?: any): AxiosPromise<Array<SubscriptionDefinitionDto>> {
            return localVarFp.apiSubscriptionDefinitionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionDefinitionIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiSubscriptionDefinitionIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionDefinitionIdGet(id: number, options?: any): AxiosPromise<SubscriptionDefinitionDetailsDto> {
            return localVarFp.apiSubscriptionDefinitionIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SubscriptionDefinitionUpdateDto} [subscriptionDefinitionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionDefinitionIdPatch(id: number, subscriptionDefinitionUpdateDto?: SubscriptionDefinitionUpdateDto, options?: any): AxiosPromise<SubscriptionDefinitionDetailsDto> {
            return localVarFp.apiSubscriptionDefinitionIdPatch(id, subscriptionDefinitionUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SubscriptionDefinitionUpdateDto} [subscriptionDefinitionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionDefinitionIdPut(id: number, subscriptionDefinitionUpdateDto?: SubscriptionDefinitionUpdateDto, options?: any): AxiosPromise<SubscriptionDefinitionDetailsDto> {
            return localVarFp.apiSubscriptionDefinitionIdPut(id, subscriptionDefinitionUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SubscriptionDefinitionCreateDto} [subscriptionDefinitionCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionDefinitionPost(subscriptionDefinitionCreateDto?: SubscriptionDefinitionCreateDto, options?: any): AxiosPromise<SubscriptionDefinitionDetailsDto> {
            return localVarFp.apiSubscriptionDefinitionPost(subscriptionDefinitionCreateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriptionDefinitionApi - object-oriented interface
 * @export
 * @class SubscriptionDefinitionApi
 * @extends {BaseAPI}
 */
export class SubscriptionDefinitionApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionDefinitionApi
     */
    public apiSubscriptionDefinitionGet(options?: any) {
        return SubscriptionDefinitionApiFp(this.configuration).apiSubscriptionDefinitionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionDefinitionApi
     */
    public apiSubscriptionDefinitionIdDelete(id: number, options?: any) {
        return SubscriptionDefinitionApiFp(this.configuration).apiSubscriptionDefinitionIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionDefinitionApi
     */
    public apiSubscriptionDefinitionIdGet(id: number, options?: any) {
        return SubscriptionDefinitionApiFp(this.configuration).apiSubscriptionDefinitionIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SubscriptionDefinitionUpdateDto} [subscriptionDefinitionUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionDefinitionApi
     */
    public apiSubscriptionDefinitionIdPatch(id: number, subscriptionDefinitionUpdateDto?: SubscriptionDefinitionUpdateDto, options?: any) {
        return SubscriptionDefinitionApiFp(this.configuration).apiSubscriptionDefinitionIdPatch(id, subscriptionDefinitionUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SubscriptionDefinitionUpdateDto} [subscriptionDefinitionUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionDefinitionApi
     */
    public apiSubscriptionDefinitionIdPut(id: number, subscriptionDefinitionUpdateDto?: SubscriptionDefinitionUpdateDto, options?: any) {
        return SubscriptionDefinitionApiFp(this.configuration).apiSubscriptionDefinitionIdPut(id, subscriptionDefinitionUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SubscriptionDefinitionCreateDto} [subscriptionDefinitionCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionDefinitionApi
     */
    public apiSubscriptionDefinitionPost(subscriptionDefinitionCreateDto?: SubscriptionDefinitionCreateDto, options?: any) {
        return SubscriptionDefinitionApiFp(this.configuration).apiSubscriptionDefinitionPost(subscriptionDefinitionCreateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


